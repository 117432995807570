/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ComponentDimensions,
  ContentEpisode,
  ContentItem,
  ContentSeason,
  CoordinateDimensions,
  Coordinated,
  Coords,
  Finals,
  GridPosition,
  ID,
  ListPosition,
  MediaDetailsCore,
  MediaDetailsExtra,
  isMediaDetailsExtra,
} from '@adiffengine/engine-types'
import { Lightning, Router } from '@lightningjs/sdk'
import defer from 'lodash-es/defer'
import isFinite from 'lodash-es/isFinite'
import isFunction from 'lodash-es/isFunction'
import isNumber from 'lodash-es/isNumber'
import isString from 'lodash-es/isString'
import { Debugger } from './debugger'
// @ts-ignore
const debug = new Debugger('lightning-tools')

export interface ParsedMedia {
  content?: MediaDetailsCore
  trailer?: MediaDetailsExtra
  extras?: MediaDetailsExtra[]
}
export const cp = (x: number) => x
export const copyParent = {
  x: cp,
  h: cp,
  w: cp,
  y: cp,
}
export function passSignal(
  s?: true | false | null | undefined | void,
  fallback = false,
) {
  return s === true || s === false ? s : fallback
}

export function firstSeason(
  seasons?: ContentSeason[] | null,
): ContentSeason | null {
  if (seasons == null || seasons.length === 0) return null
  else if (seasons.length === 1) return seasons[0] as ContentSeason
  const hasNumbers = seasons.reduce(
    (acc, s) => acc === true || isNumber(s.number),
    false,
  )
  if (hasNumbers) {
    const first = seasons.sort(
      (a, b) => (a.number ?? Infinity) - (b.number ?? Infinity),
    )[0]
    return first as ContentSeason
  }
  return seasons[0] as ContentSeason
}

export function firstEpisode(
  episodes?: ContentEpisode[] | null,
): ContentEpisode | null {
  if (episodes == null || episodes.length === 0) return null
  else if (episodes.length === 1) return episodes[0] as ContentEpisode
  const hasNumbers = episodes.reduce(
    (acc, s) => !!(acc || isNumber(s.episodeNumber)),
    false,
  )
  if (hasNumbers) {
    const first = episodes.sort(
      (a, b) => (a.episodeNumber ?? Infinity) - (b.episodeNumber ?? Infinity),
    )[0]
    return first as ContentEpisode
  }
  return episodes[0] as ContentEpisode
}

export function parseMedia(media: ContentItem['media']): ParsedMedia | null {
  if (!media) return null
  const out = media.reduce((acc, item) => {
    switch (item.type) {
      case 'movie':
      case 'episode':
        acc.content = item
        break
      case 'extra':
        acc.extras =
          Array.isArray(acc.extras) && isMediaDetailsExtra(item)
            ? ([...acc.extras, item] as MediaDetailsExtra[])
            : ([item] as MediaDetailsExtra[])
    }
    return acc
  }, {} as ParsedMedia) as ParsedMedia
  return out
}
export function isID(x: any): x is ID {
  return (x && isNumber(x)) || isString(x)
}

export function defaultBackHandler(path = 'home'): boolean {
  const history = Router.getHistory()

  if (history.length === 0) {
    defer(() => {
      Router.navigate(path.replace(/^\//, ''), false)
    })
    return true
  }
  return false
}

export function hasFinals(x: any): x is Finals {
  return (
    x &&
    isNumber(x.finalH) &&
    isNumber(x.finalW) &&
    isNumber(x.finalX) &&
    isNumber(x.finalY)
  )
}
export function hasCoords(item: any): item is Coords {
  return item && isNumber(item.finalX) && isNumber(item.finalY)
}

export function getCenterCoordinates(
  item: any,
  useFinals = false,
): CoordinateDimensions | null {
  if (item && hasFinals(item) && hasCoords(item)) {
    const coords = getCoords(item, { x: 0, y: 0 }, useFinals)
    return { ...coords, width: item.finalW, height: item.finalH }
  }
  return null
}
export function finalsToCoordinateDimensions(x: Finals) {
  return { x: x.finalX, y: x.finalY, height: x.finalH, width: x.finalW }
}

export interface CoordinateList {
  setIndex: (x: number) => void
  items: any[]
  index: number
  _lastIndex: undefined | number | null
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isCoordinateList = (x: any): x is CoordinateList =>
  x && isFunction(x.setIndex) && Array.isArray(x.items)

export function trackerId(string: string) {
  let out = string.replace(/#[0-9]*/g, '')
  out = out.replace(':', '')
  return out
}

export const centerX = (dims: CoordinateDimensions) => dims.x + dims.width / 2
export const centerY = (dims: CoordinateDimensions) => dims.y + dims.height / 2
export function getClosestIndexByDimension(
  dimension: 'x' | 'y',
  coords: CoordinateDimensions,
  list: Coordinated[],
  useFinals?: boolean,
) {
  if (useFinals === undefined) {
    useFinals = list.slice(0, 3).reduce((acc, item) => {
      return acc || item.x !== item.finalX
    }, false)
  }
  const coordinator = dimension === 'x' ? centerX : centerY
  const x = coordinator(coords)
  const closest = list.reduce(
    (acc, c, index) => {
      if (validateCoordinated(c)) {
        const testCoords = getCoordinateDimensions(c, useFinals)
        const testX = coordinator(testCoords)
        const distance = Math.abs(x - testX)
        if (distance < acc.distance || acc.current === -1) {
          acc.distance = distance
          acc.current = index
        }
      }
      return acc
    },
    {
      current: -1,
      distance: Infinity,
    } as { current: number; distance: number },
  )
  return closest.current >= 0 ? closest.current : null
}

export function getClosestIndexByX(
  coords: CoordinateDimensions,
  list: Coordinated[],
  useFinals?: boolean,
) {
  if (Array.isArray(list) && list.length) {
    const closest = getClosestIndexByDimension('x', coords, list, useFinals)
    return closest
  }
  return null
}

export function getClosestIndexByY(
  coords: CoordinateDimensions,
  list: Coordinated[],
  useFinals = false,
) {
  if (Array.isArray(list) && list.length) {
    return getClosestIndexByDimension('y', coords, list, useFinals)
  }
  return null
}

export function setClosestInList(
  coords: CoordinateDimensions,
  list: any,
  useFinals = false,
) {
  if (isCoordinateList(list)) {
    let currentDistance = Infinity
    let currentIndex = -1
    if (isGoodNumber(list._lastIndex, true)) {
      currentIndex = list._lastIndex
    } else {
      const currentCoords = getCenterCoordinates(coords, useFinals)

      if (currentCoords) {
        list.items.forEach((item: any, idx: number) => {
          const itemCoords = getCenterCoordinates(item, useFinals)
          if (itemCoords) {
            const distance = distanceBetween(currentCoords, itemCoords)
            if (distance < currentDistance) {
              currentDistance = distance
              currentIndex = idx
            }
          }
        })
      }
      if (
        currentIndex >= 0 &&
        currentIndex < list.items.length - 1 &&
        list.index !== currentIndex
      )
        list.setIndex(currentIndex)
    }
  } else {
    console.warn('Not a coordinate list')
  }
}

export function removeNullable<T extends Record<string, unknown>>(
  item: T,
): NonNullable<T> {
  return (Object.entries(item) as Array<[keyof T, T[keyof T]]>).reduce(
    (acc: Partial<T>, [key, value]) => {
      if (item[key] != null) acc[key] = value
      return acc
    },
    {} as Partial<T>,
  ) as NonNullable<T>
}

export const PrettyTimeDefaultOptions = {
  showZeroHour: false,
}
export type PrettyTimeOptions = typeof PrettyTimeDefaultOptions
export function msLessThanHour(ms: number) {
  return ms !== Infinity && ms < 1000 * 60 * 60
}
export function prettyTime(
  value: number,
  opts: Partial<PrettyTimeOptions> = {},
) {
  const { showZeroHour } = { ...PrettyTimeDefaultOptions, ...opts }
  if (isGoodNumber(value)) {
    if (value > 432000) value = value / 1000
    const hours = Math.floor(value / 60 / 60),
      minutes = Math.floor((value - hours * 60 * 60) / 60),
      seconds = Math.round(value - hours * 60 * 60 - minutes * 60)

    const hoursString = hours > 0 && showZeroHour ? `${hours}:` : ``

    return (
      hoursString +
      (minutes < 10 ? '0' + minutes : minutes) +
      ':' +
      (seconds < 10 ? '0' + seconds : seconds)
    )
  } else {
    console.warn('Got a bad value for pretty time', value)
    return `00:00:00`
  }
}
export function isSavedPosition(value: any): value is ListPosition {
  return (
    value && isGoodNumber(value.position) && isGoodNumber(value.index, true)
  )
}
export function isGoodNumber(value: any, positive = false): value is number {
  return isNumber(value) && isFinite(value) && (!positive || value >= 0)
}
export function isGoodString(value: any): value is string {
  return isString(value) && value.trim().length > 0
}
export function makeId(ci: ContentItem) {
  return `${ci.id}:${ci.type}`
}
export function distanceBetween(
  a: CoordinateDimensions,
  b: CoordinateDimensions,
) {
  return distance(itemCenter(a), itemCenter(b))
}
export function distance(a: Coords, b: Coords) {
  return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2))
}
export function itemCenter(a: CoordinateDimensions): Coords {
  return {
    x: (a.x + a.width) / 2,
    y: (a.y + a.height) / 2,
  }
}
interface ValidCoorindated extends Coordinated {
  x: number
  y: number
}
function validateCoordinated(
  x: Coordinated,
  shout = false,
): x is ValidCoorindated {
  if (!x || !isNumber(x.x) || !isNumber(x.y)) {
    if (shout) console.warn('Not Coordinated', x)
    return false
  }
  return true
}
interface ValidFinalCoorindated extends Coordinated {
  finalX: number
  finalY: number
}
function validateFinalCoordinated(
  x: Coordinated,
  shout = false,
): x is ValidFinalCoorindated {
  if (!isNumber(x.finalX) || !isNumber(x.finalY)) {
    if (shout) console.warn('Not Coordinated', x)
    return false
  }
  return true
}

export function getCoords(
  coordinated: Coordinated,
  current: Coords = { x: 0, y: 0 },
  useFinals = false,
): Coords {
  if (useFinals && validateFinalCoordinated(coordinated)) {
    current.x += coordinated.finalX
    current.y += coordinated.finalY
  } else if (validateCoordinated(coordinated)) {
    current.x += coordinated.x
    current.y += coordinated.y
  }
  if (coordinated.isRoot === false && coordinated.parent)
    return getCoords(coordinated.parent, current, useFinals)
  else return current
}

export function getCoordinateDimensions(
  coordinated: Coordinated,
  useFinals = false,
): CoordinateDimensions {
  const coords = getCoords(coordinated, { x: 0, y: 0 }, useFinals)

  const out = {
    ...coords,
    width: isGoodNumber(coordinated.finalW, true) ? coordinated.finalW : 0,
    height: isGoodNumber(coordinated.finalH, true) ? coordinated.finalH : 0,
  }
  return out
}
export function getPatchFromCoordinates(
  coords: CoordinateDimensions,
): ComponentDimensions {
  const { width, height, ...axis } = coords
  return {
    w: width,
    h: height,
    ...axis,
  }
}
export function getCoordindatesFromPatch(
  coords: ComponentDimensions,
): CoordinateDimensions {
  const { w, h, ...axis } = coords
  return {
    width: w,
    height: h,
    ...axis,
  }
}
export function isComponentDimensions(
  x: ComponentDimensions | CoordinateDimensions,
): x is ComponentDimensions {
  return (x as ComponentDimensions).w !== undefined
}

export function normalizeDimensions(
  x: ComponentDimensions | CoordinateDimensions,
): ComponentDimensions {
  return isComponentDimensions(x) ? x : getPatchFromCoordinates(x)
}
export function expandHorizontal(
  coords: CoordinateDimensions | ComponentDimensions,
  amount: number,
) {
  coords = normalizeDimensions(coords)
  return {
    ...coords,
    x: coords.x - amount,
    w: coords.w + amount * 2,
  }
}
export function expandVertical(
  coords: CoordinateDimensions | ComponentDimensions,
  amount: number,
) {
  coords = normalizeDimensions(coords)
  return {
    ...coords,
    y: coords.y - amount,
    h: coords.h + amount * 2,
  }
}

export function expandDimensions(
  coords: CoordinateDimensions | ComponentDimensions,
  amount: number,
  returnCoords: true,
): CoordinateDimensions
export function expandDimensions(
  coords: CoordinateDimensions | ComponentDimensions,
  amount: number,
  returnCoords: false,
): ComponentDimensions
export function expandDimensions(
  coords: CoordinateDimensions | ComponentDimensions,
  amount: number,
  returnCoords?: undefined,
): ComponentDimensions

export function expandDimensions<T extends boolean | undefined = undefined>(
  coords: CoordinateDimensions | ComponentDimensions,
  amount: number,
  returnCoords: T,
): T extends true ? CoordinateDimensions : ComponentDimensions {
  const returnCoordinates = returnCoords === true
  const out = {
    ...expandVertical(coords, amount),
    ...expandHorizontal(coords, amount),
  }
  return (
    returnCoordinates ? getCoordindatesFromPatch(out) : out
  ) as typeof returnCoords extends true
    ? CoordinateDimensions
    : ComponentDimensions
}

export function withinViewport(
  coordinated: Lightning.Component,
  precision = 0.8,
  safeArea = true,
) {
  const safety = safeArea === true ? 80 : 0
  const coordinates = getCoordinateDimensions(coordinated)
  const horizontalOffset =
    (coordinates.width - coordinates.width * precision) / 2
  const verticalOffset =
    (coordinates.height - coordinates.height * precision) / 2
  const startH = 0 + safety
  const startW = 0 + safety
  const finalH = coordinated.stage.h - safety
  const finalW = coordinated.stage.w - safety

  const originalWithinY =
    coordinates.y > 0 && coordinates.y + coordinates.height < finalH
  const originalWithinX =
    coordinates.y > 0 && coordinates.x + coordinates.width < finalW
  const box = {
    verticalOffset,
    horizontalOffset,
    coordinates,
    stage: {
      height: coordinated.stage.h,
      width: coordinated.stage.w,
      finalH,
      finalW,
      startH,
      startW,
    },
    original: {
      withinY: originalWithinY,
      withinX: originalWithinX,
      within: originalWithinX && originalWithinY,
      top: coordinates.y,
      bottom: coordinates.y + coordinates.height,
      left: coordinates.x,
      right: coordinates.x + coordinates.width,
    },
    precise: {
      withinY: false,
      withinX: false,
      within: false,
      top: Math.round(coordinates.y + verticalOffset),
      bottom: Math.round(coordinates.y + coordinates.height - verticalOffset),
      left: Math.round(coordinates.x + horizontalOffset),
      right: Math.round(coordinates.x + horizontalOffset),
    },
  }
  box.precise.withinY = box.precise.top > 0 && box.precise.bottom < finalH
  box.precise.withinX = box.precise.left > 0 && box.precise.right < finalW
  box.precise.within = box.precise.withinX && box.precise.withinY

  return box
}

export function climbForCoordinates(
  coordinated: Coordinated,
): CoordinateDimensions | null {
  const coords = getCoordinateDimensions(coordinated)
  if (coords.width === 0 && coords.height === 0 && coordinated.parent) {
    return climbForCoordinates(coordinated.parent)
  } else if (coords.width === 0 && coords.height === 0) {
    return null
  } else {
    return coords
  }
}

export function isArray<T = any>(x: any, hasLength?: boolean): x is T[] {
  return Array.isArray(x) && (!hasLength || (hasLength && x.length > 0))
}
export function isGridPosition(x: any): x is GridPosition {
  return (
    x &&
    isGoodNumber(x.mainIndex, true) &&
    isGoodNumber(x.crossIndex, true) &&
    isGoodNumber(x.lines, true) &&
    isGoodNumber(x.dataLength, true)
  )
}

export function getBestDimensionValue(
  element: Lightning.Element,
  dim: 'w' | 'h' | 'W' | 'H',
) {
  const dimension = dim.toLowerCase() as 'w' | 'h'
  let baseDim = element[dimension]
  baseDim = isGoodNumber(baseDim) ? baseDim : 0
  let finalDim =
    element[`final${dimension.toUpperCase()}` as 'finalW' | 'finalH']
  finalDim = isGoodNumber(finalDim) ? finalDim : 0
  return finalDim > baseDim ? finalDim : baseDim
}

export function getBestDimensionValues(element: Lightning.Element) {
  return {
    h: getBestDimensionValue(element, 'h'),
    w: getBestDimensionValue(element, 'w'),
  }
}

function hasCollisionSet(e?: Lightning.Element | undefined) {
  return e && ((e as any).collision === 2 || e.collision === true)
}

export function confirmCollisionsAreSet(element?: Lightning.Element) {
  if (!element) return
  if (element.parent && !hasCollisionSet(element.parent)) {
    ;(element.parent as any).collision = 2
  }
  if (element.parent) {
    confirmCollisionsAreSet(element.parent)
  }
}
export function isHandleHoverComponent(
  x: Lightning.Element,
): x is HandleHoverComponent {
  return x && (x as any)._handleHover
}
export function isHandleUnHoverComponent(
  x: Lightning.Element,
): x is HandleUnHoverComponent {
  return x && isFunction((x as any)._handleUnhover)
}

export function registerHoverable(name: string, widget: Lightning.Element) {
  const oldHoverHandler = isHandleHoverComponent(widget)
    ? widget._handleHover.bind(widget)
    : null
  ;(widget as any).__hoverName = `${name}::${widget.id}`
  ;(widget as HandleHoverComponent)._handleHover = function (
    this: typeof widget,
    target?: Lightning.Component,
  ) {
    if (isFireableComponent(this) && this.collision === true) {
      this.fireAncestors('$hovered', widget as Lightning.Component)
    }
    if (oldHoverHandler !== null) oldHoverHandler(target)
  }.bind(widget)
}

interface HandleHoverComponent extends Lightning.Element {
  _handleHover(target?: Lightning.Element): void | boolean
}
interface HandleUnHoverComponent extends Lightning.Element {
  _handleUnhover(target?: Lightning.Element): void | boolean
}

interface HoverNamedComponent extends Lightning.Element {
  __hoverName: string
}
export function isHoverNamedComponent(
  c: Lightning.Element,
): c is HoverNamedComponent {
  return isString((c as HoverNamedComponent).__hoverName)
}

function isFireableComponent(
  element: Lightning.Element,
): element is Lightning.Component {
  return isFunction((element as Lightning.Component).fireAncestors)
}

export function getHoverablePath(
  component: Lightning.Element,
  parts: string[] = [],
) {
  if (isHoverNamedComponent(component)) parts.unshift(component.__hoverName)
  if (component.parent) {
    return getHoverablePath(component.parent, parts)
  } else {
    return parts.join('/')
  }
}
export function climbDimensions(
  e?: Lightning.Element,
  deb = new Debugger('climber'),
) {
  if (e) {
    const coords = getCoordinateDimensions(e)
    deb.info('Coords of %s', e.ref ?? e.id, JSON.stringify(coords, null, 2), e)
    if (e.parent) climbDimensions(e.parent, deb)
  }
}
export function testCollision(
  cursorX: number,
  cursorY: number,
  cx: number,
  cw: number,
  cy: number,
  ch: number,
): boolean {
  return (
    cursorX >= cx && cursorX <= cx + cw && cursorY >= cy && cursorY <= cy + ch
  )
}
