import { Colors, Lightning } from '@lightningjs/sdk'
import isFunction from 'lodash/isFunction'
import { Debugger, isGoodNumber } from '../../lib'
import { getStoredTheme } from '../../themes'
const debug = new Debugger('Visualization')
debug.enabled = true

const sampleDelay = 0.4

export interface VisualizerBarTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Max: object
  Ping: object
  Bar: object
  BottomBar: object
  volume: number
  width: number
  height: number
}

export class VisualizerBar
  extends Lightning.Component<VisualizerBarTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<VisualizerBarTemplateSpec>
{
  static override _template(): Lightning.Component.Template<VisualizerBarTemplateSpec> {
    const theme = getStoredTheme()
    return {
      h: 100,
      w: 20,
      rect: true,
      color: 0x00000000,
      Max: {
        w: 20,
        h: 4,
      },
      Bar: {
        mountY: 1,
        x: 1,
        rect: true,
        color: Colors(theme.palette.highlights[700]).get(),
      },
      BottomBar: {
        mountY: 1,
        x: 4,
        rect: true,
        color: Colors(theme.palette.darks[900]).get(),
      },
      Ping: {
        h: 5,
        w: 30,
        rect: true,
        color: Colors('white').get(),
      },
    }
  }
  private _barAnimation: Lightning.types.Animation | null = null
  private _currentHeight: number = 0

  animateBar(height: number) {
    if (height !== this._currentHeight) {
      this._barAnimation?.finish()
      let bottomBarAnimatedStart = this._currentHeight * 0.7
      if (bottomBarAnimatedStart < 20) bottomBarAnimatedStart = 20
      let bottomBarAnimatedEnd = height * 0.7
      if (bottomBarAnimatedEnd < 20) bottomBarAnimatedEnd = 20

      const diff = Math.min(Math.abs(this._currentHeight - height), 10)
      const jig = 1 + diff / 100
      this._barAnimation = this.animation({
        duration: sampleDelay * 0.95,
        actions: [
          {
            t: 'Bar',
            p: 'h',
            v: {
              0: this._currentHeight,
              0.5: height * jig,
              1: height,
            },
          },
          {
            t: 'BottomBar',
            p: 'h',
            v: {
              0: bottomBarAnimatedStart,
              0.5: bottomBarAnimatedEnd * jig,
              1: bottomBarAnimatedEnd,
            },
          },
          {
            t: 'Ping',
            p: 'y',
            v: {
              0: this._height - (this._currentHeight + 20),

              1: this._height - (height + 20),
            },
          },
        ],
      })

      this._currentHeight = height
      this._barAnimation.start()
    }
  }
  set volume(v: number) {
    const vol = v < 10 ? 10 : v
    const maxHeight = this._height * 0.8
    let height = (maxHeight * vol) / 255
    height = height > maxHeight ? maxHeight : height
    this.animateBar(height)
  }
  private _height: number = 0
  set height(h: number) {
    this._height = h
    this.patch({
      h,
      BottomBar: {
        h: h * 0.1 * 0.8,
        y: h,
      },
      Bar: {
        h: h * 0.1,
        y: h,
      },
    })
    this.getByRef('Bar')!.patch({ y: h })
  }
  set width(w: number) {
    this.patch({
      w,
      Ping: { w },
      Max: { w, visible: false },
      Bar: { w: w - 2 },
      BottomBar: { w: w - 8 },
    })
  }
}

export class Visualizer extends Lightning.Component {
  static override _template() {
    return {
      x: 0,
      y: 0,
      h: 440,
      w: 1920,
      color: 0x00000000,
      alpha: 0.5,
    }
  }

  createBars(width: number = 20) {
    debug.info('Creating bars of width %s', width)
    const regionWidth = isGoodNumber(this.w, true) && this.w > 0 ? this.w : 1920
    const regionHeight =
      isGoodNumber(this.h, true) && this.h > 0 ? this.h : 1080
    const numberOfBars = Math.floor(regionWidth / width)
    const offset = (regionWidth % width) / 2
    for (let x = 0; x < numberOfBars; x++) {
      this.childList.add(
        this.stage.c({
          type: VisualizerBar,
          width: width,
          height: regionHeight,
          x: offset + x * width,
          y: 0,
        }),
      )
    }
  }

  private _bufferLength: number = 0
  setAnalyser(element: HTMLMediaElement): boolean {
    if (
      typeof window.AudioContext === 'undefined' &&
      typeof (window as any).webkitAudioContext === 'undefined'
    ) {
      console.warn('No window.AudioContext')
      return false
    }
    try {
      const audioCtx = new (window.AudioContext ||
        (window as any).webkitAudioContext)()
      this._analyser = audioCtx.createAnalyser()
      this._analyser.minDecibels = -240
      this._analyser.maxDecibels = -50
      const source = audioCtx.createMediaElementSource(element)
      source.connect(this._analyser)
      this._analyser.connect(audioCtx.destination)
      this._analyser.fftSize = 256
      this._bufferLength = this._analyser.frequencyBinCount
      this._frequencyData = new Uint8Array(this._bufferLength)
      const regionWidth =
        isGoodNumber(this.w, true) && this.w > 0 ? this.w : 1920
      const barWidth = regionWidth / this._bufferLength
      this.createBars(barWidth)
      return true
    } catch (error) {
      console.warn('Error creaing audio analyzer %s', error.message)
      return false
    }
  }
  private _analyser: AnalyserNode | null = null
  private _frequencyData: Uint8Array = new Uint8Array()
  override _init() {
    this._frame = this._frame.bind(this)
    this._handlePaused = this._handlePaused.bind(this)
    this._resume = this._resume.bind(this)
  }

  private _elm: HTMLMediaElement | undefined = undefined

  _handlePaused() {
    if (this._frameTimeout) clearTimeout(this._frameTimeout)
    this.patch({ alpha: 0.3 })
  }

  _resume() {
    this.patch({ alpha: 0.5 })
    this._frame()
  }

  registerEventHandlers() {
    if (this._elm) {
      this._elm.addEventListener('pause', this._handlePaused)
      this._elm.addEventListener('playing', this._frame)
    }
  }
  override _enable() {
    this._handlePaused = this._handlePaused.bind(this)
    this.registerEventHandlers()
  }

  set videoElm(elm: HTMLMediaElement) {
    debug.info('Setting video element', elm)
    if (!this._analyser || elm !== this._elm) {
      this._elm = elm
      this.registerEventHandlers()
      const response = this.setAnalyser(elm)
      if (response) {
        this._clearTimeout()
        this._frame()
      }
    }
  }

  private _frameTimeout: ReturnType<typeof setTimeout> | null = null
  private _clearTimeout() {
    if (this._frameTimeout !== null) clearTimeout(this._frameTimeout)
  }
  override _disable() {
    this._clearTimeout()
    if (this._elm) {
      this._elm.removeEventListener('pause', this._handlePaused)
      this._elm.removeEventListener('playing', this._frame)
    }
  }
  _frame() {
    if (!this._elm!.paused && this._analyser) {
      let good = false
      if (isFunction(this._analyser.getByteTimeDomainData)) {
        this._analyser.getByteTimeDomainData(this._frequencyData)
        good = true
      } else if (isFunction(this._analyser.getByteFrequencyData)) {
        this._analyser.getByteFrequencyData(this._frequencyData)
        good = true
      }
      if (good) {
        for (let i = 0; i < this._bufferLength; i++) {
          const volume = this._frequencyData[i]
          const bar = this.childList.getAt(i)
          if (bar) bar.patch({ volume })
        }
        this._clearTimeout()
        this._frameTimeout = setTimeout(() => {
          requestAnimationFrame(this._frame)
        }, sampleDelay * 1000)
      } else {
        console.info('Do not have a good frequency data function.')
      }
    }
  }
}
