// To parse this data:
//
//   import { Convert } from "./file";
//
//   const mrssFeed = Convert.toMrssFeed(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

import { isNumber } from '@adiffengine/elements'
import slugify from 'slugify'

export interface MrssFeedItemInput {
  item_id: number
  lastModified: string
  title: number | string
  showname: string
  playlist: string[] | string
  guid: string
  pubDate: string
  description: string
  keywords: string[]
  'media:thumbnail': MediaThumbnailElement[]
  'media:group': MediaGroup
  'media:category'?: string[]
}

export interface MrssFeedItem {
  item_id: string
  title: string
  showname: string
  playlist: string
  guid: string
  pubDate: Date | null
  lastModified: Date | null
  description: string
  keywords: string[]
  categories: string[]
  thumbnails: MediaThumbnailElement[]
  media: CleanMediaContent[]
  gridId?: string
}
export function parseMrssXmlItems(items: MrssFeedItemInput[]): MrssFeedItem[] {
  return items.map(parseMrss).filter(i => i !== null) as MrssFeedItem[]
}
export function parseMrss(item: MrssFeedItemInput): MrssFeedItem | null {
  const {
    title,
    description,
    playlist = '',
    item_id = '',
    showname = '',
    guid,
  } = item

  const outItem: MrssFeedItem = {
    item_id: `${item_id}`.trim(),
    title: `${title}`.trim(),
    description,
    playlist: Array.isArray(playlist) ? playlist.join('') : playlist,
    showname,
    keywords: parseStringList(item['keywords']),
    pubDate: parseDate(item['pubDate']),
    lastModified: parseDate(item['lastModified']),
    categories: parseStringList(item['media:category']),
    guid: parseString(guid),
    thumbnails: parseThumbs(item['media:thumbnail']),
    media: parseMediaGroup(item['media:group']),
    gridId: slugify(Array.isArray(playlist) ? playlist.join(':') : playlist, {
      lower: true,
      strict: true,
    }),
  }

  if (
    outItem.media.length < 1 ||
    outItem.thumbnails.length < 1 ||
    outItem.item_id.trim() === '' ||
    outItem.title.trim() === ''
  ) {
    return null
  }

  return outItem
}
export function parseThumbs(x: any) {
  x = Array.isArray(x) ? x : [x]
  return x.filter((y: unknown) => isValidThumb(y))
}
export function parseString(x: any): string {
  if (!x) return ''
  if (Array.isArray(x)) return x.map(x => parseString(x)).join('')
  else if (x && x['$text']) return parseString(x['$text'])
  else return `${x}`.trim()
}
export function parseStringList(x: any): string[] {
  x = parseString(x)
  return x.split(/\s+/)
}
export function parseDate(x: any): Date | null {
  x = parseString(x)
  const d = new Date()
  if (isNaN(d.getTime())) return null
  else return d
}

export interface GUID {
  $text: string
  isPermaLink: boolean
}

export enum MediaCategoryElement {
  HipHopRap = 'Hip Hop/Rap',
  Latin = 'Latin',
  LatinUrban = 'Latin Urban',
  Pop = 'Pop',
  RBSoul = 'R&B/Soul',
  Reggae = 'Reggae',
  RegionalMexicano = 'Regional Mexicano',
  Rock = 'Rock',
  Urban = 'Urban',
}

export interface MediaCreditElement {
  $text: string
  role: Role
}

export enum Role {
  Featuring = 'Featuring',
  Primary = 'Primary',
}

export interface MediaGroup {
  'media:content': MediaContent[]
}

export interface MediaContent {
  bitrate?: number | string
  duration?: number
  height?: number | string
  medium: Medium
  type: MediaType
  url: string
  width?: number | string
}

export enum Lang {
  En = 'en',
  Es = 'es',
}

export enum Medium {
  Video = 'video',
}

export enum MediaType {
  VideoHLS = 'video/hls',
  VideoMp4 = 'video/mp4',
}

export interface MediaThumbnailElement {
  height: number
  url: string
  width: number
}
export interface MediaContent {
  bitrate?: number | string
  duration?: number
  height?: number | string
  medium: Medium
  type: MediaType
  url: string
  width?: number | string
}
export interface CleanMediaContent {
  bitrate?: number | null
  duration?: number
  height?: number | null
  medium: Medium
  type: MediaType
  url: string
  width?: number | null
}
export function isValidMedia(elm: any): elm is MediaContent {
  if (!elm) return false
  return (
    (elm.type === MediaType.VideoHLS || elm.type === MediaType.VideoMp4) &&
    elm.medium === 'video' &&
    isString(elm.url)
  )
}
export function cleanMedia(item: MediaContent): CleanMediaContent {
  let { width = '', height = '', bitrate = '', ...rest } = item
  width = parseInt(`${width}`, 10)
  height = parseInt(`${height}`, 10)
  bitrate = parseInt(`${bitrate}`, 10)
  return {
    width: isNaN(width) ? null : width,
    height: isNaN(height) ? null : height,
    bitrate: isNaN(bitrate) ? null : bitrate,
    ...rest,
  }
}
export function parseMediaGroup(group: any): CleanMediaContent[] {
  return parseMedia(group['media:content'])
}

export function parseMedia(x: any): CleanMediaContent[] {
  if (x == null) return []
  x = Array.isArray(x) ? x : [x]
  return x.filter(isValidMedia).map(cleanMedia)
}
export function isValidThumb(elm: any): elm is MediaThumbnailElement {
  if (!elm) return false
  return isNumber(elm.height) && isString(elm.url) && isNumber(elm.width)
}

export function isString(val: any): val is string {
  return typeof val === 'string' || val instanceof String
}
export function parseDurationNumber(media: CleanMediaContent[]): number | null {
  const durations = media
    .filter(({ duration }) => isNumber(duration))
    .map(({ duration }) => duration) as number[]
  return durations[0] ?? null
}
