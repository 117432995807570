import { Launch } from '@lightningjs/sdk'
import { App } from './mitu_app'

interface InspectorWindow extends Window {
  attachInspector(lightning: unknown): void
  lng: unknown
}
function isInspectorWindow(window: Window): window is InspectorWindow {
  return (window as any).attachInspector != null && (window as any).lng != null
}

export default function LaunchApp(
  appSettings: any,
  platformSettings: any,
  appData = {},
) {
  console.info(
    'Launching with settings',
    JSON.stringify({ appSettings, platformSettings, appData }, null, 2),
  )
  if (
    platformSettings.inspector?.toString() === 'true' &&
    isInspectorWindow(window)
  ) {
    window.attachInspector(window.lng)
  }
  return Launch(App, appSettings, platformSettings, appData)
}
