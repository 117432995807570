import {
  ContentEpisode,
  ContentItem,
  ContentSeason,
  isID,
  NavigationPayload,
} from '@adiffengine/engine-types'
import { Lightning } from '@lightningjs/sdk'
import {
  Debugger,
  defaultBackHandler,
  firstEpisode,
  firstSeason,
  isGoodArray,
  isGoodNumber,
} from '../lib'

import { SeasonDetailsInnerPage } from '../components'
import { DetailsHero, isDetailsHero } from '../components/AdeDetailsHero'
import { CastDetailsInnerPage } from '../components/CastDetailsInnerPage'
import { isPagingWindow, PagingWindow } from '../components/PagingWindow'
import { RelatedGrid } from '../components/RelatedGrid'
import { isTabBar, TabBar } from '../components/TabBar'
import { isNavigationPayload } from '../lib'

const debug = new Debugger('DP')

export interface TvDetailsPageTypeConfig
  extends Lightning.Component.TypeConfig {
  IsPage: true
}
export interface TvDetailsPageTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Pager: typeof PagingWindow
}

export class TvDetailsPage
  extends Lightning.Component<
    TvDetailsPageTemplateSpec,
    TvDetailsPageTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<TvDetailsPageTemplateSpec>
{
  private _content: ContentItem | null | undefined
  Pager = this.tag('Pager')!
  static override _template(): Lightning.Component.Template<TvDetailsPageTemplateSpec> {
    return {
      x: 0,
      y: 0,
      w: 1920,
      h: 1080,
      Pager: {
        x: 0,
        y: 0,
        h: 1080,
        w: 1920,
        direction: 'column',
        type: PagingWindow,
      },
    }
  }
  _tabBarChanged(_item: string, index: number) {
    if (
      isGoodNumber(this.lowerWindow?.index) &&
      this.lowerWindow?.index !== index
    ) {
      this.lowerWindow!.patch({
        index,
      })
    }
  }
  _pagerUp() {
    this.Pager.patch({
      index: this.Pager.index - 1,
    })
  }
  _pagerDown() {
    this.Pager.patch({
      index: this.Pager.index + 1,
    })
  }

  public inWatchlist: boolean = false

  get hero(): DetailsHero | undefined {
    const out = this.Pager.childList.getAt(0)
    return isDetailsHero(out) ? out : undefined
  }

  get lowerWindow(): PagingWindow | undefined {
    const out = this.Pager.children.find(x => isPagingWindow(x))
    return (out as PagingWindow) ?? undefined
  }
  get tabBar(): TabBar | undefined {
    const out = this.Pager.children.find(x => isTabBar(x))
    return (out as TabBar) ?? undefined
  }

  patchPage() {
    if (this.content) {
      const tabBar: string[] = []
      const lowerItems: Lightning.Element.PatchTemplate[] = []
      if (isGoodArray(this.content.seasons)) {
        tabBar.push('SEASONS')
        lowerItems.push({
          type: SeasonDetailsInnerPage,
          height: 880,
          width: 1920,
          seasons: this.content.seasons,
          signals: {
            episodeSelected: this.episodeSelected.bind(this),
            up: this._pagerUp.bind(this),
            right: this.detailsRight.bind(this),
            left: this.detailsLeft.bind(this),
          },
        })
      }
      if (isGoodArray(this.content.similar)) {
        let similar = [...this.content.similar]
        if (similar.length < 8 && Array.isArray(this.content.recommendations)) {
          const similarIds = similar.map(({ id }) => id)
          const clean = this.content.recommendations.filter(
            ({ id }) => !similarIds.includes(id),
          )
          similar = similar.concat(clean)
        }
        lowerItems.push({
          type: RelatedGrid,
          h: 880,
          w: 1920,
          content: similar.filter(c => {
            const imageUrl = c.images['wide']?.getForWidth(200)
            return imageUrl !== undefined
          }),
          gridPosition: {
            x: 80,
            y: 80,
          },
          signals: {
            up: this._pagerUp.bind(this),
            right: this.detailsRight.bind(this),
            left: this.detailsLeft.bind(this),
            contentSelected: this.moreContentSelected.bind(this),
          },
        })
        tabBar.push('RELATED')
      }
      if (isGoodArray(this.content.cast)) {
        lowerItems.push({
          type: CastDetailsInnerPage,
          h: 880,
          w: 1920,
          cast: Array.isArray(this.content.cast) ? this.content.cast : [],
          signals: {
            up: this._pagerUp.bind(this),
            right: this.detailsRight.bind(this),
            left: this.detailsLeft.bind(this),
          },
        })
        tabBar.push('CAST')
      }
      const pagerItems: Lightning.Element.PatchTemplate[] = [
        {
          h: 800,
          w: 1920,
          offset: 80,
          type: DetailsHero,
          inWatchlist: this.inWatchlist,
          content: this.content,
          hideExtraButtons: true,
          signals: {
            down: this._pagerDown.bind(this),
          },
        },
      ]
      if (tabBar.length > 0) {
        pagerItems.push({
          h: 120,
          w: 1920 - 160,
          x: 80,
          type: TabBar,
          offset: 200,
          items: tabBar,
          signals: {
            up: this._pagerUp.bind(this),
            down: this._pagerDown.bind(this),
            selected: this._tabBarChanged.bind(this),
          },
        })
        pagerItems.push({
          h: 800,
          w: 1920,
          offset: 160,
          resetListOnBlur: true,
          type: PagingWindow,
          items: lowerItems,
          direction: 'row',
          signals: {
            up: this._pagerUp.bind(this),
          },
        })
      }
      this.Pager.patch({
        items: pagerItems,
      })
    }
  }

  get contentSeasons(): ContentSeason[] {
    const seasons = this.content?.seasons?.filter(
      s => s.episode_count != null && s.episode_count > 1,
    )
    return isGoodArray<ContentSeason>(seasons) ? seasons : []
  }

  $navigate(payload: string | NavigationPayload, arg?: boolean) {
    if (isNavigationPayload(payload)) {
      const path = `${payload.path.replace(/\/$/, '')}/${payload.type}/${
        payload.type === 'extra'
          ? payload.id
          : payload.seasonNumber + '/' + payload.episodeNumber
      }`
      const navigationPayload = { ...payload, path, content: this.content }
      this.fireAncestors('$navigate', navigationPayload, arg)
    } else {
      this.fireAncestors('$navigate', payload, arg)
    }
  }
  public page = 'TV Details Page'
  override _active() {
    this.stage.application.emit('screenView', this.page, 'tv_details')
  }

  getPlayButtonEpisode(searchNumber?: number) {
    if (!this.content || !Array.isArray(this.content.seasons)) return null
    const seasons = this.content.seasons as ContentSeason[]
    const currentSeason = firstSeason(seasons)
    if (!currentSeason || !Array.isArray(currentSeason.episodes)) return null
    const episode = firstEpisode(currentSeason.episodes)
    if (currentSeason && searchNumber) {
      const episode = currentSeason.episodes.find(
        ({ episodeNumber }) => searchNumber === episodeNumber,
      )
      if (!episode) return null
    }
    return episode
  }

  set content(content: ContentItem | undefined | null) {
    debug.info('TV Details Content', content)
    this._content = content
    this.patchPage()
  }

  get content() {
    return this._content
  }

  detailsRight() {
    this.lowerWindow?.patch({ index: this.lowerWindow.index + 1 })
    if (this.lowerWindow) {
      this.tabBar?.patch({
        index: this.lowerWindow.index,
      })
    }
  }
  detailsLeft() {
    this.lowerWindow?.patch({ index: this.lowerWindow.index - 1 })
    if (this.lowerWindow) {
      this.tabBar?.patch({
        index: this.lowerWindow.index,
      })
    }
  }

  override _getFocused() {
    return this.Pager as Lightning.Component
  }
  override _captureBack() {
    return defaultBackHandler()
  }
  override _captureKey(e: KeyboardEvent) {
    if (e.code === 'Backspace') return this._captureBack()
    return false
  }
  moreContentSelected(item: ContentItem) {
    this.fireAncestors('$navigate', item.paths.details)
  }
  episodeSelected(episode: ContentEpisode) {
    if (this.content && isID(episode.season) && isID(episode.episodeNumber)) {
      const path = `player/tv/${this.content.id}/episode/${episode.season}/${episode.episodeNumber}`
      this.fireAncestors('$navigate', path)
    } else {
      console.warn(
        'Could not create episode player path %s %s %s',
        this.content?.id,
        episode.season,
        episode.episodeNumber,
      )
    }
  }
}
