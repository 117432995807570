import { ID, XListPosition, YListPosition } from '@adiffengine/engine-types'
export const GRID_COLUMN_ID = 'ade/grid-column-id'
export interface SerializedGridState {
  __typename: 'GridState'
  id: ID
  items: Record<ID, XListPosition>
  column: YListPosition
}
function isSerializedGridState(
  x: ID | SerializedGridState,
): x is SerializedGridState {
  return (x as SerializedGridState).__typename === 'GridState'
}
export class GridState {
  private _columnState: YListPosition = { index: 0, yOffset: 0 }
  private items: Record<ID, XListPosition> = {}
  public readonly id
  constructor(input: ID | SerializedGridState) {
    if (isSerializedGridState(input)) {
      this.id = input.id
      this.items = input.items
      this._columnState = input.column
    } else {
      this.id = input
    }
  }
  setRowState(id: ID, state: XListPosition) {
    this.items[id] = state
  }
  getRowState(id: ID): XListPosition {
    const cached = this.items[id]
    return (
      cached ?? {
        xOffset: 0,
        index: 0,
      }
    )
  }
  set columnState(_state: YListPosition) {
    // Don't know why I'm enforcing this now, but I feel like it
    // may make sense later.
    throw new Error('Do not set column state directly, use the setter')
  }
  get columnState() {
    return this._columnState
  }
  setColumnState(state: YListPosition) {
    this.columnState = state
  }
  reset() {
    this._columnState = {
      index: 0,
      yOffset: 0,
    }
    this.items = {}
  }
  serialize(): SerializedGridState {
    return {
      __typename: 'GridState',
      column: this._columnState,
      items: this.items,
      id: this.id,
    }
  }
  toJSON() {
    return JSON.stringify(this.serialize())
  }
}

export class GridCache {
  private _serializedGrids: Record<ID, SerializedGridState> = {}
  private _ids: ID[] = []
  private _max: number = 50
  constructor(max?: number) {
    if (max) this._max = max
  }
  save(id: ID, state: SerializedGridState) {
    this._serializedGrids[id] = state
    this._ids = [...this._ids.filter(gridId => gridId !== id), id]
    if (this._ids.length > this._max) {
      const eldest = this._ids.shift()
      if (eldest) {
        delete this._serializedGrids[eldest]
      }
    }
  }
  get(id: ID): SerializedGridState | undefined {
    return this._serializedGrids[id]
  }
}
