import { Colors, Lightning, Settings, Utils } from '@lightningjs/sdk'
import { Icon } from '@lightningjs/ui-components'

import { pathJoin } from '../lib'
// const debug = new Debugger('AdeColorIcon')
export interface AdeColorIconProperties {
  color?: Icon['color']
  icon: string
}
export interface AdeColorIconTemplateSpec
  extends Lightning.Component.TemplateSpec {
  BaseIcon: typeof Icon
  FocusIcon: typeof Icon
  focusColor: Icon['color']
  icon: Partial<AdeColorIconProperties>
}

export class AdeColorIcon
  extends Lightning.Component<AdeColorIconTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<AdeColorIconTemplateSpec>
{
  BaseIcon = this.getByRef('BaseIcon')!
  FocusIcon = this.getByRef('FocusIcon')!
  static override _template() {
    return {
      visible: false,
      BaseIcon: {
        x: 0,
        y: 0,
        alpha: 1,
        color: Colors('text').get(),
        type: Icon,
      },
      FocusIcon: {
        x: 0,
        y: 0,
        alpha: 0,
        color: Colors('primaryHighlight').get(),
        type: Icon,
      },
    }
  }

  private _focusAnimation: Lightning.types.Animation | undefined = undefined
  private _animationDuration: number = 0.2

  set animationDuration(x: number) {
    this._focusAnimation = undefined
    this._animationDuration = x
  }
  get animationDuration() {
    return this._animationDuration
  }

  get focusAnimation(): Lightning.types.Animation {
    if (this._focusAnimation) return this._focusAnimation
    this._focusAnimation = this.animation({
      duration: this.animationDuration,
      actions: [
        { t: 'BaseIcon', p: 'alpha', v: { 0: 1, 1: 0 } },
        { t: 'FocusIcon', p: 'alpha', v: { 0: 0, 1: 1 } },
      ],
    })
    return this._focusAnimation
  }

  start() {
    this.focusAnimation.start()
  }
  stop() {
    this.focusAnimation.stop()
  }
  finish() {
    this.focusAnimation.finish()
  }
  private _icon: Partial<AdeColorIconProperties> = {
    color: Colors('text').get(),
  }

  set icon(icon: Partial<AdeColorIconProperties>) {
    const iconDirectory = Settings.get('app', 'icon_directory', 'images/icons')
    if (icon.icon) {
      this.patch({
        visible: true,
        BaseIcon: {
          w: this.w,
          h: this.h,
          icon: Utils.asset(pathJoin([iconDirectory, icon.icon])),
          color: this.color,
        },
        FocusIcon: {
          w: this.w,
          h: this.h,
          icon: Utils.asset(pathJoin([iconDirectory, icon.icon])),
          color: this.focusColor,
        },
      })
    }
  }
  get icon() {
    return this._icon!
  }
  private _focusColor: Icon['color'] = Colors('text').get()
  set focusColor(color: number) {
    this.FocusIcon.patch({
      color,
    })
  }
  get focusColor() {
    return this._focusColor
  }
}
