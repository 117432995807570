import { ContentSeason } from '@adiffengine/engine-types'
import { Colors, Lightning } from '@lightningjs/sdk'
import { isGoodString } from '../lib'
import { getStoredTheme } from '../themes'
// const debug = new Debugger('SeasonListItem')

export class SeasonListItem
  extends Lightning.Component<
    SeasonListItemTemplateSpec,
    SeasonListItemTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<SeasonListItemTemplateSpec>
{
  private _season: ContentSeason | null = null
  private _expanded: boolean = false
  Inner = this.tag('Inner')!
  Title = this.Inner.tag('Title')!
  Expanded = this.tag('Expanded')!
  EpisodeCountWrapper = this.Inner.tag('EpisodeCountWrapper')!
  ExpandedTitle = this.Expanded.tag('ExpandedTitle')!
  ExpandedText = this.Expanded.tag('ExpandedText')!
  private _height: number = 80
  private _totalHeight: number = 80
  private _expandAnimation: Lightning.types.Animation | undefined
  private _focusAnimation: any
  static override _template(): Lightning.Component.Template<SeasonListItemTemplateSpec> {
    const theme = getStoredTheme()
    return {
      w: 400,
      h: 80,
      Background: {
        w: 400,
        h: 80,
        x: 0,
        y: 0,
        rect: true,
        color: Colors(theme.palette.darks[800]).get(),
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 12,
        },
      },
      Inner: {
        h: 80,
        x: 4,
        y: 4,
        flex: {
          direction: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
        },
        Title: {
          color: Colors('text').get(),
          flexItem: {
            marginLeft: 12,
            marginRight: 12,
          },
          text: {
            fontSize: 24,
            fontFace: 'Regular',
          },
        },
        EpisodeCountWrapper: {
          flexItem: {
            grow: 1,
          },
          flex: {
            direction: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          },
          EpisodeCount: {
            color: Colors('text').get(),
            flexItem: {
              marginRight: 24,
            },
            text: {
              text: '',
              fontSize: 18,
              fontFace: 'Regular',
              textAlign: 'right',
            },
          },
        },
      },
      Expanded: {
        h: 240,
        y: 100,
        x: 12,
        alpha: 0,
        flex: {
          direction: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        },
        ExpandedTitle: {
          flexItem: {
            marginTop: 20,
            marginBottom: 12,
          },
          text: {
            text: '',
            fontFace: 'Bold',
            fontSize: 18,
            wordWrapWidth: 400,
            lineHeight: 24,
            wordWrap: false,
          },
        },
        ExpandedText: {
          text: {
            text: '',
            fontFace: 'Text',
            fontSize: 24,
            wordWrapWidth: 400,
            lineHeight: 36,
            wordWrap: true,
            maxLines: 4,
          },
        },
      },
    }
  }

  set width(width: number) {
    const wordWrapWidth = width - 32
    const patch: Lightning.Element.PatchTemplate<SeasonListItemTemplateSpec> = {
      Background: { w: width, h: 80 },
      Inner: { w: width },
      Expanded: {
        ExpandedTitle: { text: { wordWrapWidth } },
        ExpandedText: { text: { wordWrapWidth } },
      },
    }
    if (this.w !== width) patch.w = width
    if (Object.keys(patch).includes('w')) {
      this.signal('reposition')
    }
    this.patch(patch)
  }

  set height(height: number) {
    this._height = height
  }
  get height() {
    return this._height
  }
  patchHeight() {
    if (this.__initialized) {
      const hasText =
        this.season?.overview && this.season.overview?.trim().length > 0
      const expandHeight = hasText ? 240 : 88
      const totalHeight = this.expanded
        ? expandHeight + this.height
        : this.height
      this.patch({
        h: totalHeight,
        Background: { h: this._height },
      })
      if (this._totalHeight !== totalHeight) {
        this.signal('reposition')
      }
      this._totalHeight = totalHeight
    }
  }

  set expanded(expanded: boolean) {
    if (this._expanded !== expanded) {
      this._expanded = expanded
      this.doExpand()
    }
  }
  get expanded() {
    return this._expanded
  }
  doExpand() {
    if (this.__initialized) {
      this.patchHeight()
      if (this.expanded) {
        this.expandAnimation.start()
      } else this.expandAnimation.stop()
    }
  }

  set season(season: ContentSeason) {
    this._season = season
    this.Title.patch({ text: { text: season.name } })
    const count: number | null = season.episodes?.length
      ? season.episodes?.length
      : season.episode_count
      ? season.episode_count
      : null
    this.EpisodeCountWrapper.getByRef('EpisodeCount')?.patch({
      text: {
        text:
          count !== null
            ? `${count} Episode${count > 1 || count < 1 ? 's' : ''}`
            : '',
      },
    })

    this.ExpandedText.patch({
      text: season.overview ?? '',
    })
    this.ExpandedTitle.patch({
      text: isGoodString(season.name) ? season.name.toUpperCase() : '',
      visible: isGoodString(season.name),
    })
  }
  get season() {
    return this._season!
  }

  repo() {
    this.signal('reposition')
  }

  get expandAnimation() {
    if (!this._expandAnimation) {
      this.repo = this.repo.bind(this)
      this._expandAnimation = this.animation({
        duration: 0.4,
        actions: [{ t: 'Expanded', p: 'alpha', v: { 0: 0, 1: 1 } }],
      })
      this._expandAnimation.on('stopFinish', this.repo)
    }
    return this._expandAnimation
  }

  get focusAninmation(): Lightning.types.Animation {
    const theme = this.fireAncestors('$theme')
    if (!this._focusAnimation) {
      this._focusAnimation = this.animation({
        duration: 0.2,
        actions: [
          {
            t: 'Background',
            p: 'color',
            v: {
              0: Colors(theme.palette.darks[700]).get(),
              1: Colors(theme.palette.highlights[500]).get(),
            },
          },
        ],
      })
    }
    return this._focusAnimation
  }
  override _focus() {
    this.focusAninmation.start()
  }
  override _unfocus() {
    this.focusAninmation.stop()
  }
  override _handleEnter() {
    if (this._season) this.signal('season', this._season)
  }

  override _init() {
    this.patchHeight()
  }
  override _enable() {
    this.doExpand()
  }
}

export interface SeasonListItemTemplateSpec
  extends Lightning.Component.TemplateSpec {
  expanded: boolean
  season: ContentSeason
  width: number
  height: number
  Inner: {
    Title: object
    EpisodeCountWrapper: {
      EpisodeCount: object
    }
  }
  Background: object
  Expanded: {
    ExpandedTitle: object
    ExpandedText: object
  }
}

export interface SeasonListItemSignals {
  season(season: ContentSeason): void
  reposition(): void
}
export interface SeasonListItemTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: SeasonListItemSignals
}
