import { Track } from '@adiffengine/engine-types'
import { Colors, Img, Lightning, Utils } from '@lightningjs/sdk'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import { Debugger } from '../../lib'
import { getStoredTheme } from '../../themes'
import { AudioPlayerControls } from '../audioPlayer/AudioPlayerControls'
import { Progress } from './Progress'

const debug = new Debugger('CurrentlyPlayingSong')

export interface CurrentlyPlayingSongPatchTemplate
  extends Lightning.Element.PatchTemplate {
  track: Track
}

export interface CurrentlyPlayingSongTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Shadow: object
  Background: object
  NoSong: object
  Tracked: {
    Image: object
    Details: {
      Container: object
      Title: object
      Artist: object
    }
    PlayerControls: typeof AudioPlayerControls
    Progress: typeof Progress
  }
  container: string
  track: Track | null
  direction: 'horizontal' | 'vertical'
  hasControls: boolean
}

const HidePlayerPatchVertical = {
  y: 444,
}
const ShowPlayerPatchVertical = {
  y: 544,
}
export class CurrentlyPlayingSong
  extends Lightning.Component<CurrentlyPlayingSongTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<CurrentlyPlayingSongTemplateSpec>
{
  static verticalWidth = 600
  static horizontalWidth = 1140
  Tracked = this.tag('Tracked')!
  NoSong = this.tag('NoSong')!
  Details = this.Tracked.tag('Details')!
  Container = this.Details.tag('Container')!
  Title = this.Details.tag('Title')!
  PlayerControls = this.Tracked.tag('PlayerControls')!
  Shadow = this.tag('Shadow')!
  static override _template(): Lightning.Component.Template<CurrentlyPlayingSongTemplateSpec> {
    const theme = getStoredTheme()
    return {
      x: 0,
      y: 0,
      Background: {
        w: CurrentlyPlayingSong.verticalWidth + 80,
        h: 400 + Progress.height + 40,
        mount: 0.5,
        x: CurrentlyPlayingSong.verticalWidth / 2,
        y: (440 + Progress.height) / 2,
        rect: true,
        color: Colors(theme.palette.backgroundGradient).alpha(0.8).get(),
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 20,
        },
      },
      Shadow: {
        x: 264,
        y: 204,
        mount: 0.5,
        w: 360,
        visible: false,
        h: 360,
        color: Colors('black').alpha(0.4).get(),
        rect: true,
        shader: { type: Lightning.shaders.FadeOut, fade: 20 },
      },
      NoSong: {
        x: 0,
        y: 0,
        h: 440 + Progress.height,
        w: CurrentlyPlayingSong.verticalWidth,
        visible: false,
      },
      Tracked: {
        Image: {
          x: 100,
          y: 40,
          w: 320,
          h: 320,
          shader: {
            type: Lightning.shaders.RoundedRectangle,
            radius: 12,
          },
        },
        PlayerControls: {
          x: 24,
          y: 404,
          w: 600,
          type: AudioPlayerControls,
          passSignals: {
            up: true,
            down: true,
            left: true,
            right: true,
          },
        },
        Details: {
          x: 24,
          y: 404 + 100,
          w: 600,
          flex: {
            direction: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
          },
          Container: {
            text: {
              fontSize: 18,
              fontFace: 'Bold',
              wordWrap: true,
              lineHeight: 30,
              maxLines: 2,
              wordWrapWidth: 600,
            },
          },
          Title: {
            text: {
              fontSize: 36,
              fontFace: 'Bold',
              wordWrap: true,
              lineHeight: 45,
              maxLines: 2,
              wordWrapWidth: 600,
            },
          },
          Artist: {
            flexItem: {
              marginTop: 12,
            },
            text: {
              fontSize: 18,
              fontFace: 'Regular',
              wordWrap: true,
              maxLines: 3,
              lineHeight: 22.5,
              wordWrapWidth: 600,
            },
          },
        },
        Progress: {
          x: 100,
          y: 400,
          w: CurrentlyPlayingSong.verticalWidth,
          h: Progress.height,
          type: Progress,
        },
      },
    }
  }
  _hasControls = true
  set hasControls(controls: boolean) {
    this._hasControls = controls
    this.PlayerControls.patch({
      visible: controls,
    })
    this.Details.patch(
      this._hasControls ? ShowPlayerPatchVertical : HidePlayerPatchVertical,
    )
    this._refocus()
  }

  set track(item: Track | null) {
    debug.info('Got track', item, this)
    if (!isNumber(this.w)) {
      this.patch({ w: 400 })
    }
    if (item !== null) {
      this._setState('Tracked')
      const { title, description, images } = item
      this.title = title
      this.description = description
      if (isString(images.wallpaper?.getForWidth(400))) {
        this.imageUrl = images.wallpaper?.getForWidth(400) ?? undefined
      }
    } else {
      this._setState('NoSong')
    }
  }

  _direction: CurrentlyPlayingSongTemplateSpec['direction'] = 'vertical'
  set direction(direction: CurrentlyPlayingSongTemplateSpec['direction']) {
    if (direction !== this._direction) {
      this._direction = direction
      this.patch(direction === 'vertical' ? VerticalPatch : HorizontalPatch)
    }
  }
  get direction() {
    return this._direction
  }

  set container(text: string) {
    this.Container.patch({
      text: { text: text.toUpperCase() },
    })
  }

  set title(text: string) {
    if (isString(text)) {
      this.Title.patch({
        text: { text },
      })
    }
  }
  set description(text: string) {
    if (isString(text)) {
      this.tag('Tracked.Details.Artist')?.patch({
        text: { text },
      })
    }
  }
  set imageUrl(url: string | undefined) {
    if (isString(url)) {
      url = Utils.proxyUrl(url)
      this.Shadow.patch({ visible: false })
      this.tag('Tracked.Image')?.on('txLoaded', () => {
        this.Shadow.patch({ visible: true })
      })
      this.tag('Tracked.Image')?.patch({
        texture: Img(url).cover(400, 400),
      })
    }
  }

  override _getFocused() {
    if (this._hasControls) {
      return this.PlayerControls
    } else {
      return null
    }
  }

  static override _states(): (typeof CurrentlyPlayingSong)[] {
    return [
      class Tracked extends this {
        override $enter() {
          this.Tracked.patch({
            visible: true,
          })
        }
        override $exit() {
          this.Tracked.patch({
            visible: false,
          })
        }
      },
      class NoSong extends this {
        override $enter() {
          this.NoSong.patch({
            visible: true,
          })
        }
        override $exit() {
          this.NoSong.patch({
            visible: false,
          })
        }
      },
    ]
  }
}
const HorizontalPatch: Lightning.Element.PatchTemplate<Lightning.Element.TemplateSpecLoose> =
  {
    Tracked: {
      Details: {
        x: 480,
        y: 40,
        flex: {
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        },
      },
      Progress: {
        w: CurrentlyPlayingSong.horizontalWidth - 100,
      },
    },
    Background: {
      w: CurrentlyPlayingSong.horizontalWidth + 80,
      x: CurrentlyPlayingSong.horizontalWidth / 2,
    },
    NoSong: {
      w: CurrentlyPlayingSong.horizontalWidth,
    },
  }
const VerticalPatch: typeof HorizontalPatch = {
  Tracked: {
    Details: {
      x: 24,
      y: 444 + 100,
      flex: {
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
    },
    Progress: {
      w: CurrentlyPlayingSong.verticalWidth - 100,
    },
  },
  NoSong: {
    w: CurrentlyPlayingSong.verticalWidth,
  },
}
