import { Colors, Lightning } from '@lightningjs/sdk'
import { Debugger, isFunction, isString } from '../lib'

const debug = new Debugger('StackModal')
export interface StackModalTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Background: object
  scaleMultiplier: number | ((steps: number) => number)
  alphaMultiplier: number | ((steps: number) => number)
}

export type StackModalTemplate<
  T extends StackModalTemplateSpec = StackModalTemplateSpec,
> = Lightning.Component.Template<T>

export type StackModalImplement<
  T extends StackModalTemplateSpec = StackModalTemplateSpec,
> = Lightning.Component.ImplementTemplateSpec<T>

export type StackModalTypeConfig<
  T extends Lightning.Component.TypeConfig = Lightning.Component.TypeConfig,
> = T

export function isStackModal(x: any): x is StackModal {
  return x && x.stackModal === true
}
export abstract class StackModal<
  T extends StackModalTemplateSpec = StackModalTemplateSpec,
  S extends StackModalTypeConfig = StackModalTypeConfig,
> extends Lightning.Component<T, S> {
  static ModalName: string
  public readonly stackModal = true
  static override _template(): Lightning.Component.Template<StackModalTemplateSpec> {
    return {
      x: 0,
      y: 0,
      h: 1080,
      w: 1920,
      alpha: 0,
      color: Colors('background').alpha(0.4).get(),
      rect: true,
      rtt: false,
      // Background: {
      //   x: 1920 / 2,
      //   y: 1080 / 2,
      //   mountX: 0.5,
      //   mountY: 0.5,
      //   h: 1080 + 500,
      //   w: 1920 + 1000,
      //   rect: true,
      //   rtt: true,
      //   scale: 2,
      //   color: Colors('background').alpha(0.4).get(),
      // },
    }
  }
  public scaleMultiplier: number | ((steps: number) => number) = 0.85
  public alphaMultiplier: number | ((steps: number) => number) = 0.7
  _showAnimation: Lightning.types.Animation | undefined
  get showAnimation() {
    if (!this._showAnimation) {
      this._showAnimation = this.animation({
        duration: 0.3,
        actions: [
          {
            p: 'alpha',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            v: { 0: 0, 1: 1 },
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          { p: 'scale', v: { 0: 1.15, 1: 1 } },
        ],
      })
    }
    return this._showAnimation
  }

  private _fadeSteps = 0

  set fadeSteps(steps: number) {
    this._fadeSteps = steps
    if (steps > 0) {
      const alpha = isFunction(this.alphaMultiplier)
        ? this.alphaMultiplier(steps)
        : Math.pow(this.alphaMultiplier, steps)
      const scale = isFunction(this.scaleMultiplier)
        ? this.scaleMultiplier(steps)
        : Math.pow(this.scaleMultiplier, steps)
      const patch: Lightning.Element.PatchTemplate = {
        smooth: {
          alpha: [alpha, { duration: 0.25 }],
          scale: [scale, { duration: 0.25 }],
        },
        rtt: true,
        shader: {
          type: Lightning.shaders.LinearBlur,
          kernalRadius: 1,
        },
      }
      this.patch(patch)
    } else {
      this._fadeSteps = 0
      const patch: Lightning.Element.PatchTemplate = {
        smooth: {
          alpha: [1, { duration: 0.25 }],
          scale: [1, { duration: 0.25 }],
        },
        rtt: false,
        shader: undefined,
      }
      this.patch(patch)
    }
  }
  get fadeSteps() {
    return this._fadeSteps
  }
  private _modalName: string | undefined = undefined
  get ModalName() {
    return isString(this._modalName)
      ? this._modalName
      : (this.constructor as typeof StackModal).ModalName
  }
  set ModalName(name: string) {
    this._modalName = name
  }

  show() {
    debug.info('Showing animation!', this)
    this.showAnimation.start()
  }

  hide() {
    this.showAnimation.stop()
  }
  override _captureBack() {
    this.fireAncestors('$modalActive', StackModal.ModalName, false)
  }
  override _handleRight() {
    console.info('Modal Handler right')
    return true
  }
  override _handleLeft() {
    console.info('Modal Handler left')
    return true
  }
  override _handleUp() {
    console.info('Modal Handler up')
    return true
  }
  override _handleDown() {
    console.info('Modal Handler down')
    return true
  }
}
