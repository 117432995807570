import { Colors, Lightning } from '@lightningjs/sdk'
export interface StandardBackgroundTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Container: {
    OffsetGlow: object
    InnerGlow: object
  }
  Bubble: object
  background: number
  backgroundGradient: number
  primaryHighlight: number
}
export type StandardBackgroundTypeConfig = Lightning.Component.TypeConfig

export class StandardBackground
  extends Lightning.Component<
    StandardBackgroundTemplateSpec,
    StandardBackgroundTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<StandardBackgroundTemplateSpec>
{
  private _danceAnimation: Lightning.types.Animation | undefined
  Container = this.getByRef('Container')!
  static override _template(): Lightning.Component.Template<StandardBackgroundTemplateSpec> {
    return {
      x: 0,
      y: 0,
      w: 1920,
      h: 1080,
      rect: true,
      rtt: true,
      color: Colors('background').get(),
      Container: {
        x: 0,
        y: 0,
        w: 1920,
        pivot: 0.5,
        h: 1080,
        InnerGlow: {
          h: 1080,
          w: 1920,
          x: 0,
          y: 0,
          alpha: 0.8,
          rect: true,
          rtt: true,
          color: Colors('background').get(),
          shader: {
            type: Lightning.shaders.RadialGradient,
            pivot: [1, 1.4],
            outerColor: Colors('background').get(),
            innerColor: Colors('backgroundGradient').alpha(0.4).get(),
          },
        },
        OffsetGlow: {
          h: 1480,
          w: 2120,
          x: -200,
          y: -400,
          rect: true,
          rtt: true,
          color: 0x0000000,
          alpha: 0.4,
          shader: {
            type: Lightning.shaders.RadialGradient,
            // pivot: [0, 0.9],
            outerColor: 0x0000000,
            innerColor: Colors('backgroundGradient').alpha(1).get(),
          },
        },
      },
    }
  }

  set backgroundGradient(color: number) {
    this.Container.getByRef('InnerGlow')!.patch({
      shader: {
        innerColor: color,
      },
    })
  }
  set background(color: number) {
    console.info(
      'Background got background color %s, current color',
      color,
      this.color,
    )
    this.patch({
      color,
      Container: {
        InnerGlow: {
          color,
          shader: {
            outerColor: color,
          },
        },
      },
    })
  }
  set primaryHighlight(color: number) {
    this.Container.getByRef('OffsetGlow')?.patch({
      shader: {
        innerColor: color,
      },
    })
  }
  get danceAnimation() {
    if (!this._danceAnimation) {
      this._danceAnimation = this.animation({
        duration: 5,
        repeat: -1,
        actions: [
          {
            t: 'Container',
            p: 'rotation',
            v: { 0: 0, 1: (360 * Math.PI) / 180 },
          },
        ],
      })
    }
    return this._danceAnimation
  }
  dance() {
    //this.danceAnimation.start()
  }
  stopDancing() {
    //this.danceAnimation.stop()
  }
}
