import { ActionConfig } from '@adiffengine/engine-types'
import { Colors, Lightning } from '@lightningjs/sdk'
import { AdeButtonType } from './AdeButton'
export class TabMenuButton extends AdeButtonType {
  public buttonText?: string | undefined
  public action?: ActionConfig
  private _focusAnimation: Lightning.types.Animation | null = null
  public static width: 200
  public static height: 60
  static override _template() {
    return {
      w: TabMenuButton.width,
      h: TabMenuButton.height,
      Text: {
        w: (x: number) => x,
        h: (x: number) => x,
        mountX: 0.5,
        mountY: 0.45,
        color: Colors('white').get(),
        text: { fontSize: 24, fontFace: 'Bold' },
      },
    }
  }
  override _init() {
    const theme = this.fireAncestors('$theme')
    this.tag('Text').patch({
      color: Colors(theme.palette.text),
      text: {
        text: this.buttonText ?? '',
      },
    })
    this._focusAnimation = this.animation({
      duration: 0.2,
      actions: [
        { p: 'scale', v: { 0: 1, 1: 1.075 } },
        {
          t: 'InnerButton.Focused',
          p: 'color',
          v: {
            0: Colors(theme.palette.text).get(),
            1: Colors(theme.palette.highlights[500]).get(),
          },
        },
      ],
    })
  }
  override _captureEnter(): boolean | void {
    if (this.action) {
      this.fireAncestors(this.action.action, this.action.payload)
    } else {
      return false
    }
  }
  override _focus() {
    this._focusAnimation?.start()
  }
  override _unfocus() {
    this._focusAnimation?.stop()
  }
}
