import { Colors, Img, Lightning } from '@lightningjs/sdk'
import {
  AdeButtonCollection,
  ButtonCollectionButtonConfig,
} from '../components/AdeButtonCollection'
import { FullPageBackground } from '../components/FullPageBackground'
// import { Debugger } from '../lib'
// const debug = new Debugger('FullPageModal')

export interface FullPageModalTemplateSpec<T = unknown>
  extends Lightning.Component.TemplateSpec {
  Content: {
    HeaderText: object
    Text: object
    Buttons: typeof AdeButtonCollection<T>
  }
  LogoWrapper: {
    Logo: object
  }
  Background: typeof FullPageBackground
  primaryColor: number
  secondaryColor: number
  logo: string
  logoColor: number
  primaryText: string
  secondaryText: string
  buttons: ButtonCollectionButtonConfig<T>[]
}

export const CONTENT_BOX_WIDTH = 700
export const CONTENT_BOX_HEIGHT = 920
export interface FullPageModalTypeConfig<T = unknown>
  extends Lightning.Component.TypeConfig {
  SignalMapType: {
    selected(payload: ButtonCollectionButtonConfig<T>): void
    visible(visible: boolean): null
  }
}

export class FullPageModal<T = unknown>
  extends Lightning.Component<
    FullPageModalTemplateSpec<T>,
    FullPageModalTypeConfig<T>
  >
  implements
    Lightning.Component.ImplementTemplateSpec<FullPageModalTemplateSpec>
{
  Content = this.getByRef('Content')!
  Title = this.Content.getByRef('HeaderText')!
  Text = this.Content.getByRef('Text')!
  Background = this.getByRef('Background')!
  LogoWrapper = this.getByRef('LogoWrapper')!
  Logo = this.LogoWrapper.getByRef('Logo')!
  Buttons = this.Content.getByRef('Buttons')!
  static override _template<T>(): Lightning.Component.Template<
    FullPageModalTemplateSpec<T>
  > {
    return {
      alpha: 0.0001,
      x: 0,
      y: 0,
      w: 1920,
      h: 1080,
      Background: {
        type: FullPageBackground,
      },
      LogoWrapper: {
        x: 200,
        y: 160,
        w: 1920 - CONTENT_BOX_WIDTH - 600,
        h: 1080 - 320,
        flex: {
          alignItems: 'center',
          justifyContent: 'center',
        },
        Logo: {},
      },

      Content: {
        x: 1920 - 240 - CONTENT_BOX_WIDTH,
        y: (1080 - CONTENT_BOX_HEIGHT - 80) / 2,
        w: CONTENT_BOX_WIDTH,
        h: CONTENT_BOX_HEIGHT,
        flex: {
          direction: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
        },
        HeaderText: {
          flexItem: {
            marginTop: 48,
          },
          text: {
            text: '',
            fontFace: 'Bold',
            fontSize: 60,
          },
        },
        Text: {
          flexItem: {
            marginTop: 24,
          },
          text: {
            text: ``,
            wordWrapWidth: CONTENT_BOX_WIDTH,
            fontFace: 'Text',
            fontSize: 24,
            lineHeight: 54,
          },
        },
        Buttons: {
          type: AdeButtonCollection<T>,
          direction: 'row',
          signals: {
            selected: '_handleButton',
          },
        },
      },
    }
  }
  override _init() {
    const theme = this.fireAncestors('$theme')

    const FullPageConfig = theme.components.FullPageBackground ?? {}
    if (FullPageConfig.logo) {
      this.logo = FullPageConfig.logo
    }
    if (FullPageConfig.logoColor) {
      this.logoColor = Colors(FullPageConfig.logoColor).get()
    }
  }
  _handleButton(payload: ButtonCollectionButtonConfig<T>) {
    this.signal('selected', payload)
  }
  private _animation: Lightning.types.Animation | null = null
  private _modalVisible = false
  set _isVisible(visible: boolean) {

    if (visible !== this._modalVisible) {
      this._modalVisible = visible
      this.signal('visible', visible)
    }
  }
  get _isVisible() {
    return this._modalVisible
  }

  get focusAnimation() {
    if (this._animation === null) {
      this._animation = this.animation({
        duration: 0.2,
        actions: [{ p: 'alpha', v: { 0: 0.0001, 1: 1 } }],
      })
      this._animation.on('start', () => {

        this._isVisible = true
      })
      this._animation.on('stopFinish', () => {
  
        this._isVisible = false
      })
    }
    return this._animation
  }
  override _focus() {
    console.info('Focsuing on full page modal')
    this.focusAnimation.start()
  }
  override _unfocus() {
    this.Buttons.patch({ index: 0 })
    this.focusAnimation.stop()
  }
  public set primaryColor(value: number) {
    this.Background.patch({
      primaryColor: value,
    })
  }

  public set primaryText(text: string) {
    this.Title.patch({ text: { text } })
  }
  public get primaryText() {
    return this.Title.text!.text!
  }
  public get secondaryText() {
    return this.Text.text!.text!
  }
  public set secondaryText(text: string) {
    this.Text.patch({
      text: { text },
    })
  }
  public set secondaryColor(secondaryColor: number) {
    this.Background.patch({
      secondaryColor,
    })
  }

  public set logoColor(color: number) {
    this.Logo.patch({
      color,
    })
  }
  public set logo(src: string) {
    this.Logo.patch({
      texture: Img(src).contain(this.LogoWrapper.w, this.LogoWrapper.h),
    })
  }
  set buttons(buttons: ButtonCollectionButtonConfig<T>[]) {
    this.Buttons.patch({ buttons })
  }
  get buttons() {
    return this.Buttons.buttons
  }
  override _getFocused() {
    return this.Buttons
  }
}
