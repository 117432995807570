import {
  ContentItem,
  ContentSelectedDirectionalSignalMap,
  CoordinateDimensions,
  HorizontalList,
} from '@adiffengine/engine-types'
import { Lightning } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import isEqual from 'fast-deep-equal/es6'
import {
  Debugger,
  getClosestIndexByX,
  getCoordinateDimensions,
  isGoodNumber,
} from '../lib'
import { SongCard } from './SongCard'
const debug = new Debugger('HeaderList')

debug.enabled = true

export interface ContentCard extends Lightning.Element {
  content: ContentItem
}
export interface CardConfig {
  height: number
  width: number
}

export interface HeaderListConfig {
  items: ContentItem[]
  itemType: ContentCardConstructor
  spacing?: number
  cardConfig: Partial<CardConfig>
}
export interface CompleteHeaderListConfig extends HeaderListConfig {
  spacing: number
  cardConfig: {
    height: number
    width: number
  }
}

export type ContentCardConstructor = new (...args: any[]) => ContentCard
export interface HeaderListTemplateSpec
  extends Lightning.Component.TemplateSpec,
    HorizontalList {
  Header: object
  List: typeof List
  title: string
  items: ContentItem[]
  itemType: ContentCardConstructor
  spacing: number
  cardConfig: Partial<CardConfig>
}
export class HeaderList
  extends Lightning.Component<HeaderListTemplateSpec, HeaderListTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<HeaderListTemplateSpec>
{
  Header = this.getByRef('Header')!
  List = this.getByRef('List')!
  static height = SongCard.height + 100
  static spacing = 20
  static getHeightFromCardHeight(h?: number | null) {
    return isGoodNumber(h) ? h + 60 : this.height
  }
  static override _template(): Lightning.Component.Template<HeaderListTemplateSpec> {
    return {
      w: 1920 - 160,
      h: 300,
      Header: {
        x: 0,
        y: 0,
        text: {
          text: '',
          fontSize: 36,
          fontFace: 'ExtraBold',
          wordWrap: false,
        },
      },
      List: {
        type: List,
        direction: 'row',
        x: 0,
        y: 60,
        passSignals: {
          contentFocused: true,
        },
      },
    }
  }
  override _construct() {
    this.contentFocused = this.contentFocused.bind(this)
    this.contentSelected = this.contentSelected.bind(this)
  }
  private _config: Partial<CardConfig> = {}
  set cardConfig(config: Partial<CardConfig>) {
    const updatedConfig = { ...this.cardConfig, ...config }
    if (!isEqual(updatedConfig, this.cardConfig)) {
      this._config = updatedConfig
      this.patchList()
    }
  }

  get cardConfig(): CardConfig {
    const staticHeight = isGoodNumber((this.itemType as any)?.height)
      ? (this.itemType as any).height
      : 0
    const staticWidth = isGoodNumber((this.itemType as any)?.width)
      ? (this.itemType as any).width
      : 0
    return { ...{ width: staticWidth, height: staticHeight }, ...this._config }
  }
  private _itemType: ContentCardConstructor | undefined = undefined
  set itemType(itemType: ContentCardConstructor) {
    if (this.itemType !== itemType) {
      this._itemType = itemType
      this.patchList()
    }
  }
  get itemType() {
    return this._itemType!
  }

  patchList() {
    if (!this.itemType) return
    if (this.h !== this.cardConfig.height + 60) {
      const height = this.cardConfig.height + 60
      this.patch({
        h: height,
      })
    }
    debug.info('Patching list with spacing %s', this.spacing, this.listItems)
    if (this.listItems.length > 0) {
      const cardWidth = this.cardConfig.width + this.spacing
      const visibleItems = Math.floor((this.w - this.spacing) / cardWidth)
      const listWidth = visibleItems * cardWidth - this.spacing
      this.List.patch({
        w: listWidth,
        h: this.cardConfig.height,
        itemType: this.itemType,
        items: this.listItems,
        spacing: this.spacing,
      })
      this.List.reposition()
      this.List.scroll = {
        forward: listWidth,
        backward: 0,
      }
      debug.info('Patched List', this.List)
    } else {
      this.List.clear()
    }
    this.signal('reposition')
  }

  contentFocused(item: ContentItem) {
    this.signal('contentFocused', item)
  }
  contentSelected(item: ContentItem) {
    this.signal('contentSelected', item)
  }

  get listItems() {
    return this._items.map(content => ({
      content,
      w: this.cardConfig.width,
      h: this.cardConfig.height,
      signals: {
        contentFocused: this.contentFocused,
        contentSelected: this.contentSelected,
      },
    }))
  }

  // Spacing
  private _spacing: number = HeaderList.spacing
  set spacing(spacing: number) {
    if (spacing !== this._spacing) {
      this._spacing = spacing
      this.patchList()
    }
  }
  get spacing() {
    return this._spacing
  }

  // Content
  set title(text: string) {
    this.Header.patch({
      text: {
        text: text.toUpperCase(),
      },
    })
  }
  private _items: ContentItem[] = []
  set items(contents: ContentItem[]) {
    this._items = contents
    this.patchList()
    this.List.scroll = {
      forward: this.w - 20,
      backward: 0,
    }
  }

  get currentFocus() {
    return this.List.items[this.List.index]
  }
  override _captureLeft(): boolean | void {
    if (this.List.index === 0) this.signal('left', this.focusedCoords)
    else return false
  }
  override _captureRight(): boolean | void {
    if (this.List.index === this.List.items.length - 1)
      return this.signal('right', this.focusedCoords)
    else return false
  }
  override _captureUp(): boolean | void {
    return this.signal('up', this.focusedCoords)
  }
  override _captureDown(): boolean | void {
    return this.signal('down', this.focusedCoords)
  }
  override _captureEnter(): boolean | void {
    const item = this._items[this.List.index]
    const playlist = item
      ? this._items.filter(i => i.id !== item.id)
      : undefined
    if (item) this.signal('contentSelected', item, playlist)
  }

  getHeight() {
    return this.cardConfig.height + 60
  }
  getFocusedCoords() {
    return this.focusedCoords
  }

  get focusedCoords() {
    const focused = this.List.items[this.List.index]
    let coords = null
    if (focused) {
      coords = getCoordinateDimensions(focused, false)
    }
    return coords
  }

  setClosestByX(coords?: CoordinateDimensions | null) {
    if (coords != null) {
      const closest = getClosestIndexByX(coords, this.List.items)
      this.List.setIndex(closest)
    }
  }

  override _getFocused() {
    return this.List
  }
}

export interface HeaderListSignals extends ContentSelectedDirectionalSignalMap {
  reposition(): void
}

export interface HeaderListTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: HeaderListSignals
}
