import { Lifecycle } from '@firebolt-js/sdk'
import { DevicePlatform } from '../platforms'
export function exitPlatform(
  reason: Lifecycle.CloseReason = Lifecycle.CloseReason.USER_EXIT,
) {
  Lifecycle.close(reason)
}
export const platform: DevicePlatform = {
  exit: exitPlatform,
}
