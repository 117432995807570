import { User } from '@adiffengine/engine-types'
import { Colors, Img, Lightning } from '@lightningjs/sdk'
import { capitalCase } from 'change-case'
import equal from 'fast-deep-equal/es6'
import { Debugger, getBestDimensionValue, getBestDimensionValues } from '../lib'
const debug = new Debugger('Avatar')
export interface AvatarTemplateSpec extends Lightning.Component.TemplateSpec {
  HelloText: object
  Backup: {
    Background: object
    Text: object
    Border: object
  }
  InnerContainer: {
    Border: object
    Image: object
    Shadow: object
  }

  Image: object
  Help: object
  user: User
  borderWidth: number
  borderColor: string | number
  animatePresenceDelay: number
  entrance: 'left' | 'bottom' | 'top' | 'right'
}
export type AvatarTypeConfig = Lightning.Component.TypeConfig
export class Avatar
  extends Lightning.Component<AvatarTemplateSpec, AvatarTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<AvatarTemplateSpec>
{
  InnerContainer = this.getByRef('InnerContainer')!
  Border = this.InnerContainer.getByRef('Border')!
  Image = this.InnerContainer.getByRef('Image')!
  Backup = this.getByRef('Backup')!
  HelloText = this.getByRef('HelloText')!
  private _presenceAnimation: Lightning.types.Animation | undefined
  private _helloAnimation: Lightning.types.Animation | undefined
  static override _template(): Lightning.Component.Template<AvatarTemplateSpec> {
    return {
      alpha: 1,
      h: 100,
      w: 100,
      HelloText: {
        h: 100,
        w: 600,
        x: -20,
        y: 0,
        mountX: 1,
        color: Colors('text').get(),
        text: {
          text: 'Hello Dolly!',
          wordWrapWidth: 600,
          wordWrap: true,
          maxLines: 1,
          fontFace: 'Text',
          fontSize: 24,
          verticalAlign: 'middle',
          lineHeight: 100,
          textAlign: 'right',
        },
      },

      InnerContainer: {
        x: 0,
        y: 0,
        h: 100,
        w: 100,
        rect: true,
        rtt: true,
        Image: {
          h: 100,
          w: 100,
          x: 0,
          y: 0,
          rect: true,
          rtt: true,
        },
        Border: {
          x: 0,
          y: 0,
          w: 100,
          h: 100,
          rtt: true,
          rect: true,
          color: 0x00000000,
          shader: {
            type: Lightning.shaders.RoundedRectangle,
            radius: 50,
            stroke: 2,
            strokeColor: Colors('text').get(),
          },
        },
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 50,
        },
      },
      Backup: {
        alpha: 0,
        h: 100,
        w: 100,
        x: 0,
        rect: true,
        rtt: true,
        color: 0x00000000,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 50,
        },
        Background: {
          h: 100,
          w: 100,
          y: 0,
          x: 0,
          rect: true,
          rtt: true,
          shader: {
            type: Lightning.shaders.RadialGradient,
            x: 50,
            pivot: 0.5,
            innerColor: Colors('backgroundGradient').get(),
            outerColor: Colors('background').get(),
          },
        },
        Text: {
          h: 100,
          w: 100,
          color: Colors('text').get(),
          text: {
            text: 'A',
            lineHeight: 100,
            textAlign: 'center',
            fontFace: 'Bold',
            fontSize: 48,
            verticalAlign: 'middle',
          },
        },
        Border: {
          x: 0,
          y: 0,
          w: 100,
          h: 100,
          rtt: true,
          rect: true,
          color: 0x00000000,
          shader: {
            type: Lightning.shaders.RoundedRectangle,
            radius: 50,
            stroke: 2,
            strokeColor: Colors('text').get(),
          },
        },
      },
    }
  }

  private _name: string = ''
  set name(text: string) {
    if (this._name !== text) {
      this._name = text
      this.HelloText.patch({ text: { text } })
    }
  }
  get name() {
    return this._name
  }
  public entrance: 'left' | 'top' | 'bottom' | 'right' = 'right'
  private _borderWidth = 6
  private _initialHeight = 100

  override _init() {
    this._initialHeight = getBestDimensionValue(this, 'h')
    this.Image.on('txError', e => {
      debug.warn('Texture Error %s', e.message)
      this.Backup.patch({
        alpha: 1,
      })
      this.presenceAnimation.start()
    })

    this.Image.on('txLoaded', () => {
      debug.info(
        'Image texture loaded %s %s %s %s %s %s %s %s',
        this.Image.x,
        this.Image.y,
        this.Image.h,
        this.Image.w,
        this.w,
        this.h,
        this.x,
        this.y,
        this,
      )
      this.presenceAnimation.start()
    })
  }

  get helloAnimation() {
    if (!this._helloAnimation) {
      this._helloAnimation = this.animation({
        duration: 0.3,
        delay: 0.5,
        actions: [
          {
            t: 'HelloText',
            p: 'alpha',
            v: { 0: 0, 1: 1 },
          },
        ],
      })
    }
    this._helloAnimation.on('finish', () => {
      this.HelloText.setSmooth('alpha', 0, { duration: 0.2, delay: 3 })
    })
    return this._helloAnimation
  }

  get presenceAnimation() {
    if (!this._presenceAnimation) {
      const entranceProp =
        this.entrance === 'right' || this.entrance === 'left' ? 'x' : 'y'
      const entranceOffset =
        this.entrance === 'right' || this.entrance === 'bottom'
          ? this.h
          : -1 * this.h
      this._presenceAnimation = this.animation({
        delay: this.animatePresenceDelay,
        duration: 1,
        actions: [
          {
            p: 'scale',
            v: { 0: 0.7, 1: 1 },
          },
          {
            p: 'alpha',
            v: { 0: 0.0001, 1: 1 },
          },
          {
            t: 'InnerContainer',
            p: entranceProp,
            v: { 0: entranceOffset, 1: 0 },
          },
          {
            t: 'Backup',
            p: entranceProp,
            v: { 0: entranceOffset, 1: 0 },
          },
        ],
      })
    }
    this._presenceAnimation.on('start', () => {
      this.helloAnimation.start()
    })
    this._presenceAnimation.on('finish', () => {
      debug.info('Presence Animation end', this)
    })
    return this._presenceAnimation
  }

  checkDimensions() {
    const h = getBestDimensionValue(this, 'h')
    debug.info('Got active with h')
    if (h !== this._initialHeight) {
      this._initialHeight = h
      this.patch({
        w: this.h,
        HelloText: {
          text: {
            lineHeight: this.h - 4,
          },
        },
        Backup: {
          h: this.h,
          w: this.w,
          Background: {
            h: this.h,
            w: this.w,
          },
          Text: {
            h: this.h,
            w: this.w,
            text: {
              lineHeight: this.h,
            },
          },
          shader: {
            radius: this.h / 2,
          },
          Border: {
            h: this.h,
            w: this.w,
            shader: {
              radius: this.h / 2,
            },
          },
        },
        InnerContainer: {
          x: 0,
          y: 0,
          h,
          w: h,
          Image: {
            h: this.h,
            w: this.w,
            texture: this._user
              ? Img(this._user.avatar).cover(h, h)
              : undefined,
          },
          shader: {
            radius: this.h / 2,
          },
          Border: {
            h: this.h,
            w: this.w,
            shader: {
              radius: this.h / 2,
            },
          },
        },
      })
    }
  }
  override _active() {
    this.checkDimensions()
  }
  set borderWidth(stroke: number) {
    if (stroke !== this._borderWidth) {
      this._borderWidth = stroke
      this.Border.patch({
        shader: {
          stroke,
        },
      })
    }
  }
  get borderWidth() {
    return this._borderWidth
  }

  private _borderColor = Colors('text').get()
  set borderColor(color: string | number) {
    color = Colors(color).get()
    if (color !== this._borderColor) {
      this._borderColor = color
      this.Border.patch({
        shader: {
          strokeColor: color,
        },
      })
    }
  }
  get borderColor() {
    return this._borderColor
  }
  private _animatePresenceDelay = 0
  set animatePresenceDelay(delay: number) {
    if (delay !== this._animatePresenceDelay) {
      this._animatePresenceDelay = delay
      this._presenceAnimation = undefined
    }
  }
  get animatePresenceDelay() {
    return this._animatePresenceDelay
  }

  private _user: User | undefined
  public set user(user: User) {
    if (!equal(user, this._user)) {
      this.checkDimensions()
      this._user = user
      const { h, w } = getBestDimensionValues(this)
      this.Image.patch({
        texture: Img(user.avatar).cover(w, h),
        shader: undefined,
      })
      this.HelloText.patch({
        text: {
          text: `Hi ${capitalCase(user.name)}!`,
        },
      })
    }
  }
}
