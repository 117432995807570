import { DirectionalSignalMap } from '@adiffengine/engine-types'
import { Colors, Lightning } from '@lightningjs/sdk'
import { Debugger, cp, getCoordinateDimensions, isGoodNumber } from '../lib'
import { getStoredTheme } from '../themes'
import { HoverZone } from './helpers/HoverZone'
const debug = new Debugger('SearchLetter')
const LETTER_HEIGHT = 54

export interface SearchLetterTemplateSpec
  extends Lightning.Component.TemplateSpec {
  dontHandleKeys: boolean
  Shadow: object
  Background: object
  TextContainer: {
    Text: object
  }
  HoverZone: typeof HoverZone
  label: string
}
export interface SearchLetterTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: DirectionalSignalMap
}
export class SearchLetter
  extends Lightning.Component<SearchLetterTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<SearchLetterTemplateSpec>
{
  TextContainer = this.getByRef('TextContainer')!
  Text = this.TextContainer.getByRef('Text')!
  static getSizePatch(w: number, h: number) {
    w = isGoodNumber(w, true) ? w : 54
    h = isGoodNumber(h, true) ? h : LETTER_HEIGHT
    return {
      w: w,
      h: h,
      Shadow: {
        x: w / 2,
        y: h / 2,
        h: h * 1.5,
        w: w * 1.5,
        alpha: 0,
        shader: {
          fade: h * 0.375,
        },
      },
      Background: {
        x: w / 2,
        y: h / 2,
        w: w,
        h: h,
      },
      TextContainer: {
        y: 2,
        w: w,
        h: h,
        fontSize: h / 2.5,
      },
      HoverZone: {
        w: w,
        h: h,
      },
    }
  }

  static override _template() {
    const theme = getStoredTheme()
    return {
      w: LETTER_HEIGHT,
      h: LETTER_HEIGHT,
      Shadow: {
        x: LETTER_HEIGHT / 2,
        y: LETTER_HEIGHT / 2,
        h: LETTER_HEIGHT * 1.5,
        w: LETTER_HEIGHT * 1.5,
        mountX: 0.5,
        mountY: 0.5,
        color: Colors(theme.palette.darks[500]).alpha(0.4).get(),
        rect: true,
        rtt: true,
        shader: {
          type: Lightning.shaders.FadeOut,
          fade: LETTER_HEIGHT * 0.375,
        },
      },
      Background: {
        rect: true,
        x: LETTER_HEIGHT / 2,
        y: LETTER_HEIGHT / 2,
        w: LETTER_HEIGHT,
        h: LETTER_HEIGHT,
        mountX: 0.5,
        mountY: 0.5,
        alpha: 1,
        color: Colors('buttonBackground').get(),
        rtt: true,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 4,
        },
      },
      TextContainer: {
        y: 2,
        w: LETTER_HEIGHT,
        h: LETTER_HEIGHT,
        flex: {
          justifyContent: 'center',
          alignItems: 'center',
        },
        Text: {
          color: Colors('buttonText').get(),
          text: {
            fontFace: 'Bold',
            fontSize: LETTER_HEIGHT / 2.5,
          },
        },
      },
      HoverZone: {
        x: 0,
        y: 0,
        w: cp,
        h: cp,
        type: HoverZone,
        signals: {
          hovered: '_keyHover',
          clicked: '_handleEnter',
        },
      },
    }
  }
  _keyHover() {
    debug.info('hovered over letter')
    this.signal('hovered', getCoordinateDimensions(this))
  }
  private _label: string = ''

  set label(text: string) {
    this.Text.patch({
      text: { text },
    })
    this._label = text
  }

  override _focus() {
    this._focusAnimation?.start()
  }
  override _unfocus() {
    this._focusAnimation?.stop()
  }

  override _handleDown() {
    if (!this.dontHandleKeys) return this.signalDirection('down')
    else return false
  }
  override _handleUp() {
    if (!this.dontHandleKeys) return this.signalDirection('up')
    else return false
  }
  override _handleLeft() {
    if (!this.dontHandleKeys) return this.signalDirection('left')
    else return false
  }
  override _handleRight() {
    if (!this.dontHandleKeys) {
      const response = this.signalDirection('right')
      debug.info('response', response)
      return response
    } else return false
  }

  public dontHandleKeys: boolean = false

  signalDirection(dir: 'up' | 'down' | 'left' | 'right') {
    debug.info('Signaling direction', (this as any).__signals)
    const coords = getCoordinateDimensions(this)
    return this.signal(dir, coords)
  }

  override _handleEnter() {
    if (this._label.trim() !== '') {
      this.fireAncestors('$searchKey', this._label)
    }
  }

  private _focusAnimation: Lightning.types.Animation | null = null
  override _init() {
    const theme = this.fireAncestors('$theme')
    const { w = LETTER_HEIGHT, h = LETTER_HEIGHT } = this
    const sizePatch = SearchLetter.getSizePatch(w, h)
    this.patch(sizePatch)
    if (theme) {
      this._focusAnimation = this.animation({
        duration: 0.2,
        actions: [
          { t: 'Shadow', p: 'alpha', v: { 0: 0, 1: 0.5 } },
          {
            t: 'Background',
            p: 'color',
            v: {
              0: Colors(theme.palette.buttonBackground).get(),
              1: Colors(theme.palette.highlights[500]).get(),
            },
          },
          { t: 'Background', p: 'scale', v: { 0: 1, 1: 1.05 } },
          { t: 'Shadow', p: 'scale', v: { 0: 1, 1: 1.05 } },
          {
            t: 'TextContainer.Text',
            p: 'color',
            v: {
              0: Colors(theme.palette.buttonText).get(),
              1: Colors(theme.palette.buttonHighlightText).get(),
            },
          },
        ],
      })
    }
  }
}
