import type { Parameters } from '@firebolt-js/sdk'

import { isGoodString } from '../lightning-tools'
import {
  isSearchIntent,
  isTVEpisodeEntity,
  isTVSeasonEntity,
  isTVSeriesEntity,
} from './types'
export interface FirebotParamsRoute {
  path: string
}

export function isEntityIntent(
  x: Parameters.NavigationIntent,
): x is Parameters.EntityIntent {
  return x.action === 'entity'
}
export function paramsRoutePath(
  params: Parameters.AppInitialization,
): string | null {
  if (params.discovery?.navigateTo) {
    switch (params.discovery.navigateTo.action) {
      case 'home':
        return 'home'
      case 'search':
        return 'search'
      case 'entity': {
        const { data: entityData } = params.discovery.navigateTo
        if (isTVEpisodeEntity(entityData)) {
          return `details/tv/${entityData.seriesId}`
        } else if (isTVSeriesEntity(entityData)) {
          return `details/tv/${entityData.entityId}`
        } else if (isTVSeasonEntity(entityData)) {
          return `details/tv/${entityData.seriesId}`
        }
        return `details/movie/${entityData.entityId}`
      }
      case 'playback': {
        const { data: playbackData } = params.discovery.navigateTo
        if (isTVEpisodeEntity(playbackData)) {
          return `player/tv/${playbackData.seriesId}/episode/${playbackData.entityId}`
        } else {
          return `player/movie/${playbackData.entityId}`
        }
      }
      default:
        return null
    }
  } else {
    return null
  }
}
function paramsQueryParser(
  x: Parameters.AppInitialization,
): Record<string, string> {
  const nav = x.discovery?.navigateTo
  if (isSearchIntent(nav)) {
    const query = nav.data?.query ? nav.data?.query.trim() : ''
    if (isGoodString(query)) {
      return {
        query,
      }
    }
  }
  return {}
}
export interface ParamsRouteResponse {
  path: string
  params: Record<string, string>
}
export function paramsRoute(bootParams: Parameters.AppInitialization) {
  const path = paramsRoutePath(bootParams)
  const params = paramsQueryParser(bootParams)
  return {
    path,
    params,
  }
}
