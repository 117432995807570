import { Colors, Lightning } from '@lightningjs/sdk'
import { Debugger } from '../lib'
const debug = new Debugger('SimpleCardText')
export interface SimpleCardTextTemplateSpec
  extends Lightning.Component.TemplateSpec {
  TextWrapper: {
    Title: object
    Description: object
    Details: object
  }
  title: string
  details: string
  description: string
  largeFonts: boolean
  width: number
  fontColor: number
}
export interface SimpleCardTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: {
    active(height: number): void
  }
}
export class SimpleCardText extends Lightning.Component {
  TextWrapper = this.getByRef('TextWrapper')
  static override _template() {
    return {
      TextWrapper: {
        color: 0x00000000,
        flex: {
          direction: 'column',
        },
        Title: {
          color: Colors('text').get(),
          text: {
            text: '',
            advancedRenderer: true,
            lineHeight: 20 * 1.25,
            fontSize: 20,
            fontFace: 'Regular',
            textAlign: 'left',
            wordWrap: true,
            maxLines: 1,
          },
        },
        Description: {
          visible: false,
          flexItem: {
            marginTop: 0,
            grow: 1,
          },
          color: Colors('text').get(),
          text: {
            text: '',
            advancedRenderer: true,
            fontFace: 'Text',
            fontSize: 16,
            lineHeight: 16 * 1.5,
            textAlign: 'left',
            wordWrap: true,
            maxLines: 2,
          },
        },
        Details: {
          color: Colors('text').get(),
          text: {
            text: '',
            advancedRenderer: true,
            fontFace: 'Regular',
            fontSize: 16,
            lineHeight: 16 * 1.5,
            wordWrap: true,
            maxLines: 1,
            verticalAlign: 'bottom',
          },
        },
      },
    }
  }
  set width(wordWrapWidth: number) {
    debug.info('Setting Word Wrap Width To %s', wordWrapWidth)
    this.TextWrapper.patch({
      Title: { text: { wordWrapWidth } },
      Details: { text: { wordWrapWidth } },
      Description: { text: { wordWrapWidth } },
    })
  }

  set fontColor(color: number) {
    this.TextWrapper.patch({
      Title: { color },
      Details: { color },
      Description: { color },
    })
  }
  set title(text: string) {
    this.TextWrapper.getByRef('Title').patch({
      text: { text },
    })
  }
  set details(text: string) {
    this.TextWrapper.getByRef('Details').patch({
      text: { text },
    })
  }
  set description(text: string) {
    this.TextWrapper.getByRef('Description').patch({
      text: { text },
      visible: text.trim().length > 0,
    })
  }
  private _largeText: boolean = false
  set largeText(large: boolean) {
    if (large !== this._largeText) {
      this.patch(
        large ? SimpleCardText.largeFontPatch : SimpleCardText.regularFontPatch,
      )
      this._largeText = large
    }
  }

  static largeFontPatch = {
    Label: {
      text: {
        lineHeight: 24 * 1.5,
        fontSize: 24,
      },
    },
    Description: {
      text: {
        lineHeight: 18 * 1.5,
        fontSize: 18,
      },
    },
    Details: {
      text: {
        lineHeight: 18 * 1.5,
        fontSize: 18,
      },
    },
  }
  static regularFontPatch = {
    Label: {
      text: {
        lineHeight: 18 * 1.5,
        fontSize: 18,
      },
    },
    Description: {
      text: {
        lineHeight: 16 * 1.5,
        fontSize: 16,
      },
    },
    Details: {
      text: {
        lineHeight: 16 * 1.5,
        fontSize: 16,
      },
    },
  }
}
