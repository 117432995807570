import { Colors, Lightning } from '@lightningjs/sdk'

import { AreYouStillThere } from '..'
import { Debugger } from '../lib'

const debug = new Debugger('DevScreen')

// function convertSvgToHastElement(svg: RootNode): HastElement {
//   const children = svg.children.map(child => {
//     const { value, children, ...rest } = child
//     return {
//       value: `${value}`,
//       ...rest,
//       tagName: '',
//       children: children.map(c => !isString(c))
//       properties: (rest as any).properties ?? {},
//     }
//   })
//   return {
//     tagName: 'svg',
//     properties: {},
//     ...svg,
//     children,
//     type: 'element',
//   }
// }

export interface DevScreenTypeConfig
  extends Lightning.Component.TypeConfigLoose {
  IsPage: true
}
export class DevScreen extends Lightning.Component<
  Lightning.Component.TemplateSpecLoose,
  DevScreenTypeConfig
> {
  StillHere = this.getByRef('StillHere')!
  Nothing = this.getByRef('Nothing')!
  static override _template() {
    return {
      x: 0,
      y: 0,
      w: 1920,
      h: 1080,
      StillHere: {
        x: 0,
        y: 0,
        w: 1920,
        h: 1080,
        type: AreYouStillThere,
      },
      Nothing: {
        x: 100,
        y: 100,
        w: 100,
        h: 100,
        rect: true,
        color: Colors('background').get(),
        rtt: true,
      },
    }
  }
  override async _active() {
    const done = await this.StillHere.check()
    debug.info('Still Here Done', done, this.Nothing.attached, this.Nothing.p)
    this.currentFocus = null
  }

  private _currentFocus: typeof this.StillHere | null = this.StillHere
  public get currentFocus(): typeof this.StillHere | typeof this.Nothing {
    return this._currentFocus
  }

  public set currentFocus(value: typeof this.StillHere | typeof this.Nothing) {
    this._currentFocus = value
    this._refocus()
  }

  override _getFocused() {
    return this._currentFocus
  }
}
