import {
  Coords,
  PointerEvents,
  PointerState,
  RemoteDirection,
} from '@adiffengine/engine-types'
import Emittery from 'emittery'
import { getSafeDocument, isNumber } from './utils'
import { Registry, Settings } from '@lightningjs/sdk'
import { Debugger } from './debugger'
import { isGoodNumber } from './lightning-tools'
const debug = new Debugger('mouseHelper')

export interface MouseHelperEvents {
  drag: {
    current: Coords
    delta: Coords
  }
  mousedown: Coords
  mouseup: Coords
}

const mc = (e: MouseEvent) => ({
  x: e.clientX,
  y: e.clientY,
})

export class MouseHelper extends Emittery<MouseHelperEvents> {
  private _window: Window | null = null
  private _throttle: number = 10
  constructor(throttle?: number) {
    super()
    try {
      if (typeof window !== undefined) {
        this._window = window
      }
    } catch (error) {
      console.warn('got error initializing mouse helper')
    }
    this._handleMouseDown = this._handleMouseDown.bind(this)
    this._handleMouseUp = this._handleMouseUp.bind(this)
    this._handleMouseMove = this._handleMouseMove.bind(this)
    const settingsThrottle = Settings.get('app', 'mouseThrottle')
    if (isNumber(settingsThrottle)) {
      this._throttle = settingsThrottle
    } else if (isNumber(throttle)) this._throttle = throttle
  }
  _paused = true
  _dragging = false
  start() {
    this._paused = false
    this._window?.addEventListener('mousedown', this._handleMouseDown)
    this._window?.addEventListener('mouseup', this._handleMouseUp)
    this._window?.addEventListener('mousemove', this._handleMouseMove)
  }
  private _clearListeners() {
    this._window?.removeEventListener('mousedown', this._handleMouseDown)
    this._window?.removeEventListener('mouseup', this._handleMouseUp)
    this._window?.removeEventListener('mousemove', this._handleMouseMove)
  }
  private _lastEventCoordinates: Coords = {
    x: 0,
    y: 0,
  }
  pause() {
    this._paused = true
    this._clearListeners()
    if (this._dragging) {
      this.emit('drag', {
        current: this._lastEventCoordinates,
        delta: this._getDelta(this._lastEventCoordinates),
      })
      this._dragging = false
    }
  }
  private _startPosition: Coords = {
    x: 0,
    y: 0,
  }

  _handleMouseDown(e: MouseEvent) {
    this._dragging = true
    this._startPosition = mc(e)
    this._lastEventCoordinates = mc(e)
    this.emit('mousedown', mc(e))
  }
  _handleMouseUp(e: MouseEvent) {
    this._dragging = false
    const current = mc(e)
    const delta = this._getDelta(current)
    this.emit('drag', {
      current,
      delta,
    })
    this._lastEventCoordinates = mc(e)
    this.clearListeners()
  }
  private _throttling = false

  _handleMouseMove(e: MouseEvent) {
    if (!this._throttling && this._dragging) {
      this._throttling = true
      const current = mc(e)
      const delta = this._getDelta(current)
      debug.info('Mouse Move', { current, delta })
      this.emit('drag', {
        current,
        delta,
      })
      Registry.setTimeout(() => {
        this._throttling = false
      }, this._throttle)
    }
    if (this._dragging) this._lastEventCoordinates = mc(e)
  }
  private _getDelta(current: Coords): Coords {
    const delta = {
      x: current.x - this._startPosition.x,
      y: current.y - this._startPosition.y,
    }
    debug.info(
      'Getting delta start: %s, current: %s, delta: %s',
      this._startPosition.x,
      current.x,
      delta.x,
    )

    return delta
  }
}

interface ScrollTrack {
  direction: RemoteDirection
  tracks: number[]
}
interface ScrollEvents {
  scroll: number
}

export class ScrollHelper extends Emittery<ScrollEvents> {
  private _throttleTimer: ReturnType<typeof Registry.setTimeout> | null = null
  private _throttled: boolean = false
  private _timeout: number
  private _pointerEnabled: boolean = false
  constructor() {
    super()
    const settingsTimeout = Settings.get('app', 'SCROLL_TIMEOUT', 1000)
    this._timeout = isGoodNumber(settingsTimeout) ? settingsTimeout : 1000
    this._pointerEnabled = Settings.get('app', 'enablePointer', false)
  }

  private _scrollTrack: ScrollTrack | null = null

  event(event: WheelEvent) {
    if (!this._throttled && this._pointerEnabled) {
      debug.info('Event', event)
      const direction = event.deltaY < 0 ? 'down' : 'up'
      const strength =
        Math.abs(event.deltaY) < 75 ? 1 : Math.abs(event.deltaY) > 200 ? 3 : 2
      if (!this._scrollTrack || this._scrollTrack.direction !== direction) {
        this._scrollTrack = {
          direction,
          tracks: [strength],
        }
      } else {
        this._scrollTrack.tracks.push(strength)
      }
      if (this._scrollTrack.tracks.length > 4) {
        const max = Math.max(...this._scrollTrack.tracks)
        this.emit('scroll', max)
        this._throttle()
      }
    }
  }
  private _clearThrottleTimer() {
    if (this._throttleTimer !== null) {
      debug.info('Clearing Throttle Timer', this._throttleTimer)
      Registry.clearTimeout(this._throttleTimer)
      this._throttleTimer = null
    }
  }
  private _throttle() {
    this._clearThrottleTimer()
    this._throttled = true
    this._throttleTimer = Registry.setTimeout(() => {
      this._throttleTimer = null
      this._scrollTrack = null
      this._throttled = false
    }, this._timeout)
  }
}

export class PointerHelper extends Emittery<PointerEvents> {
  private _doc: Document | null = null
  private _state: PointerState = 'disabled'
  private _timer: ReturnType<typeof Registry.setTimeout> | null = null
  private _timeout: number
  private _throttleTimer: ReturnType<typeof Registry.setTimeout> | null = null
  private _throttled: boolean = false

  constructor() {
    super()
    const settingsTimeout = Settings.get('app', 'POINTER_TIMEOUT', 4000)
    this._timeout = isGoodNumber(settingsTimeout) ? settingsTimeout : 4000
    const pointerEnabled = Settings.get('app', 'enablePointer', false)
    if (pointerEnabled === false) {
      this._disabled()
    } else {
      this._pointerMove = this._pointerMove.bind(this)
      try {
        this._doc = getSafeDocument()
        if (this._doc === null) {
          this._disabled()
        } else {
          this.resume()
        }
      } catch (error) {
        console.warn('Error getting document for Mouse Move Detect')
        this._disabled()
      }
    }
  }
  private _clearThrottleTimer() {
    if (this._throttleTimer) {
      Registry.clearTimeout(this._throttleTimer)
      this._throttleTimer = null
    }
  }
  private _throttle() {
    this._clearThrottleTimer()
    this._throttled = true
    this._throttleTimer = Registry.setTimeout(() => {
      this._throttleTimer = null
      this._throttled = false
    }, 200)
  }
  private _resetTimer() {
    this._clearTimer()
    this._timer = Registry.setTimeout(() => {
      this.state = 'inactive'
    }, this._timeout)
  }
  private _clearTimer() {
    if (this._timer !== null) {
      Registry.clearTimeout(this._timer)
      this._timer = null
    }
  }
  private _pointerMove() {
    if (!this._throttled) {
      this._throttle()
      this._resetTimer()
      this.state = 'active'
      this.emit('moved')
    }
  }
  set state(state: PointerState) {
    if (state !== this._state) {
      this._state = state
      this.emit('state', state)
    }
  }
  get state() {
    return this._state
  }
  pause() {
    this._doc?.removeEventListener('mousedown', this._pointerMove)
    this._doc?.removeEventListener('mousemove', this._pointerMove)
  }
  resume() {
    this._doc?.addEventListener('mousedown', this._pointerMove)
    this._doc?.addEventListener('mousemove', this._pointerMove)
  }
  destroy() {
    this._clearThrottleTimer()
    this._clearTimer()
    this.pause()
    this._doc = null
  }

  private _disabled() {
    this.state = 'disabled'
  }
}
