import { Registry, Settings } from '@lightningjs/sdk'
import Emittery from 'emittery'
import { isGoodNumber } from '../../lib'
import { Debugger } from '../../lib/debugger'
type LocalTimeout = ReturnType<typeof Registry.setTimeout>
interface StillHereState {
  last_activity: Date
  timer: LocalTimeout | null
}
interface StillHereStateEvents {
  checkForState: Date
  inactive: undefined
}

type RoundingKey = keyof Pick<Math, 'round' | 'floor' | 'ceil'>
const validKeys: RoundingKey[] = ['round', 'floor', 'ceil']

function convertMinutesToMs(minutes: number) {
  return minutes * 60 * 1000
}

export class StillHereMonitor extends Emittery<StillHereStateEvents> {
  private _state: StillHereState
  private _timeoutDurationInMinutes: number = Settings.get(
    'app',
    'APP_WAITING_TIMEOUT_DURATION',
    150,
  )
  static convertMinutesToMs(minutes: number) {
    return minutes * 60 * 1000
  }
  static convertMsToMinutes(ms: number, rounding: RoundingKey = 'floor') {
    if (rounding && !validKeys.includes(rounding)) {
      console.warn(
        'Got invalid rounding method for concertMsToMinutes so using the default of "floor"',
      )
      rounding = 'floor'
    }
    return Math[rounding](ms / 601000)
  }
  constructor(
    timeoutDurationInMinutes: number | undefined | null = undefined,
    force = false,
  ) {
    super()
    this._state = {
      last_activity: new Date(),
      timer: null,
    }
    this._alert = this._alert.bind(this)
    if (
      isGoodNumber(timeoutDurationInMinutes) &&
      (force ||
        (timeoutDurationInMinutes > 5 && timeoutDurationInMinutes < 12 * 60))
    ) {
      this._timeoutDurationInMinutes = timeoutDurationInMinutes
    } else {
      console.error(
        'Got invalid timeout duration in minutes %s. Duration must be between 5 minutes and 720 minutes (12 hours)',
        timeoutDurationInMinutes,
      )
    }
  }
  private async _alert() {
    await this.emit('inactive')
  }
  get lastActivity() {
    return this._state.last_activity
  }

  restart() {
    if (this._state.timer !== null) {
      Registry.clearTimeout(this._state.timer)
      this._state = {
        last_activity: new Date(),
        timer: Registry.setTimeout(
          this._alert,
          StillHereMonitor.convertMinutesToMs(this._timeoutDurationInMinutes),
        ),
      }
    }
  }
}

export interface MediaStillHereOptions {
  timeoutInMinutes: number
  initialState: Partial<{ mediaActive: boolean; mediaPaused: boolean }>
}

export enum MediaStillHereStates {
  MEDIA_PAUSED = 'MediaPaused',
  MEDIA_PLAYING = 'MediaPlaying',
  MEDIA_INACTIVE = 'MediaInactive',
}

export enum MediaStillHerePauseState {
  INACTIVE = 'PauseStateInactive',
  PENDING = 'PauseStatePending',
  EXPIRED = 'PauseStateExpired',
}

export interface MediaStillHereEvents {
  needsMediaCheck: undefined
  needsPauseCheck: undefined
  mediaTimeReset: undefined
  pauseTimerState: MediaStillHerePauseState
}

export interface MediaStillHereConfig {
  currentState: MediaStillHereStates
  pauseTimeoutInMinutes: number
  playingTimeoutInMinutes: number
  currentMediaDurationInMinutes?: number | null | undefined
}

export const defaultMediaStillHereConfig: MediaStillHereConfig = {
  currentState: MediaStillHereStates.MEDIA_INACTIVE,
  pauseTimeoutInMinutes: 5,
  playingTimeoutInMinutes: 250,
}

const mediaDebug = new Debugger('StillHereMediaMonitor')
export class StillHereMediaMonitor extends Emittery<MediaStillHereEvents> {
  currentPlaytime: number = 0
  pausedTimer: LocalTimeout | null = null
  playbackStartTime: number = -1
  pauseTime = 0
  private _config: Omit<MediaStillHereConfig, 'currentState'>
  constructor(
    config: Partial<MediaStillHereConfig> = defaultMediaStillHereConfig,
  ) {
    super()
    const { currentState, ...rest } = {
      ...defaultMediaStillHereConfig,
      ...config,
    }
    this._mediaState = currentState
    this._config = rest
    mediaDebug.info('Created Media Monitor', this._mediaState, this._config)
  }

  private _pauseState: MediaStillHerePauseState =
    MediaStillHerePauseState.INACTIVE

  private _clearPauseTimer() {
    if (this.pausedTimer != null) Registry.clearTimeout(this.pausedTimer)
  }
  set pauseState(state: MediaStillHerePauseState) {
    if (state !== this._pauseState) {
      mediaDebug.info(
        'Media Monitor pauseState change from %s to %s',
        this._pauseState,
        state,
      )
      this._pauseState = state
      switch (state) {
        case MediaStillHerePauseState.INACTIVE:
          this._clearPauseTimer()
          break
        case MediaStillHerePauseState.PENDING:
          this._clearPauseTimer()
          mediaDebug.info(
            'Starting Pause State Timer for %s seconds',
            Math.round(
              convertMinutesToMs(this._config.pauseTimeoutInMinutes) / 1000,
            ),
          )
          this.pausedTimer = Registry.setTimeout(() => {
            mediaDebug.info('Pause Timer Timed Out')
            this.pauseState = MediaStillHerePauseState.EXPIRED
            this.pausedTimer = null
          }, convertMinutesToMs(this._config.pauseTimeoutInMinutes))
          break
        case MediaStillHerePauseState.EXPIRED:
          this.emit('needsPauseCheck')
          break
      }
      this.emit('pauseTimerState', state)
    }
  }
  private _mediaState: MediaStillHereStates =
    MediaStillHereStates.MEDIA_INACTIVE
  private _pausedDuration: number = 0
  private _lastPauseTime: number | null = null

  resetMedia() {
    this._lastPauseTime = null
    this._pausedDuration = 0
  }

  set mediaState(state: MediaStillHereStates) {
    if (this._mediaState !== state) {
      mediaDebug.info(
        'Media Monitor mediaState change from %s to %s',
        this._mediaState,
        state,
      )
      switch (state) {
        case MediaStillHereStates.MEDIA_INACTIVE:
          this.pauseState = MediaStillHerePauseState.INACTIVE
          this.resetMedia()
          break
        case MediaStillHereStates.MEDIA_PLAYING:
          this.pauseState = MediaStillHerePauseState.INACTIVE
          this.playbackStartTime = new Date().getTime()
          if (this._lastPauseTime !== null) {
            const pauseDuration = this.playbackStartTime - this._lastPauseTime
            this._lastPauseTime = null
            this._pausedDuration += pauseDuration
          }
          break
        case MediaStillHereStates.MEDIA_PAUSED:
          mediaDebug.info('Paused, going to pending.')
          this.pauseState = MediaStillHerePauseState.PENDING
          this._lastPauseTime = new Date().getTime()
      }
      this._mediaState = state
    }
  }
  get mediaState() {
    return this.mediaState
  }
  mediaPlaying() {
    mediaDebug.info('Media Playing Called')
    this.mediaState = MediaStillHereStates.MEDIA_PLAYING
  }
  mediaPaused() {
    mediaDebug.info('Media Paused Called')
    this.mediaState = MediaStillHereStates.MEDIA_PAUSED
  }
  mediaInactive() {
    this.mediaState = MediaStillHereStates.MEDIA_INACTIVE
  }
}
