import { ContentItem, MediaDetails } from '@adiffengine/engine-types'
export interface LoaderOpts {
  vastUrl?: string
}
export interface VideoDetails {
  media?: MediaDetails
  content?: ContentItem
}
export type VideoLoader = (url: string, video: HTMLVideoElement) => void
export type CreateVideoLoader = (
  details: VideoDetails,
  opts?: LoaderOpts,
) => VideoLoader

export interface ADEVastPlayerSources {
  src: string
  adUrl?: string | null
}
export type IsBooleanOrString<T> = T extends true ? string : number
export const ADE_CONTAINER_ID = 'ade-vast'
export const ADE_CONTAINER_CLASS = 'ade-vast-container'
export const ADE_CONTENT_CLASS = 'ade-vast-content'
export const ADE_VIDEO_ID = 'ade-vast-video'
export const ADE_VIDEO_CLASS = ADE_VIDEO_ID
