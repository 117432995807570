export function domSafeDoc(): Document | null {
  try {
    if (typeof document !== 'undefined') {
      return document
    } else {
      return null
    }
  } catch (e) {
    return null
  }
}
