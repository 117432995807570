import { Lightning } from '@lightningjs/sdk'

import QRCode from 'qrcode'
export type FactoryCallback = (
  err: string | Event | Error | null,
  canvas?: HTMLCanvasElement,
) => void
export interface AdeQrCodeTemplateSpec
  extends Lightning.Component.TemplateSpec {
  url: string
  size: number
  Code: object
}
export class AdeQrCode extends Lightning.Component<AdeQrCodeTemplateSpec> {
  Code = this.getByRef('Code')!
  static override _template() {
    return {
      w: 400,
      h: 400,
      Code: {
        rtt: true,
        x: 0,
        y: 0,
        w: 400,
        h: 400,
      },
    }
  }

  private _size = 400
  set size(size: number) {
    if (size !== this._size) {
      this._size = size
      this.renderTexture()
      this.patch({
        w: size,
        h: size,
        Code: {
          w: size,
          h: size,
        },
      })
    }
  }
  get size() {
    return this._size
  }
  override _construct() {
    this._createCodeCanvas = this._createCodeCanvas.bind(this)
  }
  private _url: string | null = null
  set url(url: string | null) {
    console.info('Got url for QR Code %s', url)
    this._url = url
    this.renderTexture()
  }
  get url() {
    return this._url
  }

  renderTexture() {
    if (this._url !== null) {
      this.Code.patch({
        alpha: 1,
        texture: Lightning.Tools.getCanvasTexture(
          this._createCodeCanvas(this._url),
          this._url,
        ),
      })
    } else {
      this.Code.patch({
        alpha: 0,
      })
    }
  }
  private _createCodeCanvas(url: string) {
    return (cb: FactoryCallback, stage: Lightning.Stage) => {
      const canvas = stage.getDrawingCanvas()
      canvas.width = this.size
      canvas.height = this.size
      QRCode.toCanvas(canvas, url, function (error) {
        if (error) {
          console.error('Error generating QR Code %s', error.message, error)
          cb(error)
        } else {
          cb(null, canvas)
        }
      })
    }
  }
}
