export const MituSettingsText: Record<
  string,
  { headerText: string; text: string; subheaderText?: string }
> = {
  about_us: {
    headerText: 'About Us',
    text: `mitú is the leading digital media company representing the Latino point of view among consumers 18-44. Through our multiple touch points in video, editorial, social media and commerce, we connect brands, content buyers, and creators to the massive community of Latino consumers in America.
Our audience is the 200% —  100% American and 100% Latino – who inspire us to create authentic, culturally relevant stories.  We reach a massive, cross-cultural audience across a variety of social and O&O platforms.

mitú is a proud GoDigital Media Group company headquartered in Los Angeles, CA with operations in New York, Miami, Chicago, Dallas, Mexico, Colombia, Belarus, Sri Lanka and South Korea.`,
  },
  privacy: {
    headerText: 'Privacy Policy',
    subheaderText: 'EFFECTIVE DATE: March 31, 2020',
    text: `For the mitú privacy policy please visit https://www.mitu.tv/privacy-policy/

If you have questions about this Privacy Policy please contact us at legal@nglmitu.com.`,
  },
  terms: {
    headerText: 'Terms',
    subheaderText: `February 21, 2015`,
    text: `Please visit https://www.mitu.tv/terms to view Terms and Conditions`,
  },
}
export type MituTextKey = keyof typeof MituSettingsText
