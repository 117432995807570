import {
  ContentItem,
  CoordinateDimensions,
  DirectionalSignalMap,
  GridResponse,
  setsByY,
} from '@adiffengine/engine-types'
import { Lightning, Router } from '@lightningjs/sdk'
import defer from 'lodash/defer'
import { MainMenu } from '../components/MainMenu'
import { Debugger } from '../lib'
import { GridHomeContent } from './GridHomeContent'
type NavigationPath = keyof ContentItem['paths']
const navPaths: NavigationPath[] = ['details', 'player']
export type GridCardType = 'wide' | 'box' | null
// @ts-ignore
const debug = new Debugger('Home')

export interface GridHomeTypeConfig extends Lightning.Component.TypeConfig {
  IsPage: true
  initialFocus: 'Grid' | 'Menu'
  SignalMapType: DirectionalSignalMap
}
export interface GridHomeTemplateSpec extends Lightning.Component.TemplateSpec {
  Content: typeof GridHomeContent
  navigationPath: NavigationPath
  content: GridResponse
  Menu: typeof MainMenu
  card: GridCardType
  page: string
}

export class GridHome
  extends Lightning.Component<GridHomeTemplateSpec, GridHomeTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<GridHomeTemplateSpec>
{
  public IsPage = true
  Content = this.getByRef('Content')!

  override get id() {
    return 'GridHome'
  }

  public page = 'Grid Home'
  public navigationPath: 'details' | 'player' = 'details'
  static override _template() {
    return {
      x: 0,
      y: 0,
      w: 1920,
      h: 1080,
      Content: {
        x: 120 + MainMenu.widthClosed, // 80 margin left, menu, 40 margin right to hero.
        y: 0,
        type: GridHomeContent,
        w: 1920 - (200 + MainMenu.widthClosed),
        signals: {
          left: '_gridLeft',
        },
      },
    }
  }

  set content(grid: GridResponse) {
    this.Content.patch({ content: grid })
  }
  get content() {
    return this.Content.content
  }

  public set card(card: GridCardType) {
    this.Content.patch({ card })
  }
  public get card() {
    return this.Content.card
  }

  private _openAnimation: Lightning.types.Animation | undefined = undefined
  get mainMenuOpenAnimation() {
    if (!this._openAnimation) {
      this._openAnimation = this.animation({
        duration: 0.2,
        actions: [
          {
            t: 'Content',
            p: 'x',
            v: { 0: MainMenu.widthClosed + 120, 1: MainMenu.width + 120 },
          },
        ],
      })
    }
    return this._openAnimation
  }

  getValidPath(paths: ContentItem['paths']): string | null {
    let destination: string | null = paths[this.navigationPath] ?? null
    if (destination) return destination
    destination = navPaths.reduce((acc, path) => {
      if (acc) return acc
      return paths[path] ?? null
    }, null as string | null)
    return destination
  }

  $contentSelected(item: ContentItem) {
    const destination = this.getValidPath(item.paths)
    if (destination) {
      this.fireAncestors('$navigate', destination)
    }
  }

  _gridLeft(coords: CoordinateDimensions | null) {
    if (this.widgets) {
      if (coords !== null && setsByY(this.widgets['mainmenu'])) {
        this.widgets['mainmenu'].setClosestByY(coords)
      }
      defer(() => {
        Router.focusWidget('MainMenu')
      })
    } else {
      return this.signal('left')
    }
  }

  override _getFocused(): Lightning.Component | undefined {
    return this.Content
  }

  override _init() {
    const menuOpen = this.fireAncestors('$menuOpen')
    this.Content.patch({
      x: menuOpen ? 120 + MainMenu.width : 120 + MainMenu.widthClosed,
    })
    this.stage.application.on('menuOpen', (open: boolean) => {
      if (open) this.mainMenuOpenAnimation.start()
      else this.mainMenuOpenAnimation.stop()
    })
  }

  override _active() {
    this.fireAncestors('$enableMainMenuPointer', true)
    this.stage.application.emit('screenView', this.page, 'grid_home')
  }
}
