import { Colors, Lightning, Router, Settings } from '@lightningjs/sdk'
import { Grid } from '@lightningjs/ui'

import isNumber from 'lodash/isNumber'
import { MainMenu } from '../components/MainMenu'
import {
  CardSettingsTemplateSpec,
  ThemeSelector,
} from '../components/ThemeSelectCard'
import { ToggleRow, ToggleRowTemplateSpec } from '../components/ToggleRow'
import { Themes, getStoredTheme } from '../themes/theme'

import { GridPosition, ThemeConfig } from '@adiffengine/engine-types'
import isFunction from 'lodash/isFunction'
import {
  Debugger,
  SettingsStore,
  Settings as TSettings,
  isGoodNumber,
  isGridPosition,
} from '../lib'

const debug = new Debugger('SET')

export interface SettingsPageTypeConfig extends Lightning.Component.TypeConfig {
  IsPage: true
}

export interface SettingsPageTemplateSpec
  extends Lightning.Component.TemplateSpec {
  MainMenu: typeof MainMenu
  Content: {
    Background: object
    Settings: typeof Grid
  }
}

export class SettingsPage
  extends Lightning.Component<SettingsPageTemplateSpec, SettingsPageTypeConfig>
  implements
    Lightning.Component.ImplementTemplateSpec<SettingsPageTemplateSpec>
{
  public initialFocus: 'Menu' | 'Content' | 'SearchBar' = 'Menu'
  public IsPage = true
  Content = this.getByRef('Content')!
  Settings = this.Content.getByRef('Settings')!
  static override _template(): Lightning.Component.Template<SettingsPageTemplateSpec> {
    const theme = getStoredTheme()
    return {
      x: 0,
      y: 0,
      w: 1920,
      h: 1080,
      Content: {
        y: 80,
        x: 120 + MainMenu.widthClosed, // 80 margin left, menu, 40 margin right to hero.
        w: 1920 - (200 + MainMenu.widthClosed),
        h: 1080 - 160,
        Background: {
          color: Colors(theme.palette.darks[600]).get(),
          rect: true,
          shader: {
            type: Lightning.shaders.RoundedRectangle,
            radius: 12,
          },
        },
        Settings: {
          x: 0,
          y: 0,
          h: 1080 - 160,
          w: 1920 - (200 + MainMenu.widthClosed),
          type: Grid,
          spacing: 40,
          columns: 2,
          direction: 'column',
          signals: {
            onIndexChanged: '_indexChanged',
          },
        },
      },
    }
  }

  _spacing: number | null = null
  _items: Lightning.Element.PatchTemplate[] = []
  _contentAnimation: Lightning.types.Animation | undefined = undefined
  _baseTheme: ThemeConfig | undefined = undefined

  get spacing(): number {
    if (isGoodNumber(this._spacing)) return this._spacing
    const tag = this.Settings
    if (tag) {
      const { spacing, _mainSpacing = 20 } = this.Settings!
      return isGoodNumber(spacing)
        ? spacing
        : isGoodNumber(_mainSpacing)
        ? _mainSpacing
        : 20
    }
    return 20
  }

  $setting<T extends keyof TSettings>(
    setting: T,
    value?: TSettings[T],
  ): TSettings[T] | null {
    if (value == null) {
      const response = SettingsStore.get(setting)
      if (response) return response as TSettings[T]
      else return null
    } else {
      const changed = SettingsStore.set(setting, value)
      if (changed) {
        if (isFunction(document?.location?.reload)) {
          document.location.reload()
        }
      }
      return null
    }
  }

  set items(
    x: (
      | Lightning.Element.PatchTemplate<CardSettingsTemplateSpec>
      | Lightning.Element.PatchTemplate<ToggleRowTemplateSpec>
      | Lightning.Element.PatchTemplate
    )[],
  ) {
    this._items = x
    const { w } = this.Content
    const { spacing = 40, columns = 2 } = this.Settings
    const itemWidth = (w - (spacing * columns - 1)) / columns
    const items = x.map(content => ({
      ...content,
      w: itemWidth,
    }))
    this.Settings.patch({ items })
  }
  get items() {
    return this._items
  }

  override _active() {
    const menuOpen = this.fireAncestors('$menuOpen')
    this.Content.patch({
      x: menuOpen ? 120 + MainMenu.width : 120 + MainMenu.widthClosed,
    })
  }

  override _init() {
    this.stage.application.on('menuOpen', (menuOpen: boolean) => {
      this.Content.setSmooth(
        'x',
        menuOpen ? 120 + MainMenu.width : 120 + MainMenu.widthClosed,
        { duration: 0.2 },
      )
    })
    this.Settings?.patch({
      w: this.w - 1920 - (200 + MainMenu.widthClosed),
      h: this.h,
    })
    this.setItems()
    this._setState('ContentFocused')
  }

  setItems() {
    const theme = this.fireAncestors('$theme')
    const demoMode = Settings.get('app', 'demoMode', 'false').toString()
    let items: (
      | Lightning.Element.PatchTemplate<CardSettingsTemplateSpec>
      | Lightning.Element.PatchTemplate<ToggleRowTemplateSpec>
      | Lightning.Element.PatchTemplate
    )[] = [
      {
        type: ToggleRow,
        settingsKey: 'staticHero',
        label: 'Static Hero',
        description: 'Show Static (pinned) hero on Home Screen',
        h: 120,
      },
    ]
    if (demoMode === 'true') {
      items = [
        ...items,
        ...Themes.map(themeConfig => ({
          type: ThemeSelector,
          isTheme: true,
          h: 240,
          theme: themeConfig,
          current: themeConfig.name === theme.name,
          action: {
            action: '$setTheme',
            payload: themeConfig,
          },
        })),
      ]
    }
    this.items = items
  }

  $setTheme(config: ThemeConfig | null, temp?: boolean) {
    if (temp) {
      this.setTempTheme(config)
    } else if (config !== null) {
      this._baseTheme = undefined
      this.Settings.items?.forEach((i: Lightning.Element) => {
        if (isCurrentTheme(i) && i.isTheme === true) {
          i.patch({ current: i.themeConfig?.name === config.name })
        }
      })

      SettingsStore.set('theme', config.name)
      if (typeof document !== undefined) document.location?.reload()
    } else if (temp === false) {
      console.warn('Sent null and temp as false to set theme, this is a no op')
    }
  }

  setTempTheme(config: ThemeConfig | null) {
    if (config !== null) {
      const theme = this.fireAncestors('$theme')
      this._baseTheme = theme
      this.fireAncestors('$setTheme', config)
    } else {
      if (this._baseTheme) this.fireAncestors('$setTheme', this._baseTheme)
    }
  }

  _edges: {
    left: boolean
    right: boolean
    top: boolean
    bottom: boolean
  } = { left: false, right: false, top: false, bottom: false }

  _indexChanged(args: GridPosition) {
    this._checkEdges(args)
  }

  _checkEdges(x?: GridPosition) {
    const position = x ?? this._getGridPositionFromIndex()
    if (isGridPosition(position)) {
      const columns = this.Settings!.columns
      const lastRowMaxColumn =
        position!.mainIndex === position.lines - 1
          ? (position.dataLength % columns) - 1
          : columns - 1
      const edge: Partial<typeof this._edges> = {}
      edge.bottom = position.mainIndex === position.lines - 1
      edge.top = position.mainIndex === 0
      edge.left = position.crossIndex === 0
      edge.right = position.crossIndex === lastRowMaxColumn
      const newEdges = { ...this._edges, ...edge }
      this._edges = newEdges
    } else {
      debug.info('Invalid Grid Position', position)
    }
  }

  _getGridPositionFromIndex(
    index?: number,
  ): Omit<
    GridPosition,
    'previousIndex' | 'previousCrossIndex' | 'previousMainIndex'
  > | null {
    index = isNumber(index) ? index : this.Settings!.index
    if (this.Settings.items.length > 0) {
      const currentLocation = this.tag(
        'Content.Settings',
      )!._findLocationOfIndex(index) as Pick<
        GridPosition,
        'crossIndex' | 'mainIndex'
      >
      const dataLength = this.Settings.items.length
      const lines = Math.ceil(
        this.Settings.items.length / this.Settings!.columns,
      )
      return {
        ...currentLocation,
        lines,
        dataLength,
      }
    } else {
      return null
    }
  }

  private _gridAnimation: Lightning.types.Animation | undefined = undefined
  get gridAnimation() {
    if (!this._gridAnimation) {
      this._gridAnimation = this.animation({
        duration: 0.2,
        actions: [
          {
            t: 'Content',
            p: 'x',
            v: { 1: MainMenu.widthClosed + 120, 0: MainMenu.width + 120 },
          },
        ],
      })
    }
    return this._gridAnimation
  }

  override _handleLeft(): boolean | void {
    if (this.currentFocus === 'Content.Settings' && this._edges.left) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Router.focusWidget('MainMenu')
    }
  }
  _menuRight(): boolean | void {
    if (this.currentFocus === 'MainMenu') {
      this.currentFocus = 'Content.Settings'
      return false
    }
  }

  private _focused: 'Content.Settings' | 'MainMenu' = 'Content.Settings'

  set currentFocus(x: typeof this._focused) {
    this._focused = x
    if (x === 'Content.Settings') {
      this.gridAnimation.stop()
    } else {
      this.gridAnimation.start()
    }
    this._refocus()
  }

  get currentFocus() {
    return this._focused
  }
  override _getFocused() {
    return this.tag(this.currentFocus)
  }

  override set params(params: Router.PageParams) {
    this.mainMenuInitalActive(params?.['fromMain'] === 'true')
  }
  private _justMounted = false
  override _focus() {
    this.gridAnimation.start()
    if (this._justMounted === true) {
      this.gridAnimation.finish()
      this._justMounted = true
    }
    this._checkEdges()
  }
  override _unfocus() {
    this.gridAnimation.stop()
  }
  mainMenuInitalActive(active = false) {
    if (active) {
      this.Content.patch({
        x: MainMenu.width + 120,
      })
    } else {
      this._justMounted = true
    }
  }
}
export interface CurrentThemeElement extends Lightning.Element {
  isTheme: boolean
  current: boolean
  themeConfig?: ThemeConfig
}
export function isCurrentTheme(x: Lightning.Element): x is CurrentThemeElement {
  return (
    (x as CurrentThemeElement).isTheme != null &&
    (x as CurrentThemeElement).current != null
  )
}
