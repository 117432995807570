import {
  CoordinateDimensions,
  DirectionalSignalMap,
  GridPosition,
  RemoteDirection,
} from '@adiffengine/engine-types'
import { Lightning } from '@lightningjs/sdk'
import { Grid } from '@lightningjs/ui'
import isNumber from 'lodash/isNumber'
import { Debugger, isGridPosition } from '../lib'
import { SearchLetter } from './SearchLetter'

const debug = new Debugger('SearchKeyboard')
const LETTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
export const LETTER_HEIGHT = 54
export const LETTER_SPACING = 4
export const LETTER_COLUMNS = 6
export const KEYBOARD_WIDTH =
  (LETTER_HEIGHT + LETTER_SPACING) * LETTER_COLUMNS - LETTER_SPACING
export const KEYBOARD_HEIGHT =
  (LETTER_HEIGHT + LETTER_SPACING) * Math.ceil(LETTERS.length / LETTER_COLUMNS)

export class SearchKeyboard
  extends Lightning.Component<
    SearchKeyboardTemplateSpec,
    SearchKeyboardTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<SearchKeyboardTemplateSpec>
{
  static letters = LETTERS
  SearchKeys = this.getByRef('SearchKeys')!
  static override _template(): Lightning.Component.Template<SearchKeyboardTemplateSpec> {
    return {
      w: KEYBOARD_WIDTH,
      h: KEYBOARD_HEIGHT,
      SearchKeys: {
        x: 0,
        y: 0,
        w: 360,
        h: 400,
        type: Grid,
        columns: 6,
        itemType: SearchLetter,
        spacing: 4,
        direction: 'column',
        signals: {
          onIndexChanged: '_indexChanged',
        },
      },
      ExtraKeys: {
        x: 116,
        y: 232,
        SpaceKey: {
          x: 0,
          y: 0,
          w: 112,
          h: 54,
          label: 'SPACE',
          type: SearchLetter,
          signals: {
            hovered: '_spaceHover',
            left: '_spaceLeft',
            right: '_spaceRight',
            up: '_spaceUp',
          },
        },
        DelKey: {
          x: 116,
          y: 0,
          w: 112,
          h: 54,
          label: 'DEL',
          signals: {
            left: '_delLeft',
            up: '_delUp',
            hovered: '_delHover',
          },
          type: SearchLetter,
        },
      },
    }
  }

  private _edges: {
    left: boolean
    right: boolean
    top: boolean
    bottom: boolean
  } = { left: false, right: false, top: false, bottom: false }

  _indexChanged(args: GridPosition) {
    this._checkEdges(args)
  }
  _getGridPositionFromIndex(
    index?: number,
  ): Pick<
    GridPosition,
    'mainIndex' | 'crossIndex' | 'lines' | 'dataLength'
  > | null {
    index = isNumber(index) ? index : this.tag('SearchKeys')!.index
    const currentLocation = this.tag('SearchKeys')!._findLocationOfIndex(
      index,
    ) as Pick<GridPosition, 'crossIndex' | 'mainIndex'>
    const dataLength = SearchKeyboard.letters.length
    const lines = Math.ceil(
      SearchKeyboard.letters.length / this.tag('SearchKeys')!.columns,
    )
    return {
      ...currentLocation,
      lines,
      dataLength,
    }
  }
  _checkEdges(x?: GridPosition) {
    const position = x ?? this._getGridPositionFromIndex()
    if (isGridPosition(position)) {
      const columns = this.tag('SearchKeys')!.columns
      const lastRowMaxColumn =
        position.mainIndex === position.lines - 1
          ? (position.dataLength % columns) - 1
          : columns - 1
      const edge: Partial<typeof this._edges> = {}
      edge.bottom = position.mainIndex === position.lines - 1
      edge.top = position.mainIndex === 0
      edge.left = position.crossIndex === 0
      edge.right = position.crossIndex === lastRowMaxColumn
      const newEdges = { ...this._edges, ...edge }
      this._edges = newEdges
    } else {
      debug.info('Invalid Grid Position', position)
    }
  }

  override _init() {
    this._captureButtonDirection = this._captureButtonDirection.bind(this)
    const items = SearchKeyboard.letters.map((label, idx) => ({
      h: LETTER_HEIGHT,
      w: LETTER_HEIGHT,
      label,
      signals: {
        up: this._keyUp.bind(this),
        down: this._keyDown.bind(this),
        left: this._keyLeft.bind(this),
        right: this._keyRight.bind(this),
        hovered: () => {
          this._keyHovered(idx)
        },
      },
    }))
    this.SearchKeys.patch({ items })
  }
  override _focus() {
    this._checkEdges()
  }
  _keyHovered(idx: number) {
    this.currentFocus = 'SearchKeys'
    this.SearchKeys.setIndex(idx)
  }

  _keyUp(coords?: CoordinateDimensions) {
    return this._captureButtonDirection('up', coords)
  }
  _keyDown(coords?: CoordinateDimensions) {
    return this._captureButtonDirection('down', coords)
  }
  _keyLeft(coords?: CoordinateDimensions) {
    return this._captureButtonDirection('left', coords)
  }
  _keyRight(coords?: CoordinateDimensions) {
    return this._captureButtonDirection('right', coords)
  }

  private _captureButtonDirection(
    dir: RemoteDirection,
    coords?: CoordinateDimensions,
  ) {
    debug.info('Capturing button direction: ', dir, coords, this.currentFocus)

    if (this.currentFocus === 'SearchKeys') {
      const currentIndex = this.SearchKeys.index
      debug.info('SearchKeyboard Current Index %s', currentIndex)

      if (this._edges.top && dir === 'up') {
        this.signal(dir, coords)
      } else if (this._edges.left && dir === 'left') {
        return this.signal(dir, coords)
      } else if (this._edges.right && dir === 'right' && !this._edges.bottom) {
        return this.signal(dir, coords)
      } else if (
        (dir === 'down' && (currentIndex == 20 || currentIndex === 21)) ||
        (dir === 'right' && currentIndex === 25)
      ) {
        this.currentFocus = 'ExtraKeys.SpaceKey'
        return true
      } else if (
        dir === 'down' &&
        (currentIndex === 22 || currentIndex === 23)
      ) {
        this.currentFocus = 'ExtraKeys.DelKey'
        return true
      } else if (this._edges.bottom && dir === 'down') {
        this.signal(dir, coords)
      }
    } else if (this.currentFocus === 'ExtraKeys.DelKey') {
      if (dir === 'up') {
        this.tag('SearchKeys').setIndex(22)
        this.currentFocus = 'SearchKeys'
        return true
      } else if (dir === 'left') {
        this.currentFocus = 'ExtraKeys.SpaceKey'
        return true
      } else {
        this.signal(dir, coords)
      }
    } else if (this.currentFocus === 'ExtraKeys.SpaceKey') {
      if (dir === 'up' || dir == 'left') {
        this.tag('SearchKeys').setIndex(dir === 'up' ? 20 : 25)
        this.currentFocus = 'SearchKeys'
        return false
      } else if (dir === 'right') {
        this.currentFocus = 'ExtraKeys.DelKey'
        return true
      } else {
        return this.signal(dir, coords)
      }
    }
    return false
  }

  $captureButtonDirection(
    dir: 'up' | 'down' | 'left' | 'right',
    coords?: CoordinateDimensions,
  ) {
    this._captureButtonDirection(dir, coords)
  }

  private _currentFocus:
    | 'SearchKeys'
    | 'ExtraKeys.SpaceKey'
    | 'ExtraKeys.DelKey' = 'SearchKeys'
  override _getFocused() {
    return this.tag(this._currentFocus)
  }
  set currentFocus(focus: typeof this._currentFocus) {
    this._currentFocus = focus
    this._refocus()
  }

  get currentFocus() {
    return this._currentFocus
  }

  _delLeft() {
    this.currentFocus = 'ExtraKeys.SpaceKey'
    return false
  }
  _delUp() {
    this.tag('SearchKeys').setIndex(22)
    this.currentFocus = 'SearchKeys'
    return false
  }
  _delHover() {
    this.currentFocus = 'ExtraKeys.DelKey'
  }
  _spaceLeft() {
    this.tag('SearchKeys').setIndex(25)
    this.currentFocus = 'SearchKeys'
    return false
  }
  _spaceRight() {
    debug.info('Space Right')
    this.currentFocus = 'ExtraKeys.DelKey'
    return false
  }
  _spaceUp() {
    debug.info('Space Up')
    this.tag('SearchKeys').setIndex(20)
    this.currentFocus = 'SearchKeys'
    return false
  }
  _spaceHover() {
    this.currentFocus = 'ExtraKeys.SpaceKey'
  }
}

export interface SearchKeyboardTemplateSpec
  extends Lightning.Component.TemplateSpec {
  SearchKeys: typeof Grid
  ExtraKeys: {
    SpaceKey: typeof SearchLetter
    DelKey: typeof SearchLetter
  }
}

export interface SearchKeyboardTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: DirectionalSignalMap
}
