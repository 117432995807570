import { RowButtonConfig } from '@adiffengine/engine-types'
import { Lightning } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import { TabMenuButton } from './TabMenuButton'

export interface AdeTabMenuTypeConfig extends Lightning.Component.TypeConfig {
  buttonType: object
  buttons: RowButtonConfig[]
}

export interface AdeTabMenuTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Buttons: typeof List
}
export class AdeTabMenu
  extends Lightning.Component<AdeTabMenuTemplateSpec, AdeTabMenuTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<AdeTabMenuTemplateSpec>
{
  private _buttonType: object = TabMenuButton
  private _buttons: RowButtonConfig[] = []
  public margin: number = 0
  static override _template() {
    return {
      x: 0,
      y: 0,
      w: 1920,
      h: 60,
      Buttons: {
        w: (x: number) => x,
        h: (x: number) => x,
        type: List,
        direction: 'row',
      },
    }
  }
  setButtons() {
    const tag = this.tag('Buttons')
    if (tag) {
      tag.patch({
        items: this._buttons.map(b => ({
          ...b,
          type: this._buttonType,
        })),
      })
    }
  }
  set buttonType(type: object) {
    this._buttonType = type
  }
  set buttons(buttons: RowButtonConfig[]) {
    this._buttons = buttons
    this.setButtons()
  }
  get buttons() {
    return this._buttons
  }
}
