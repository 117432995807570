import { Colors, Lightning } from '@lightningjs/sdk'
import { Debugger, cp } from '../lib'

const debug = new Debugger('BackgroundPlane')

export interface BackgroundPlaneTemplateSpec
  extends Lightning.Component.TemplateSpec {
  radius: number
  strokeWidth: number
  backgroundColor: number
  borderColor: number
  Plane: object
}
export class BackgroundPlane
  extends Lightning.Component<BackgroundPlaneTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<BackgroundPlaneTemplateSpec>
{
  Plane = this.getByRef('Plane')!
  static _defaultRadius = 24
  static override _template(): Lightning.Component.Template<BackgroundPlaneTemplateSpec> {
    return {
      Plane: {
        x: 0,
        y: 0,
        w: cp,
        h: cp,
        rect: true,
        rtt: true,
        color: Colors('background').get(),
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: this._defaultRadius,
          strokeColor: Colors('primaryHighlight').get(),
          stroke: 8,
          fillColor: Colors('backgroundColor').get(),
        },
      },
    }
  }

  override _firstActive() {
    this.Plane.patch({
      shader: {
        stroke: this.strokeWidth,
        fillColor: this.backgroundColor,
        strokeColor: this.borderColor,
        radius: this.radius,
      },
    })
    debug.info('Patched Plane', this.Plane, Colors)
  }

  private _strokeWidth = 8
  public get strokeWidth() {
    return this._strokeWidth
  }
  public set strokeWidth(value: number) {
    if (value !== this._strokeWidth) {
      this._strokeWidth = value
      this.Plane.patch({
        shader: {
          stroke: value,
        },
      })
    }
  }

  private _backgroundColor = Colors('background').get()
  set backgroundColor(color: number) {
    if (this._backgroundColor !== color) {
      this._backgroundColor = color
      debug.info('Setting background color')
      this.Plane.patch({
        shader: {
          fillColor: color,
        },
      })
    }
  }
  get backgroundColor() {
    return this._backgroundColor
  }
  private _radius = BackgroundPlane._defaultRadius
  set radius(radius: number) {
    if (this._radius !== radius) {
      this._radius = radius
      this.Plane.patch({
        shader: {
          radius,
        },
      })
    }
  }
  get radius() {
    return this._radius
  }
  private _borderColor = Colors('primaryHighlight').get()
  set borderColor(color: number) {
    if (this._borderColor !== color) {
      this._borderColor = color
      this.Plane.patch({
        shader: {
          strokeColor: color,
        },
      })
    }
  }
  get borderColor() {
    return this._borderColor
  }
}
