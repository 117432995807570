import { ColorHue, ThemeConfig } from '@adiffengine/engine-types'
import { createPalette, createThemeConfig } from '../lib'

const darks: ColorHue = {
  50: '#e4e4e5',
  100: '#babbbf',
  200: '#8d8e94',
  300: '#5f6169',
  400: '#3c3f49',
  500: '#1a1d29',
  600: '#171a24',
  700: '#13151f',
  800: '#0f1119',
  900: '#080a0f',
}

const highlights: ColorHue = {
  50: '#ffe2ee',
  100: '#ffb6d6',
  200: '#ff85ba',
  300: '#ff549e',
  400: '#ff308a',
  500: '#ff0b75',
  600: '#ff0a6d',
  700: '#ff0862',
  800: '#ff0658',
  900: '#ff0345',
}

export const excitementTheme: ThemeConfig = createThemeConfig({
  name: 'Extreme Pink',
  logo_size: 400,
  palette: createPalette(
    { darks, highlights },
    {
      text: '#F9F9F9',
      buttonText: highlights[50],
      background: darks[700],
      backgroundGradient: darks[400],
    },
  ),
  logo: 'images/logos/logo@0.75x.png',
  fonts: {
    name: 'Rubik',
    Text: 'fonts/Rubik/Rubik-Light.ttf',
    Regular: 'fonts/Rubik/Rubik-Regular.ttf',
    Bold: 'fonts/Rubik/Rubik-SemiBold.ttf',
    ExtraBold: 'fonts/Rubik/Rubik-Bold.ttf',
  },

  components: {
    VideoCardConfig: {
      radius: 24,
      textHighlightColor: highlights[50],
    },

    MainMenuConfig: {
      radius: 12,
    },
    SimpleCardConfig: {
      radius: 24,
      imageRadius: 12,
    },
    BoxCardConfig: {
      radius: 24,
      textHighlightColor: highlights[50],
    },
  },
})
