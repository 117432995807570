import { createPalette, createThemeConfig } from '@adiffengine/elements'
import { ColorHue, ThemeConfig } from '@adiffengine/engine-types'

const darks: ColorHue = {
  '50': '#e4e4e5',
  '100': '#bbbcbf',
  '200': '#8e9094',
  '300': '#606369',
  '400': '#3e4149',
  '500': '#1c2029',
  '600': '#191c24',
  '700': '#14181f',
  '800': '#111319',
  '900': '#090b0f',
}

const highlights: ColorHue = {
  50: '#FCE7FD',
  100: '#F7C2FB',
  200: '#F299F8',
  300: '#ED70F5',
  400: '#E952F2',
  500: '#E533F0',
  600: '#E22EEE',
  700: '#DE27EC',
  800: '#DA20E9',
  900: '#D314E5',
}

export const mituTheme: ThemeConfig = createThemeConfig({
  name: 'Mitú',

  palette: createPalette(
    { darks, highlights },
    {
      text: '#F9F9F9',
      buttonText: highlights[50],
      background: darks[700],
      backgroundGradient: darks[400],
    },
  ),
  logo_size: 400,
  logo: 'images/mitu_logo.png',
  fonts: {
    name: 'Prompt',
    Text: 'fonts/Prompt/Prompt-Light.ttf',
    Regular: 'fonts/Prompt/Prompt-Regular.ttf',
    Bold: 'fonts/Prompt/Prompt-SemiBold.ttf',
    ExtraBold: 'fonts/Prompt/Prompt-Bold.ttf',
  },

  components: {
    VideoCardConfig: {
      radius: 24,
      textHighlightColor: highlights[50],
    },
    MainMenuConfig: {
      radius: 12,
      icon: 'images/mitu-icon-menu.png',
      logo: 'images/mitu-logo-menu.png',
    },
    FullPageBackground: {
      logo: 'images/mitu_logo_unspaced.png',
      logoColor: highlights[50],
      backgroundColor: darks[700],
      highlightColor: highlights[900],
    },
    SimpleCardConfig: {
      radius: 24,
      imageRadius: 12,
    },
    BoxCardConfig: {
      radius: 24,
      textHighlightColor: highlights[50],
    },
  },
})
