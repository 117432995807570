import {
  ContentItem,
  CoordinateDimensions,
  setsByY,
} from '@adiffengine/engine-types'
import { Lightning, Router } from '@lightningjs/sdk'
import { MainMenu } from '../components/MainMenu'
import { SearchBar } from '../components/SearchBar'
import { VideoCardGrid } from '../components/VideoCardGrid'
import { Debugger, isGoodArray, registerHoverable } from '../lib'
const debug = new Debugger('SearchScreenContent')
export interface SearchPageTypeConfig extends Lightning.Component.TypeConfig {
  IsPage: true
}

export interface SearchPageTemplateSpec
  extends Lightning.Component.TemplateSpec {
  SearchMask: {
    SearchResults: typeof VideoCardGrid
  }
  SearchBar: typeof SearchBar
}

export class SearchScreenContent
  extends Lightning.Component<SearchPageTemplateSpec, SearchPageTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<SearchPageTemplateSpec>
{
  public initialFocus: 'Menu' | 'Content' | 'SearchBar' = 'Menu'
  public IsPage = true
  SearchBar = this.getByRef('SearchBar')!
  SearchResults = this.getByRef('SearchMask')!.getByRef('SearchResults')!
  override get id() {
    return 'SerachScreenContent'
  }
  static xPos = 120 + MainMenu.widthClosed
  static yPos = 0
  static width = 1920 - (120 + MainMenu.widthClosed)
  static height = 1080
  static override _template(): Lightning.Component.Template<SearchPageTemplateSpec> {
    return {
      collision: true,
      x: this.xPos, // 80 margin left, menu, 40 margin right to hero.
      y: this.yPos,
      w: this.width, // 80 margin left, menu, 40 margin right to hero.
      h: this.height,
      rect: true,
      color: 0x00000000,
      SearchBar: {
        type: SearchBar,
        x: 0, // 80 margin left, menu, 40 margin right to hero.
        y: 80,
        w: SearchBar.width,
        h: this.height,
        signals: {
          left: '_searchLeft',
          right: '_searchRight',
          query: '_query',
        },
      },
      SearchMask: {
        y: 0,
        x: SearchBar.width + 20,
        h: 1080,
        w: 1920 - (SearchBar.width + MainMenu.widthClosed + 240),
        color: 0x00000000,
        rtt: true,
        rect: true,
        shader: {
          type: Lightning.shaders.FadeOut,
          left: 20,
          bottom: 80,
          top: 80,
        },
        SearchResults: {
          y: 80,
          x: 20,
          h: 1080 - 320,
          w: 1920 - (SearchBar.width + MainMenu.widthClosed + 320),
          type: VideoCardGrid,
          columns: 3,
          signals: {
            left: '_resultsLeft',
            hovered: '_resultsHover',
          },
        },
      },
    }
  }

  override _firstEnable() {
    const initialQuery = this.fireAncestors('$initialSearchQuery')
    if (initialQuery !== null) {
      this.SearchBar.setQuery(initialQuery)
    }
  }

   _hasResults = false
  public get hasResults() {
    return this._hasResults
  }
  public set hasResults(value) {
    if (this._hasResults !== value) {
      this._hasResults = value
      this.fireAncestors('$enableMainMenuPointer', value)
    }
  }
  async _query(term: string) {
    const results = await this.fireAncestors('$search', term, 3)
    debug.info('Query Results', results)
    if (isGoodArray<ContentItem>(results)) {
      this.hasResults = true
      this.SearchResults.patch({
        items: results.map(contentItem => ({ content: contentItem })),
      })
    } else {
      this.hasResults = false
    }
  }

  _resultsLeft() {
    this._setState('SearchBarFocused')
    return false
  }

  _searchLeft(coords?: CoordinateDimensions) {
    if (setsByY(this.widgets['mainmenu'])) {
      this.widgets['mainmenu'].setClosestByY(coords)
      Router.focusWidget('MainMenu')
    }
  }

  override _handleBack() {
    return true
  }

  _searchRight(coords?: CoordinateDimensions): boolean | void {
    if (this._hasResults) {
      this.SearchResults.setClosestByY('left', coords)
      this._setState('SearchResultsFocused')
    } else {
      return false
    }
  }

  _resultsHover() {
    if (this.hasResults) {
      this._setState('SearchResultsFocused')
    }
  }

  override _attach() {
    this._setState('SearchBarFocused')
  }

  // Monitor the main menu
  override _active() {
    const menuOpen = this.fireAncestors('$menuOpen')
    this.patch({
      x: menuOpen ? 120 + MainMenu.width : 120 + MainMenu.widthClosed,
    })
    this.stage.application.emit('screenView', 'Search Page', 'search_page')
    this.fireAncestors('$enableMainMenuPointer', this.hasResults)
  }

  override _init() {
    registerHoverable('SearchScreenContent', this)

    this.stage.application.on('menuOpen', (menuOpen: boolean) => {
      this.setSmooth(
        'x',
        menuOpen ? 120 + MainMenu.width : 120 + MainMenu.widthClosed,
        { duration: 0.2 },
      )
    })
  }

  static override _states() {
    return [
      class SearchBarFocused extends this {
        override $enter() {
          this._refocus()
        }
        override _getFocused() {
          return this.SearchBar
        }
      },

      class SearchResultsFocused extends this {
        override $enter() {
          this._refocus()
        }
        override _getFocused() {
          return this.SearchResults
        }
      },
    ]
  }
}
