import {
  ContentItem,
  CoordinateDimensions,
  DetailsContext,
  DirectionalSignalMap,
  isShowContentItem,
  ListIndexChange,
  SetsByX,
} from '@adiffengine/engine-types'
import { Colors, Lightning, Settings } from '@lightningjs/sdk'
import { format } from 'fecha'
import { Debugger, isGoodString, parseMedia, setClosestInList } from '../lib'
import { isBoolean, isValidDate } from '../lib/utils'
import {
  AdeButtonCollection,
  ButtonCollectionActionConfig,
  ButtonCollectionButtonConfig,
  ButtonCollectionWatchListAction,
} from './AdeButtonCollection'
import { AdeDetailsHeroImage } from './AdeDetailsHeroImage'
import { Rating } from './Rating'

const debug = new Debugger('AdeDetailsHero')
debug.enabled = true
export interface DetailsHeroTypeConfig extends Lightning.Component.TypeConfig {
  content: ContentItem
  hideExtraButtons: boolean
  SignalMapType: DirectionalSignalMap
}

const dateString = (title: string, date: any): string | null =>
  isValidDate(date)
    ? `${title.toUpperCase()}: ${format(
        date,
        'dddd MMM Do, YYYY',
      ).toUpperCase()}`
    : null
export interface DetailsHeroTemplateSpec
  extends Lightning.Component.TemplateSpecLoose {
  Background: typeof AdeDetailsHeroImage
  Details: {
    Title: object
    Subtitle: object
    Description: object
    Buttons: typeof AdeButtonCollection
    Release: {
      Date: object
      Rating: typeof Rating
    }
  }
  inWatchlist: boolean
  overflowToScreen: boolean
}

export function isDetailsHero(x: any): x is DetailsHero {
  return x?.constructor === DetailsHero
}
export type HeroButtonConfig =
  | ButtonCollectionActionConfig
  | ButtonCollectionWatchListAction

export type HeroButtonButtonConfig =
  | ButtonCollectionButtonConfig<ButtonCollectionActionConfig>
  | ButtonCollectionButtonConfig<ButtonCollectionWatchListAction>

export class DetailsHero
  extends Lightning.Component<DetailsHeroTemplateSpec, DetailsHeroTypeConfig>
  implements
    Lightning.Component.ImplementTemplateSpec<DetailsHeroTemplateSpec>,
    SetsByX
{
  static height = 800
  private _content: ContentItem | null | undefined
  Details = this.getByRef('Details')!
  Buttons = this.Details.tag('Buttons')!
  Release = this.Details.getByRef('Release')!
  Date = this.Release.getByRef('Date')!
  Rating = this.Release.getByRef('Rating')!
  Background = this.getByRef('Background')!

  static override _template() {


    return {
      x: 0,
      y: 0,
      w: 1920,
      h: 800,
      Background: {
        x: 0,
        y: 0,
        w: 1920 - 160,
        h: 800,
        type: AdeDetailsHeroImage,
      },
      Details: {
        x: 160,
        y: 32,
        w: 800,
        h: 800,
        flex: {
          direction: 'column',
          justifyContent: 'center',
          padding: 20,
        },
        Title: {
          color: Colors('white').get(),
          text: {
            text: '',
            fontFace: 'Bold',
            fontSize: 60,
            textAlign: 'left',
            wordWrap: true,
            wordWrapWidth: 1200,
            maxLines: 2,
          },
        },
        Subtitle: {
          text: {
            text: '',
            color: Colors('white').get(),
            fontFace: 'Bold',
            fontSize: 36,
            textAlign: 'left',
            wordWrap: true,
            wordWrapWidth: 1200,
            maxLines: 3,
          },
        },
        Description: {
          flexItem: {
            marginTop: 20,
            marginBottom: 20,
          },
          text: {
            text: '',
            color: Colors('white').get(),
            fontFace: 'Text',
            fontSize: 24,
            lineHeight: 36,
            textAlign: 'left',
            wordWrap: true,
            wordWrapWidth: 1200,
            maxLines: 5,
          },
        },
        Release: {
          h: 24,
          flex: {
            direction: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
          },
          Date: {
            visible: false,
            h: 24,
            text: {
              fontSize: 18,
              fontFace: 'Text',
            },
          },
          Rating: {
            flexItem: {
              marginLeft: 12,
            },
            type: Rating,
            visible: false,
            size: 24,
            ratio: 2,
          },
        },
        Buttons: {
          flexItem: {
            marginTop: 20,
          },
          h: 60,
          type: AdeButtonCollection<HeroButtonConfig>,
          direction: 'row',
          spacing: 30,
          buttonSize: {
            h: 60,
            w: 240,
          },
          signals: {
            hovered: '_hovered',
            selected: '_itemSelected',
          },
        },
      },
    }
  }

  private _overFlowToScreen = false
  public get overFlowToScreen() {
    return this._overFlowToScreen
  }
  public set overFlowToScreen(value: boolean) {
    if (this._overFlowToScreen !== value) {
      this._overFlowToScreen = value
    }
  }

  renderHero() {
    this.Background.patch({
      imageSrc: this.content?.images.wide?.getForWidth(1920) ?? null,
      overflowed: true,
      h: AdeDetailsHeroImage.height,
      w: this.w,
    })
  }

  public hideExtraButtons = false
  setButtons() {
    if (this.content) {
      const useWatchlist = Settings.get('app', 'useWatchlist', false)
      const parsedMedia = parseMedia(this.content.media)
      const playerPath = this.content!.paths.player
      const buttons: HeroButtonButtonConfig[] = []
      if (playerPath) {
        buttons.push({
          buttonText: 'Play',
          payload: {
            action: '$navigate',
            payload: playerPath,
          },
        })
      }
      if (parsedMedia !== null) {
        const { trailer = null, extras = [] } = parsedMedia
        if (!this.hideExtraButtons) {
          if (trailer !== null) {
            const {
              paths: { player },
              button_text = 'Trailer',
            } = trailer
            buttons.push({
              buttonText: button_text,
              payload: {
                action: '$navigate',
                payload: player,
              },
            })
          }

          extras.forEach(extra => {
            const {
              paths: { player },
            } = extra
            buttons.push({
              buttonText: extra.button_text!,
              payload: {
                action: '$navigate',
                payload: player,
              },
            })
          })
        }
        if (useWatchlist) {
          buttons.push({
            buttonText: this._inwatchList ? 'Unfavorite' : 'Favorite',
            leftIcon: `${
              this._inwatchList ? 'remove' : 'add'
            }-circle-outline.svg`,
            payload: {
              action: '$addToWatchlist',
              payload: this.content,
            },
          })
        }
      }
      this._onLeft = true
      this._onRight = buttons.length === 0
      if (buttons.length > 0) {
        this.Buttons.patch({ buttons })
      } else {
        this.Buttons.patch({ buttons: [] })
      }
    }
  }

  setClosestByX(coords: CoordinateDimensions) {
    const list = this.tag('Details.Buttons')
    setClosestInList(coords, list)
  }

  set content(content: ContentItem | undefined | null) {
    debug.info('Content', content)
    this._content = content
    if (this.content != null) {
      this.renderHero()

      this.Details.patch({
        Title: { text: { text: this.content.title } },
        Subtitle: {
          text: { text: this.content.tagline ?? '' },
          visible: isGoodString(this.content.tagline),
        },
        Description: { text: { text: this.content.description } },
      })
      let date = null
      if (isShowContentItem(this.content)) {
        const next = dateString('Next Episode', this.content.nextAirDate)
        const last = dateString('Last Episode', this.content.lastAirDate)
        const first = dateString('First Aired', this.content.firstAirDate)
        date = next ?? last ?? first
      } else {
        date = dateString('Released', this.content.release)
      }

      this.Date.patch({
        visible: date !== null,
        text: {
          text: date === null ? '' : date,
        },
      })
      debug.info('Release !', this.content)

      this.setButtons()
    }
  }
  get content() {
    return this._content
  }
  async $addToWatchlist(content: ContentItem) {
    // Interceptor
    const added = await this.fireAncestors(
      '$addToWatchlist',
      content,
      this._inwatchList,
    )
    if (added) {
      this.inWatchlist = !this._inwatchList
    }
  }

  private _inwatchList = false

  set inWatchlist(inWatchlist: boolean) {
    this._inwatchList = inWatchlist
    const items = this.Buttons.buttons as HeroButtonButtonConfig[]
    if (Array.isArray(items)) {
      const watchlistButton = items.find(
        item => item.payload?.action === '$addToWatchlist',
      )

      if (watchlistButton) {
        // if (inWatchlist) {
        //   watchlistButton.patch({
        //     buttonText: 'UNFAVORITE',
        //     leftIcon: 'remove-circle-outline.svg',
        //   })
        // } else {
        //   watchlistButton.patch({
        //     buttonText: 'favorite',
        //     leftIcon: 'add-circle-outline.svg',
        //   })
        // }
      }
    }
  }
  get inWatchlist() {
    return this._inwatchList
  }

  async reloadDetails(params: DetailsContext['params']) {
    try {
      const content = await this.fireAncestors('$reloadDetails', {
        params,
      })

      if (content) this.content = content.meta
    } catch (error) {
      console.warn('Error loading details %s', error.message, error)
    }
  }

  override set params(args: { id: string; type: ContentItem['type'] }) {
    const { id, type } = args
    if (id && type) {
      const currentContent = this.fireAncestors('$currentContent', id, type)
      if (currentContent) {
        this.content = currentContent
      }
      this.reloadDetails(args)
    } else {
      console.warn('No id and type params passed to Details Page')
    }
  }

  _indexChagned(change: ListIndexChange) {
    this._onLeft = change.index === 0
    this._onRight = change.index === change.dataLength - 1
  }
  override _captureDown() {
    const response = this.signal('down')
    return isBoolean(response) ? response : false
  }
  override _captureUp() {
    const response = this.signal('up')
    return isBoolean(response) ? response : false
  }
  private _onLeft = true
  private _onRight = false
  override _handleLeft(): false | void {
    if (this._onLeft) this.signal('left')
    else return false
  }
  override _handleRight(): false | void {
    if (this._onRight) this.signal('right')
    else return false
  }
  _itemSelected(item: HeroButtonConfig) {
    this.fireAncestors(item.action, item.payload)
  }

  override _getFocused() {
    return this.tag('Details.Buttons')
  }
  _hovered(coords?: CoordinateDimensions) {
    this.signal('hovered', coords)
  }
}
