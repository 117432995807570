import { Colors, Lightning } from '@lightningjs/sdk'
export interface RatingTemplateSpec extends Lightning.Component.TemplateSpec {
  Rating: object
  size: number
  rating: string
}
export type RatingTypeConfig = Lightning.Component.TypeConfig
export class Rating
  extends Lightning.Component<RatingTemplateSpec, RatingTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<RatingTemplateSpec>
{
  Rating = this.getByRef('Rating')!
  static override _template(): Lightning.Component.Template<RatingTemplateSpec> {
    return {
      h: 18,
      w: 44,
      rect: true,
      flex: {
        direction: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      },
      Rating: {
        color: Colors('background').get(),
        text: {
          text: 'PG',
          fontSize: 16,
          fontFace: 'Text',
        },
      },
    }
  }

  private _rating: string = 'PG'
  set rating(text: string) {
    this.Rating.patch({ text: { text } })
    this._rating = text
  }

  get rating() {
    return this._rating
  }

  private _ratio: number = 2
  set ratio(ratio: number) {
    this.patch({
      w: this.h * ratio,
    })
  }
  get ratio() {
    return this._ratio
  }
  private _size: number = 18
  set size(size: number) {
    this.patch({ h: size })
    this.Rating.patch({ text: { fontSize: size * 0.75 } })
    this._size = size
  }
  get size() {
    return this._size
  }

  override _init() {
    this.patch({
      w: this.h * this.ratio,
      Rating: {
        text: { text: this.rating ?? '' },
      },
      shader: {
        type: Lightning.shaders.RoundedRectangle,
        radius: this.w * 0.15,
      },
    })
  }
}

export class PGRating extends Rating {
  override _init() {
    this.rating = 'PG'
    super._init()
  }
}

export class GRating extends Rating {
  override _init() {
    this.rating = 'G'
  }
}
