import { Colors, Lightning, Router } from '@lightningjs/sdk'
import { getStoredTheme } from '../themes'
import { AdeButton } from '../components/AdeButton'

export interface AreYouStillThereTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Content: {
    Background: object
    TextWrapper: {
      Label: object
      Text: object
    }
    Buttons: {
      OkButton: typeof AdeButton
      CancelButton: typeof AdeButton
    }
    AutomaticCancelProgress: object
  }
  title: string
  description: string
}
export const ARE_YOU_STILL_THERE_WIDTH = 1200
export const ARE_YOU_STILL_THERE_HEIGHT = 600

export interface AreYouStillThereResultGone {
  here: false
  reason: 'button' | 'timeout'
}
export interface AreYouStillThereResultHere {
  here: true
}
export type AreYouStillThereResult =
  | AreYouStillThereResultGone
  | AreYouStillThereResultHere

export class AreYouStillThere
  extends Lightning.Component<AreYouStillThereTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<AreYouStillThereTemplateSpec>
{
  Content = this.getByRef('Content')!
  TextWrapper = this.Content.getByRef('TextWrapper')!
  Label = this.TextWrapper.getByRef('Label')!
  Text = this.TextWrapper.getByRef('Text')!
  Buttons = this.Content.getByRef('Buttons')!
  OkButton = this.Buttons.getByRef('OkButton')!
  CancelButton = this.Buttons.getByRef('CancelButton')!
  static defaultLabel = 'Are you still there?'
  static defaultText = `Just wondering if you're still around.`
  static width = ARE_YOU_STILL_THERE_WIDTH
  static height = ARE_YOU_STILL_THERE_HEIGHT
  static override _template(): Lightning.Component.Template<AreYouStillThereTemplateSpec> {
    const theme = getStoredTheme()
    const {
      components: { MainMenuConfig },
    } = theme
    return {
      alpha: 0.0001,
      x: 0,
      y: 0,
      h: 1080,
      w: 1920,
      rect: true,
      rtt: true,
      color: Colors('background').alpha(0.5).get(),
      zIndex: 100,
      Content: {
        x: (1920 - ARE_YOU_STILL_THERE_WIDTH) / 2,
        y: (1080 - ARE_YOU_STILL_THERE_HEIGHT) / 2,
        w: ARE_YOU_STILL_THERE_WIDTH,
        h: ARE_YOU_STILL_THERE_HEIGHT,
        Background: {
          rect: true,
          rtt: true,
          x: 0,
          y: 0,
          w: ARE_YOU_STILL_THERE_WIDTH,
          h: ARE_YOU_STILL_THERE_HEIGHT,
          shader: {
            strokeColor: Colors('backgroundGradient').get(),
            stroke: 8,
            type: Lightning.shaders.RoundedRectangle,
            radius: MainMenuConfig.radius,
          },
          color: Colors('background').get(),
        },
        TextWrapper: {
          x: 120,
          y: 40,
          h: ARE_YOU_STILL_THERE_HEIGHT - 200,
          flex: {
            direction: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
          },
          Label: {
            text: {
              fontFace: 'Bold',
              text: 'Are you still there?',
              maxLines: 2,
              wordWrap: true,
              wordWrapWidth: ARE_YOU_STILL_THERE_WIDTH - 100,
              fontSize: 64,
            },
          },
          Text: {
            flexItem: {
              marginTop: 24,
            },
            text: {
              fontFace: 'Text',
              text: `Just wondering if you're still around.`,
              fontSize: 46,
              maxLines: 3,
              wordWrap: true,
              wordWrapWidth: ARE_YOU_STILL_THERE_WIDTH - 100,
            },
          },
        },
        Buttons: {
          x: 120,
          y: ARE_YOU_STILL_THERE_HEIGHT - 120,
          flex: {
            direction: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
          },
          OkButton: {
            type: AdeButton,
            buttonText: 'Yes! Still Here.',
            flexItem: {
              marginRight: 24,
            },
          },
          CancelButton: {
            type: AdeButton,
            buttonText: 'Nope, done.',
            flexItem: {
              marginRight: 24,
            },
          },
        },
      },
    }
  }

  private _promise: Promise<AreYouStillThereResult> | null = null
  private _resolver:
    | ((
        value: AreYouStillThereResult | PromiseLike<AreYouStillThereResult>,
      ) => void)
    | null = null

  set title(text: string) {
    this.Label.patch({
      text: { text },
    })
  }
  get title() {
    return this.Label.text?.text ?? ''
  }
  set description(text: string) {
    this.Text.patch({
      text: { text },
    })
  }
  get description() {
    return this.Text.text?.text ?? ''
  }

  private _button: AdeButton = this.OkButton
  override _captureRight() {
    if (this._button === this.OkButton) {
      this._button = this.CancelButton
      this._refocus()
      return true
    } else {
      return false
    }
  }

  override _focus() {
    this.setSmooth('alpha', 1)
  }

  override _unfocus() {
    this.setSmooth('alpha', 0.0001)
  }
  override _captureLeft() {
    if (this._button === this.CancelButton) {
      this._button = this.OkButton
      this._refocus()
      return true
    } else {
      return false
    }
  }

  waitForIt() {
    Router.focusWidget('AreYouStillThere')
    if (this._promise === null) {
      this._promise = new Promise(resolve => (this._resolver = resolve))
      return this._promise
    } else {
      return this._promise
    }
  }

  resolve(here: false, reason: AreYouStillThereResultGone['reason']): void
  resolve(here: true): void
  resolve(here: boolean, reason?: AreYouStillThereResultGone['reason']) {
    if (this._resolver) {
      if (here) {
        this._resolver({ here } as AreYouStillThereResultHere)
      } else this._resolver({ here, reason } as AreYouStillThereResultGone)
    }
    this._promise = null
  }

  override _captureEnter() {
    if (this._resolver) {
      if (this._button === this.OkButton) {
        this.resolve(true)
      } else {
        this.resolve(false, 'button')
      }
    }
  }
  override _getFocused() {
    return this._button
  }
}
