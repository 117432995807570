import type { UserRecord } from 'firebase-admin/auth'
import type { DocumentData } from 'firebase-admin/firestore'
import { CallableRequest } from 'firebase-functions/v2/https'
import { ZodError, z } from 'zod'

export const organizationLinkSchema = z.object({
  id: z.string(),
  name: z.string(),
})
export const userDataSchema = z.object({
  _id: z.string().optional(),
  permissions: z.enum(['site-user', 'ade-admin']).default('site-user'),
  email: z.string(),
  organizations: z.array(organizationLinkSchema),
  organizationIds: z.array(z.string()),
  name: z.string(),
  uid: z.string().optional(),
})
export const appSchema = z.object({
  _id: z.string().optional(),
  name: z.string(),
  owner: z.array(organizationLinkSchema),
  slug: z.string(),
  _plausibleToken: z.string().optional(),
  plausibleUrl: z.string().url().optional(),
  plausibleSite: z.string().optional(),
  _muxSecret: z.string().optional(),
  muxSecretId: z.string().optional(),
  ownerIds: z.array(z.string()),
})
//nx generate @nx/next:application --name=asgard --directory=apps/asgard --appDir=true
export const orgSchema = z.object({
  name: z.string(),
})
export type AsgardOrganizationData = z.infer<typeof orgSchema>
export type AsgardOrganization = WithId<AsgardOrganizationData>
export type AsgardOrgLookup = z.infer<typeof organizationLinkSchema>

export type AsgardUserData = z.infer<typeof userDataSchema>
export type AsgardUser = WithId<AsgardUserData>
export type AsgardAppData = z.infer<typeof appSchema>
export interface AsgardApp extends AsgardAppData {
  _id: string
}
export function isAsgardApp(x: AsgardAppData): x is AsgardApp {
  return x._id != null
}
export type CallableRequestHandler<T = any> = (
  request: CallableRequest<T>,
) => void
export type WithId<T extends DocumentData> = T & {
  _id: string
}
export interface EmailUserRecord extends UserRecord {
  email: string
}
export function isEmailUserRecord(user: UserRecord): user is EmailUserRecord {
  return user.email != null
}

export type Callable<T = any> = (
  request: CallableRequest<T>,
  ...args: any[]
) => unknown

export type ValidUserCallable<T = any> = (
  request: CallableRequest<T>,
  user: EmailUserRecord,
) => unknown

export type ValidAppCallable<T extends AppRequestParams = AppRequestParams> = (
  request: CallableRequest<T>,
  app: AsgardApp,
) => unknown
export const singleAppRequest = z.object({
  appId: z.string(),
})

export type AppRequestParams = z.infer<typeof singleAppRequest>
export type AuthorizedAppCalllable<
  T extends AppRequestParams = AppRequestParams,
> = (request: CallableRequest<T>, app: AsgardApp) => unknown
export class ZodNotAuthorizedError extends ZodError {
  public code = 'permission-denied'
  constructor(message: string, path = 'authorization') {
    super([
      {
        fatal: true,
        message,
        path: [path],
        code: 'custom',
      },
    ])
  }
}

export type AsgardAppPublic = Exclude<
  AsgardApp,
  '_muxSecret' | '_plausibleToken'
>
export function publicizeAsgardApp(app: AsgardApp): AsgardAppPublic {
  const { _muxSecret, _plausibleToken, ...rest } = app
  return rest
}
