import { GridResponse } from '@adiffengine/engine-types'
import { Lightning } from '@lightningjs/sdk'

import { Debugger } from '../../lib'
import { createHeroCarousel } from '../AdeHero'
import { MainMenu } from '../MainMenu'
import { AdvancedBoxCard } from './AdvancedBoxCard'
import { AdvancedGrid, AdvancedGridTemplateSpec } from './AdvancedGrid'
import { AdvancedGridList } from './AdvancedGridList'
import { AdvancedWideCard } from './AdvancedWideCard'

const debug = new Debugger('AdvancedGridDevScreen')

export interface AdvancedGridDevScreenTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Grid: typeof AdvancedGrid
  grid: GridResponse | null
}
export interface AdvancedGridDevScreenTypeConfig
  extends Lightning.Component.TypeConfig {
  IsPage: true
}

export class AdvancedGridDevScreen
  extends Lightning.Component<
    AdvancedGridDevScreenTemplateSpec,
    AdvancedGridDevScreenTypeConfig
  >
  implements
    Lightning.Component
      .ImplementTemplateSpec<AdvancedGridDevScreenTemplateSpec>
{
  Grid = this.getByRef('Grid')!

  static override _template(): Lightning.Component.Template<AdvancedGridDevScreenTemplateSpec> {
    return {
      x: 100 + MainMenu.widthClosed, // 80 margin left, menu, 40 margin right to hero.
      y: 0,
      w: 1920 - (MainMenu.widthClosed + 180), // 1920 - 96 - 180
      h: 1080,
      Grid: {
        x: 0, // 80 margin left, menu, 40 margin right to hero.
        y: 80,
        w: 1920 - (MainMenu.widthClosed + 180), // 1920 - 96 - 180
        h: 1080 - 160,
        type: AdvancedGrid,
      },
    }
  }
  private _grid: GridResponse | null = null
  set grid(grid: GridResponse | null) {
    if (grid) {
      const items: Lightning.Element.PatchTemplate<AdvancedGridTemplateSpec>['items'] =
        grid?.grid.map((content, idx) => {
          const switcher =
            idx % 2 === 0
              ? {
                  cardSetup: {
                    spacing: 40,
                    cardsPerRow: 5,
                  },
                  h: AdvancedGridList.getCardHeight(this.w, AdvancedWideCard),
                  card: AdvancedWideCard,
                }
              : {
                  cardSetup: {
                    spacing: 20,
                    cardsPerRow: 7,
                  },
                  h: AdvancedGridList.getCardHeight(this.w, AdvancedBoxCard),
                  card: AdvancedBoxCard,
                }
          return {
            content,
            type: AdvancedGridList,
            ...switcher,
            w: this.w,
          }
        })
      if (grid.heros) {
        items.unshift(
          createHeroCarousel(grid.heros.items, {
            w: this.w,
            h: 440,
            passSignals: {
              left: true,
            },
          }),
        )
      }

      const patch: Lightning.Element.PatchTemplate<AdvancedGridTemplateSpec> = {
        items,
      }
      this.patch({ visible: true })
      this.Grid.patch(patch)
      debug.info('Patched GridList', patch, this)
    } else {
      this.patch({ visible: false })
    }
  }

  get grid() {
    return this._grid
  }

  override _enable() {
    debug.info('Enabled Dev Screen', this)
  }

  override _getFocused() {
    return this.Grid
  }
}
