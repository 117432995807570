import { Colors, Lightning, Settings, Utils } from '@lightningjs/sdk'
import { Icon } from '@lightningjs/ui-components'
import { Debugger, pathJoin } from '../../lib'
import { HoverZone } from '../helpers/HoverZone'
import { isNumber } from '@adiffengine/engine-types'
const debug = new Debugger('PlayControlButton')
export type PlayControlHoverName =
  | 'next'
  | 'skip-forward'
  | 'play'
  | 'previous'
  | 'skip-back'
  | 'pause'
export interface PlayControlButtonTemplateSpec
  extends Lightning.Component.TemplateSpec {
  action: keyof Lightning.Component.FireAncestorsMap | null
  actionPayload: any | null
  icon: string
  Icons: {
    Icon: typeof Icon
    FocusIcon: typeof Icon
  }
  HoverZone: typeof HoverZone
}

export interface PlayControlButtonTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: {
    hovered(type: PlayControlHoverName): void
    selected(): boolean | void
    pressed(): boolean | void
    released(): boolean | void
  }
}

export class PlayControlButton
  extends Lightning.Component<
    PlayControlButtonTemplateSpec,
    PlayControlButtonTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<PlayControlButtonTemplateSpec>
{
  public static width: number = 50
  public static height: number = 50
  public action: keyof Lightning.Component.FireAncestorsMap | null = null
  public actionPayload: any | null = null
  private _pressAnimation: Lightning.types.Animation | null = null
  static override _template(): Lightning.Component.Template<PlayControlButtonTemplateSpec> {
    return {
      w: this.width,
      h: this.height,
      x: 0,
      y: 0,
      Icons: {
        mount: 0.5,
        x: 30,
        y: 30,
      },
      HoverZone: {
        mount: 0.5,
        type: HoverZone,
        x: this.width / 2,
        y: this.height / 2,
        w: 80,
        h: 80,
        signals: {
          hovered: '_hovered',
          clicked: '_clicked',
        },
      },
    }
  }
  private _focusAnimation: Lightning.types.Animation | undefined = undefined
  override _init() {
    const theme = this.fireAncestors('$theme')
    const width = this.finalW
    const height = this.finalH
    const iconPath = Settings.get('app', 'icon_directory', 'images/icons')

    this.tag('Icons')!.patch({
      x: width / 2,
      y: height / 2,
      mount: 0.5,
      h: height * 0.8,
      w: width * 0.8,
      Icon: {
        x: 0,
        y: 0,
        alpha: 1,
        h: height * 0.8,
        w: width * 0.8,
        icon: Utils.asset(pathJoin([iconPath, this._icon])),
        type: Icon,
        color: Colors(theme.palette.text).get(),
      },
      FocusIcon: {
        x: 0,
        y: 0,
        alpha: 0,
        h: height * 0.8,
        w: width * 0.8,
        icon: Utils.asset(pathJoin([iconPath, this._icon])),
        type: Icon,
        color: Colors(theme.palette.highlights[500]).get(),
      },
    })
    this._focusAnimation = this.animation({
      duration: 0.2,
      actions: [
        { t: 'Icons.Icon', p: 'alpha', v: { 0: 1, 1: 0 } },
        { t: 'Icons.FocusIcon', p: 'alpha', v: { 0: 0, 1: 1 } },
        { t: 'Icons', p: 'scale', v: { 0: 1, 1: 1.2 } },
      ],
    })
    this._pressAnimation = this.animation({
      duration: 0.2,
      actions: [
        { t: 'Icons', p: 'scale', v: { 0: 1.2, 1: 0.8 } },
        { t: 'Icons', p: 'alpha', v: { 0: 1, 1: 0.6 } },
      ],
    })
  }
  private _icon: string | null = null
  set icon(icon: string) {
    this._icon = icon
  }
  private _isDown = false
  override _captureEnterRelease() {
    debug.info('Player Button Up')
    this._isDown = false
    this._pressAnimation?.stop()
    this._focusAnimation?.start()
    if (this.action) {
      this.fireAncestors(this.action, this.actionPayload)
    } else {
      this.signal('released')
      return this.signal('selected')
    }
  }

  override _captureEnter(): boolean | void {
    if (!this._isDown) {
      debug.info('Player Button Down')
      this._isDown = true
      this._focusAnimation?.finish()
      this._pressAnimation?.start()
      this.signal('pressed')
    }
  }
  override _focus() {
    this._focusAnimation?.start()
  }
  override _unfocus() {
    this._pressAnimation?.finish()
    this._focusAnimation?.stop()
  }

  get hoverName(): PlayControlHoverName | null {
    switch (this.action) {
      case '$skip':
        if (isNumber(this.actionPayload)) {
          return this.actionPayload >= 0 ? 'skip-forward' : 'skip-back'
        }
        break
      case '$previous':
        return 'previous'
      case '$next':
        return 'next'
      case '$playerControl':
        return this.actionPayload
      default:
        return null
    }
    return null
  }
  _hovered() {
    if (this.hoverName !== null) {
      debug.info('Play control button signaling hover for %s', this.hoverName)
      this.signal('hovered', this.hoverName)
    }
  }
  _clicked() {
    this._captureEnter()
  }
}
