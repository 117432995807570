import type { Timestamp } from 'firebase-admin/firestore'
import { z } from 'zod'
import { PublicRecordSet } from './utils'

function isTimestamp(x: any): x is Timestamp {
  return x && x.toDate && typeof x.toDate === 'function'
}

const publishable = z.object({
  _startDate: z.preprocess(val => {
    if (isTimestamp(val)) {
      return val.toDate()
    } else {
      return val
    }
  }, z.date()),
  weight: z.number().default(0),
  _endDate: z
    .preprocess(val => {
      if (isTimestamp(val)) {
        return val.toDate()
      } else {
        return val
      }
    }, z.date())
    .optional()
    .refine(
      val => {
        console.info('Parsing end date of %s', val)
        return val == null || val.getTime() > new Date().getTime()
      },
      {
        message: 'End Date must be undefined or later than right now',
      },
    ),
  _published: z.boolean().default(false),
})
export const heroConfigSchema = z
  .object({
    _id: z.string(),
    wideImage: z.string().url(),
    details: z.string(),
    title: z.string(),
    description: z.string(),
    externalId: z.string(),
  })
  .merge(publishable)
export const promotedContentConfigSchema = z
  .object({
    externalId: z.string(),
    title: z.string(),
    promotedContentType: z.preprocess(
      val => String(val).toLowerCase().replace(/\s+/g, '-'),
      z.enum(['category', 'content-item']),
    ),
  })
  .merge(publishable)

export type MituHeroConfig = z.infer<typeof heroConfigSchema>
export type PublicHeroConfig = PublicRecordSet<MituHeroConfig>

export type PromotedContentConfig = z.infer<typeof promotedContentConfigSchema>
export type PublicPromitedContentConfig = PublicRecordSet<PromotedContentConfig>

export function makePublicRecord<T extends object>(x: T): PublicRecordSet<T> {
  return Object.entries(x).reduce((acc, [key, value]) => {
    if (key.indexOf('_') !== 0) acc[key as keyof T] = value
    return acc
  }, {} as Partial<T>) as PublicRecordSet<T>
}
export interface MituConfig {
  heros: PublicHeroConfig[]
  promotedContent: PublicPromitedContentConfig[]
}
