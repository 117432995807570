import {
  ContentItem,
  ContentSelectedDirectionalSignalMap,
} from '@adiffengine/engine-types'

import { Colors, Lightning, Registry, Utils } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import isEqual from 'fast-deep-equal/es6'
import { isGoodString } from '../lib'
import { getStoredTheme } from '../themes'
import { AdeButton } from './AdeButton'
import { AdeImage } from './AdeImage'
import {
  StackModal,
  StackModalImplement,
  StackModalTemplate,
  StackModalTemplateSpec,
  StackModalTypeConfig,
} from './StackModal'
import { StandardBackground } from './backgrounds'

// const debug = new Debugger('AlbumEpisodeModal')

export interface AlbumEpisodeModalTemplateSpec extends StackModalTemplateSpec {
  Shadow: {
    Drop: object
  }
  BackgroundWrapper: {
    ThemedBackground: typeof StandardBackground
  }
  InnerWrapper: object
  ContentItem: typeof AlbumEpisodeModalContent
  content: ContentItem
}

const MODAL_HEIGHT = 600
const MODAL_WIDTH = 1200
const MODAL_X = (1920 - MODAL_WIDTH) / 2
const MODAL_Y = (1080 - MODAL_HEIGHT) / 2
const MODAL_PADDING = 40
const MODAL_IMAGE_DIMENSION = 200

const CONTENT_X = MODAL_X + MODAL_PADDING
const CONTENT_Y = MODAL_Y + MODAL_PADDING
const CONTENT_WIDTH = MODAL_WIDTH - MODAL_PADDING * 2
const CONTENT_HEIGHT = MODAL_HEIGHT - MODAL_PADDING * 2
const CONTENT_TEXT_WIDTH =
  CONTENT_WIDTH - MODAL_IMAGE_DIMENSION - MODAL_PADDING * 2

export type AlbumEpisodeModalTypeConfig = StackModalTypeConfig

export class AlbumEpisodeModal
  extends StackModal<AlbumEpisodeModalTemplateSpec, AlbumEpisodeModalTypeConfig>
  implements StackModalImplement<AlbumEpisodeModalTemplateSpec>
{
  static override ModalName = 'AlbumEpisodeModal'

  ContentItem = this.tag('ContentItem')!
  ThemedBackground = this.tag('BackgroundWrapper')!.tag('ThemedBackground')!
  Shadow = this.getByRef('Shadow')!
  static override _template(): StackModalTemplate<AlbumEpisodeModalTemplateSpec> {
    const theme = getStoredTheme()
    return {
      ...super._template(),
      Shadow: {
        x: MODAL_X + MODAL_WIDTH / 2 + 12,
        y: MODAL_Y + MODAL_HEIGHT / 2 + 12,
        w: MODAL_WIDTH + 96,
        h: MODAL_HEIGHT + 96,
        mountX: 0.5,
        mountY: 0.5,
        color: Colors(theme.palette.darks[600]).alpha(1).get(),
        rect: true,
        rtt: true,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 24,
        },
        alpha: 0.0001,
        texture: Lightning.Tools.getShadowRect(
          MODAL_WIDTH + 96,
          MODAL_HEIGHT + 96,
          24,
          24,
          48,
        ),
      },
      BackgroundWrapper: {
        x: MODAL_X,
        y: MODAL_Y,
        w: MODAL_WIDTH,
        h: MODAL_HEIGHT,
        rect: true,
        rtt: true,
        color: Colors('background').get(),
        ThemedBackground: {
          alpha: 1,
          x: 0,
          y: 0,
          w: MODAL_WIDTH,
          h: MODAL_HEIGHT,
          type: StandardBackground,
        },
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 24,
        },
      },

      ContentItem: {
        type: AlbumEpisodeModalContent,
        x: CONTENT_X,
        y: CONTENT_Y,
        w: CONTENT_WIDTH,
        h: CONTENT_HEIGHT,
      },
    }
  }
  override _construct() {
    this.displayShadowRect = this.displayShadowRect.bind(this)
  }
  private _timeout: number | null = null
  displayShadowRect() {
    if (this._timeout !== null) {
      Registry.clearTimeout(this._timeout)
    }
    this._timeout = Registry.setTimeout(() => {
      this._timeout = null
      this.Shadow.patch({
        alpha: 0.8,
      })
      this._timeout = null
    }, 100)
  }
  override _firstActive() {
    this.Shadow.on('txLoaded', this.displayShadowRect)
  }
  override _active() {
    const simpleColors = this.fireAncestors('$getCurrentPalette')

    if (simpleColors) {
      this.ThemedBackground.patch({
        smooth: {
          colorTop: [Colors(simpleColors.background).get(), { duration: 0.2 }],
          colorBottom: [Colors(simpleColors.gradient).get(), { duration: 0.2 }],
        },
      })
    }
  }

  set content(content: ContentItem) {
    this.ContentItem.patch({ content })
  }

  override _getFocused() {
    return this.ContentItem
  }
}

export interface AlbumEpisodeModalContentTemplateSpec
  extends Lightning.Component.TemplateSpec {
  ListWrapper: {
    List: typeof List
  }
  content: ContentItem
}

export interface AlbumEpisodeModalContentTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: ContentSelectedDirectionalSignalMap
}

export interface AlbumEpisodeModalContentTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Text: {
    Title: object
    Description: object
    Details: object
  }
  PlaySection: {
    Art: typeof AdeImage
    PlayButton: typeof AdeButton
  }
  content: ContentItem
}
export interface AlbumEpisodeModalContentTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: ContentSelectedDirectionalSignalMap
}

export class AlbumEpisodeModalContent
  extends Lightning.Component<
    AlbumEpisodeModalContentTemplateSpec,
    AlbumEpisodeModalContentTypeConfig
  >
  implements
    Lightning.Component
      .ImplementTemplateSpec<AlbumEpisodeModalContentTemplateSpec>
{
  Text = this.getByRef('Text')!
  Title = this.Text.getByRef('Title')!
  Description = this.Text.getByRef('Description')!
  Details = this.Text.getByRef('Details')!
  PlaySection = this.getByRef('PlaySection')!
  PlayButton = this.PlaySection.getByRef('PlayButton')!
  Art = this.PlaySection.getByRef('Art')!
  static override _template(): Lightning.Component.Template<AlbumEpisodeModalContentTemplateSpec> {
    return {
      x: 0,
      y: 0,
      w: MODAL_WIDTH - MODAL_PADDING * 2,
      h: MODAL_HEIGHT - MODAL_PADDING * 2,
      clipping: true,
      color: 0x00000000,
      Text: {
        x: 0,
        y: 0,
        w: CONTENT_TEXT_WIDTH,
        flex: {
          direction: 'column',
        },
        Title: {
          visible: false,
          color: Colors('text').get(),
          text: {
            wordWrapWidth: CONTENT_TEXT_WIDTH,
            fontFace: 'Bold',
            fontSize: 36 * 1.25,
            wordWrap: true,
            maxLines: 2,
          },
          flexItem: {
            marginBottom: 24,
          },
        },
        Description: {
          flexItem: {
            marginTop: 0,
            marginBottom: 24,
          },
          visible: false,
          color: Colors('text').get(),
          text: {
            wordWrapWidth: CONTENT_TEXT_WIDTH,
            fontFace: 'Text',
            fontSize: 20,
            lineHeight: 24 * 1.5,
            wordWrap: true,
            maxLines: 10,
          },
        },
        Details: {
          flexItem: {
            marginTop: 0,
          },
          visible: false,
          color: Colors('text').get(),
          text: {
            wordWrapWidth: CONTENT_TEXT_WIDTH,
            fontFace: 'Regular',
            fontSize: 16,
            wordWrap: true,
            maxLines: 1,
          },
        },
      },
      PlaySection: {
        w: MODAL_IMAGE_DIMENSION,
        x: CONTENT_WIDTH - MODAL_IMAGE_DIMENSION - MODAL_PADDING,
        y: 0,
        flexItem: {
          marginTop: 24,
          marginBottom: 24,
        },
        flex: {
          direction: 'column',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        },
        Art: {
          type: AdeImage,
          flexItem: {
            marginBottom: MODAL_PADDING,
          },
          h: 200,
          w: 200,
        },
        PlayButton: {
          type: AdeButton,
          buttonText: 'PLAY',
          signals: {
            selected: 'contentSelected',
          },
          leftIcon: `play-sharp.svg`,
        },
      },
    }
  }

  override _construct() {
    this.artError = this.artError.bind(this)
  }

  override _active() {
    this.Art.on('txError', this.artError)
  }

  artError() {
    this.Art.patch({ visible: false })
  }

  private _content: ContentItem | undefined = undefined
  set content(item: ContentItem) {
    if (!isEqual(item, this._content)) {
      this._content = item
      this.Title.patch({
        visible: isGoodString(item.title),
        text: {
          text: isGoodString(item.title) ? item.title.toUpperCase().trim() : '',
        },
      })
      this.Description.patch({
        visible: isGoodString(item.description),
        text: {
          text: isGoodString(item.description)
            ? item.description.replace(/<\/?[^>]+(>|$)/gi, '').trim()
            : '',
        },
      })
      this.Details.patch({
        visible: isGoodString(item.details),
        text: {
          text: isGoodString(item.details) ? item.details : '',
        },
      })

      const image = item.images.thumb
        ? item.images.thumb.getForWidth(200)
        : null
      const imageUrl = image ? Utils.proxyUrl(image) : undefined
      if (imageUrl) {
        this.Art.patch({
          visible: true,
          imageSrc: imageUrl,
          borderRadius: 12,
        })
      } else {
        this.artError()
      }
    }
  }

  get content() {
    return this._content!
  }
  contentSelected() {
    if (this.content) {
      this.fireAncestors('$play', this.content)
    }
  }
  override _captureRight() {
    return true
  }
  override _captureLeft() {
    return true
  }
  override _captureDown() {
    return true
  }
  override _captureUp() {
    return true
  }
  override _getFocused() {
    return this.PlayButton
  }
}
