import { Colors, Lightning } from '@lightningjs/sdk'
import isEqual from 'fast-deep-equal/es6'
import defer from 'lodash/defer'
import { Debugger, cp, getBestDimensionValue } from '../lib'
const debug = new Debugger('Marquee')
debug.enabled = false

export interface MarqueeTemplateSpec extends Lightning.Component.TemplateSpec {
  Marquee: object
  textStyle: Omit<Lightning.textures.TextTexture.Settings, 'text'>
  textString: string
  spacing: number
  durationInWindow: number
}

export type MarqueeTypeConfig = Lightning.Component.TypeConfig

export class Marquee
  extends Lightning.Component<MarqueeTemplateSpec, MarqueeTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<MarqueeTemplateSpec>
{
  Marquee = this.getByRef('Marquee')!
  private _spacing: number = 40
  private _textStyle: Omit<Lightning.textures.TextTexture.Settings, 'text'> = {
    fontFace: 'Text',
    fontSize: 24,
    wordWrap: false,
  }
  private _textString: string = ''
  private _tickerConfig = {
    textWidth: 0,
    start: 0,
    duration: 0,
    end: 0,
    triggerNext: 0,
  }
  private _textColor: string = 'text'
  private _resettingWidth: number = 0
  private _durationInWindow: number = 10
  static override _template(): Lightning.Component.Template<MarqueeTemplateSpec> {
    return {
      h: 40,
      w: 300,
      rect: true,
      clipping: true,
      color: 0x0000000,
      Marquee: {
        x: 10,
        h: cp,
        w: cp,
      },
      shader: {
        type: Lightning.shaders.FadeOut,
        left: 10,
        right: 10,
      },
    }
  }

  // Lifecycle
  override _construct() {
    this.nextTick = this.nextTick.bind(this)
    this.startMarquee = this.startMarquee.bind(this)
  }
  override _active() {
    this.Marquee.childList.forEach(c => {
      c.transition('x').play()
    })
  }
  override _inactive() {
    this.Marquee.childList.forEach(c => {
      c.transition('x').stop()
    })
  }

  // Setters and Getters
  set textStyle(style: Lightning.textures.TextTexture.Settings) {
    const update = { ...this._textStyle, ...style }
    if (!isEqual(update, this._textStyle)) {
      this._textStyle = update
      this.reset()
    }
  }
  get textStyle() {
    return this._textStyle
  }
  set textString(string: string) {
    string = string.trim()
    if (string !== this._textString) {
      this._textString = string
      this.reset()
    }
  }
  get textString() {
    return this._textString
  }

  set spacing(spacing: number) {
    if (spacing !== this._spacing) {
      this._spacing = spacing
      this.reset()
    }
  }
  get spacing() {
    return this._spacing
  }

  set textColor(textColor: string) {
    if (textColor !== this._textColor) {
      this._textColor = textColor
      this.Marquee.childList.forEach(c => {
        c.patch({
          color: Colors(textColor).get(),
        })
      })
    }
  }
  get textColor() {
    return this._textColor
  }

  set durationInWindow(duration: number) {
    const greater = duration > this._durationInWindow
    this._durationInWindow = duration
    if (greater) this.reset()
  }
  get durationInWindow() {
    return this._durationInWindow
  }

  startMarquee(
    firstItem: Lightning.Element<
      Lightning.Element.TemplateSpecLoose,
      Lightning.Element.TypeConfig
    >,
  ) {
    const textWidth = getBestDimensionValue(firstItem, 'w')
    const windowWidth = getBestDimensionValue(this, 'w')

    debug.info(
      'Text Width %s Window Width %s, spacing %s',
      textWidth,
      windowWidth,
      this.spacing,
    )
    if (
      textWidth > windowWidth &&
      windowWidth > 0 &&
      textWidth !== this._resettingWidth
    ) {
      this._tickerConfig = {
        textWidth,
        start: windowWidth + this.spacing,
        duration:
          ((textWidth + this.spacing) / windowWidth) * this.durationInWindow,
        end: -1 * (textWidth + this.spacing),
        triggerNext: -1 * (textWidth - windowWidth),
      }
      debug.info('Ticker Config', JSON.stringify(this._tickerConfig, null, 2))
      debug.info(
        'Sliding first textWidth %s, wid item to %s duration: %s',
        -1 * (textWidth + this.spacing + windowWidth),
        this._tickerConfig.duration,
      )
      this.patch({
        shader: {
          left: 10,
          right: 10,
        },
      })
      firstItem.patch({
        smooth: {
          x: [
            -1 * (textWidth + this.spacing + windowWidth),
            { duration: this._tickerConfig.duration, timingFunction: 'linear' },
          ],
        },
      })
      let nextTriggered = false
      firstItem.transition('x').on('progress', () => {
        if (
          firstItem.x < this._tickerConfig.triggerNext &&
          !nextTriggered &&
          firstItem.attached
        ) {
          debug.info('First item triggering next')
          nextTriggered = true
          this.nextTick()
        }
      })
      firstItem.transition('x').on('finish', () => {
        debug.info('First Item Finished')
        this.Marquee.childList.remove(firstItem)
      })
    } else if (textWidth < windowWidth && windowWidth > 0) {
      this._tickerConfig = {
        textWidth,
        start: 0,
        duration: 0,
        end: 0,
        triggerNext: 0,
      }
    }
  }

  reset() {
    debug.info('Reset Called')
    this.patch({
      shader: {
        left: 0,
        right: 0,
      },
    })
    this.Marquee.childList.forEach(c => {
      c.transition('x').finish()
    })

    this.Marquee.childList.clear()
    debug.info('Child List Length %s', this.Marquee.childList.length)
    const w = getBestDimensionValue(this, 'w')
    debug.info('Resetting Marquee, w: %s textString: %s', w, this.textString)
    this.Marquee.patch({
      w,
    })

    const firstItem = this.stage.c({
      text: {
        text: this.textString,
        ...this.textStyle,
      },
    })
    this._resettingWidth = -1
    firstItem.on('txLoaded', () => {
      defer(() => {
        debug.info(
          'Texture Loaded, starting Marquee %s %s',
          this._resettingWidth,
          this.Marquee.childList.length,
        )
        this.startMarquee(firstItem)
      })
    })
    this.Marquee.childList.add(firstItem)
  }

  resetTicker() {
    this.Marquee.childList.clear()
  }

  next() {
    const next = this.stage.c({
      renderOffscreen: true,
      color: Colors(this.textColor).get(),
      text: {
        text: this.textString,
        ...this.textStyle,
      },
    })
    return next
  }
  nextTick() {
    const doNext = () => {
      const nextChild = this.next()
      this.Marquee.childList.add(nextChild)
      nextChild.patch({
        x: this._tickerConfig.start,
        smooth: {
          x: [
            this._tickerConfig.end,
            { duration: this._tickerConfig.duration, timingFunction: 'linear' },
          ],
        },
      })
      let nextTriggered = false
      nextChild.transition('x').on('start', () => {
        debug.info('Next Item Started')
      })
      nextChild.transition('x').on('progress', () => {
        if (
          nextChild.x < this._tickerConfig.triggerNext &&
          nextChild.attached &&
          !nextTriggered
        ) {
          nextTriggered = true
          debug.info('Still progressing... ', this.attached, this.active)
          this.nextTick()
        }
      })
      nextChild.transition('x').on('finish', () => {
        if (nextChild.attached) {
          this.Marquee.childList.remove(nextChild)
        }
      })
    }
    doNext()
  }
  clearItem(item: Lightning.Element) {
    let index = -1
    this.Marquee.childList.forEach((c, idx) => {
      if (c === item) index = idx
    })
    if (index > -1) this.Marquee.childList.removeAt(index)
  }
}
