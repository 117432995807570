import {
  ActionConfig,
  ContentItem,
  Direction,
  ID,
} from '@adiffengine/engine-types'
import { Colors, Img, Lightning, Utils } from '@lightningjs/sdk'

import isFunction from 'lodash/isFunction'

import isNumber from 'lodash/isNumber'
import { cp, isGoodNumber, isGoodString } from '../lib'
import { getStoredTheme } from '../themes'
import { ContentCard } from './HeaderList'
import { AlbumCardTextStyle } from './LargeAlbumCard'

export interface AlbumCardType extends Lightning.Component.TypeConfig {
  SignalMapType: {
    focus(): void
    unfocus(): void
  }
}

export interface AlbumCardStyle {
  fontSize: number
  maxLines: number
  fontFace: 'Regular' | 'Bold' | 'Text'
  radius: number
}
export interface AlbumCardTextTemplateSpec
  extends Lightning.Component.TemplateSpec {
  TextWrapper: {
    Label: object
    Description: object
    Details: {
      DetailsText: object
    }
  }
  style: Partial<AlbumCardStyle>
}
export interface AlbumCardTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: {
    active(height: number): void
  }
}
export class AlbumCardText extends Lightning.Component {
  static override _template() {
    return {
      TextWrapper: {
        rect: true,
        color: 0x00000000,
        flex: {
          direction: 'column',
        },
        Label: {
          color: Colors('text').get(),
          text: {
            advancedRenderer: true,
            text: '',
            lineHeight: 18 * 1.25,
            fontSize: 18,
            fontFace: 'Regular',
            textAlign: 'left',
            wordWrap: true,
            maxLines: 3,
          },
        },
      },
    }
  }
}
export interface AlbumCardTemplateConfig {
  contentId: ID
  title: string
  imageSrc: string | null
  padding: number
  action: ActionConfig
  disabled: boolean
}

export interface AlbumCardSpec
  extends Lightning.Component.TemplateSpec,
    AlbumCardTemplateConfig {
  Background: object
  Shadow: object
  ImageWrapper: {
    Placeholder: object
    Image: object
  }
  Text: {
    TextWrapper: {
      Label: object
      Description: object
      Details: {
        DetailsText: object
      }
    }
  }
}

// const debug = new Debugger('SC')

export class AlbumCard
  extends Lightning.Component<AlbumCardSpec, AlbumCardType>
  implements ContentCard
{
  public direction: Direction = 'horizontal'
  public imageWidthRatio = 1
  private _leaveAnimation: Lightning.types.Animation | undefined
  static height = 280
  static width = 180
  Text = this.getByRef('Text')!
  TextWrapper = this.Text.getByRef('TextWrapper')!
  Title = this.TextWrapper.getByRef('Label')!
  ImageWrapper = this.getByRef('ImageWrapper')!
  Image = this.ImageWrapper.getByRef('Image')!
  Placeholder = this.ImageWrapper.getByRef('Placeholder')!
  static override _template(): Lightning.Component.Template<AlbumCardSpec> {
    const theme = getStoredTheme()
    const {
      components: { SimpleCardConfig },
    } = theme
    const cardRadiusShader = {
      type: Lightning.shaders.RoundedRectangle,
      radius: SimpleCardConfig.radius,
    }
    return {
      Shadow: {
        alpha: 0,
        mountX: 0.5,
        mountY: 0.5,
        rect: true,
        color: Colors(theme.palette.darks[900]).get(),
        shader: cardRadiusShader,
      },
      Background: {
        x: 0,
        y: 0,
        h: cp,
        w: cp,
        alpha: 0,
        rect: true,
        shader: {
          ...cardRadiusShader,
          fillColor: Colors(theme.palette.darks[900]).get(),
        },
      },
      ImageWrapper: {
        x: 8,
        y: 8,
        shader: cardRadiusShader,
        Image: {
          x: 0,
          y: 0,
          rtt: true,
        },
        Placeholder: {
          x: 0,
          y: 0,
          rect: true,
          colorTop: Colors(theme.palette.darks[300]).get(),
          colorBottom: Colors(theme.palette.darks[700]).get(),
          shader: cardRadiusShader,
        },
      },
      Text: {
        y: 8,
        x: 8,
        TextWrapper: {
          rect: true,
          color: 0x00000000,
          flex: {
            direction: 'column',
          },
          Label: {
            color: Colors('text').get(),
            text: {
              advancedRenderer: true,
              text: '',
              lineHeight: 18 * 1.25,
              fontSize: 18,
              fontFace: 'Text',
              textAlign: 'left',
              wordWrap: true,
              maxLines: 3,
            },
          },
        },
      },
    }
  }

  set content(content: ContentItem) {
    this.title = content.title
    const image =
      content.images.thumb ??
      content.images.wallpaper ??
      content.images.wide ??
      content.images.box
    if (image) {
      const imageSrc = image.getForWidth(this.w) ?? null
      if (imageSrc) this.imageSrc = Utils.proxyUrl(imageSrc)
    }
  }

  set style(style: Partial<AlbumCardStyle>) {
    const { fontFace = null, fontSize, maxLines, radius } = style
    const textPatch: AlbumCardTextStyle = {}
    if (isNumber(fontSize)) textPatch.fontSize = fontSize
    if (isNumber(maxLines)) textPatch.maxLines = maxLines
    if (isGoodString(style.fontFace)) textPatch['fontFace'] = fontFace
    if (isGoodString(style.fontFace)) textPatch['fontFace'] = fontFace
    if (Object.keys(textPatch).length > 0) {
      this.Title.patch({
        text: textPatch,
      })
    }
    if (isGoodNumber(radius)) {
      const shader = {
        type: Lightning.shaders.RoundedRectangle,
        radius: radius,
      }
      this.patch({
        Background: {
          shader,
        },
        ImageWrapper: {
          shader,
          Placeholder: {
            shader,
          },
        },
      })
    }
  }
  private _cardWidth: number = 100
  set cardWidth(width: number) {
    this._cardWidth = width
  }
  get cardWidth() {
    return this._cardWidth
  }
  get cardHeight(): number {
    return this.cardWidth * 1.4
  }

  set title(text: string | undefined) {
    this.tag('Text.TextWrapper.Label')?.patch({
      text: { text: text ?? '' },
    })
  }

  private _imageSrc: string | null = null
  set imageSrc(src: string | null) {
    this._imageSrc = src
    if (this.__initialized) this.patchForDimensions()
  }

  private _focusAnimation: Lightning.types.Animation | null = null
  get animationConfig(): Parameters<typeof this.animation>[0] {
    const theme = getStoredTheme()
    return {
      duration: 0.2,
      actions: [
        { p: 'scale', v: { 0: 1, 1: 1.05 } },
        { t: 'Background', p: 'scale', v: { 0: 1, 1: 1.05 } },
        {
          t: 'Background',
          // @ts-ignore - bad types in lightning animation
          p: 'shader.fillColor',
          v: {
            0: Colors(theme.palette.darks[900]).get(),
            1: Colors(theme.palette.highlights[500]).get(),
          },
        },
        {
          t: 'Background',
          p: 'alpha',
          v: { 0: 0, 1: 1 },
        },
        { t: 'Shadow', p: 'alpha', v: { 0: 0, 1: 0.3 } },
        {
          t: 'Text.TextWrapper.Label',
          p: 'color',
          v: {
            0: Colors(theme.palette.text).get(),
            1: Colors(theme.palette.darks[900]).get(),
          },
        },
        {
          t: 'Text.TextWrapper.Description',
          p: 'color',
          v: {
            0: Colors(theme.palette.text).get(),
            1: Colors(theme.palette.darks[900]).get(),
          },
        },
        {
          t: 'Text.TextWrapper.Details.DetailsText',
          p: 'color',
          v: {
            0: Colors(theme.palette.text).get(),
            1: Colors(theme.palette.darks[900]).get(),
          },
        },
      ],
    }
  }
  get focusAnimation(): Lightning.types.Animation {
    if (!this._focusAnimation) {
      this._focusAnimation = this.animation(this.animationConfig)
    }
    return this._focusAnimation
  }

  override _active() {
    this.patchForDimensions()
  }
  private _padding: number = 4
  set padding(padding: number) {
    this._padding = padding
    if (this.__initialized) this.patchForDimensions()
  }
  get padding() {
    return this._padding
  }

  patchForDimensions() {
    const width = this.w
    const height = this.h - this.padding
    const innerWidth = width - this.padding * 2
    this.patch({
      Shadow: {
        h: height,
        w: width,
        x: width / 2,
        y: height / 2,
      },
      Background: {
        h: height,
      },
      ImageWrapper: {
        x: this.padding,
        y: this.padding,
        w: innerWidth,
        h: innerWidth,
        Placeholder: {
          w: innerWidth,
          h: innerWidth,
        },
        Image: {
          w: innerWidth,
          h: innerWidth,
        },
      },

      Text: {
        w: innerWidth,
        x: this.padding,
        y: innerWidth + this.padding * 3,
        h: this.cardHeight - (innerWidth + this.padding * 2),
        TextWrapper: {
          w: innerWidth,
          Label: {
            text: {
              wordWrapWidth: innerWidth,
            },
          },
        },
      },
    })
    if (this._imageSrc) {
      this.Image.patch({
        texture: Img(this._imageSrc).cover(innerWidth, innerWidth),
      })
    }
    this.Image.on('txLoaded', () => {
      this.Placeholder.setSmooth('alpha', 0)
    })
  }
  private _action: ActionConfig | undefined = undefined
  set action(x: ActionConfig | undefined) {
    this._action = x
  }
  get action() {
    return this._action
  }
  get leaveAnimation() {
    if (this._leaveAnimation) return this._leaveAnimation
    this._leaveAnimation = this.animation({
      duration: 0.2,
      actions: [
        { p: 'alpha', v: { 0: 1, 1: 0 } },
        { p: 'scale', v: { 0: 1, 1: 0.4 } },
      ],
    })
    return this._leaveAnimation
  }

  disappear(cb: () => void) {
    if (isFunction(cb)) {
      this.leaveAnimation.on('finish', cb)
    }
    this.leaveAnimation.start()
  }

  override _handleEnter() {
    if (this.action) {
      this.fireAncestors(this.action.action, this.action.payload)
      return false
    } else {
      return true
    }
  }

  forceFocus() {
    this._focusAnimation = this.animation(this.animationConfig)
    this._focusAnimation.start()
  }

  override _focus() {
    this.signal('focus')
    this.focusAnimation.start()
  }

  override _unfocus() {
    this.signal('unfocus')
    this.focusAnimation.stop()
  }
}
