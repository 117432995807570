/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ContentEpisode,
  ContentItem,
  ContentSeason,
  CoordinateDimensions,
  MediaDetails,
  PartialSettingsFile,
  SettingsFile,
} from '@adiffengine/engine-types'
import { Lightning, Registry, Router, VideoPlayer } from '@lightningjs/sdk'
import isDate from 'lodash-es/isDate'
import isFun from 'lodash-es/isFunction'
import isNum from 'lodash-es/isNumber'
import isStr from 'lodash-es/isString'
import deepmerge from 'ts-deepmerge'
import { AdeBoxArtButton, BoxArtHeight } from '../components'
import { CodedError, MessagedError, NotFoundMessageError } from './coded-error'
import { Debugger } from './debugger'
import {
  expandDimensions,
  getCoordinateDimensions,
  getCoordindatesFromPatch,
  isComponentDimensions,
} from './lightning-tools'
// @ts-ignore
const debug = new Debugger('utils')

export const isNumber = isNum
export const isFunction = isFun
export const isString = isStr

export function isValidDate(last: any): last is Date {
  return isDate(last) && isFinite(last.getTime())
}

export function parseIdFromPath(path: string): string | null {
  path = path.trim()
  path = path.replace(/^\//, '').replace(/\/$/, '')
  if (path.length === 0) return null
  const matches = path.match(/^(details|player)\/(movie|tv)\/([^/]*)/)
  return matches && matches[3] ? matches[3] : null
}
export function isBoolean(x: unknown): x is boolean {
  return x === true || x === false
}
export function safeHash(): string {
  try {
    return document.location.hash.replace(/^#/, '')
  } catch (e) {
    const hash = Router.getActiveHash()
    return hash ? hash.replace(/^#/, '') : 'unknown'
  }
}

export function getProcessVar<T = any>(key: string): T | null {
  try {
    if (typeof process !== undefined && typeof process.env !== undefined) {
      const value = process.env[key]
      return (value as T) ?? null
    } else {
      console.warn('process.env not set.')
      return null
    }
  } catch (e) {
    console.warn('Error getting process const %s', e.message)
    return null
  }
}
export function isObject(x: unknown): x is Record<string, unknown> {
  return typeof x === 'object' && !Array.isArray(x) && x !== null
}

export function boxListItems(
  content: ContentItem[],
  opts: Record<string, any> = {},
) {
  return content.map(c => ({
    type: AdeBoxArtButton,
    h: isNumber(opts['h']) ? opts['h'] : 218,
    content: c,
    imageSrc: c.images.box!.getForWidth(
      ((isNumber(opts['h']) ? opts['h'] : BoxArtHeight) * 44) / 66,
    ),
    ...opts,
  }))
}

export function sortByKey<T = any>(key: keyof T, a: T, b: T) {
  const aValue = a[key]
  const bValue = b[key]
  const aNumber = isNumber(aValue) ? aValue : Infinity
  const bNumber = isNumber(bValue) ? bValue : Infinity
  return aNumber - bNumber
}

export function getFirstSeason(item: ContentItem) {
  if (!Array.isArray(item.seasons)) return null
  const sorted = item.seasons
    .filter(({ number }) => number !== 0)
    .sort((a, b) => sortByKey<ContentSeason>('number', a, b))
  return sorted[0]
}
export function getFirstEpisode(item: ContentSeason) {
  if (!Array.isArray(item.episodes)) return null
  const sorted = item.episodes.sort((a, b) =>
    sortByKey<ContentEpisode>('episodeNumber', a, b),
  )
  return sorted[0]
}
export function hashCode(s: string) {
  return s
    .split('')
    .reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0)
      return a & a
    }, 0)
    .toString()
}
export function getExtension(url: any): string | null {
  if (isString(url)) {
    const items = url.split(/[#?]/)[0].split('.')
    return items.length > 0 ? items.pop()!.trim().toLowerCase() : null
  } else {
    console.warn('Did not get string to pull extension from', url)
    return null
  }
}
export function isGoodArray<T = unknown>(x: any): x is T[] {
  return Array.isArray(x) && x.length > 0
}

export function getMovieMedia(item: ContentItem): MediaDetails {
  if (item.type !== 'movie') throw new Error('Content Item is not a movie')
  const { media } = item
  if (isGoodArray<MediaDetails>(media) && media.length > 0) {
    const movie = media.find(({ type }) => type === 'movie')
    if (movie) return movie
    const trailer = media.find(
      ({ type, extra_type }) => type === 'extra' && extra_type === 'trailer',
    )
    if (trailer) return trailer
  }
  throw new NotFoundMessageError(
    `No media for ${item.title}`,
    'Unable to locate playable media for this content',
  )
}

export function isMessagedError(error: Error): error is CodedError {
  return (
    isString((error as MessagedError).code) &&
    isString((error as MessagedError).title)
  )
}

export function spacePause(e: KeyboardEvent) {
  if (e.code === 'Space' && VideoPlayer.src) {
    if (VideoPlayer.playing) VideoPlayer.pause()
    else VideoPlayer.play()
  }
}
type ValueOf<T> = T[keyof T]
type Entries<T> = [keyof T, ValueOf<T>][]
export function objectEntries<T extends object>(obj: T): Entries<T> {
  return Object.entries(obj) as Entries<T>
}

export function hasGoodEpisodes(episodes?: ContentSeason['episodes']) {
  if (isGoodArray<ContentEpisode>(episodes)) {
    const now = new Date()
    const hasGood = episodes.reduce(
      (acc, episode) => {
        const goodDate = isDate(episode.air_date) && episode.air_date < now
        return acc || goodDate
      },

      false,
    )
    return hasGood
  } else {
    return false
  }
}
export function goodEpisodes(
  episodes: ContentSeason['episodes'],
): ContentEpisode[] {
  if (isGoodArray<ContentEpisode>(episodes)) {
    const now = new Date()
    return episodes.filter(
      episode => isDate(episode.air_date) && episode.air_date < now,
    )
  } else {
    return []
  }
}

export const sleep = (ms: number) =>
  new Promise(resolve => {
    Registry.setTimeout(resolve, ms)
  })

type Func<TS extends any[], R> = (...args: TS) => R

export type LocalTimeout = ReturnType<typeof Registry.setTimeout>
export function delay(
  func: Func<any[], any>,
  wait: number,
  ...args: any[]
): LocalTimeout {
  return Registry.setTimeout(() => {
    func.call(undefined, ...args)
  }, wait)
}

export function defer(func: Func<any[], any>, ...args: any[]): LocalTimeout {
  return delay(func, 0, ...args)
}
export function getSafeHash(): string | null {
  try {
    if (document !== undefined && document.location !== undefined) {
      const cleanHash = document.location.hash.replace(/^[#/$]*/, '').trim()
      return cleanHash.length > 0 ? cleanHash : null
    }
  } catch (error) {
    console.warn('Safe hash error')
  }
  return null
}
export function simpleUUID() {
  let d = new Date().getTime() //Timestamp
  let d2 =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0 //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}
export function pathJoin(parts: string[] | string, sep?: string): string {
  parts = Array.isArray(parts) ? parts : [parts]
  const separator = sep || '/'
  const replace = new RegExp(separator + '{1,}', 'g')
  return parts.join(separator).replace(replace, separator)
}

export function splitAndLowerPath(s: string, delimiter: string = '/') {
  return s.split(delimiter).map(s => s.toLowerCase())
}

export function getSafeDocument(): Document | null {
  try {
    if (typeof document !== undefined && isFun(document.addEventListener)) {
      return document
    } else {
      return null
    }
  } catch (e) {
    console.warn('Error getting save document ' + e.message, { error: e })
    return null
  }
}
const withSymbol = typeof Symbol !== 'undefined'
type Scalar = number | string | boolean | symbol
function isScalar(value: any): value is Scalar {
  var type = typeof value
  if (type === 'string') return true
  if (type === 'number') return true
  if (type === 'boolean') return true
  if (withSymbol === true && type === 'symbol') return true

  if (value == null) return true
  if (withSymbol === true && value instanceof Symbol) return true
  if (value instanceof String) return true
  if (value instanceof Number) return true
  if (value instanceof Boolean) return true

  return false
}
export function isScrollTransition(x: any): x is Lightning.types.Transition {
  return x != null && !isScalar(x) && x.isTransition === true
}

function getBoundsTest(target: CoordinateDimensions) {
  target = isComponentDimensions(target)
    ? getCoordindatesFromPatch(target)
    : target
  return {
    left: target.x,
    right: target.x + target.width,
    top: target.y,
    bottom: target.y + target.height,
  }
}
export function testWithinContainer(
  container: Lightning.Component,
  test: Lightning.Component,
  direction?: 'horizontal' | 'vertical',
) {
  if (direction === 'horizontal') {
    return componentWithinContainerHorizontally(container, test)
  } else if (direction === 'vertical') {
    return componentWithinContainerVertically(container, test)
  } else {
    return isFullyWithinContainer(
      getCoordinateDimensions(container),
      getCoordinateDimensions(test),
    )
  }
}
export function componentWithinContainerVertically(
  bucket: Lightning.Element,
  target: Lightning.Element,
) {
  return isWithinContainerVertically(
    getCoordinateDimensions(bucket),
    getCoordinateDimensions(target),
  )
}
export function componentWithinContainerHorizontally(
  bucket: Lightning.Element,
  target: Lightning.Element,
) {
  return isWithinContainerHorizontally(
    getCoordinateDimensions(bucket),
    getCoordinateDimensions(target),
  )
}

export function isWithinContainerVertically(
  bucket: CoordinateDimensions,
  target: CoordinateDimensions,
) {
  const truth = getBoundsTest(bucket)
  const test = getBoundsTest(target)

  return test.top >= truth.top && test.bottom <= truth.bottom
}
export function isWithinContainerHorizontally(
  bucket: CoordinateDimensions,
  target: CoordinateDimensions,
): boolean {
  const truth = getBoundsTest(bucket)
  const test = getBoundsTest(target)
  return test.left >= truth.left && test.right <= truth.right
}
export function isFullyWithinContainer(
  bucket: CoordinateDimensions,
  target: CoordinateDimensions,
): boolean {
  return (
    isWithinContainerVertically(bucket, target) &&
    isWithinContainerHorizontally(bucket, target)
  )
}
export function stamper(txt = 'Stamp') {
  return () => {
    const deb = new Debugger(txt)
    deb.info(new Date().getTime().toString().slice(-5))
  }
}
export function getParentWrapper(
  component: Lightning.Element,
): Lightning.Element | null {
  let parent = component.parent
  if (!parent) return null
  return parent && parent.ref === 'Wrapper' ? parent.parent : parent
}

export function isWithinParentBounds(
  component: Lightning.Element,
  fuzz: number = 0,
): boolean | null {
  const parent = getParentWrapper(component)
  if (!parent) return null
  let myCoords = getCoordinateDimensions(component, true)
  const parentCoords = getCoordinateDimensions(parent, true)
  if (fuzz !== 0) {
    myCoords = expandDimensions(myCoords, fuzz, true)
  }
  return isFullyWithinContainer(parentCoords, myCoords)
}
export function mergeConfig(
  base: SettingsFile,
  ...merges: PartialSettingsFile[]
): SettingsFile {
  if (merges.length === 0) return base
  merges.forEach(merge => {
    if (isObject(merge.appSettings?.keys)) {
      base.appSettings.keys = {}
    }
    base = deepmerge(base, merge) as SettingsFile
  })
  return base
}
export function safeWindowVar(key: string) {
  try {
    if (typeof window !== undefined) {
      return (window as any)[key]
    } else if (typeof global !== undefined) {
      return (global as any)[key]
    }
    return undefined
  } catch (error) {
    console.warn('Error in safeWindowVar %s', error.message)
    return undefined
  }
}
