import { PointerState, RemoteDirection } from '@adiffengine/engine-types'
import { Lightning } from '@lightningjs/sdk'
import { Debugger, defer } from '../../../lib'
import { AdvancedGridPageClicker } from './AdvancedGridPageClicker'
// const debug = new Debugger('AdvancedGridMainMenuScrollButtons')
const debug = new Debugger('HoverZone')

export interface AdvancedGridMainMenuScrollButtonsTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Buttons: {
    UpButton: typeof AdvancedGridPageClicker
    Text: object
    DownButton: typeof AdvancedGridPageClicker
  }
  handleInactivePointer: boolean
  menuActive: boolean
}
export interface AdvancedGridMainMenuScrollButtonsTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: {
    up(): void
    down(): void
  }
}
export class AdvancedGridMainMenuScrollButtons
  extends Lightning.Component<
    AdvancedGridMainMenuScrollButtonsTemplateSpec,
    AdvancedGridMainMenuScrollButtonsTypeConfig
  >
  implements
    Lightning.Component
      .ImplementTemplateSpec<AdvancedGridMainMenuScrollButtonsTemplateSpec>
{
  static width = 50
  static height = 156

  Buttons = this.getByRef('Buttons')!
  UpButton = this.Buttons.getByRef('UpButton')!
  DownButton = this.Buttons.getByRef('DownButton')!

  static override _template(): Lightning.Component.Template<AdvancedGridMainMenuScrollButtonsTemplateSpec> {
    return {
      alpha: 1,
      w: this.width,
      h: this.height,
      Buttons: {
        x: 0,
        flex: {
          direction: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        },
        w: this.width,
        h: this.height,
        UpButton: {
          type: AdvancedGridPageClicker,
          direction: 'up',
          flexItem: {
            marginBottom: 18,
          },
          signals: {
            clicked: '_signalUp',
          },
        },
        Text: {
          h: 20,
          text: {
            text: 'SCROLL',
            fontFace: 'Text',
            fontSize: 14,
            textAlign: 'center',
          },
        },
        DownButton: {
          flexItem: {
            marginTop: 18,
          },
          type: AdvancedGridPageClicker,
          direction: 'down',
          signals: {
            clicked: '_signalDown',
          },
        },
      },
    }
  }
  _handleInactivePointer = true
  set handleInactivePointer(handle: boolean) {
    if (this._handleInactivePointer !== handle) {
      this._handleInactivePointer = handle
      if (!handle) {
        this._trackPointer()
      } else {
        this._deactivateTrackPointer()
      }
    }
  }
  get handleInactivePointer() {
    return this._handleInactivePointer
  }
  _dontHammer = false
  _signalUp() {
    if (!this._dontHammer) {
      // For some reason this signal is gettign called twice, so we're blocking that.
      this._dontHammer = true
      defer(() => {
        this._dontHammer = false
        this.signal('up')
        this.stage.application.emit('mainMenuScrollButton', 'up')
      })
    }
  }
  _signalDown() {
    if (!this._dontHammer) {
      // For some reason this signal is gettign called twice, so we're blocking that.
      this._dontHammer = true
      defer(() => {
        this._dontHammer = false
        this.signal('down')
        this.stage.application.emit('mainMenuScrollButton', 'down')
      })
    }
  }

  private _showAnimationInstance: Lightning.types.Animation | null = null
  get _showAnimation() {
    if (this._showAnimationInstance === null) {
      this._showAnimationInstance = this.animation({
        duration: 0.4,
        actions: [
          {
            p: 'alpha',
            v: { 0: 0.0001, 1: 1 },
          },
        ],
      })
      this._showAnimation.on('start', () => {
        debug.info('Show Animation Start')
        this.UpButton.patch({
          disabled: false,
        })
        this.DownButton.patch({
          disabled: false,
        })
      })
      this._showAnimation.on('stop', () => {
        debug.info('Show Animation Stop')
        this.UpButton.patch({
          disabled: true,
        })
        this.DownButton.patch({
          disabled: true,
        })
      })
    }
    return this._showAnimationInstance
  }

  private _menuActive = false
  set menuActive(active: boolean) {
    if (active !== this._menuActive) {
      this._menuActive = active
      if (this.menuActive) {
        this._showAnimation.stop()
      } else {
        this._showAnimation.start()
      }
    }
  }
  get menuActive() {
    return this._menuActive
  }

  _handleMenuState(open: boolean) {
    this.menuActive = open
  }
  _handlePointerState(state: PointerState) {
    if (state === 'active') this._pointerActive()
    else this._pointerInactive()
  }

  _pointerActive() {
    if (!this._menuActive) {
      this._showAnimation.start()
    }
  }
  _pointerInactive() {
    this._showAnimation.stop()
  }

  override _construct() {
    this._handlePointerState = this._handlePointerState.bind(this)
    this._handleMenuState = this._handleMenuState.bind(this)
  }

  private _deactivateTrackPointer() {
    this.stage.application.off('pointerState', this._handlePointerState)
    this.stage.application.off('menuOpen', this._handleMenuState)
  }

  private _trackPointer() {
    if (this.handleInactivePointer) {
      const pointerActive = this.fireAncestors('$pointerState')
      if (pointerActive) {
        this._pointerActive()
      }
      this.stage.application.on('pointerState', this._handlePointerState)
      this.stage.application.on('menuOpen', this._handleMenuState)
    }
  }
  override _active() {
    this._trackPointer()
  }
  override _inactive() {
    this._deactivateTrackPointer()
  }
  setDisabled(
    direction: Omit<RemoteDirection, 'left' | 'right'>,
    disabled = true,
  ) {
    if (direction === 'up') {
      this.UpButton.patch({ disabled })
    }
    if (direction === 'down') {
      this.DownButton.patch({ disabled })
    }
  }
}
