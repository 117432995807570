/* eslint  @typescript-eslint/no-explicit-any: 0 */
import { Metrics } from '@firebolt-js/sdk'
import isString from 'lodash-es/isString'
export class CodedError extends Error {
  code = ''
  errorCode = 'ADE_UNKNOWN'
  errorType: Metrics.ErrorType = Metrics.ErrorType.OTHER
  errorDescription = 'An Error Occured'
  errorTitle = 'Unknown Error'
  visible = true
  extra: any = null
  constructor(msg?: string, errorCode?: string, extra?: any) {
    super(msg)
    if (!msg) this.message = this.errorTitle
    if (errorCode) this.errorCode = errorCode
    if (extra) this.extra = extra
  }
}

export function isCodedError(e: Error): e is CodedError {
  const error = e as CodedError
  return (
    isString(error.code) &&
    isString(error.errorCode) &&
    isString(error.errorType)
  )
}
export const MediaErrorCodes: Record<number, string> = {
  1: 'MEDIA_ERR_ABORTED',
  2: 'MEDIA_ERR_NETWORK',
  3: 'MEDIA_ERR_DECODE',
  4: 'MEDIA_ERR_SRC_NOT_SUPPORTED',
}

export function getCodedError(e: Error): CodedError {
  if (!isCodedError(e)) {
    ;(e as any).code = 'unknown-error'
    ;(e as any).errorCode = 'ADE_UN_1'
    ;(e as any).errorType = Metrics.ErrorType.OTHER
    return e as CodedError
  } else {
    return e
  }
}

export class ProviderMissingError extends CodedError {
  public override readonly code = 'provider-not-found'
  public override readonly errorCode = 'ADE_PNF_1'
  public override readonly visible = true

  constructor(msg: string, providerName?: string) {
    super(
      `${msg} must be used within ${
        providerName ? providerName + ' provider' : 'The necessary provider'
      }`,
    )
  }
}

export class NotAuthenticatedError extends CodedError {
  public override readonly code = 'not-authenticated'
  public override readonly errorCode = 'ADE_NA_1'
  public override readonly errorType = Metrics.ErrorType.ENTITLEMENT
  public override readonly visible = true
  public override errorDescription = 'Not Authenticated'
  public override errorTitle =
    'You must be authenticated to view this part of the application.'
}
export class UnprocessableError extends CodedError {
  public override readonly code = 'unprocessable'
  public override errorCode = 'ADE_UE_1'
  public override readonly errorType = Metrics.ErrorType.NETWORK
}

export class NotFoundError extends CodedError {
  public override readonly code = 'not-found'
  public override errorCode = 'ADE_NF_1'
  public override readonly errorType = Metrics.ErrorType.NETWORK
  public override errorDescription = 'Not Found'
  public override errorTitle = `We were unable to find your content, please try again later.`
}

export class NotLinkedError extends CodedError {
  public override readonly code = 'not-linked'
  public override readonly errorCode = 'ADE_NL_1'
  public override readonly errorType = Metrics.ErrorType.ENTITLEMENT
}

export class FirebaseError extends CodedError {}
export abstract class MessagedError extends CodedError {
  public title: string
  constructor(title: string, msg: string) {
    super(msg)
    this.title = title
  }
}

export class BadRequest extends CodedError {
  public override code = 'bad-request'
  public override readonly errorCode = 'ADE_BR_1'
}
export class NotFoundMessageError extends MessagedError {
  public override code = 'not-found'
}
