import { AppTemplateConfig } from '@adiffengine/engine-types'
import { Router } from '@lightningjs/sdk'
import {
  AdePlayerPlane,
  ConfirmModal,
  HeroWidget,
  KeyWidgetDisplay,
  MainMenu,
  StandardBackground,
  ThorErrorModal,
} from './components'

import {
  ThorError,
  ThorErrorType,
  convertErrorToThorError,
  isThorError,
} from './lib'

export interface ThorAppTemplateSpec extends Router.App.TemplateSpec {
  Background: typeof StandardBackground
  Widgets: {
    VideoPlayer: typeof AdePlayerPlane
    MainMenu: typeof MainMenu
    HeroWidget: typeof HeroWidget
    KeyWidgetDisplay: typeof KeyWidgetDisplay
    ErrorModal: typeof ThorErrorModal
    ConfirmModal: typeof ConfirmModal
  }
  Main: object
  Fader: object
}

export type ThorAppBaseType<
  T extends ThorAppTemplateSpec = ThorAppTemplateSpec,
  C extends AppTemplateConfig = AppTemplateConfig,
> = typeof Router.App<T, C>

export type ThorAppBase = Router.App<ThorAppTemplateSpec, AppTemplateConfig>

export const appErrorWrapper = <
  PageType extends Router.PageInstance = Router.PageInstance,
  PageParams extends Router.PageParams = Router.PageParams,
>(
  before?: (page: PageType, params?: PageParams) => Promise<void>,
) => {
  return before
    ? async (page: PageType, params: PageParams) => {
        try {
          const queryParams: Record<string, string | undefined> =
            params[Router.symbols.queryParams] ?? {}
          const { error, errorType: et } = queryParams
          if (error === 'true') {
            let type: ThorErrorType = ThorError.Type.UnknownError
            if (et) {
              const errorType = parseInt(et, 10)
              if (Object.values(ThorError.Type).includes(errorType)) {
                type = errorType
              }
            }
            throw new ThorError('Test Error', type)
          }
          await before(page, params)
        } catch (error) {
          if (!isThorError(error)) {
            throw convertErrorToThorError(error)
          } else {
            throw error
          }
        }
      }
    : before
}
