import { DirectionalSignalMap } from '@adiffengine/engine-types'
import { Lightning } from '@lightningjs/sdk'
import isFunction from 'lodash/isFunction'
import isNumber from 'lodash/isNumber'
import { Debugger, isGoodNumber } from '../lib'
const debug = new Debugger('PagingWindow')
export type HorW = 'h' | 'w'
export type WindowChild = Lightning.Component & {
  offset: number | undefined
}

export interface PagingWindowTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: DirectionalSignalMap
}

export interface PagingWindowTemplateSpec
  extends Lightning.Component.TemplateSpec {
  items: Record<string, unknown>[]
  index: number
  direction: 'row' | 'column'
}

export function isPagingWindow(x: any): x is PagingWindow {
  return x?.constructor === PagingWindow
}
export type PagingWindowItem = Record<string, unknown>

export class PagingWindow
  extends Lightning.Component<PagingWindowTemplateSpec, PagingWindowTypeConfig>
  implements
    Lightning.Component.ImplementTemplateSpec<PagingWindowTemplateSpec>
{
  static override _template(): Lightning.Component.Template<PagingWindowTemplateSpec> {
    return {
      x: 0,
      y: 0,
      h: 1080,
      w: 1920,
    }
  }

  private _direction: 'row' | 'column' = 'column'
  private _items: PagingWindowItem[] = []

  _onRight() {
    this.signal('right')
  }
  _onLeft() {
    this.signal('left')
  }
  _onUp() {
    this.signal('up')
  }
  _onDown() {
    this.signal('down')
  }
  set items(items: PagingWindowItem[]) {
    this.childList.clear()
    items.forEach(item => {
      const staticCoordinate = this.direction === 'row' ? 'y' : 'x'
      return this.childList.a({ ...item, [staticCoordinate]: 0 })
    })
    console.info('Items Set', this.childList)
    this._items = items
    this.layout()
  }
  get items(): PagingWindowItem[] {
    return this._items
  }
  set direction(dir: 'row' | 'column') {
    this._direction = dir
  }
  get direction() {
    return this._direction
  }
  private _index = 0
  set index(x: number) {
    if (x >= 0 && x < this.children.length) {
      if (isFunction((this.childList.getAt(this._index) as any)?._onBlur)) {
        ;(this.childList.getAt(this._index) as any)!._onBlur()
      }
      this._index = x
      this.setSmooth(this.direction === 'column' ? 'y' : 'x', this._offsets[x])
    }
  }
  get index() {
    return this._index
  }

  $go(dir: 'up' | 'down' | 'left' | 'right') {
    if (
      (dir === 'up' && this.direction === 'column' && this.index > 0) ||
      (dir === 'left' && this.direction === 'row' && this.index > 0)
    ) {
      this.index--
    } else if (
      (dir === 'down' &&
        this.direction === 'column' &&
        this.index < this.childList.length - 1) ||
      (dir === 'right' &&
        this.direction === 'row' &&
        this.index < this.childList.length - 1)
    ) {
      this.index++
    }

    if (this.direction === 'row' && (dir === 'up' || dir === 'down')) {
      this.fireAncestors('$go', dir)
    } else if (
      this.direction === 'column' &&
      (dir === 'left' || dir === 'right')
    ) {
      this.fireAncestors('$go', dir)
    }
  }
  private _offsets: number[] = [0]

  static dimensions = {
    h: 'height',
    w: 'width',
  }

  layout() {
    let total = 0
    const coordinateProps = this.direction === 'column' ? 'y' : 'x'
    const altCoordinate = this.direction === 'column' ? 'x' : 'y'
    this.children.forEach((child, idx) => {
      let dimensionProp: HorW = this.direction === 'column' ? 'h' : 'w'
      if ((child as any).useDimensionName === true) {
        dimensionProp = PagingWindow.dimensions[dimensionProp] as HorW
      }

      const dimension =
        isNumber(child[dimensionProp]) && child[dimensionProp] > 0
          ? child[dimensionProp]
          : this[dimensionProp]
      const coordinate =
        idx > 0
          ? this.children[idx - 1][dimensionProp] +
            this.children[idx - 1][coordinateProps]
          : 0

      let altDimension: HorW = this.direction === 'column' ? 'w' : 'h'
      if ((child as any).useDimensionName === true) {
        altDimension = PagingWindow.dimensions[altDimension] as HorW
      }

      const altDimensionSize =
        isNumber(this._items[idx][altDimension]) &&
        (this._items[idx][altDimension] as number) > 0
          ? this._items[idx][altDimension]
          : this[altDimension]

      const offset = isNumber((this.children[idx - 1] as any)?.offset)
        ? (this.children[idx - 1] as any).offset
        : 0

      this._offsets[idx] = (coordinate - offset) * -1

      const patch = {
        [coordinateProps]: coordinate,
        [dimensionProp]: dimension,
        [altDimension]: altDimensionSize,
        [altCoordinate]: isGoodNumber(this._items[idx][altCoordinate])
          ? this._items[idx][altCoordinate]
          : 0,
      }
      child.patch(patch)
      total += dimension
    })

    this.patch({ [this.direction === 'column' ? 'h' : 'w']: total })
    debug.info('Layout Done ', this)
  }

  override _active() {
    this.layout()
  }

  override _getFocused(): Lightning.Component | null {
    debug.info('Paging List Get Focused')
    const response = this.childList.getAt(this.index)
    if (response?.attached) {
      return response as Lightning.Component
    } else {
      debug.warn('Paging window item is not attached', response, this)
    }
    return null
  }
}
