import { ImaAds } from './ima-ads'
import { AdLoader } from './types/adTypes'

type AdLoaders = 'ima'

export async function getAdLoader(
  videoElement: HTMLVideoElement,
  loaderType?: AdLoaders,
): Promise<AdLoader | null> {
  if (!loaderType) return null
  switch (loaderType) {
    case 'ima': {
      const adHandler = await ImaAds.init(videoElement)
      return adHandler
    }
    default:
      return null
  }
}
