import { DeviceTypes, PartialSettingsFile } from '@adiffengine/engine-types'

export const DeviceSettings: PartialSettingsFile = {
  appSettings: {
    platform: DeviceTypes.Samsung,
    stage: {
      readPixelsBeforeDraw: true,
      readPixelsAfterDraw: true,
    },
    keys: {
      '38': 'Up',
      '40': 'Down',
      '37': 'Left',
      '39': 'Right',
      '13': 'Enter',
      '10009': 'Back',
      '10252': 'PlayPause',
      '412': 'Rewind',
      '417': 'FastForward',
      '415': 'Play',
      '19': 'Pause',
      '413': 'Stop',
      '10232': 'TrackPrevious',
      '10233': 'TrackNext',
    },
  },
}
