import {
  ContentItem,
  ContentSelectedSignalMap,
} from '@adiffengine/engine-types'
import { Colors, Img, Lightning, Utils } from '@lightningjs/sdk'
import { Debugger, PaletteColor, getBestDimensionValue, hexToHsl } from '../lib'

import isEqual from 'fast-deep-equal/es6'
import isString from 'lodash/isString'

import { AdeButton } from './AdeButton'
import { AdeImage } from './AdeImage'
const debug = new Debugger('SongCard')
export interface SongCardTemplateSpec extends Lightning.Component.TemplateSpec {
  Shadow: object
  Background: {
    Image: object
    Flare: typeof FlareBackground
    Overlay: object
  }
  Cover: object
  TextClipper: {
    Text: {
      Title: object
      Description: object
      Details: object
    }
  }
  Image: typeof AdeImage
  PlayButton: typeof AdeButton
  ImageWrapper: {
    Image: object
  }
  Debug: object
  content: ContentItem
}

const CARD_HEIGHT = 300
const CARD_WIDTH = 550
const CARD_PADDING = 24
const CONTENT_WIDTH = CARD_WIDTH - CARD_PADDING * 2
const CONTENT_HEIGHT = CARD_HEIGHT - (CARD_PADDING * 2 + AdeButton.height)
const IMAGE_WIDTH = 140
const TEXT_LEFT = IMAGE_WIDTH + CARD_PADDING * 2
const TEXT_WIDTH = CARD_WIDTH - (TEXT_LEFT + CARD_PADDING)

export interface SongCardTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: ContentSelectedSignalMap
}
export class SongCard
  extends Lightning.Component<SongCardTemplateSpec, SongCardTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<SongCardTemplateSpec>
{
  Cover = this.getByRef('Cover')!
  Background = this.getByRef('Background')!
  Overlay = this.Background.getByRef('Overlay')!
  BackgroundImage = this.Background.getByRef('Image')!
  Flare = this.Background.getByRef('Flare')!
  TextClipper = this.getByRef('TextClipper')!
  Text = this.TextClipper.getByRef('Text')!
  Image = this.getByRef('Image')!
  PlayButton = this.getByRef('PlayButton')!
  Title = this.Text.getByRef('Title')!
  Description = this.Text.getByRef('Description')!
  Details = this.Text.getByRef('Details')!
  Debug = this.getByRef('Debug')!
  static width = CARD_WIDTH
  static height = CARD_HEIGHT
  private _focusAnimation: any
  static override _template(): Lightning.Component.Template<SongCardTemplateSpec> {
    return {
      h: 240,
      w: 550,
      Shadow: {
        x: CARD_WIDTH / 2,
        y: CARD_HEIGHT / 2,
        h: CARD_HEIGHT,
        w: CARD_WIDTH,
        mountX: 0.5,
        mountY: 0.5,
        rtt: true,
        rect: true,
        alpha: 0.4,
        color: Colors('background').get(),
        texture: Lightning.Tools.getShadowRect(
          CARD_WIDTH + 24,
          CARD_HEIGHT + 24,
          24,
          12,
          12,
        ),
      },
      Background: {
        x: 0,
        y: 0,
        h: CARD_HEIGHT,
        w: CARD_WIDTH,
        rect: true,
        rtt: true,
        color: Colors('background').get(),
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 24,
        },
        Image: {
          x: 0,
          y: 0,
          h: CARD_HEIGHT,
          w: CARD_WIDTH,
          rtt: true,
        },
        Flare: {
          visible: false,
          x: 0,
          y: 0,
          w: CARD_WIDTH,
          h: CARD_HEIGHT,
          rtt: true,
          type: FlareBackground,
          background: 'rgba(0,0,0,0)',
          alpha: 0.0001,
        },
        Overlay: {
          x: 0,
          y: 0,
          w: CARD_WIDTH,
          h: CARD_HEIGHT,
          visible: true,
          rect: true,
          rtt: true,
          color: Colors('background').get(),
          alpha: 0.3,
        },
      },

      Image: {
        type: AdeImage,
        x: CARD_PADDING,
        y: CARD_PADDING,
        h: IMAGE_WIDTH,
        w: IMAGE_WIDTH,
        generatePalette: true,
        rtt: true,
        signals: {
          palette: 'paletteLoaded',
        },
        borderRadius: 12,
      },
      TextClipper: {
        x: IMAGE_WIDTH + CARD_PADDING * 2,
        y: CARD_PADDING,
        h: CONTENT_HEIGHT,
        w: TEXT_WIDTH,
        clipping: true,
        rect: true,
        color: 0x00000000,
        Text: {
          x: 0,
          y: 0,
          h: CONTENT_HEIGHT,
          w: TEXT_WIDTH,
          flex: {
            direction: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          },
          flexItem: {
            grow: 1,
          },
          Title: {
            text: {
              fontSize: 22,
              lineHeight: 30,
              fontFace: 'Regular',
              wordWrap: true,
              wordWrapWidth: TEXT_WIDTH,
              maxLines: 2,
            },
          },
          Description: {
            text: {
              fontSize: 18,
              lineHeight: 18 * 1.5,
              fontFace: 'Text',
              wordWrap: true,
              wordWrapWidth: TEXT_WIDTH,
              maxLines: 4,
            },
          },
        },
      },
      PlayButton: {
        mountX: 1,
        mountY: 1,
        x: CARD_WIDTH - CARD_PADDING,
        y: CARD_HEIGHT - CARD_PADDING,
        type: AdeButton,
        buttonText: 'Play Episode',
        w: TEXT_WIDTH * 0.75,
        leftIcon: `play-sharp.svg`,
      },

      Debug: {
        x: 20,
        y: 0,
        visible: false,
        w: CARD_WIDTH - 40,
        flex: {
          direction: 'row',
          wrap: true,
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        },
      },
    }
  }

  set debug(visible: boolean) {
    this.Debug.patch({ visible })
  }
  private _texts: Lightning.Element[] = [this.Title, this.Description]
  paletteLoaded(p: PaletteColor[]) {
    this.palette = p
  }
  private _palette: PaletteColor[] = []
  set palette(palette: PaletteColor[]) {
    if (!isEqual(this._palette, palette)) {
      this._palette = palette
      debug.info(
        'Got palette, setting overlay color to %s',
        palette[palette.length - 1],
      )
      this.Overlay.patch({
        color: Colors(palette[palette.length - 1].color).get(),
        alpha: 0.3,
        visible: true,
      })

      if (this.Debug.visible) {
        const bySat = palette.sort((a, b) => {
          const av = hexToHsl(a.color).s + hexToHsl(a.color).l
          const bv = hexToHsl(b.color).s + hexToHsl(b.color).l
          return bv - av
        })
        this.Debug.childList.clear()
        bySat.forEach(({ color }) => {
          this.Debug.childList.add(
            this.stage.c({
              h: 24,
              w: 24,
              rect: true,
              rtt: true,
              color: Colors(color).get(),
              text: {
                fontFace: 'Text',
                fontSize: 16,
                text: `${Math.round(hexToHsl(color).l)}`,
              },
            }),
          )
        })
      }

      const textColorHsl = hexToHsl(palette[0].color)

      const textColor = textColorHsl.l < 85 ? 'text' : palette[0].color

      this._texts.forEach(t =>
        t.patch({
          color: Colors(textColor).get(),
        }),
      )
    }
  }
  set content(content: ContentItem) {
    this._texts.forEach(t => t.patch({ color: Colors('text').get() }))
    const wallpaper = content.images.wallpaper ?? content.images.thumb
    const img = wallpaper!.getForWidth(CARD_WIDTH)
    const proxyBackgroundImage = isString(img)
      ? Utils.proxyUrl(img, { process: 'blur', blurAmount: 14 })
      : undefined

    const thumb = content.images.thumb ?? content.images.wallpaper

    const imageFail = () => {
      this.Text.patch({
        x: CARD_PADDING,
      })

      const wordWrapWidth = CONTENT_WIDTH
      this.Title.patch({
        text: {
          wordWrapWidth,
        },
      })
      this.Description.patch({
        text: {
          wordWrapWidth,
        },
      })
      this.Details?.patch({
        text: {
          wordWrapWidth,
        },
      })
    }
    if (thumb) {
      this.Image.patch({
        imageSrc: Utils.proxyUrl(thumb.getForWidth(IMAGE_WIDTH)!),
      })
      this.Image.on('txError', imageFail)
    }
    if (proxyBackgroundImage) {
      this.BackgroundImage.patch({
        texture: Img(proxyBackgroundImage).cover(CARD_WIDTH, CARD_HEIGHT),
      })
    }

    this.TextClipper.patch({
      x: thumb ? TEXT_LEFT : CONTENT_WIDTH,
      w: thumb ? TEXT_WIDTH : CONTENT_WIDTH,
    })
    const wordWrapWidth = proxyBackgroundImage
      ? CONTENT_WIDTH - TEXT_LEFT
      : CONTENT_WIDTH
    this.Title.patch({
      text: {
        text: content.title,
        wordWrapWidth,
      },
    })
    this.Description.patch({
      visible: isString(content.description),
      text: { text: content.description ?? '', wordWrapWidth },
    })
    this.Details?.patch({
      visible: isString(content.details),
      text: { text: content.details ?? '', wordWrapWidth },
    })
  }
  get focusAnimation() {
    if (!this._focusAnimation) {
      const theme = this.fireAncestors('$theme')
      this._focusAnimation = this.animation({
        duration: 0.2,
        actions: [
          {
            t: 'Background',
            p: 'scale',
            v: { 0: 1, 1: 1.04 },
          },
          {
            t: 'Shadow',
            p: 'scale',
            v: { 0: 1, 1: 1.04 },
          },
          {
            t: 'Shadow',
            p: 'color',
            v: {
              0: Colors(theme.palette.background).get(),
              1: Colors(theme.palette.highlights[800]).get(),
            },
          },
        ],
      })
    }
    return this._focusAnimation
  }
  override _focus() {
    this.focusAnimation.start()
  }
  override _unfocus() {
    this.focusAnimation.stop()
  }
  override _handleEnter() {
    debug.info('Got it')
    if (this.content) this.signal('contentSelected', this.content)
  }
  override _getFocused() {
    return this.PlayButton
  }
}
export interface FlareBackgroundTemplateSpec
  extends Lightning.Component.TemplateSpec {
  background: string
}
export class FlareBackground extends Lightning.Component {
  static override _template() {
    return {
      rect: true,
      color: 0x00000000,
      rtt: true,
    }
  }
  set background(color: string) {
    this.patch({
      color: Colors(color).get(),
    })
  }
  private _colors: PaletteColor[] = []
  set colors(colors: PaletteColor[]) {
    this.childList.clear()
    const w = getBestDimensionValue(this, 'w')
    const h = getBestDimensionValue(this, 'h')
    colors.forEach((c, idx) => {
      this.childList.add(
        this.stage.c({
          x: idx === 0 ? w : 0,
          y: idx === 0 ? h : 0,
          h: w,
          w: w,
          mountX: idx === 0 ? 1 : 0,
          mountY: idx === 0 ? 1 : 0,
          rect: true,
          color: 0x00000000,
          scale: 2,
          alpha: 0.7,
          shader: {
            type: Lightning.shaders.RadialGradient,
            innerColor: Colors(c.color).get(),
            outerColor: 0x00000000,
            radius: 110,
            pivot: 0.75,
          },
        }),
      )
    })
    this._colors = colors
  }
  get colors() {
    return this._colors
  }
}
