import { MainMenu } from '../MainMenu'

export interface HeroContentSetup {
  visible: boolean
  height: number
  contentWidth: number
  contentHeight: number
  width: number
  left: number
  top: number
  smooth: boolean
  hasButtons: boolean
  maxDescriptionLines: 4
}
export const defaultDetailCoordinates: HeroContentSetup = {
  visible: false,
  width: 1920,
  contentWidth: 1200,
  contentHeight: 400,
  height: 800,
  left: MainMenu.widthClosed + 160,
  top: 32,
  smooth: false,
  hasButtons: false,
  maxDescriptionLines: 4,
}
