/// <reference path="../globals.d.ts" />
import {
  ContentItem,
  ContentSelectedDirectionalSignalMap,
  CoordinateDimensions,
  ID,
  setsByX,
} from '@adiffengine/engine-types'
import { Lightning } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import equal from 'fast-deep-equal/es6'
import { Debugger } from '../lib'
import { HeaderList } from './HeaderList'
const debug = new Debugger('SimplifiedGrid')

export interface HeaderRowData {
  title: string
  items: ContentItem[]
  id?: ID
}
export interface SimplifiedGridTemplateSpec
  extends Lightning.Component.TemplateSpec {
  cardType: Lightning.Component.Constructor
  pathAction: 'player' | 'details'
  rows: HeaderRowData[]
  List: typeof List
}

export interface SimplifiedGridTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: ContentSelectedDirectionalSignalMap
}

export class SimplifiedGrid
  extends Lightning.Component<
    SimplifiedGridTemplateSpec,
    SimplifiedGridTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<SimplifiedGridTemplateSpec>
{
  private _cardType: Lightning.Component.Constructor | undefined = undefined
  public pathAction: 'details' | 'player' = 'details'
  List = this.getByRef('List')
  static override _template(): Lightning.Component.Template<SimplifiedGridTemplateSpec> {
    return {
      x: 0,
      y: 0,
      h: 1080 - 160,
      w: 1920 - 160,
      List: {
        type: List,
        direction: 'column',
        spacing: 40,
        x: 0,
        y: 0,
        h: 1080 - 160,
        w: 1920 - 160,
      },
    }
  }

  next(coords: CoordinateDimensions) {
    if (this.List.items[this.List.index + 1]) {
      const next = this.List.items[this.List.index + 1]
      if (setsByX(next)) next.setClosestByX(coords)
      this.List.next()
    }
  }
  previous(coords: CoordinateDimensions) {
    if (this.List.index > 0) {
      const next = this.List.items[this.List.index - 1]
      if (setsByX(next)) next.setClosestByX(coords)
      this.List.previous()
    }
  }
  focusedContent(item: ContentItem) {
    debug.info('Focused on content ', item)
    this.fireAncestors('$currentFocusContent', item)
  }
  selectedContent(item: ContentItem) {
    const destination = item.paths[this.pathAction]
    debug.info('Content Selected %s %s', destination, item.title)
    this.fireAncestors('$navigate', item.paths[this.pathAction])
  }
  override _construct() {
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.focusedContent = this.focusedContent.bind(this)
    this.selectedContent = this.selectedContent.bind(this)
  }

  set cardType(cardType: Lightning.Component.Constructor) {
    if (this._cardType !== cardType) {
      this._cardType = cardType
      this.render()
    }
  }
  get cardType() {
    return this._cardType!
  }
  private _rows: HeaderRowData[] = []
  set rows(rows: HeaderRowData[]) {
    if (!equal(rows, this._rows)) {
      this._rows = rows
      this.render()
    }
  }

  get rows() {
    return this._rows
  }

  get items() {
    return this.cardType !== undefined
      ? this.rows.map(r => {
          const { id, ...rest } = r
          return {
            ...rest,
            spacing: 40,
            type: HeaderList,
            itemType: this.cardType,
            h: HeaderList.getHeightFromCardHeight(
              (this.cardType as any).height,
            ),
            signals: {
              contentFocused: this.focusedContent,
              contentSelected: this.selectedContent,
              up: this.previous,
              down: this.next,
              left: () => this.signal('left'),
              right: () => this.signal('right'),
            },
          }
        })
      : []
  }
  render() {
    debug.info('Rendering', this.items)
    if (this.items.length > 0) {
      this.List.patch({
        h: this.h,
        w: this.w,
        items: this.items,
      })
    } else {
      this.List.clear()
    }
  }
  override _getFocused() {
    return this.List
  }
}
