import { DirectionalSignalMap } from '@adiffengine/engine-types'
import { Colors, Lightning } from '@lightningjs/sdk'
import equal from 'fast-deep-equal/es6'
import { Debugger, cp, isGoodString } from '../lib'
import { defer, sleep } from '../lib/utils'
import { getStoredTheme } from '../themes'
import { Scroller } from './Scroller'
import { SignaledList } from './SignaledList'
const debug = new Debugger('ScrollableText')
export interface ScrollableTextSetup {
  text: string
  headerText?: string
  subheaderText?: string
}
export interface ScrollableTextTemplateSpec
  extends Lightning.Component.TemplateSpec {
  CastList: typeof SignaledList
  Background: object
  Wrapper: {
    Content: {
      Header: object
      Subheader: object
      Text: object
    }
  }
  Scroller: typeof Scroller
  textSetup: ScrollableTextSetup
}
export interface ScrollableTextScrollableSignal extends DirectionalSignalMap {
  scrollable(canScroll: boolean): void
}
export interface ScrollableTextTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: ScrollableTextScrollableSignal
}

export class ScrollableText
  extends Lightning.Component<
    ScrollableTextTemplateSpec,
    ScrollableTextTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<ScrollableTextTemplateSpec>
{
  Wrapper = this.getByRef('Wrapper')!
  Content = this.Wrapper.getByRef('Content')!
  Scroller = this.getByRef('Scroller')!
  Header = this.Content.getByRef('Header')!
  Subheader = this.Content.getByRef('Subheader')!
  Text = this.Content.getByRef('Text')!

  static override _template(): Lightning.Component.Template<ScrollableTextTemplateSpec> {
    const theme = getStoredTheme()

    return {
      color: 0x00000000,

      Background: {
        alpha: 0,
        rect: true,
        rtt: true,
        x: 0,
        y: 0,
        w: cp,
        h: cp,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: theme.components.MainMenuConfig.radius,
        },
        color: Colors(theme.components.MainMenuConfig.backgroundColor).get(),
      },
      Scroller: {
        x: 25,
        y: 40,
        h: (h: number) => h - 80,
        w: 10,
        alpha: 0.0001,
        type: Scroller,
        mountX: 1,
        passSignals: true,
        signals: {
          percent: 'scrollPercent',
          left: 'signalLeft',
          right: 'signalRight',
        },
      },
      Wrapper: {
        x: 80,
        y: 20,
        w: (w: number) => w - 108,
        h: (h: number) => h + 40,
        rtt: true,
        rect: true,
        color: 0x00000000,
        shader: {
          type: Lightning.shaders.FadeOut,
          top: 20,
          bottom: 20,
        },
        Content: {
          x: 0,
          y: 0,
          w: (w: number) => w - 108,
          visible: true,
          flex: {
            direction: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          },
          Header: {
            visible: false,
            flexItem: {
              marginBottom: 20,
            },
            text: {
              text: '',
              fontSize: 44,
              lineHeight: 44,
              fontFace: 'Bold',
              wordWrapWidth: 600,
              wordWrap: true,
            },
          },
          Subheader: {
            visible: false,
            flexItem: {
              marginBottom: 20,
            },
            text: {
              text: '',
              fontSize: 24,
              lineHeight: 36,
              fontFace: 'Regular',
              wordWrapWidth: 600,
              wordWrap: true,
            },
          },
          Text: {
            visible: true,
            flexItem: {
              marginBottom: 20,
            },
            text: {
              text: '',
              fontSize: 20,
              lineHeight: 36,
              fontFace: 'Text',
              wordWrapWidth: 600,
              wordWrap: true,
            },
          },
        },
      },
    }
  }
  private _textSetup: ScrollableTextSetup = { text: '' }
  set textSetup(text: ScrollableTextSetup) {
    if (!equal(text, this._textSetup)) {
      this.Content.patch({
        y: 0,
      })
      this.Scroller.patch({
        alpha: 0.0001,
      })
      const wordWrapWidth = this.w - 140
      this.Text.patch({
        visible: true,
        text: { text: text.text, wordWrapWidth },
      })
      this.Header.patch({
        visible: isGoodString(text.headerText),
        text: {
          text: isGoodString(text.headerText) ? text.headerText : '',
          wordWrapWidth,
        },
      })
      this.Subheader.patch({
        visible: isGoodString(text.subheaderText),
        text: {
          text: isGoodString(text.subheaderText) ? text.subheaderText : '',
          wordWrapWidth,
        },
      })
      defer(() => {
        debug.info('Final H: %s', this.Text.finalH)
        this.setUpScroller()
      })
    }
  }
  async setUpScroller() {
    await sleep(100)
    const textHeight = this.Text.finalY + this.Text.finalH
    debug.info(
      'Setting up scroller  finalH: %s, finalY: %s',
      this.Text.finalH,
      this.Text.finalY,
    )
    if (textHeight > this.h - 180) {
      this.Scroller.patch({
        h: this.h,
        heightPercent: (this.h - 180) / textHeight,
        percent: 0,
        smooth: {
          alpha: [1, { duration: 0.2 }],
        },
      })
      this.signal('scrollable', true)
    } else {
      this.Scroller.patch({
        h: this.h,
        heightPercent: (this.h - 180) / textHeight,
        percent: 0,
        smooth: {
          alpha: [0.0001, { duration: 0.2 }],
        },
      })
      this.signal('scrollable', false)
    }
  }

  scrollPercent(x: number) {
    const textHeight = this.Text.finalY + this.Text.finalH
    const containerHeight = this.h - 180
    if (textHeight > containerHeight) {
      const diff = textHeight - containerHeight
      const amount = x * diff * -1
      this.Content.patch({
        smooth: {
          y: [amount + 40, { duration: 0.2 }],
        },
      })
    }
  }
  override _firstEnable() {
    ;['x', 'y', 'w', 'h'].forEach(value => {
      if (!(this as any)[value]) {
        console.warn(
          'Scrollable Text needs x, y, w, and h set, you are missing %s',
          value,
        )
      }
    })
  }
  override _getFocused() {
    return this.Scroller
  }

  signalRight() {
    this.signal('right')
  }
  signalLeft() {
    this.signal('left')
  }
}
