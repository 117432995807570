import {
  CoordinateDimensions,
  DirectionalSignalMap,
  ListSpaceHelpers,
  SetsByY,
} from '@adiffengine/engine-types'
import { Lightning } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import { getClosestIndexByY, getCoordinateDimensions } from '../lib'
import { SimpleCardPatch } from './SimpleCard'
// const debug = new Debugger('SignaledList')

export class SignaledList
  extends Lightning.Component<SignaledListTemplateSpec, SignaledListTypeConfig>
  implements
    Lightning.Component.ImplementTemplateSpec<SignaledListTemplateSpec>,
    SetsByY,
    ListSpaceHelpers
{
  List = this.getByRef('List')
  static override _template(): Lightning.Component.Template<SignaledListTemplateSpec> {
    return {
      x: 0,
      y: 0,
      List: {
        type: List,
      },
    }
  }
  private _listConfig: Lightning.Element.PatchTemplate = {}
  set listConfig(config: typeof this._listConfig) {
    this._listConfig = config
    this.updatePatch()
  }
  get listConfig() {
    return this._listConfig
  }
  override _init() {
    this.updatePatch()
  }
  updatePatch() {
    this.List.patch({
      w: this.w,
      h: this.h,
      ...this.listConfig,
    })
  }

  setIndex(x: number) {
    this.List.setIndex(x)
  }
  set index(x) {
    this.setIndex(x)
  }

  get index() {
    return this.List.index
  }

  set items(items: SimpleCardPatch[]) {
    if (Array.isArray(items) && items.length) {
      this.List.patch({ items, visible: true })
      this.List.reposition()
    } else {
      this.List.patch({ visible: false })
    }
  }
  get items() {
    return this.List.items
  }

  set height(h: number) {
    this.List.patch({ h })
    this.patch({ h })
  }
  set width(w: number) {
    this.List.patch({ w })
    this.patch({ w })
  }

  override _getFocused() {
    return this.List
  }
  setClosestByY(coords?: CoordinateDimensions) {
    if (coords) {
      const closest = getClosestIndexByY(coords, this.List.items)
      this.List.setIndex(closest)
    }
  }
  getCurrentIndexCoordinates() {
    return getCoordinateDimensions(this.List.items[this.List.index])
  }

  override _handleUp(): false | void {
    if (
      this.List.direction === 'row' ||
      (this.List.index === 0 && this.List.direction === 'column')
    ) {
      this.signal('up')
    } else {
      return false
    }
  }
  override _handleDown(): false | void {
    if (
      this.List.direction === 'row' ||
      (this.List.index === this.List.items.length - 1 &&
        this.List.direction === 'column')
    ) {
      this.signal('down')
    } else {
      return false
    }
  }
  override _handleLeft(): false | void {
    if (
      this.List.direction === 'column' ||
      (this.List.index === 0 && this.List.direction === 'row')
    ) {
      this.signal('left')
    } else {
      return false
    }
  }
  override _handleRight(): false | void {
    if (
      this.List.direction === 'column' ||
      (this.List.index === this.List.items.length - 1 &&
        this.List.direction === 'row')
    ) {
      this.signal('right')
    } else {
      return false
    }
  }
  override _handleEnter(): void {
    this.signal('selected', this.List.index)
  }
}
export interface SignaledListTemplateSpec
  extends Lightning.Component.TemplateSpec {
  List: typeof List
  listConfig: Lightning.Element.PatchTemplate
  items: SimpleCardPatch[]
  height: number
  width: number
}

export interface SignaledListSignals extends DirectionalSignalMap {
  selected(selected: number): void
}
export interface SignaledListTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: SignaledListSignals
}
