import { Colors, Lightning, Settings, Utils } from '@lightningjs/sdk'
import { Icon } from '@lightningjs/ui-components'

import { Debugger, pathJoin } from '../../lib'
import { HoverZone } from '../helpers/HoverZone'
const debug = new Debugger('MoreTab')
export class MoreTab
  extends Lightning.Component<MoreTabTemplateSpec, MoreTabTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<MoreTabTemplateSpec>
{
  Text = this.getByRef('Text')!
  static override _template(): Lightning.Component.Template<MoreTabTemplateSpec> {
    const iconPath = Settings.get('app', 'icon_directory', 'images/icons')
    return {
      x: 0,
      y: 0,
      w: 1920,
      h: 74,
      Text: {
        x: 0,
        y: 0,
        w: 1920,
        h: 24,
        color: Colors('text').get(),
        text: {
          text: 'More'.toUpperCase(),
          fontFace: 'Bold',
          fontSize: 24,
          textAlign: 'center',
          lineHeight: 30,
        },
      },
      Icon: {
        w: 40,
        h: 40,
        y: 34,
        pivotX: 0.5,
        pivotY: 0.5,
        mountX: 0.5,
        x: 1920 / 2,
        type: Icon,
        icon: Utils.asset(pathJoin([iconPath, 'chevron-down.svg'])),
        color: Colors('text').get(),
      },
      FocusIcon: {
        w: 40,
        h: 40,
        y: 34,
        mountX: 0.5,
        pivotX: 0.5,
        pivotY: 0.5,
        x: 1920 / 2,
        alpha: 0,
        type: Icon,
        icon: Utils.asset(pathJoin([iconPath, 'chevron-down.svg'])),
        color: Colors('primaryHighlight').get(),
      },
      HoverZone: {
        type: HoverZone,
        w: 400,
        h: 74,
        x: (1920 - 400) / 2,
        y: 0,

        signals: {
          clicked: '_clicked',
          hovered: '_hovered',
        },
      },
    }
  }
  private _moreText: string = 'More'

  set moreText(text: string) {
    text = text.toUpperCase()
    if (text !== this._moreText) {
      this._moreText = text
      this.Text.patch({ text: text })
    }
  }

  private _arrowUp: boolean = false
  private _arrowAnimation: Lightning.types.Animation | undefined = undefined
  get arrowAnimation() {
    if (!this._arrowAnimation) {
      this._arrowAnimation = this.animation({
        duration: 0.2,
        actions: [
          {
            t: 'Icon',
            p: 'rotation',
            v: { 0: 0, 1: Math.PI },
          },
          {
            t: 'FocusIcon',
            p: 'rotation',
            v: { 0: 0, 1: Math.PI },
          },
        ],
      })
    }
    return this._arrowAnimation
  }

  private _focusAnimation: Lightning.types.Animation | undefined = undefined
  get focusAnimation() {
    if (!this._focusAnimation) {
      const { palette } = this.fireAncestors('$theme')
      this._focusAnimation = this.animation({
        duration: 0.2,
        actions: [
          { t: 'Icon', p: 'alpha', v: { 0: 1, 1: 0 } },
          { t: 'FocusIcon', p: 'alpha', v: { 0: 0, 1: 1 } },
          {
            t: 'Text',
            p: 'color',
            v: {
              0: Colors(palette.text).get(),
              1: Colors(palette.primaryHighlight).get(),
            },
          },
        ],
      })
    }
    return this._focusAnimation
  }

  set arrowUp(arrowUp: boolean) {
    if (arrowUp !== this._arrowUp) {
      this._arrowUp = arrowUp
      if (this.arrowUp) this.arrowAnimation.start()
      else this.arrowAnimation.stop()
    }
  }

  _hovered() {
    debug.info('Hovered More Tab')
    this.signal('hovered')
  }

  _clicked() {
    this.signal('selected')
  }
  get arrowUp() {
    return this._arrowUp
  }

  override _handleUp() {
    debug.info('Up from More Tab')
    this.signal('up')
  }
  override _handleDown() {
    this.signal('down')
  }

  override _handleEnter() {
    this.signal('selected')
  }
  override _focus() {
    this.focusAnimation.start()
  }
  override _unfocus() {
    this.focusAnimation.stop()
  }
}

export interface MoreTabTemplateSpec extends Lightning.Component.TemplateSpec {
  Text: object
  Icon: typeof Icon
  FocusIcon: typeof Icon
  moreText: string
  arrowUp: boolean
  HoverZone: typeof HoverZone
}
export interface MoreTabTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: {
    up(): void
    down(): void
    selected(): void
    hovered(): void
  }
}
