import { DirectionalSignalMap } from '@adiffengine/engine-types'
import { Colors, Lightning } from '@lightningjs/sdk'
import { Debugger, cp } from '../lib'
import { getStoredTheme } from '../themes'
const debug = new Debugger('Scroller')

export interface ScrollerTemplateSpec extends Lightning.Component.TemplateSpec {
  Bar: object
  Position: object
  heightPercent: number
  percent: number
}

export interface ScrollerSignals extends DirectionalSignalMap {
  percent(percent: number): void
}
export interface ScrollerTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: ScrollerSignals
}
export class Scroller
  extends Lightning.Component<ScrollerTemplateSpec, ScrollerTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<ScrollerTemplateSpec>
{
  private _focusAnimation: Lightning.types.Animation | undefined
  Position = this.getByRef('Position')!
  static width = 12
  static override _template(): Lightning.Component.Template<ScrollerTemplateSpec> {
    const theme = getStoredTheme()
    return {
      x: 0,
      y: 0,
      h: 1080,
      w: 10,
      Bar: {
        rect: true,
        x: 5,
        y: 0,
        w: 2,
        h: cp,
        color: Colors(theme.palette.darks[500]).get(),
      },
      Position: {
        x: 0,
        y: 0,
        w: 12,
        h: 80,
        rect: true,
        rtt: true,
        color: Colors(theme.palette.darks[100]).get(),
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 5,
        },
      },
    }
  }

  private _percent: number = 0
  set percent(x: number) {
    x = x < 0 ? 0 : x
    x = x > 1 ? 1 : x
    if (x >= 0 && x <= 1 && this._percent !== x) {
      this._percent = x
      this.signal('percent', this._percent)
      const destination = (this.h - this._positionHeight) * this._percent
      debug.info('Position setting to destination: %s', destination)
      this.Position.setSmooth('y', destination)
    }
  }

  get percent() {
    return this._percent
  }
  private _positionHeight: number = 80
  private _scrollAmount: number = 0
  set heightPercent(x: number) {
    if (x < 0 || x > 1) {
      console.warn(
        'Invalid height percentage "%s", must be a number between 0 and 1',
        x,
      )
    }
    debug.info('Height Percent %s %s %s', x, this.h, this.h * x)
    x = x < 0.1 ? 0.1 : x
    x = x > 0.9 ? 0.9 : x
    if (x >= 0.1 && x <= 0.9) {
      this._positionHeight = this.h * x
      this._scrollAmount = this.h - this._positionHeight
      if (this._scrollAmount < 0) this._scrollAmount = 0
      debug.info(
        'Setting Position height to this.h (%s) * x (%s) = %s',
        this.h,
        x,
        this.h * x,
      )
      this.Position.patch({
        h: this.h * x,
      })
    }
  }

  get focusAnimation() {
    const theme = this.fireAncestors('$theme')
    if (!this._focusAnimation) {
      this._focusAnimation = this.animation({
        duration: 0.2,
        actions: [
          {
            t: 'Position',
            p: 'color',
            v: {
              0: Colors(theme.palette.darks[100]).get(),
              1: Colors(theme.palette.highlights[500]).get(),
            },
          },
        ],
      })
    }
    return this._focusAnimation
  }

  override _handleLeft() {
    this.signal('left')
  }
  override _handleRight() {
    this.signal('right')
  }

  override _handleUp() {
    if (this._percent === 0) this.signal('up')
    else {
      const percent = this._scrollAmount < 40 ? 0 : 40 / this._scrollAmount
      this.percent -= percent
    }
  }

  override _handleDown() {
    if (this.percent === 1) this.signal('down')
    else {
      const percent = this._scrollAmount < 40 ? 1 : 40 / this._scrollAmount
      this.percent += percent
    }
  }

  override _focus() {
    debug.info('Focus on scroller', this)
    this.focusAnimation.start()
  }
  override _unfocus() {
    debug.info('UnFocus on scroller', this)
    this.focusAnimation.stop()
  }
  override _inactive() {
    this.focusAnimation.stop()
  }
}
