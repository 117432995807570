import { DeployEnvironments, SettingsFile } from '@adiffengine/engine-types'

export const baseSettings: SettingsFile = {
  appSettings: {
    debug: import.meta.env.MODE !== 'production',
    api_url: 'https://api.mitu.tv/mrss/ade-x1.mrss',
    version: '1.0.2',
    env: import.meta.env.MODE as DeployEnvironments,
    keys: {
      '8': 'Back',
      '13': 'Enter',
      '27': 'Exit',
      '37': 'Left',
      '38': 'Up',
      '39': 'Right',
      '40': 'Down',
      '68': 'Debug',
      '76': 'Last',
      '77': 'Menu',
      '78': 'Next',
      '80': 'Prev',
      '83': 'Star',
      '84': 'TextBanner',
      '86': 'VoiceGuidance',
      '179': 'PlayPause',
      '219': 'Rewind',
      '221': 'FastForward',
      '227': 'Rewind',
      '228': 'FastForward',
      '403': 'ColorA',
      '404': 'ColorB',
      '405': 'ColorC',
    },
    APP_MUX_ENV_KEY: 'j54ai5idnhrn8ikiseq7b4h8c',
    APP_MUX_DEBUG: true,
    APP_SHORT_NAME: 'mitu',
    APP_ERROR_CODE: 'MITU',
    ADS_ENABLED: false,
    UP_NEXT_TIME_IN_SECONDS: 20,
    UP_NEXT_OFFSET_FROM_END: 3,
    UP_NEXT_DISABLED: false,
    DEBUG_UP_NEXT: false,
    SENTRY_DSN:
      'https://c080a74a21bd6200e49f9b5c3526d8fa@o210186.ingest.sentry.io/4505646442348544',
    FIREBASE_APP_ID: '1:311719570977:web:c449fb696c86f2ea50bd60',
    FIREBASE_MEASUREMENT_ID: 'G-C3PXCZDEF6',
    PLAUSIBLE_DOMAIN: 'mitu.adeengine.com',
    FORCE_GA_TAG: true,
    enablePointer: false,
    APP_TIMEOUT_DURATION_IN_MINUTES: 0.5,
    stage: {
      clearColor: '0x00000000',
    },
  },
  platformSettings: {
    path: './',
    log: false,
    clearColor: '0x00000000',
    showVersion: import.meta.env.MODE !== 'production',
    proxyUrl:
      'https://us-central1-valhalla-f2ec8.cloudfunctions.net/feedFM-imageProxy',
    router: {
      backtracking: true,
    },
    inspector: true,
  },
}
