import { ColorHue, ThemeConfig } from '@adiffengine/engine-types'
import { createPalette, createThemeConfig } from '../lib'

const darks: ColorHue = {
  50: '#e6e5e5',
  100: '#c1bfbd',
  200: '#979491',
  300: '#6d6965',
  400: '#4e4944',
  500: '#2f2923',
  600: '#2a241f',
  700: '#231f1a',
  800: '#1d1915',
  900: '#120f0c',
}

const highlights: ColorHue = {
  50: '#f9f4e8',
  100: '#f1e3c5',
  200: '#e8d09e',
  300: '#dfbd77',
  400: '#d8af5a',
  500: '#d1a13d',
  600: '#cc9937',
  700: '#c68f2f',
  800: '#c08527',
  900: '#b5741a',
}

export const baseTheme: ThemeConfig = createThemeConfig({
  name: 'ADE Base Theme',
  palette: createPalette({ darks, highlights }),
  logo: 'images/logos/logo@0.75x.png',
  logo_size: 400,
  fonts: {
    name: 'Outfit',
    Text: 'fonts/Outfit/Outfit-Regular.ttf',
    Regular: 'fonts/Outfit/Outfit-Medium.ttf',
    Bold: 'fonts/Outfit/Outfit-Bold.ttf',
    ExtraBold: 'fonts/Outfit/Outfit-ExtraBold.ttf',
  },
})
