/*
 * If not stated otherwise in this file or this component's LICENSE file the
 * following copyright and licenses apply:
 *
 * Copyright 2020 Metrological
 *
 * Licensed under the Apache License, Version 2.0 (the License);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { Log, Metrics, Settings, Ads } from '@lightningjs/sdk'
import VideoTexture from './VideoTexture'
import { AppInstance } from '@lightningjs/sdk/src/Application'
import { events } from './events'

export let mediaUrl = url => url
let videoEl
let videoTexture
let metrics
let consumer
let precision = 1
let textureMode = false

export const initVideoPlayer = config => {
  if (config.mediaUrl) {
    mediaUrl = config.mediaUrl
  }
}
// todo: add this in a 'Registry' plugin
// to be able to always clean this up on app close
let eventHandlers = {}

const state = {
  adsEnabled: false,
  playing: false,
  _playingAds: false,
  get playingAds() {
    return this._playingAds
  },
  set playingAds(val) {
    if (this._playingAds !== val) {
      this._playingAds = val
      fireOnConsumer(val === true ? 'AdStart' : 'AdEnd')
    }
  },
  skipTime: false,
  playAfterSeek: null,
}

const hooks = {
  play() {
    state.playing = true
  },
  pause() {
    state.playing = false
  },
  seeked() {
    state.playAfterSeek === true && videoPlayerPlugin.play()
    state.playAfterSeek = null
  },
  abort() {
    deregisterEventListeners()
  },
}

const withPrecision = val => Math.round(precision * val) + 'px'

const fireOnConsumer = (event, args) => {
  if (consumer) {
    consumer.fire('$videoPlayer' + event, args, videoEl.currentTime)
    consumer.fire('$videoPlayerEvent', event, args, videoEl.currentTime)
  }
}

const fireHook = (event, args) => {
  hooks[event] &&
    typeof hooks[event] === 'function' &&
    hooks[event].call(null, event, args)
}

let customLoader = null
let customUnloader = null

const loader = (url, videoEl, config) => {
  return customLoader && typeof customLoader === 'function'
    ? customLoader(url, videoEl, config)
    : new Promise(resolve => {
        url = mediaUrl(url)
        videoEl.setAttribute('src', url)
        videoEl.load()
        resolve()
      })
}

const unloader = videoEl => {
  return customUnloader && typeof customUnloader === 'function'
    ? customUnloader(videoEl)
    : new Promise(resolve => {
        videoEl.removeAttribute('src')
        videoEl.load()
        resolve()
      })
}

export const setupVideoTag = () => {
  const videoEls = document.getElementsByTagName('video')
  if (videoEls && videoEls.length) {
    return videoEls[0]
  } else {
    const videoEl = document.createElement('video')
    const platformSettingsWidth = Settings.get('platform', 'width')
      ? Settings.get('platform', 'width')
      : 1920
    const platformSettingsHeight = Settings.get('platform', 'height')
      ? Settings.get('platform', 'height')
      : 1080
    videoEl.setAttribute('id', 'video-player')
    videoEl.setAttribute('width', withPrecision(platformSettingsWidth))
    videoEl.setAttribute('height', withPrecision(platformSettingsHeight))
    videoEl.style.position = 'absolute'
    videoEl.style.zIndex = '1'
    videoEl.style.display = 'none'
    videoEl.style.visibility = 'hidden'
    videoEl.style.top = withPrecision(0)
    videoEl.style.left = withPrecision(0)
    videoEl.style.width = withPrecision(platformSettingsWidth)
    videoEl.style.height = withPrecision(platformSettingsHeight)
    document.body.appendChild(videoEl)
    return videoEl
  }
}

export const setUpVideoTexture = () => {
  if (!AppInstance.tag('VideoTexture')) {
    const el = AppInstance.stage.c({
      type: VideoTexture(),
      ref: 'VideoTexture',
      zIndex: 0,
      videoEl,
    })
    AppInstance.childList.addAt(el, 0)
  }
  return AppInstance.tag('VideoTexture')
}

let eventsPaused = false

const registerEventListeners = () => {
  Log.info('VideoPlayer', 'Registering event listeners')
  Object.keys(events).forEach(event => {
    const handler = e => {
      if (!eventsPaused) {
        // Fire a metric for each event (if it exists on the metrics object)
        if (metrics && metrics[event] && typeof metrics[event] === 'function') {
          metrics[event]({ currentTime: videoEl.currentTime })
        }
        // fire an internal hook
        fireHook(event, { videoElement: videoEl, event: e })

        // fire the event (with human friendly event name) to the consumer of the VideoPlayer
        fireOnConsumer(events[event], { videoElement: videoEl, event: e })
      }
    }

    eventHandlers[event] = handler
    videoEl.addEventListener(event, handler)
  })
}

const deregisterEventListeners = () => {
  Log.info('VideoPlayer', 'Deregistering event listeners')
  Object.keys(eventHandlers).forEach(event => {
    videoEl.removeEventListener(event, eventHandlers[event])
  })
  eventHandlers = {}
}

const videoPlayerPlugin = {
  consumer(component) {
    consumer = component
  },

  loader(loaderFn) {
    customLoader = loaderFn
  },

  unloader(unloaderFn) {
    customUnloader = unloaderFn
  },

  position(top = 0, left = 0) {
    videoEl.style.left = withPrecision(left)
    videoEl.style.top = withPrecision(top)
    if (textureMode === true) {
      videoTexture.position(top, left)
    }
  },

  size(width = 1920, height = 1080) {
    videoEl.style.width = withPrecision(width)
    videoEl.style.height = withPrecision(height)
    videoEl.width = parseFloat(videoEl.style.width)
    videoEl.height = parseFloat(videoEl.style.height)
    if (textureMode === true) {
      videoTexture.size(width, height)
    }
  },

  area(top = 0, right = 1920, bottom = 1080, left = 0) {
    this.position(top, left)
    this.size(right - left, bottom - top)
  },

  open(url, config = {}) {
    eventsPaused = false
    if (!this.canInteract) return
    metrics = Metrics.media(url)

    this.hide()
    deregisterEventListeners()

    if (this.src == url) {
      this.clear().then(this.open(url, config))
    } else {
      const adConfig = { enabled: state.adsEnabled, duration: 300 }
      if (config.videoId) {
        adConfig.caid = config.videoId
      }
      Ads.get(adConfig, consumer).then(ads => {
        state.playingAds = true
        ads.prerolls().then(() => {
          state.playingAds = false
          loader(url, videoEl, config)
            .then(() => {
              registerEventListeners()
              this.show()
              return this.play()
            })
            .catch(e => {
              fireOnConsumer('error', { videoElement: videoEl, event: e })
            })
        })
      })
    }
  },

  reload() {
    if (!this.canInteract) return
    const url = videoEl.getAttribute('src')
    this.close()
    this.open(url)
  },

  close() {
    Ads.cancel()
    if (state.playingAds) {
      state.playingAds = false
      Ads.stop()
      // call self in next tick
      setTimeout(() => {
        this.close()
      })
    }
    if (!this.canInteract) return
    this.clear()
    this.hide()
    deregisterEventListeners()
  },

  clear() {
    if (!this.canInteract) return
    // pause the video first to disable sound
    this.pause()
    if (textureMode === true) videoTexture.stop()
    return unloader(videoEl).then(() => {
      fireOnConsumer('Clear', { videoElement: videoEl })
    })
  },

  play() {
    if (!this.canInteract) return
    if (textureMode === true) videoTexture.start()
    return executeAsPromise(videoEl.play, null, videoEl).catch(e => {
      fireOnConsumer('error', { videoElement: videoEl, event: e })
    })
  },

  pause() {
    if (!this.canInteract) return
    videoEl.pause()
  },

  playPause() {
    if (!this.canInteract) return
    this.playing === true ? this.pause() : this.play()
  },

  mute(muted = true) {
    if (!this.canInteract) return
    videoEl.muted = muted
  },

  loop(looped = true) {
    videoEl.loop = looped
  },
  /**
   * @param {number} time - the URI to the VAST resource to be loaded - or raw VAST XML if params.vastXmlInput is true
   * @type {() => void}
   */
  seek(time) {
    if (!this.canInteract) return
    if (!this.src) return
    // define whether should continue to play after seek is complete (in seeked hook)
    if (state.playAfterSeek === null) {
      state.playAfterSeek = !!state.playing
    }
    // pause before actually seeking
    this.pause()
    // currentTime always between 0 and the duration of the video (minus 0.1s to not set to the final frame and stall the video)
    videoEl.currentTime = Math.max(0, Math.min(time, this.duration - 0.1))
  },
  /**
   * @type {() => void}
   */
  registerEventListeners() {
    registerEventListeners()
  },
  /**
   * @type {() => void}
   */
  pauseEventListeners() {
    console.info('Pause Event Listeners')
    eventsPaused = true
  },
  /**
   * @type {() => void}
   */
  resumeEventListeners() {
    console.info('Resume Event Listeners')
    eventsPaused = false
  },
  /**
   * @type {() => void}
   */
  deregisterEventListeners() {
    deregisterEventListeners()
  },
  /**
   * @type {() => void}
   */
  skip(seconds) {
    if (!this.canInteract) return
    if (!this.src) return

    state.skipTime = (state.skipTime || videoEl.currentTime) + seconds
    easeExecution(() => {
      this.seek(state.skipTime)
      state.skipTime = false
    }, 300)
  },
  /**
   * @type {() => void}
   */
  show() {
    if (!this.canInteract) return
    if (textureMode === true) {
      videoTexture.show()
    } else {
      videoEl.style.display = 'block'
      videoEl.style.visibility = 'visible'
    }
  },
  /**
   * @type {() => void}
   */
  hide() {
    if (!this.canInteract) return
    if (textureMode === true) {
      videoTexture.hide()
    } else {
      videoEl.style.display = 'none'
      videoEl.style.visibility = 'hidden'
    }
  },

  enableAds(enabled = true) {
    state.adsEnabled = enabled
  },

  /* Public getters */
  get duration() {
    return videoEl && (isNaN(videoEl.duration) ? Infinity : videoEl.duration)
  },

  get currentTime() {
    return videoEl && videoEl.currentTime
  },

  get muted() {
    return videoEl && videoEl.muted
  },

  get looped() {
    return videoEl && videoEl.loop
  },

  get src() {
    return videoEl && videoEl.getAttribute('src')
  },

  get playing() {
    return state.playing
  },

  get playingAds() {
    return state.playingAds
  },

  get canInteract() {
    // todo: perhaps add an extra flag wether we allow interactions (i.e. pauze, mute, etc.) during ad playback
    return state.playingAds === false
  },

  get top() {
    return videoEl && parseFloat(videoEl.style.top)
  },

  get left() {
    return videoEl && parseFloat(videoEl.style.left)
  },

  get bottom() {
    return videoEl && parseFloat(videoEl.style.top - videoEl.style.height)
  },

  get right() {
    return videoEl && parseFloat(videoEl.style.left - videoEl.style.width)
  },

  get width() {
    return videoEl && parseFloat(videoEl.style.width)
  },

  get height() {
    return videoEl && parseFloat(videoEl.style.height)
  },

  get visible() {
    if (textureMode === true) {
      return videoTexture.isVisible
    } else {
      return videoEl && videoEl.style.display === 'block'
    }
  },

  get adsEnabled() {
    return state.adsEnabled
  },

  // prefixed with underscore to indicate 'semi-private'
  // because it's not recommended to interact directly with the video element
  get _videoEl() {
    return videoEl
  },

  get _consumer() {
    return consumer
  },
}

export const MetroPlayer = autoSetupMixin(videoPlayerPlugin, () => {
  precision =
    (AppInstance &&
      AppInstance.stage &&
      AppInstance.stage.getRenderPrecision()) ||
    precision

  videoEl = setupVideoTag()

  textureMode = Settings.get('platform', 'textureMode', false)
  if (textureMode === true) {
    videoEl.setAttribute('crossorigin', 'anonymous')
    videoTexture = setUpVideoTexture()
  }
})

const executeAsPromise = (method, args = null, context = null) => {
  let result
  if (method && typeof method === 'function') {
    try {
      result = method.apply(context, args)
    } catch (e) {
      result = e
    }
  } else {
    result = method
  }

  // if it looks like a duck .. ehm ... promise and talks like a promise, let's assume it's a promise
  if (
    result !== null &&
    typeof result === 'object' &&
    result.then &&
    typeof result.then === 'function'
  ) {
    return result
  }
  // otherwise make it into a promise
  else {
    return new Promise((resolve, reject) => {
      if (result instanceof Error) {
        reject(result)
      } else {
        resolve(result)
      }
    })
  }
}

function autoSetupMixin(sourceObject, setup = () => {}) {
  let ready = false

  const doSetup = () => {
    if (ready === false) {
      setup()
      ready = true
    }
  }

  return Object.keys(sourceObject).reduce((obj, key) => {
    if (typeof sourceObject[key] === 'function') {
      obj[key] = function () {
        doSetup()
        return sourceObject[key].apply(sourceObject, arguments)
      }
    } else if (
      typeof Object.getOwnPropertyDescriptor(sourceObject, key).get ===
      'function'
    ) {
      obj.__defineGetter__(key, function () {
        doSetup()
        return Object.getOwnPropertyDescriptor(sourceObject, key).get.apply(
          sourceObject,
        )
      })
    } else if (
      typeof Object.getOwnPropertyDescriptor(sourceObject, key).set ===
      'function'
    ) {
      obj.__defineSetter__(key, function () {
        doSetup()
        return Object.getOwnPropertyDescriptor(
          sourceObject,
          key,
        ).set.sourceObject[key].apply(sourceObject, arguments)
      })
    } else {
      obj[key] = sourceObject[key]
    }
    return obj
  }, {})
}
let timeout = null

const easeExecution = (cb, delay) => {
  clearTimeout(timeout)
  timeout = setTimeout(() => {
    cb()
  }, delay)
}
