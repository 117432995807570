import { Colors, Lightning } from '@lightningjs/sdk'
import { getCoordinateDimensions } from '../lightning-tools'

export interface FocusableSpecTemplateSpec
  extends Lightning.Component.TemplateSpec {
  FocusableShadow: object
  FocusableBackground: object
}

export interface FocusableSpecConstructorBase {
  new (...args: any[]): Lightning.Component
}

export class Focusable<
    C extends Lightning.Component.TypeConfig = Lightning.Component.TypeConfig,
  >
  extends Lightning.Component<FocusableSpecTemplateSpec, C>
  implements
    Lightning.Component.ImplementTemplateSpec<FocusableSpecTemplateSpec>
{
  private _focusAnimation: Lightning.types.Animation | null = null

  static override _template(): Lightning.Component.Template<FocusableSpecTemplateSpec> {
    return {
      FocusableShadow: {
        alpha: 0,
        visible: false,
        mountX: 0.5,
        mountY: 0.5,
        color: Colors('black').alpha(0.4).get(),
        rect: true,
        shader: { type: Lightning.shaders.FadeOut, fade: 32 },
      },
      FocusableBackground: {
        alpha: 0,
        visible: false,
        mountX: 0.5,
        mountY: 0.5,
        rect: true,
        shader: { type: Lightning.shaders.FadeOut, fade: 32 },
      },
    }
  }

  override _enable() {
    const theme = this.fireAncestors('$theme')
    const coords = getCoordinateDimensions(this)
    if (theme && coords) {
      const { width, height } = coords
      this.patch({
        FocusableShadow: {
          w: width,
          h: height,
          x: width / 2,
          y: height / 2,
          alpha: 0.2,
          color: Colors(theme.palette.highlights[700]).get(),
        },

        FocusableBackground: {
          w: width,
          h: height,
          x: width / 2,
          y: height / 2,
          alpha: 1,
          color: Colors(theme.palette.buttonBackground).get(),
        },
      })
      this._focusAnimation = this.animation({
        duration: 0.2,
        actions: [
          {
            t: 'FocusableShadow',
            p: 'alpha',
            v: { 0: 0.2, 1: 0.4 },
          },
          {
            t: 'FocusableShadow',
            p: 'scale',
            v: { 0: 1, 1: 1.025 },
          },
        ],
      })
    }
  }

  override _focus() {
    this._focusAnimation?.start()
  }
  override _unfocus() {
    this._focusAnimation?.start()
  }
}
