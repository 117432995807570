import {
  ContentSeason,
  CoordinateDimensions,
  DirectionalSignalMap,
  ID,
  isID,
  ListSpaceHelpers,
  SetsByY,
} from '@adiffengine/engine-types'
import { Lightning } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import isFunction from 'lodash/isFunction'
import { Debugger, getClosestIndexByY, getCoordinateDimensions } from '../lib'
import { SeasonListItem } from './SeasonListItem'
const debug = new Debugger('SeasonList')
debug.enabled = false

export class SeasonList
  extends Lightning.Component<SeasonListTemplateSpec, SeasonListTypeConfig>
  implements
    Lightning.Component.ImplementTemplateSpec<SeasonListTemplateSpec>,
    SetsByY,
    ListSpaceHelpers
{
  private _seasons: ContentSeason[] = []
  List = this.getByRef('List')
  private _season: ID | undefined = undefined
  static override _template(): Lightning.Component.Template<SeasonListTemplateSpec> {
    return {
      w: 400,
      h: 700,
      x: 0,
      y: 0,
      List: {
        x: 0,
        y: 0,
        w: 400,
        h: 700,
        type: List,
        direction: 'column',
        spacing: 20,
        itemType: SeasonListItem,
        passSignals: {
          season: true,
        },
      },
    }
  }

  private _width: number = 400
  set width(w: number) {
    this.List.patch({ w })
    this._width = w
    this.patchList()
  }
  get width() {
    return this._width
  }
  set height(h: number) {
    this.List.patch({ h })
  }
  set seasons(seasons: ContentSeason[]) {
    this._seasons = seasons
    if (this.__initialized) this.patchList()
    this.season = seasons[0]?.id
  }
  get seasons() {
    return this._seasons
  }

  reposition() {
    this.List.reposition()
  }

  _currentSeason(season: ContentSeason) {
    debug.info('Got current season', season)
  }
  set season(season: ID | undefined) {
    if (this._season !== season) {
      this._season = season
      this.updateList()
      const currentSeason = this.seasons.find(({ id }) => id === season)
      this.signal('season', currentSeason)
    }
  }
  get season() {
    return this._season
  }
  setSeason(season: ContentSeason) {
    this.season = season.id
  }
  updateList() {
    if (this.__initialized) {
      this.List.items.forEach(
        (item: {
          patch: (arg0: { expanded: boolean }) => void
          season: { id: ID | undefined }
          expanded: boolean
        }) => {
          if (isFunction(item.patch) && isID(this.season)) {
            debug.info('Testing List?', item.season.id, this.season)
            item.patch({ expanded: item.season.id === this.season })
          } else {
            item.expanded = item.season.id === this.season
          }
        },
      )
    }
  }
  patchList() {
    this.List.patch({
      items: this.seasons.map(season => ({
        season,
        height: 80,
        width: this.w,
        signals: {
          reposition: this.reposition.bind(this),
          season: this.setSeason.bind(this),
        },
      })),
    })
    this.List.reposition()
  }
  override _init() {
    this.patchList()
    this.season = this._seasons[0]?.id
  }
  override _active() {
    if (this._seasons[0] && !this.season) {
      this.setSeason(this._seasons[0])
    } else if (this.season) {
      this.updateList()
    }
  }

  override _getFocused() {
    return this.List
  }
  override _handleRight() {
    this.signal('right')
  }
  override _handleLeft() {
    this.signal('left')
  }
  override _handleUp(): false | void {
    if (this.List.index === 0) this.signal('up')
    else return false
  }
  override _handleDown(): false | void {
    if (this.List.index === this.List.items.length - 1) this.signal('down')
    else return false
  }

  set index(x: number) {
    this.List.setIndex(x)
  }
  get index() {
    return this.List.index
  }

  setClosestByY(coords?: CoordinateDimensions) {
    if (coords) {
      const closest = getClosestIndexByY(coords, this.List.items)
      this.List.setIndex(closest)
    }
  }
  getCurrentIndexCoordinates() {
    return getCoordinateDimensions(this.List.items[this.List.index])
  }
}

export interface SeasonListTemplateSpec
  extends Lightning.Component.TemplateSpec {
  seasons: ContentSeason[]
  season?: ID
  List: typeof List
  width: number
  height: number
  index: number
}
export interface SeasonListSignals extends DirectionalSignalMap {
  season(season: ContentSeason | undefined): void
}
export interface SeasonListTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: SeasonListSignals
}
