import { ActionConfig, ThemeConfig } from '@adiffengine/engine-types'
import { Colors, Lightning } from '@lightningjs/sdk'
import { Debugger } from '../lib'
import { SimpleCard } from './SimpleCard'

const debug = new Debugger('PC')

export interface CardSettingsTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: {
    toggle(setting: string, x: boolean): void
  }
}

export interface CardSettingsTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Content: typeof SimpleCard
  Current: object
  theme: ThemeConfig
  action?: ActionConfig
  isTheme?: boolean
}

export class ThemeSelector
  extends Lightning.Component<CardSettingsTemplateSpec, CardSettingsTypeConfig>
  implements
    Lightning.Component.ImplementTemplateSpec<CardSettingsTemplateSpec>
{
  private _themeConfig: ThemeConfig | null = null
  private _action: ActionConfig | undefined = undefined
  private _currentAnimation: Lightning.types.Animation | undefined = undefined
  private _focusAnimation: Lightning.types.Animation | undefined = undefined
  set action(action: ActionConfig) {
    debug.info('Setting action on ThemeSelectCard')
    this._action = action
    this.tag('Content')?.patch({ action })
  }
  set theme(themeConfig: ThemeConfig) {
    this._themeConfig = themeConfig
    console.info('Got themeconfig themeconfig', themeConfig)
    if (themeConfig !== null) {
      this.tag('Content')?.patch({
        title: themeConfig.name,
        fontName: themeConfig.fonts.name,
      })
      this.tag('Current')?.patch({
        color: Colors(themeConfig.palette.highlights[800]).get(),
      })
    }
  }
  private _current: boolean = false
  set current(current: boolean) {
    this._current = current
    if (current && this.__initialized) {
      this.currentAnimation.start()
    } else {
      this.currentAnimation.stop()
    }
  }
  get current() {
    return this._current
  }
  get currentAnimation(): Lightning.types.Animation {
    if (this._currentAnimation !== undefined) return this._currentAnimation
    this._currentAnimation = this.animation({
      duration: 0.2,
      actions: [
        {
          t: 'Current',
          p: 'alpha',
          v: { 0: 0, 1: 1 },
        },
      ],
    })
    return this._currentAnimation
  }
  get focusAnimation(): Lightning.types.Animation {
    if (this._focusAnimation !== undefined) return this._focusAnimation
    this._focusAnimation = this.animation({
      duration: 0.2,
      actions: [
        {
          t: 'Current',
          p: 'scale',
          v: { 0: 1, 1: 1.05 },
        },
      ],
    })
    return this._focusAnimation
  }
  get themeConfig() {
    if (this._themeConfig) return this._themeConfig
    else {
      const theme = this.fireAncestors('$theme')
      return theme
    }
  }

  override _init() {
    this.patch({
      Content: {
        fontName: this._themeConfig?.fonts.name,
        type: SimpleCard,
        title: this._themeConfig?.name ?? 'Some Theme',
        description:
          "I'm baby same vice plaid fam tumblr small batch you probably haven't heard of them 3 wolf moon hashtag snackwave.",
        imageSrc: 'https://picsum.photos/400/600',
        h: this.h,
        w: this.w,
        action: this._action,
      },
      Current: {
        x: 0,
        y: 0,
        alpha: 0,
        h: this.h,
        w: this.w,
        rect: true,
        rtt: true,
        color: 0x00000000,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          stroke: 4,
          radius: this.themeConfig.components.SimpleCardConfig.radius,
          strokeColor: Colors('white').get(),
          fillColor: 0x00000000,
        },
      },
    })
    if (this.current) this.currentAnimation.start()
    debug.info('Init theme card', this)
  }

  static override _template(): Lightning.Component.Template<CardSettingsTemplateSpec> {
    return {
      h: 240,
      w: 500,
    }
  }

  override _focus() {
    this.focusAnimation.start()
    this.fireAncestors('$setTheme', this.themeConfig, true)
  }
  override _unfocus() {
    this.focusAnimation.stop()
    this.fireAncestors('$setTheme', null)
  }

  $theme() {
    return this.themeConfig
  }

  override _handleEnter() {
    debug.info('Handle Enter on ThemeSelect')
    if (this.themeConfig) {
      this.fireAncestors('$setTheme', this.themeConfig)
    }
    return false
  }
  override _getFocused() {
    return this.tag('Content')
  }
}
