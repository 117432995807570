import { Lightning } from '@lightningjs/sdk'
import { AdeLoader } from './AdeLoadingPage'

export interface AdeSplashScreenTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Content: typeof AdeLoader
}

export interface AdeSplashScreenTypeConfig
  extends Lightning.Component.TypeConfig {
  IsPage: true
}

export class AdeSplashScreen
  extends Lightning.Component<
    AdeSplashScreenTemplateSpec,
    AdeSplashScreenTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<AdeSplashScreenTemplateSpec>
{
  static override _template(): Lightning.Component.Template<AdeSplashScreenTemplateSpec> {
    return {
      x: 0,
      y: 0,
      w: 1920,
      h: 1080,
      Content: {
        x: 0,
        y: 0,
        w: 1920,
        h: 1080,
        type: AdeLoader,
      },
    }
  }
  override _active() {
    this.stage.application.emit('screenView', 'Splash Screen', 'splash_screen')
  }
}
