import { Lightning, Registry } from '@lightningjs/sdk'
import equal from 'fast-deep-equal/es6'
import { Debugger, getBestDimensionValue, getBestDimensionValues } from '../lib'
import { Avatar, AvatarTemplateSpec } from './Avatar'

import { User } from '@adiffengine/engine-types'
const debug = new Debugger('AvatarList')
export interface AvatarListTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Images: object
  spacing: number
  direction: 'row' | 'column'
  users: User[]
}

export interface AvatarPatchTemplate
  extends Lightning.Element.PatchTemplate<AvatarTemplateSpec> {
  type: typeof Avatar
}

export class AvatarList
  extends Lightning.Component<
    AvatarListTemplateSpec,
    Lightning.Component.TypeConfig
  >
  implements Lightning.Component.ImplementTemplateSpec<AvatarListTemplateSpec>
{
  Images = this.getByRef('Images')!
  static override _template(): Lightning.Component.Template<AvatarListTemplateSpec> {
    return {
      h: 1080,
      w: 100,
      Images: {
        x: 0,
        y: 0,
        mountY: 1,
      },
    }
  }

  private _direction: 'row' | 'column' = 'column'
  set direction(direction: 'row' | 'column') {
    if (this._direction !== direction) {
      this._direction = direction
    }
  }
  get direction() {
    return this._direction
  }

  private _usersToAdd: User[] = []
  private _loopingImagesToAdd: boolean = false
  private _currentUsers: User[] = []
  set usersToAdd(users: User[]) {
    const start = this._usersToAdd.length
    users = users.filter(i => !this._usersToAdd.includes(i))
    this._usersToAdd = this._usersToAdd.concat(users)
    if (
      this._usersToAdd.length > 0 &&
      this._usersToAdd.length !== start &&
      !this._loopingImagesToAdd
    ) {
      debug.info('Calling add image')
      this.addUser()
    } else {
      debug.info(
        'Not calling images to add',
        this._usersToAdd.length,
        start,
        this._loopingImagesToAdd,
      )
    }
  }

  getNextAvatarPosition() {
    let x = 0,
      y = 0
    if (this.direction === 'column') {
      y = this.h
    } else {
      x = this.w
    }
    return { x, y }
  }

  avatarDimensions() {
    const dimension = this.direction === 'column' ? 'w' : 'h'
    return getBestDimensionValue(this, dimension)
  }
  addUser() {
    const newUser = this._usersToAdd.shift()
    if (newUser) {
      const direction = this.direction === 'column' ? 'y' : 'x'
      const avatarDimension = this.avatarDimensions()
      const avatarPosition = this.getNextAvatarPosition()
      const step = (avatarDimension + this.spacing) * -1
      const listDimensions = getBestDimensionValues(this)
      this.childList.forEach((child, idx) => {
        const startPosition =
          this.direction === 'column' ? listDimensions.h : listDimensions.w
        const offset = startPosition + (this.childList.length - idx) * step
        child.setSmooth(direction, offset, { duration: 0.2 })
      })

      const patch: AvatarPatchTemplate = {
        h: avatarDimension,
        w: avatarDimension,
        mountX: this.direction === 'column' ? 0 : 1,
        mountY: this.direction === 'column' ? 1 : 0,
        x: avatarPosition.x,
        y: avatarPosition.y,
        type: Avatar,
        user: newUser,
        entrance: this.direction === 'row' ? 'bottom' : 'right',
      }
      const item = this.stage.c(patch)
      this.childList.a(item)
      this._currentUsers.push(newUser)
      if (this._usersToAdd.length > 0) {
        this._loopingImagesToAdd = true
        Registry.setTimeout(() => {
          this.addUser()
        }, 400)
      } else {
        this._loopingImagesToAdd = false
      }
    }
  }
  public spacing = 10
  private _users: User[] = []
  set users(users: User[]) {
    if (!equal(users, this._users)) {
      const newUsers = users.filter(
        i => !this._users.map(({ id }) => id).includes(i.id),
      )

      this._users = users
      this.usersToAdd = newUsers
    }
    this.Images.patch({
      w: this.w,
      h: this.h,
      x: 0,
      y: this.h,
    })
  }
  get images() {
    return this._users
  }
}
