import { Colors, Lightning } from '@lightningjs/sdk'
import { Debugger } from '../lib'
import { getStoredTheme } from '../themes'
import { TextWidthTester } from './MagicText/MagicText'
export interface SearchTextInputTemplateSpec
  extends Lightning.Component.TemplateSpec {
  SearchTextBackground: object
  SearchText: {
    Text: object
  }
  TextWidthTester: typeof TextWidthTester
  PlaceholderText: object
  searchText: string
  placeholderText: string
  lines: number
  width: number
}
export interface SearchTextInputSignals {
  stringTooLong(str: string): void
  stringGood(): void
}
export interface SearchTextInputTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: SearchTextInputSignals
}

export const MAX_SEARCH_STRING_LENGTH = 18

const textSetup = {
  advancedRenderer: true,
  fontFace: 'Text',
  fontSize: 20,
  lineHeight: 30,
}
const debug = new Debugger('SearchTextInput')

export class SearchTextInput
  extends Lightning.Component<
    SearchTextInputTemplateSpec,
    SearchTextInputTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<SearchTextInputTemplateSpec>
{
  SearchTextBackground = this.getByRef('SearchTextBackground')!
  SearchText = this.getByRef('SearchText')!
  PlaceholderText = this.getByRef('PlaceholderText')!
  static override _template(): Lightning.Component.Template<SearchTextInputTemplateSpec> {
    const theme = getStoredTheme()

    return {
      SearchTextBackground: {
        x: 0,
        y: 0,
        w: (w: number) => w,
        h: 100,
        rect: true,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 8,
          stroke: 2,
          fillColor: Colors(theme.palette.darks[700]).alpha(0.6).get(),
          strokeColor: Colors(theme.palette.text).alpha(0.4).get(),
        },
      },
      SearchText: {
        x: 12,
        y: 8,
        Text: {
          color: Colors(theme.palette.text).get(),
          text: {
            ...textSetup,
            text: '',
          },
        },
      },
      PlaceholderText: {
        x: 12,
        y: 8,
        color: Colors(theme.palette.text).alpha(0.6).get(),
        visible: true,
        text: {
          ...textSetup,
          verticalAlign: 'middle',
          wordWrap: true,
        },
      },
    }
  }

  set width(w: number) {
    this.patch({ w })
    debug.info('Setting wordwrap to %s', w)
    this.PlaceholderText.patch({ text: { wordWrapWidth: w } })
  }

  private _lines = 2
  override _init() {
    this.patch({
      SearchTextBackground: {
        w: this.w,
        h: this.lines * 40 + 20,
      },
      PlaceholderText: {
        text: {
          wordWrapWidth: this.w - 24,
        },
      },
    })
    this.SearchTextBackground.patch({
      w: this.w,
      h: this.lines * 40 + 20,
    })
  }

  get maxText(): number {
    return MAX_SEARCH_STRING_LENGTH * this.lines
  }
  set lines(lines: number) {
    this._lines = lines
    this.patch({
      SearchTextBackground: {
        h: lines * 40 + 20,
        w: this.w,
      },
      PlaceholderText: {
        text: {
          wordWrap: lines > 1,
          maxLines: lines > 1 ? lines : undefined,
        },
      },
    })
    this.patch({ h: this.lines * 40 + 20 })
  }
  get lines() {
    return this._lines
  }

  private _searchText = ''
  set searchText(text: string) {
    text = text.trim()
    this.SearchText.patch({
      text: {
        text: this.getBrokenStrings(text),
        ...textSetup,
      },
    })

    if (text === '') {
      this.PlaceholderText.patch({
        visible: true,
      })
    } else {
      this.PlaceholderText.patch({
        visible: false,
      })
    }
  }
  private _placeholderText = ''
  getBrokenStrings(str: string) {
    str = str.replace(/\s+/g, ' ').trim()
    if (str.length <= MAX_SEARCH_STRING_LENGTH) return str
    const lines: string[] = []
    let stringLeft = str
    while (
      lines.length <= this.lines &&
      stringLeft.trim().length > MAX_SEARCH_STRING_LENGTH
    ) {
      let line = stringLeft.substring(0, MAX_SEARCH_STRING_LENGTH).trim()
      const end = line.lastIndexOf(' ')
      if (end > -1) {
        line = stringLeft.substring(0, end).trim()
      }
      stringLeft = stringLeft.replace(new RegExp(`^${line}`), '').trim()
      lines.push(line.trim())
    }

    if (stringLeft.length > 0 && lines.length <= this.lines)
      lines.push(stringLeft)
    debug.info('lines %s vs %s', lines.length, this.lines, lines)
    if (lines.length > this.lines) {
      debug.info('Signaling string too long')
      this.signal('stringTooLong', lines.slice(0, this.lines).join(''))
    } else {
      debug.info('Signaling string good')
      this.signal('stringGood')
    }
    return lines.slice(0, this.lines).join('\n')
  }
  set placeholderText(text: string) {
    text = text.trim()
    this.PlaceholderText.patch({
      text: {
        text,
        wordWrapWidth: this.w - 24,
        maxLines: this._lines > 1 ? this._lines : undefined,
      },
    })
    this._placeholderText = text
    if (this._searchText === '') {
      this.PlaceholderText.patch({
        visible: true,
      })
    } else {
      this.PlaceholderText.patch({
        visible: false,
      })
    }
  }
  get placeholderText() {
    return this._placeholderText
  }
}
