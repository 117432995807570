import { ActionConfig, DirectionalSignalMap } from '@adiffengine/engine-types'
import { Colors, Lightning } from '@lightningjs/sdk'
import { getStoredTheme } from '../themes'

export interface ButtonConfig {
  icon?: string
  label: string
  action: ActionConfig
}

export interface AdeFlexMenuTemplateSpec
  extends Lightning.Component.TemplateSpecLoose {
  buttons: ButtonConfig[]
  direction: 'row' | 'column'
  Content: object
}
export interface AdeFlexMenuTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: DirectionalSignalMap
}

export class AdeFlexMenu
  extends Lightning.Component<AdeFlexMenuTemplateSpec, AdeFlexMenuTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<AdeFlexMenuTemplateSpec>
{
  static height = 80
  static width = 1920 - 160
  static override _template(): Lightning.Component.Template<AdeFlexMenuTemplateSpec> {
    const theme = getStoredTheme()
    return {
      x: 80,
      y: 80,
      w: 1920 - 160,
      h: 80,
      rect: true,
      color: Colors(theme.palette.darks[600]).get(),
      Content: {
        w: (w: number) => w,
        h: (h: number) => h,
        x: 0,
        y: 0,
        flex: {
          direction: this.bindProp('direction'),
          alignItems: 'center',
          justifyContent: 'flex-start',
        },
      },
    }
  }

  public direction: 'row' | 'column' = 'column'

  private _buttons: ButtonConfig[] = []
  set buttons(buttons: ButtonConfig[]) {
    this._buttons = buttons
  }
  get buttons(): ButtonConfig[] {
    return this._buttons
  }
  private _currentIndex = 0
  set currentIndex(x: number) {
    if (x > 0 && x < this._buttons.length - 1) {
      this._currentIndex = x
      this._refocus()
    }
  }
  get currentIndex() {
    return this._currentIndex
  }

  override _getFocused(): Lightning.Component | undefined | null {
    return this.tag('Content')?.childList.getAt(
      this._currentIndex,
    ) as Lightning.Component
  }
}
