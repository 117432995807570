import { Colors } from '@lightningjs/sdk'
import Lightning from '@lightningjs/sdk/src/Lightning'
import { ThorError, defaultBackHandler } from '../../lib'
import { AdeErrorMessage } from './AdeErrorScreen'

export interface ErrorModalTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Background: object
  ErrorMessage: typeof AdeErrorMessage
  error: ThorError | null
  vignetteOpacity: number
  modalBackgroundOpacity: number
}
export class ThorErrorModal
  extends Lightning.Component<ErrorModalTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<ErrorModalTemplateSpec>
{
  static defaultVignetteOpacity = 0.8
  Background = this.getByRef('Background')!
  ErrorMessage = this.getByRef('ErrorMessage')!
  static override _template(): Lightning.Component.Template<ErrorModalTemplateSpec> {
    return {
      alpha: 0,
      x: 0,
      y: 0,
      w: 1920,
      h: 1080,
      Background: {
        x: 0,
        y: 0,
        w: 1920,
        h: 1080,
        rect: true,
        rtt: true,
        color: Colors('background').alpha(this.defaultVignetteOpacity).get(),
      },
      ErrorMessage: {
        x: (1920 - AdeErrorMessage.width) / 2,
        y: (1080 - AdeErrorMessage.height) / 2,
        w: AdeErrorMessage.width,
        h: AdeErrorMessage.height,
        type: AdeErrorMessage,
      },
    }
  }
  private _error: ThorError | null = null
  set error(error: ThorError | null) {
    this.ErrorMessage.patch({
      error,
    })
    this._error = error
  }
  get error(): ThorError | null {
    return this._error
  }
  override _getFocused() {
    return this.ErrorMessage
  }
  override _firstActive(): void {
    this.ErrorMessage.patch({
      buttons: [
        {
          buttonId: 'back',
          buttonText: 'Go Back',
          selected: this.goBack.bind(this),
        },
        {
          buttonId: 'home',
          buttonText: 'Go Home',
          selected: this.goHome.bind(this),
        },
      ],
    })
  }

  override _focus(): void {
    this.setSmooth('alpha', 1)
  }
  override _unfocus(): void {
    this.setSmooth('alpha', 0)
  }

  set vignetteOpacity(opacity: number) {
    if (opacity >= 0 && opacity <= 1) {
      this.Background.patch({
        color: Colors('background').alpha(opacity).get(),
      })
    } else {
      console.warn('Invalid vignette opacity %s', opacity)
    }
  }
  set modalBackgroundOpacity(backgroundAlpha: number) {
    if (backgroundAlpha >= 0 && backgroundAlpha <= 1) {
      this.ErrorMessage.patch({
        backgroundAlpha,
      })
    } else {
      console.warn('Invalid vignette opacity %s', backgroundAlpha)
    }
  }

  goHome() {
    this.fireAncestors('$navigate', 'home', false)
  }
  goBack() {
    defaultBackHandler()
  }
}
