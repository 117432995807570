import {
  ComponentDimensions,
  ContentItem,
  ContentSelectedDirectionalSignalMap,
  ImageSet,
} from '@adiffengine/engine-types'
import { Colors, Img, Lightning } from '@lightningjs/sdk'
import isEqual from 'fast-deep-equal/es6'
import { format } from 'fecha'
import merge from 'ts-deepmerge'
import { PartialDeep } from 'type-fest'
import { getStoredTheme, isValidDate } from '..'
import { Debugger, isGoodString } from '../lib'
const debug = new Debugger('SimpleHorizontalContentCard')
export interface SimpleHorizontalCardTemplateConfig {
  width: number
  height: number
  imageWidthRatio: number
  padding: number
  titleConfig: TextConfig
  descriptionConfig: TextConfig
}

export const SIMPLE_HORZ_CARD_BASE_WIDTH = 300
export const SIMPLE_HORZ_CARD_BASE_HEIGHT = 100
export const SIMPLE_HORZ_CARD_BASE_PADDING = 16
export const SIMPLE_HORZ_CARD_BASE_RATIO = 16 / 9

export interface SimpleHorizontalCardContentItem
  extends Pick<ContentItem, 'title' | 'description' | 'details'> {
  imageSet?: ImageSet | null
}

export const EpisodeCardConfig: SimpleHorizontalCardTemplateConfig = {
  height: 200,
  width: 500,
  imageWidthRatio: 4 / 3,
  padding: 24,
  titleConfig: {
    fontSize: 24,
    lineHeight: 30,
    maxLines: 2,
  },
  descriptionConfig: {
    fontSize: 18,
    lineHeight: 24,
    maxLines: 3,
  },
}

export type SimpleContentItem = Pick<
  ContentItem,
  'title' | 'description' | 'details' | 'release' | 'images' | 'id'
>
export interface TextConfig {
  maxLines: number
  fontSize: number
  lineHeight: number
}

export interface CardSetup {
  content: SimpleContentItem
  cardContent: SimpleHorizontalCardContentItem
  contentConverter(item: SimpleContentItem): SimpleHorizontalCardContentItem
  cardConfig: PartialDeep<SimpleHorizontalCardTemplateConfig>
}

export interface SimpleHorizontalContentCardTemplateSpec
  extends Lightning.Component.TemplateSpec,
    CardSetup {
  Background: object
  Shadow: object
  ImageWrapper: {
    Image: object
  }
  Text: {
    Title: object
    Description: object
  }
  Details: object
}

export interface SimpleHorizontalContentCardSignals
  extends ContentSelectedDirectionalSignalMap {
  reposition(): void
}

export interface SimpleHorizontalContentCardTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: SimpleHorizontalContentCardSignals
}
1
export class SimpleHorizontalContentCard
  extends Lightning.Component<
    SimpleHorizontalContentCardTemplateSpec,
    SimpleHorizontalContentCardTypeConfig
  >
  implements
    Lightning.Component
      .ImplementTemplateSpec<SimpleHorizontalContentCardTemplateSpec>
{
  static defaultTitleConfig: TextConfig = {
    fontSize: 24,
    maxLines: 2,
    lineHeight: 30,
  }
  static defaultDescriptionConfig: TextConfig = {
    fontSize: 24,
    maxLines: 3,
    lineHeight: 30,
  }

  static defaultConfig: SimpleHorizontalCardTemplateConfig = {
    height: SIMPLE_HORZ_CARD_BASE_HEIGHT,
    width: SIMPLE_HORZ_CARD_BASE_WIDTH,
    imageWidthRatio: SIMPLE_HORZ_CARD_BASE_RATIO,
    padding: SIMPLE_HORZ_CARD_BASE_PADDING,
    titleConfig: this.defaultTitleConfig,
    descriptionConfig: this.defaultDescriptionConfig,
  }
  private _focusAnimation: any

  static getImageDimensions(
    config: SimpleHorizontalCardTemplateConfig,
  ): ComponentDimensions {
    const height = config.height - config.padding * 2
    const width = height * config.imageWidthRatio
    return {
      x: config.padding,
      y: config.padding,
      w: width,
      h: height,
    }
  }

  static getTextDimensions(
    config: SimpleHorizontalCardTemplateConfig,
    noImage: boolean = false,
  ): ComponentDimensions {
    const image = this.getImageDimensions(config)
    const height = config.height - config.padding * 2
    const imageWidth = noImage === true ? 0 : image.w
    const x = config.width - (imageWidth + config.padding * 2)
    const width = config.width - (x + config.padding)
    const y = config.padding
    return {
      x,
      y,
      w: width,
      h: height - 24,
    }
  }

  static getCardDimensions(
    cardConfig: Partial<SimpleHorizontalCardTemplateConfig>,
  ) {
    const fullConfig: SimpleHorizontalCardTemplateConfig = {
      ...this.defaultConfig,
      ...cardConfig,
    }
    return { w: fullConfig.width, height: fullConfig.height }
  }
  static getDetailsDimensions(
    config: SimpleHorizontalCardTemplateConfig,
    noImage: boolean = false,
  ): Omit<ComponentDimensions, 'w'> {
    const image = this.getImageDimensions(config)
    const imageWidth = noImage === true ? 0 : image.w
    const x = config.width - (imageWidth + config.padding * 2)
    const y = config.height - config.padding
    return {
      x,
      y,
      h: 24,
    }
  }

  ImageWrapper = this.getByRef('ImageWrapper')!
  Image = this.ImageWrapper.getByRef('Image')!
  Text = this.getByRef('Text')!
  Title = this.Text.getByRef('Title')!
  Description = this.Text.getByRef('Description')!
  Details = this.getByRef('Details')!

  static override _template(): Lightning.Component.Template<SimpleHorizontalContentCardTemplateSpec> {
    const theme = getStoredTheme()
    const {
      components: { SimpleCardConfig },
    } = theme
    const imageDimensions = this.getImageDimensions(this.defaultConfig)
    const { w: textWidth, ...textDimensions } = this.getTextDimensions(
      this.defaultConfig,
    )
    const detailsDimensions = this.getDetailsDimensions(this.defaultConfig)
    return {
      h: this.defaultConfig.height,
      w: this.defaultConfig.width,
      Shadow: {
        mountX: 0.5,
        mountY: 0.5,
        x: this.defaultConfig.width / 2 + 8,
        y: this.defaultConfig.height / 2 + 8,
        h: this.defaultConfig.height + 16,
        w: this.defaultConfig.width + 16,
        rtt: true,
        rect: true,
        color: Colors(SimpleCardConfig.shadowColor).get(),
        alpha: 0.2,
        shader: {
          type: Lightning.shaders.FadeOut,
          fade: 16,
        },
      },
      Background: {
        x: 0,
        y: 0,
        h: this.defaultConfig.height,
        w: this.defaultConfig.width,
        rect: true,
        rtt: true,
        color: Colors(SimpleCardConfig.backgroundColor).get(),
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: SimpleCardConfig.radius,
          strokeColor: Colors(SimpleCardConfig.backgroundColor).get(),
          stroke: Colors(SimpleCardConfig.stroke).get(),
        },
      },
      ImageWrapper: {
        ...imageDimensions,
        rtt: true,
        rect: true,
        color: 0x00000000,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: SimpleCardConfig.radius,
        },
        Image: {
          x: 0,
          y: 0,
          w: imageDimensions.w,
          h: imageDimensions.h,
        },
      },
      Text: {
        ...textDimensions,
        w: textWidth,
        flex: {
          direction: 'column',
        },
        Title: {
          color: Colors('text').get(),
          text: {
            text: 'Title',
            wordWrapWidth: textWidth,
            wordWrap: true,
            fontFace: 'Bold',
            ...this.defaultTitleConfig,
          },
        },
        Description: {
          color: Colors('text').get(),
          text: {
            text: 'Description',
            wordWrapWidth: textWidth,
            wordWrap: true,
            fontFace: 'Text',
            ...this.defaultDescriptionConfig,
          },
        },
      },
      Details: {
        ...detailsDimensions,
        color: Colors('text').get(),
        mountY: 1,
        text: {
          text: 'Description',
          wordWrapWidth: textWidth,
          wordWrap: true,
          maxLines: 4,
          fontFace: 'Regular',
          fontSize: 18,
          lineHeight: 24,
        },
      },
    }
  }

  static defaultContentConverter: SimpleHorizontalContentCardTemplateSpec['contentConverter'] =
    (item: SimpleContentItem) => {
      const imageSet =
        item.images.wide ??
        item.images.thumb ??
        item.images.wallpaper ??
        item.images.box
      const details =
        item.details ?? isValidDate(item.release)
          ? `${
              item.release!.getTime() < new Date().getTime()
                ? 'Aired'
                : 'Airing'
            }: ${format(item.release!, 'MMM Do, YYYY')}`
          : ''
      return {
        title: item.title,
        description: item.description,
        details,
        imageSet,
      }
    }

  private _content: SimpleContentItem | null = null
  private _cardConfig: SimpleHorizontalCardTemplateConfig =
    SimpleHorizontalContentCard.defaultConfig
  private _cardContent: SimpleHorizontalCardContentItem | null = null
  private _contentConverter: SimpleHorizontalContentCardTemplateSpec['contentConverter'] =
    SimpleHorizontalContentCard.defaultContentConverter

  set content(content: SimpleContentItem) {
    if (!isEqual(content, this._content)) {
      this._content = content
      this._convertContent()
    }
  }
  get content() {
    return this._content!
  }

  set contentConverter(
    converter: SimpleHorizontalContentCardTemplateSpec['contentConverter'],
  ) {
    if (converter && this._contentConverter !== converter) {
      this._contentConverter = converter
      this._convertContent()
    } else {
      this._contentConverter =
        SimpleHorizontalContentCard.defaultContentConverter
    }
  }

  get contentConverter() {
    return this._contentConverter
  }

  set cardContent(content: SimpleHorizontalCardContentItem) {
    if (!isEqual(this._cardContent, content)) {
      this.Title.patch({ text: { text: content.title.toUpperCase() } })
      this.Description.patch({
        text: { text: content.description ?? '' },
        visible: isGoodString(content.description),
      })
      this.Details.patch({
        text: { text: content.details ?? '' },
        visible: isGoodString(content.details),
      })
      if (content.imageSet) {
        const { w, h } = SimpleHorizontalContentCard.getImageDimensions(
          this.cardConfig,
        )
        this.Image.patch({
          texture: Img(content.imageSet.getForWidth(w)!).cover(w, h),
        })
      }
      this.imageMissing = !content.imageSet
    }
  }

  private _convertContent() {
    this.cardContent = this.contentConverter(this.content)
  }

  set cardConfig(cardConfig: Partial<SimpleHorizontalCardTemplateConfig>) {
    const updatedConfig = merge({
      ...SimpleHorizontalContentCard.defaultConfig,
      ...cardConfig,
    })
    if (!isEqual(updatedConfig, this._cardConfig)) {
      this._cardConfig = updatedConfig
      this.positionChildren()
    }
  }

  get cardConfig(): SimpleHorizontalCardTemplateConfig {
    return this._cardConfig
  }

  private _lastDimensions = {
    width: SimpleHorizontalContentCard.defaultConfig.width,
    height: SimpleHorizontalContentCard.defaultConfig.height,
  }

  checkDimensionsUpdate() {
    const currentDimensions = {
      width: this.cardConfig.width,
      height: this.cardConfig.height,
    }
    if (!isEqual(this._lastDimensions, currentDimensions)) {
      this._lastDimensions = currentDimensions
      this.signal('reposition')
    }
  }

  positionChildren(noImage: boolean = false) {
    const baseSizes = {
      h: this.cardConfig.height,
      w: this.cardConfig.width,
    }
    const imageDimensions = SimpleHorizontalContentCard.getImageDimensions(
      this.cardConfig,
    )
    const { w: textWidth, ...textDimensions } =
      SimpleHorizontalContentCard.getTextDimensions(this.cardConfig, noImage)
    const detailsPositions = SimpleHorizontalContentCard.getDetailsDimensions(
      this.cardConfig,
      noImage,
    )

    this.patch({
      ...baseSizes,
      Shadow: {
        w: baseSizes.w + 16,
        h: baseSizes.h + 16,
        x: baseSizes.w / 2 + 8,
        y: baseSizes.h / 2 + 8,
      },
      Background: {
        ...baseSizes,
      },
      ImageWrapper: {
        visible: !noImage,
        ...imageDimensions,
        Image: {
          w: imageDimensions.w,
          h: imageDimensions.h,
        },
      },
      Text: {
        ...textDimensions,
        Title: {
          text: {
            wordWrapWidth: textWidth,
            ...this.cardConfig.titleConfig,
          },
        },
        Description: {
          text: {
            wordWrapWidth: textWidth,
            ...this.cardConfig.descriptionConfig,
          },
        },
      },
      Details: {
        ...detailsPositions,
        text: {
          wordWrapWidth: textWidth,
        },
      },
    })
  }

  private _imageMissing = false
  set imageMissing(missing: boolean) {
    if (this._imageMissing !== missing) {
      this._imageMissing = missing
      this.positionChildren(missing)
    }
  }
  get imageMissing() {
    return this._imageMissing
  }
  setImageMissing() {
    this.imageMissing = true
  }
  override _init() {
    this.setImageMissing = this.setImageMissing.bind(this)
    this.Image.on('txError', this.setImageMissing)
  }

  get focusAnimation() {
    if (!this._focusAnimation) {
      const {
        components: { SimpleCardConfig },
      } = this.fireAncestors('$theme')

      debug.info('Shadow Color %s', SimpleCardConfig.shadowColor)
      this._focusAnimation = this.animation({
        duration: 0.2,
        actions: [
          {
            t: 'Background',
            // @ts-ignore - bad types in lightning.
            p: 'shader.strokeColor',
            v: {
              0: Colors(SimpleCardConfig.backgroundColor).get(),
              1: Colors('primaryHighlight').get(),
            },
          },
          {
            t: 'Shadow',
            p: 'color',
            v: {
              0: Colors(SimpleCardConfig.shadowColor).get(),
              1: Colors('primaryHighlight').get(),
            },
          },
          {
            t: 'Shadow',
            p: 'alpha',
            v: { 0: 0.2, 1: 0.3 },
          },
        ],
      })
    }
    return this._focusAnimation
  }

  override _focus() {
    this.focusAnimation.start()
  }
  override _unfocus() {
    this.focusAnimation.stop()
  }
}
