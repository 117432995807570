import {
  PageTrackingEvent,
  VideoTrackingEvent,
  VideoViewTrackingEvent,
  isSearchingEvent,
} from '@adiffengine/engine-types'
import { Settings } from '@lightningjs/sdk'
import {
  Analytics,
  getAnalytics,
  isSupported,
  logEvent,
} from 'firebase/analytics'
import { FirebaseApp, getApp, initializeApp } from 'firebase/app'
// @ts-ignore
import { gtag, install } from 'ga-gtag'

import { Debugger } from '../../debugger'
import { isBoolean, isNumber, isString } from '../../utils'
import type { ThorMetrics } from '../metrics'
import { FirebaseConfig } from '../lifecycle-types'
const debug = new Debugger('ga')

const firebaseDefaultConfig = {
  apiKey: 'AIzaSyBLysQcbfo9rIQga4qi8Kkm-OWuLoSsl8g',
  authDomain: 'valhalla-f2ec8.firebaseapp.com',
  projectId: 'valhalla-f2ec8',
  storageBucket: 'valhalla-f2ec8.appspot.com',
  messagingSenderId: '311719570977',
  appId: '1:311719570977:web:442dcb3ba38499a750bd60',
  measurementId: 'G-JBKV8GC7H4',
}
function safelyGetApp(config: FirebaseConfig): FirebaseApp {
  try {
    const existing = getApp()
    return existing
  } catch (error) {
    const app = initializeApp(config)
    return app
  }
}

export class GaTracker {
  public _fbApp: FirebaseApp
  private _fbAnalytics: Analytics | null = null
  private _platform: string
  constructor(metrics: ThorMetrics, measurementId: string) {
    this._platform = Settings.get('app', 'platform', 'smart-tv')
    const appId = Settings.get(
      'app',
      'FIREBASE_APP_ID',
      firebaseDefaultConfig.appId,
    )

    this._fbApp = safelyGetApp({
      ...firebaseDefaultConfig,
      measurementId,
      appId,
    })
    metrics.on('screen_view_event', e => {
      debug.info('Screen View Event')
      this.screen_view_event(e)
    })
    metrics.on('video_playback_event', e => {
      debug.info('Video Playback Event', e)

      this.video_playback_event(e)
    })
    metrics.on('video_view_event', e => {
      debug.info('Video View Event', e)
      this.video_view_event(e)
    })
    metrics.on('search_event', e => {
      if (isSearchingEvent(e)) {
        this.search_event(e.payload.term)
      }
    })
    this.initializeAnalytics()
  }
  search_event(term: string) {
    this.logEvent('search', {
      search_term: term,
    })
  }
  static cleanPayload(
    payload: Record<string, unknown>,
  ): Record<string, string | number | boolean | null> {
    return Object.entries(payload).reduce((acc, [key, value]) => {
      if (isString(value) || isNumber(value) || isBoolean(value)) {
        acc[key] = value
      } else {
        acc[key] = null
      }
      return acc
    }, {} as Record<string, string | number | boolean | null>)
  }
  screen_view_event(event: PageTrackingEvent) {
    const { page_type, page_class, ...rest } = event.payload
    const out = {
      firebase_screen: page_type,
      firebase_screen_class: page_class,
      ...GaTracker.cleanPayload(rest),
    }
    this.logEvent('screen_view', out)
  }
  video_playback_event(event: VideoTrackingEvent) {
    this.logEvent('video_playback', event.payload)
  }
  video_view_event(event: VideoViewTrackingEvent) {
    this.logEvent('video_view', event.payload)
  }
  private _anlyticsInitialized = false
  private _eventsWaitingForAnalytics: { event: any; payload: any }[] = []
  async initializeAnalytics() {
    const forceGa = Settings.get('app', 'FORCE_GA_TAG', false)
    const analyticsSupported = await isSupported()
    if (analyticsSupported && !forceGa) {
      debug.info('Firebase analytics enabled. Using those.')
      this._fbAnalytics = getAnalytics(this._fbApp)
    } else {
      debug.info('Firebase analytics not enabled. Installing Gtag')
      const measurementId = Settings.get(
        'app',
        'FIREBASE_MEASUREMENT_ID',
        firebaseDefaultConfig.measurementId,
      )
      install(measurementId, { send_page_view: false })
      debug.info('Done installing analytics')
    }
    this._anlyticsInitialized = true
    this._eventsWaitingForAnalytics.forEach(item => {
      this.logEvent(item.event, item.payload)
    })
    this._eventsWaitingForAnalytics = []
  }
  logEvent(event: any, payload?: any) {
    payload = payload
      ? { ...payload, platform: this._platform }
      : { platform: this._platform }
    if (this._anlyticsInitialized) {
      if (this._fbAnalytics !== null) {
        debug.info('Logging event %s to firebase analytics', event, payload)
        logEvent(this._fbAnalytics, event, payload)
      } else {
        try {
          debug.info('Logging event %s to gtag', event, payload)
          gtag('event', event, payload)
        } catch (error) {
          console.warn('gtag error', error)
        }
      }
    } else {
      debug.info('Caching event %s until analytics are ready.', event, payload)
      this._eventsWaitingForAnalytics.push({ event, payload })
    }
  }
  static trackEvents(metrics: ThorMetrics) {
    const measurementId = Settings.get(
      'app',
      'FIREBASE_MEASUREMENT_ID',
      firebaseDefaultConfig.measurementId,
    )
    if (measurementId) {
      const tracker = new GaTracker(metrics, measurementId)
      debug.info('Created new GA Tracker with id %s', measurementId, tracker)
    }
  }
}
