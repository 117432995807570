import {
  ContentItem,
  CoordinateDimensions,
  ID,
} from '@adiffengine/engine-types'
import { Colors, Img, Lightning } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import { format } from 'fecha'
import isDate from 'lodash/isDate'
import { Debugger, isGoodString, parseMedia, setClosestInList } from '../../lib'
import { getStoredTheme } from '../../themes'
import { AdeButton } from '../AdeButton'
import { MainMenu } from '../MainMenu'
import { HeroContentSetup, defaultDetailCoordinates } from './StaticHeroSetup'
const debug = new Debugger('HeroWidgetItem')

export class HeroWidgetItem
  extends Lightning.Component<
    HeroWidgetItemTemplateSpec,
    HeroWidgetItemTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<HeroWidgetItemTemplateSpec>
{
  Content = this.getByRef('Content')!
  Buttons = this.Content.getByRef('Buttons')!
  Image = this.getByRef('Image')!
  Poster = this.Image.getByRef('Poster')!
  _showAnimation: Lightning.types.Animation | undefined

  static override _template(): Lightning.Component.Template<HeroWidgetItemTemplateSpec> {
    const theme = getStoredTheme()
    const {
      components: { HeroWidgetItemConfig },
    } = theme
    return {
      Image: {
        x: 0,
        y: 0,
        w: 1920,
        h: 800,
        rect: true,
        rtt: true,
        color: 0x00000000,
        shader: {
          type: Lightning.shaders.FadeOut,
          bottom: 200,
        },
        Poster: {
          x: 0,
          y: 0,
          w: 1920,
          h: 800,
        },
        Overlay: {
          alpha: 1,
          w: defaultDetailCoordinates.width,
          h: defaultDetailCoordinates.height,
          x: 0,
          scale: 1.02,
          y: 0,
          rect: true,
          colorLeft: Colors(HeroWidgetItemConfig.colorLeft).alpha(1).get(),
          colorRight: Colors(HeroWidgetItemConfig.colorRight)
            .alpha(0.001)
            .get(),
          shader: {
            type: Lightning.shaders.RoundedRectangle,
          },
        },
      },
      Content: {
        x: defaultDetailCoordinates.left,
        y: defaultDetailCoordinates.top,
        w: defaultDetailCoordinates.contentWidth,
        h: defaultDetailCoordinates.contentHeight,
        flex: {
          direction: 'column',
          justifyContent: 'center',
          padding: 20,
        },
        Title: {
          rtt: true,
          color: Colors('white').get(),
          text: {
            text: '',
            fontFace: 'Bold',
            fontSize: 60,
            textAlign: 'left',
            wordWrap: true,
            wordWrapWidth: defaultDetailCoordinates.contentWidth,
            maxLines: 2,
          },
        },
        Subtitle: {
          rtt: true,
          color: Colors('white').get(),
          text: {
            text: '',
            fontFace: 'Bold',
            fontSize: 36,
            textAlign: 'left',
            wordWrap: true,
            wordWrapWidth: defaultDetailCoordinates.contentWidth,
            maxLines: 3,
          },
        },
        Description: {
          rtt: true,
          flexItem: {
            marginTop: 20,
            marginBottom: 20,
          },
          color: Colors('text').get(),
          text: {
            text: '',
            fontFace: 'Text',
            fontSize: 24,
            lineHeight: 36,
            textAlign: 'left',
            wordWrap: true,
            wordWrapWidth: defaultDetailCoordinates.contentWidth,
            maxLines: defaultDetailCoordinates.maxDescriptionLines,
          },
        },
        Release: {
          color: Colors('text').get(),
          text: {
            text: '',
            fontFace: 'Bold',
            fontSize: 18,
            lineHeight: 24,
            textAlign: 'left',
            wordWrap: true,
            wordWrapWidth: defaultDetailCoordinates.contentWidth,
            maxLines: 6,
          },
        },
        Buttons: {
          flexItem: {
            marginTop: 20,
          },
          h: 60,
          type: List,
          direction: 'row',
          spacing: 30,
          w: 1600,
          signals: {
            onIndexChanged: '_indexChanged',
          },
        },
      },
    }
  }

  override _init() {
    this.stage.application.on('menuOpen', (open, duration = 0.2) => {
      if (!this.active) duration = 0
      const value = open ? MainMenu.width : MainMenu.widthClosed
      this.Content.setSmooth('x', value + 160, { duration })
    })
  }

  override _active() {
    const { left } = this.detailCoordinates
    this.Content.patch({ x: left })
  }

  get detailCoordinates() {
    const menuOpen = this.fireAncestors('$menuOpen') === true

    const coords = {
      ...defaultDetailCoordinates,
      left: menuOpen ? MainMenu.width + 160 : MainMenu.widthClosed + 160,
    }
    return coords
  }
  get showAnimation() {
    if (!this._showAnimation) {
      this._showAnimation = this.animation({
        duration: 0.4,
        actions: [
          {
            p: 'alpha',
            v: { 0: 0.0001, 1: 1 },
          },
        ],
      })
      this._showAnimation.on('stopFinish', () => {
        this.signal('removeItem', this.id)
      })
    }
    return this._showAnimation
  }
  showHero() {
    this.showAnimation.start()
  }
  hideHero() {
    this.showAnimation.stop()
  }

  public hideExtraButtons = false
  private _content: ContentItem | null = null
  set content(content: ContentItem) {
    this._content = content
    const imageUrl = this._content.images.wide?.getForWidth(1920)
    if (imageUrl) {
      this.Poster.patch({
        texture: Img(imageUrl).cover(
          this.detailCoordinates.width,
          this.detailCoordinates.height,
        ),
      })
    }

    debug.info('Setting hero widget item', this.detailCoordinates)

    this.Content.patch({
      w: this.detailCoordinates.contentWidth,
      h: this.detailCoordinates.contentHeight,
      Title: {
        text: {
          text: content.title ?? '',
        },
        visible: isGoodString(content.title),
      },
      Subtitle: {
        text: {
          text: content.tagline ?? '',
        },
        visible: isGoodString(content.tagline),
      },
      Description: {
        text: {
          text: content.description ?? '',
        },
        visible: isGoodString(content.description),
      },
      Release: {
        text: {
          text: content.release
            ? format(content.release, 'dddd MMM Do, YYYY').toUpperCase()
            : '',
        },
        visible: isDate(content.release),
      },
    })
    this.setButtons()
  }

  get content() {
    return this._content!
  }

  async setButtons() {
    if (!this.content) return
    if (!this.detailCoordinates.hasButtons) {
      this.Buttons.patch({
        visible: false,
      })
      return
    }
    const inWatchlist = await this.checkWatchlist()
    const parsedMedia = parseMedia(this.content.media)
    if (parsedMedia !== null) {
      const buttons: Record<string, unknown>[] = []
      const { content = null, trailer = null, extras = [] } = parsedMedia
      const playerPath = this.content!.paths.player

      if (content !== null) {
        buttons.push({
          buttonText: content.button_text ?? 'Play',
          type: AdeButton,
          action: {
            action: '$navigate',
            payload: playerPath,
          },
        })
      }

      if (!this.hideExtraButtons) {
        if (trailer !== null) {
          const {
            paths: { player },
          } = trailer
          buttons.push({
            buttonText: 'Trailer',
            type: AdeButton,
            action: {
              action: '$navigate',
              payload: player,
            },
          })
        }

        extras.forEach(extra => {
          const {
            paths: { player },
          } = extra
          buttons.push({
            buttonText: extra.button_text,
            type: AdeButton,
            action: {
              action: '$navigate',
              payload: player,
            },
          })
        })
      }
      buttons.push({
        buttonText: inWatchlist ? 'Unfavorite' : 'Favorite',
        type: AdeButton,
        leftIcon: `${inWatchlist ? 'remove' : 'add'}-circle-outline.svg`,
        action: {
          action: '$addToWatchlist',
          payload: this.content,
        },
      })

      if (buttons.length > 0) {
        this.Buttons.patch({ items: buttons, visible: true })
      } else {
        this.Buttons.patch({ visible: false })
      }
    }
  }

  async checkWatchlist() {
    if (this._inwatchList !== null) return this._inwatchList
    if (this._content) {
      try {
        this._inwatchList = await this.fireAncestors(
          '$checkWatchlist',
          `${this._content.type}:${this._content.id}`,
        )
        return this._inwatchList
      } catch (error) {
        console.info('Error fetching watchlist')
        return false
      }
    }
    return false
  }
  private _inwatchList: boolean | null = null

  set inWatchlist(inWatchlist: boolean | null) {
    this._inwatchList = inWatchlist
    const items = this.Buttons.items
    if (Array.isArray(items)) {
      const watchlistButton = items.find(
        item => item.action?.action === '$addToWatchlist',
      )

      if (watchlistButton === true) {
        if (inWatchlist) {
          watchlistButton.patch({
            buttonText: 'UNFAVORITE',
            leftIcon: 'remove-circle-outline.svg',
          })
        } else {
          watchlistButton.patch({
            buttonText: 'favorite',
            leftIcon: 'add-circle-outline.svg',
          })
        }
      }
    }
  }
  get inWatchlist() {
    return this._inwatchList
  }
  setClosest(coords: CoordinateDimensions) {
    setClosestInList(coords, this.Buttons)
  }
}
export interface HeroWidgetItemTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Image: {
    Poster: object
    Overlay: object
  }
  Content: {
    Title: object
    Subtitle: object
    Description: object
    Release: object
    Buttons: typeof List
    content: ContentItem
    hideExtraButtons: boolean
  }
  content: ContentItem
  detailCoordinates: HeroContentSetup
}
export interface HeroWidgetItemTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: {
    removeItem(id: ID): void
  }
}
