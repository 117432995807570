import { ContentSource, SlowPlayerState } from '@adiffengine/engine-types'
import { Lightning, VideoPlayer } from '@lightningjs/sdk'
import isEqual from 'fast-deep-equal/es6'
import { Debugger, getExtension, isGoodNumber } from '../../lib'
import { Playlist } from '../../lib/Playlist'
import { LoaderLookup, PlayerPlaneTemplateSpec } from './PlayerPlane'
import { hlsLoader, unloader } from './lib/hls'
import { PlayerEventPayload } from './lib/playerEvents'
const debug = new Debugger('AudioPlane')

const visualLoader = async (url: string, videoEl: HTMLVideoElement) => {
  videoEl.setAttribute('crossOrigin', 'anonymous')
  videoEl.src = url
}

function getLoader(src: string): LoaderLookup | null {
  const extension = getExtension(src)

  const loader = hlsLoader({})
  if (!extension) return { loader, unloader }
  switch (extension) {
    case 'm3u8':
      return { loader, unloader }
    case 'mp3':
      return { loader: visualLoader }
    default:
      return null
  }
}
export class AudioPlayerPlane extends Lightning.Component<PlayerPlaneTemplateSpec> {
  private _audioSrc: string | null = null
  static override _template() {
    return {
      w: 1,
      h: 1,
      x: 1,
      y: 1,
    }
  }

  _playlist: Playlist | null = null
  setPlaylist(playlist: Playlist, play = true) {
    debug.info('Got Playlist ', playlist)
    this._playlist = playlist
    if (play) {
      this.nextItem()
    }
  }
  nextItem() {
    if (this._playlist) {
      return this._playlist.next().then(next => {
        if (next) {
          const src = Playlist.getSourceFromItem(next) as ContentSource
          this.audioSrc = src.src
          this.stage.application.emit('currentPlaybackItem', next)
          return next
        } else {
          this.stage.application.emit('playlistEnded')
          VideoPlayer.reload()
          return null
        }
      })
    } else {
      VideoPlayer.reload()
      return null
    }
  }
  previousItem() {
    if (this._playlist) {
      return this._playlist.previous().then(next => {
        if (next) {
          const src = Playlist.getSourceFromItem(next) as ContentSource
          this.audioSrc = src.src
          this.stage.application.emit('currentPlaybackItem', next)
          return next
        } else {
          this.stage.application.emit('playlistEnded')
          VideoPlayer.reload()
          return null
        }
      })
    } else {
      VideoPlayer.reload()
      return null
    }
  }
  static defaultPlayerState: SlowPlayerState = {
    paused: true,
    duration: 0,
    canPlay: false,
    ended: false,
    error: null,
    content: null,
    adActive: false,
    controlsHidden: false,
  }
  private _playerState: SlowPlayerState = AudioPlayerPlane.defaultPlayerState

  set playerState(state: Partial<SlowPlayerState>) {
    const update = { ...this._playerState, ...state }
    debug.info('Player state update', state, update, this._playerState)
    if (!isEqual(update, this._playerState)) {
      this._playerState = update
      this.stage.application.emit('playerState', this.playerState)
    }
  }

  get playerState(): SlowPlayerState {
    return this._playerState
  }

  override _firstActive() {
    VideoPlayer.consumer(this)
  }

  $videoPlayerError(e: MediaError) {
    console.warn('Video Player Error %s', e.message)
    console.warn(e)
    this.playerState = { error: e }
    this.nextItem()
  }
  $videoPlayerEnded() {
    this.playerState = { ended: true }
    this.nextItem()
  }
  $videoPlayerTimeUpdate({ videoElement }: PlayerEventPayload) {
    /*eslint-env es6*/
    let { duration, currentTime } = videoElement
    duration = isGoodNumber(duration) ? duration : Infinity
    this.stage.application.emit('playerTime', currentTime, duration)
    if (this.playerState.duration !== videoElement.duration) {
      this.playerState = {
        duration: VideoPlayer.duration,
      }
    }
  }
  $videoPlayerPlaying({ videoElement }: PlayerEventPayload) {
    this.playerState = { paused: videoElement.paused }
  }
  $videoPlayerPlay({ videoElement }: PlayerEventPayload) {
    this.playerState = { paused: videoElement.paused }
  }
  $videoPlayerPause({ videoElement }: PlayerEventPayload) {
    this.playerState = { paused: videoElement.paused }
  }
  $videoPlayerCanPlay({ videoElement }: PlayerEventPayload) {
    this.playerState = {
      canPlay: true,
      duration: VideoPlayer.duration,
      paused: videoElement.paused,
    }
  }
  // $videoPlayerEvent(event: EventTypes, eventData?: PlayerEventPayload) {
  //   if (eventData) {
  //     const { videoElement } = eventData
  //     const current: Partial<SlowPlayerState> = {
  //       paused: videoElement.paused,
  //       duration: isNumber(videoElement.duration)
  //         ? videoElement.duration
  //         : Infinity,
  //       error: videoElement.error ?? null,
  //     }
  //     this.updateState(current)
  //   }

  //     case 'CanPlay':
  //       this.updateState({ canPlay: true })
  //       this.startTimer()
  //       break
  //     case 'Pause':
  //       this.clearTimer()
  //       this.showUi()
  //       break
  //     case 'Play':
  //       if (this._playerState.canPlay) {
  //         this.startTimer()
  //       }
  //       break
  //   }
  //   this.trackEvent(event, eventData)
  // }

  set audioSrc(src: string | null) {
    if (src && src !== VideoPlayer.src) {
      this._audioSrc = src
      this._startAudio()
    }
  }

  get videoSrc() {
    return this._audioSrc
  }
  override _enable() {
    VideoPlayer.position(0, 0)
    VideoPlayer.size(this.w, this.h)
  }

  async _startAudio() {
    if (this._audioSrc) {
      const loaders = getLoader(this._audioSrc)
      VideoPlayer.close()
      if (loaders !== null) {
        const { loader, unloader = null } = loaders
        VideoPlayer.loader(loader, { debug: true })
        if (unloader) VideoPlayer.unloader(unloader)
      }
      const playerSettings = {
        debug: false,
      }
      VideoPlayer.open(this.videoSrc, playerSettings)
    }
  }
}
