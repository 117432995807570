import {
  ContentItem,
  ContentSource,
  MediaDetails,
} from '@adiffengine/engine-types'
import { Debugger } from './debugger'
import { isGoodArray } from './utils'

const debug = new Debugger('Playlist')

export interface PlaylistConstructor {
  new (playist?: ContentItem[]): IPlaylist
}
export interface IPlaylist {
  setFetcher(fetcher: PlaylistNextFetcher, offset?: number): void
  setPlaylist(items: ContentItem[]): void
  fetchMore(): void
  currentItem: ContentItem
  next(): Promise<ContentItem | null>
  previous(): Promise<ContentItem | null>
  nextSrc(): Promise<string | null>
}

export type PlaylistNextFetcher = (index?: number) => Promise<ContentItem[]>

export class Playlist implements IPlaylist {
  private _playlist: ContentItem[] = []
  private _fetcher: PlaylistNextFetcher | undefined = undefined
  private _moreToFetch = false
  private _fetcherOffset = 2
  private _index: number = -1

  constructor(playlist?: ContentItem[]) {
    if (isGoodArray(playlist)) {
      this.setPlaylist(playlist)
    }
  }

  setFetcher(fetcher: PlaylistNextFetcher, offset = 2) {
    this._fetcher = fetcher
    this._moreToFetch = false
    this._fetcherOffset = offset
  }

  setPlaylist(items: ContentItem[]) {
    if (isGoodArray(items)) {
      const playlist: ContentItem[] = []
      items.forEach(item => {
        const src = Playlist.getSourceFromItem(item)
        if (src) playlist.push(item)
        else {
          console.warn(
            'Could not get playable for playlist item %s',
            item.title,
            item,
          )
        }
      })
      this._playlist = playlist
      this._index = -1
      debug.info('Set Playlist', this._playlist, items)
    }
  }
  private _fetching = false
  async fetchMore() {
    if (this._moreToFetch && !this._fetching && this._fetcher) {
      try {
        this._fetching = true
        const more = await this._fetcher(this._index)
        this._fetching = false
        if (isGoodArray(more) && more.length > 0) {
          this._playlist = this._playlist.concat(more)
        } else {
          this._moreToFetch = false
        }
      } catch (error) {
        console.warn('Fetching more items failed')
      }
    }
  }
  private _maybeFetchMore() {
    if (
      this._fetcher &&
      this._index === this._playlist.length - this._fetcherOffset
    ) {
      this.fetchMore()
    }
  }
  async next(): Promise<ContentItem | null> {
    if (
      this._index < this._playlist.length - 1 &&
      this._playlist[this._index + 1]
    ) {
      this._index++
      this._maybeFetchMore()
      return this._playlist[this._index]
    }
    return null
  }
  get currentItem() {
    return this._playlist[this._index]
  }
  static getSourceFromItem(item: ContentItem): ContentSource | null {
    const { media = null } = item
    if (isGoodArray<MediaDetails>(media)) {
      const episode = media.find(m => m.type === 'episode')
      const src =
        episode && isGoodArray(episode.sources)
          ? episode.sources.find(s => s.type === 'mp3')
          : null
      return src as ContentSource
    }
    return null
  }

  async nextSrc(): Promise<string | null> {
    const next = await this.next()
    if (next) {
      const source = Playlist.getSourceFromItem(next)
      return (source as ContentSource).src as string // Note we validate these coming in.
    } else {
      return null
    }
  }
  get items() {
    return [...this._playlist]
  }
  async previous() {
    // Doesn't need to be async, but this way it aligns with the async next (which does need to.)
    if (this._index > 0 && this._playlist[this._index - 1]) {
      this._index--
      return this._playlist[this._index]
    }
    return null
  }
}
