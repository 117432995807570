import { Lightning, Registry } from '@lightningjs/sdk'
import { HoverZone } from '../../helpers/HoverZone'
import { AdvancedGridListProgress } from './AdvancedGridListProgress'
import { AdvancedGridPageClicker } from './AdvancedGridPageClicker'
import { Debugger } from '../../../lib'
import equal from 'fast-deep-equal/es6'
import { PointerState } from '@adiffengine/engine-types'
const debug = new Debugger('AdvancedGridMousePager')
interface PageSetter {
  current: number
  total: number
}
export interface AdvancedGridMousePagerTemplateSpec
  extends Lightning.Component.TemplateSpec {
  HoverZone: typeof HoverZone
  Progress: typeof AdvancedGridListProgress
  PageForward: typeof AdvancedGridPageClicker
  PageBack: typeof AdvancedGridPageClicker
  Page: object
  progressWidth: number
  progressPercent: number
  width: number
  page: PageSetter
  focusable: boolean
}

export interface AdvancedGridMousePagerTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: {
    pageForward(): void
    pageBack(): void
    hovered(): void
    unhovered(): void
  }
}
export class AdvancedGridMousePager
  extends Lightning.Component<
    AdvancedGridMousePagerTemplateSpec,
    AdvancedGridMousePagerTypeConfig
  >
  implements
    Lightning.Component
      .ImplementTemplateSpec<AdvancedGridMousePagerTemplateSpec>
{
  HoverZone = this.getByRef('HoverZone')!
  Progress = this.getByRef('Progress')
  PageForward = this.getByRef('PageForward')!
  PageBack = this.getByRef('PageBack')!
  Page = this.getByRef('Page')
  static width = 400
  static override _template(): Lightning.Component.Template<AdvancedGridMousePagerTemplateSpec> {
    return {
      alpha: 0.0001,
      zIndex: 10,
      w: 400,
      Page: {
        x: 50,
        mountY: 0.5,
        w: 200,
        y: 34,
        text: {
          text: 'Page 1 of 3',
          textAlign: 'center',
          wordWrapWidth: 200,
          fontFace: 'Bold',
          fontSize: 24,
        },
      },

      PageForward: {
        x: 300,
        y: 30,
        mountX: 1,
        mountY: 0.5,
        type: AdvancedGridPageClicker,
        name: 'forward',
        direction: 'right',
        signals: {
          clicked: '_pageForward',
          hovered: '_handleHover',
          unhovered: '_handleUnhover',
        },
      },
      PageBack: {
        x: 0,
        y: 30,
        mountY: 0.5,
        type: AdvancedGridPageClicker,
        direction: 'left',
        name: 'back',
        signals: {
          clicked: '_pageBack',
          hovered: '_handleHover',
          unhovered: '_handleUnhover',
        },
      },
    }
  }
  private _focusable: boolean = true
  public get focusable(): boolean {
    return this._focusable
  }
  public set focusable(value: boolean) {
    if (value !== this._focusable) {
      this._focusable = value
    }
  }
  get canHaveFocus() {
    return this.focusable && this.page.total > 1
  }
  private _page: PageSetter = {
    current: 0,
    total: 0,
  }
  set page(page: PageSetter) {
    if (!equal(page, this._page)) {
      this._page = page
      debug.info(
        'Page? ',
        page.current === 1,
        page.current === page.total,
        page,
      )
      if (page.total === 1) {
        this._activePaging = false
        this.patch({
          alpha: 0.00001,
          PageForward: {
            disabled: true,
          },
          PageBack: {
            disabled: true,
          },
        })
      } else {
        this._activePaging = true
        this.patch({
          alpha: 1,
        })
      }

      if (page.current <= 1 && page.total <= 1) {
        this.Page?.patch({ text: '' })
      } else {
        this.Page?.patch({
          text: `Page ${page.current} of ${page.total}`,
        })
      }
      this.enableButtons()
    }
  }
  get page() {
    return this._page
  }
  set width(width: number) {
    this.patch({ w: width, Progress: { width: width - 240 } })
    this.patchSizes()
  }

  private _progressWidth = 0
  set progressWidth(width: number) {
    if (this._progressWidth !== width) {
      this._progressWidth = width
      this.Progress?.patch({ progressWidth: width })
    }
  }
  set progressPercent(progress: number) {
    debug.info('Progress Percent %s', progress)
    this.Progress?.patch({ progress })
  }

  _pageForward() {
    debug.info('Page forward clicked')
    this.signal('pageForward')
  }
  _pageBack() {
    debug.info('Page Back Clicked')
    this.signal('pageBack')
  }
  clearTimeout() {
    if (this._hoverTimeout !== null) {
      Registry.clearTimeout(this._hoverTimeout)
      this._hoverTimeout = null
    }
    this._hoverTimeout = null
  }
  private _hovered = false

  set hovered(hovered: boolean) {
    if (hovered !== this._hovered && this.canHaveFocus) {
      this._hovered = hovered
      this.signal(hovered ? 'hovered' : 'unhovered')
    }
  }

  get backDisabled() {
    return this.page.current <= 1
  }
  get forwardDisabled() {
    return this.page.current >= this.page.total
  }
  enableForwardIfCan() {
    this.PageForward.patch({
      disabled: this.forwardDisabled,
    })
  }
  enableBackIfCan() {
    this.PageBack.patch({
      disabled: this.backDisabled,
    })
  }
  enableButtons() {
    this.enableBackIfCan()
    this.enableForwardIfCan()
  }
  disableButtons() {
    this.PageBack.patch({
      disabled: true,
    })
    this.PageForward.patch({
      disabled: true,
    })
  }
  get hovered() {
    return this._hovered
  }

  private _hoverTimeout: ReturnType<typeof Registry.setTimeout> | null = null
  override _handleHover() {
    this.clearTimeout()

    this.hovered = true
  }
  private _showAnimationInstance: Lightning.types.Animation | null = null
  get _showAnimation() {
    if (this._showAnimationInstance === null) {
      this._showAnimationInstance = this.animation({
        duration: 0.4,
        actions: [
          {
            p: 'alpha',
            v: { 0: 0.0001, 1: 1 },
          },
        ],
      })
      this._showAnimation.on('start', () => {
        this.enableButtons()
      })
      this._showAnimation.on('stop', () => {
        debug.info('Show Animation Stop')
        this.disableButtons()
      })
    }
    return this._showAnimationInstance
  }

  _activePaging = false
  _pointerActive() {
    if (this.canHaveFocus) {
      this._showAnimation.start()
    }
  }
  _pointerInactive() {
    if (this.canHaveFocus) {
      this._showAnimation.stop()
    }
  }
  _handlePointerState(state: PointerState) {
    if (state === 'active') this._pointerActive()
    else this._pointerInactive()
  }
  override _construct() {
    this._handlePointerState = this._handlePointerState.bind(this)
  }

  override _inactive() {
    this.stage.application.off('pointerState', this._handlePointerState)
  }

  _handleUnhover() {
    this._hoverTimeout = Registry.setTimeout(() => {
      this.hovered = false
    }, 200)
  }

  patchSizes() {
    this.Page?.patch({
      y: this.h / 2,
      mountY: 0.5,
    })
    this.PageBack.patch({
      y: this.h / 2,
    })
    this.PageForward.patch({
      y: this.h / 2,
    })
  }

  override _active() {
    const pointerActive = this.fireAncestors('$pointerState')
    if (pointerActive) {
      this._pointerActive()
    }
    this.stage.application.on('pointerState', this._handlePointerState)
    this.patchSizes()
  }
}
