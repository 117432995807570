import { HoverComponentSignalMap } from '@adiffengine/engine-types'
import { Colors, Lightning, Settings } from '@lightningjs/sdk'
import {
  Debugger,
  confirmCollisionsAreSet,
  getCoordinateDimensions,
} from '../../lib'
const debug = new Debugger('HoverZone')
// const debug = new Debugger('HoverZone')
export interface HoverZoneTemplateSpec
  extends Lightning.Component.TemplateSpec {
  debug: boolean
}
export interface HoverZoneTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: HoverComponentSignalMap
}
export class HoverZone
  extends Lightning.Component<HoverZoneTemplateSpec, HoverZoneTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<HoverZoneTemplateSpec>
{
  static override _template(): Lightning.Component.Template<HoverZoneTemplateSpec> {
    return {
      collision: true,
      rect: true,
      color: 0x00000000,
    }
  }
  override _init() {
    const enablePointer = Settings.get('app', 'enablePointer', false)
    if (enablePointer) {
      confirmCollisionsAreSet(this)
    } else {
      this.patch({
        visible: false,
      })
    }
  }

  private _debug = false

  set debug(debug: boolean) {
    if (this._debug !== debug) {
      this._debug = debug
      this.patch({
        color: debug ? Colors('background').alpha(0.5).get() : 0x00000000,
      })
    }
  }
  get debug() {
    return this._debug
  }

  override _handleHover() {
    debug.info('Handle Hover over zone', this)
    if (this.debug) {
      this.setSmooth('color', Colors('primaryHighlight').alpha(0.6).get())
    }
    return this.signal('hovered', getCoordinateDimensions(this))
  }
  _handleUnhover() {
    if (this.debug) {
      this.setSmooth('color', Colors('background').alpha(0.3).get())
    }
    return this.signal('unhovered', getCoordinateDimensions(this))
  }
  _handleClick() {
    debug.info('zone clicked')
    return this.signal('clicked', getCoordinateDimensions(this))
  }
}
