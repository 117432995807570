export const envSettings = {
  appSettings: {
    debug: false,
    APP_MUX_ENV_KEY: 'abi38lce0k2m5r5tledc8c6f5',
    environment: 'production',
  },
  platformSettings: {
    log: false,
    showVersion: false,
  },
}
