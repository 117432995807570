export type Resolver<T extends any = any> = (value: T | PromiseLike<T>) => void
/* eslint-disable-next-line @typescript-eslint/no-explicit-any*/
export function isString(val: any): val is string {
  return typeof val === 'string' || val instanceof String
}
/* eslint-disable-next-line @typescript-eslint/no-explicit-any*/
export function isNumberId(val: any): val is number {
  return Number.isInteger(val)
}
export function isNumber(val: any): val is number {
  return !isNaN(val) && typeof val === 'number'
}
export function isFunction(value: any) {
  return typeof value === 'function'
}
export type FilterNotStartingWith<
  Set,
  Needle extends string,
> = Set extends `${Needle}${infer _X}` ? never : Set
export type PublicRecordSet<T> = Pick<T, FilterNotStartingWith<keyof T, '_'>>
