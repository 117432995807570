import {
  ContentSource,
  ID,
  RadioStation,
  Track,
} from '@adiffengine/engine-types'
import {
  Colors,
  Lightning,
  Router,
  Settings,
  VideoPlayer,
} from '@lightningjs/sdk'
import isString from 'lodash/isString'
import { AdeButton } from '../components'
import { BlurImage } from '../components/AdeBlurImage'
import { AdeStack } from '../components/AdeStack'
import { AudioPlayerControls } from '../components/audioPlayer/AudioPlayerControls'
import {
  AdePlayerPlane,
  EventTypes,
  PlayerEventPayload,
} from '../components/player'
import { CurrentlyPlayingSong } from '../components/player/CurrentlyPlayingSong'
import { Visualizer } from '../components/player/Visualization'
import { Debugger, isGoodArray, spacePause } from '../lib'
import { getStoredTheme } from '../themes'
import { PlayerPage } from './PlayerPage'

const debug = new Debugger('AdeRadioHomeScreen')
debug.enabled = false
const messageDuration = 3
export interface AdeRadioHomeTypeConfig extends Lightning.Component.TypeConfig {
  IsPage: true
}

export interface AdeRadioHomeTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Content: object
  Visualizer: typeof Visualizer
  Background: object
  SongBackground: {
    Image: typeof BlurImage
    Overlay: object
  }
  PlayerPlane: typeof AdePlayerPlane
  StationMenu: {
    Vignette: object
    MainMenuTitle: object
    MainMenu: typeof AdeStack
    MainMenuDescription: object
  }
  StationControls: {
    PlayerControls: typeof AudioPlayerControls
  }
  CurrentlyPlaying: typeof CurrentlyPlayingSong
  ActionMessage: object
}

export class AdeRadioHomeScreen
  extends PlayerPage<AdeRadioHomeTemplateSpec, AdeRadioHomeTypeConfig>
  implements
    Lightning.Component.ImplementTemplateSpec<AdeRadioHomeTemplateSpec>
{
  public IsPage = true
  StationMenu = this.tag('StationMenu')!
  MainMenu = this.StationMenu.tag('MainMenu')
  static override _template(): Lightning.Component.Template<AdeRadioHomeTemplateSpec> {
    const theme = getStoredTheme()
    return {
      x: 0,
      y: 0,
      w: 1920,
      h: 1080,
      color: 0x00000000,
      PlayerPlane: {
        type: AdePlayerPlane,
        x: 0,
        y: 0,
        h: 1,
        w: 1,
      },

      Visualizer: {
        type: Visualizer,
        x: 0,
        y: 0,
        w: 1920,
        h: 1080,
      },

      StationMenu: {
        y: 0,
        x: 0,
        w: 1920,
        h: 300,
        Vignette: {
          y: 0,
          x: 0,
          w: 1920,
          h: 400,
          rect: true,
          rtt: true,
          colorBottom: Colors('black').alpha(0).get(),
          colorTop: Colors('black').alpha(0.8).get(),
        },
        MainMenuTitle: {
          y: 80,
          x: 0,
          w: 1920,
          h: 54,
          text: {
            text: 'SELECT A STATION',
            fontFace: 'Bold',
            verticalAlign: 'middle',
            textAlign: 'center',
            fontSize: 48,
            lineHeight: 54,
          },
        },

        MainMenu: {
          type: AdeStack,
          itemType: AdeButton,
          x: 80,
          y: 200,
          w: 1920 - 160,
          h: 60,
          stackConfig: {
            direction: 'row',
            spacing: 12,
            alignItems: 'center',
            justifyContent: 'center',
          },
          signals: {
            down: '_menuDown',
            focus: '_stationFocus',
            unfocus: '_stationUnfocus',
          },
        },
        MainMenuDescription: {
          y: 140,
          x: 0,
          w: 1920,
          h: 40,
          text: {
            text: '',
            fontFace: 'Text',
            verticalAlign: 'middle',
            textAlign: 'center',
            fontSize: 24,
            lineHeight: 40,
          },
        },
      },
      CurrentlyPlaying: {
        alpha: 0,
        x: (1920 - CurrentlyPlayingSong.horizontalWidth) / 2,
        y: 180 + 60 + 80,
        w: CurrentlyPlayingSong.horizontalWidth,
        type: CurrentlyPlayingSong,
        hasControls: false,
        direction: 'horizontal',
        signals: {
          up: '_currentlyPlayingUp',
        },
      },

      StationControls: {
        alpha: 0,
        y: 846,
        w: 400,
        h: 80,
        x: 1520 / 2,
        rect: true,
        rtt: true,
        color: Colors(theme.palette.backgroundGradient).alpha(0.8).get(),
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 40,
          fillColor: Colors('white').get(),
        },
        flex: {
          alignItems: 'center',
          justifyContent: 'center',
          direction: 'row',
        },
        PlayerControls: {
          type: AudioPlayerControls,
          useDefaults: true,
          signals: {
            up: '_currentlyPlayingUp',
            like: '_like',
            dislike: '_dislike',
            previous: '_previous',
            next: '_next',
          },
        },
      },

      ActionMessage: {
        y: 946,
        h: 40,
        w: 1000,
        x: 460,
        color: Colors(theme.palette.highlights[300]).get(),
        text: {
          text: '',
          fontFace: 'Regular',
          fontSize: 24,
          textAlign: 'center',
        },
      },
    }
  }

  _actionMessageAnimation: Lightning.types.Animation | undefined = undefined
  set actionMessage(text: string) {
    this._actionMessageAnimation?.finish()
    const step = 0.2 / messageDuration
    this.getByRef('ActionMessage')?.patch({
      alpha: 0,
      text: {
        text,
      },
    })
    this._actionMessageAnimation = this.animation({
      duration: messageDuration,
      actions: [
        {
          t: 'ActionMessage',
          p: 'alpha',
          v: { 0: 0, [step]: 1, [1 - step]: 1, 1: 0 },
        },
      ],
    })
    this._actionMessageAnimation.start()
  }
  async _like() {
    if (this._currentTrack !== null) {
      await this.fireAncestors('$like', {
        action: 'like',
        payload: this._currentTrack.id,
      })
      this.actionMessage = 'We got it... You dig this song!'
    }
  }

  async _dislike() {
    if (this._currentTrack !== null) {
      await this.fireAncestors('$like', {
        action: 'dislike',
        payload: this._currentTrack.id,
      })
      this.actionMessage = "Got it... You aren't digging this song!"
    }
  }
  async _next() {
    if (this.station != null && this._currentTrack != null) {
      const response = await this.fireAncestors('$next', {
        listId: this.station.id,
        trackId: this._currentTrack.id,
      })
      if (response === false) {
        this.actionMessage = 'Unable to skip song'
      } else if (response !== null) {
        this.$playTrack(response)
      } else {
        this.actionMessage = 'No more songs to play.'
        VideoPlayer.pause()
      }
    }
  }

  $currentTrack(track: Track): void
  $currentTrack(): Track | null
  $currentTrack(track?: Track): Track | void | null {
    if (track) {
      return (this._currentTrack = track)
    } else return this._currentTrack ?? null
  }

  override _captureKey(e: KeyboardEvent) {
    spacePause(e)
    return false
  }
  _currentTrack: Track | null = null
  _startTracked: ID | undefined = undefined
  $playTrack(track: Track) {
    this._currentTrack = track
    const { sources } = track
    if (isGoodArray<ContentSource>(sources)) {
      const videoSrc = sources[0].src
      this._startTracked = track.id
      this.getByRef('PlayerPlane')?.patch({ videoSrc })
    }
  }
  _currentStation: RadioStation | null = null
  set station(station: typeof this._currentStation) {
    this._currentStation = station
  }
  get station() {
    return this._currentStation
  }

  _song: Track | null = null
  set track(track: Track | null) {
    if (track != null) {
      const patch: Record<string, unknown> = { track: track }
      if (this.station) patch['container'] = this.station.title
      this.tag('CurrentlyPlaying')?.patch(patch)
      this.getByRef('CurrentlyPlaying')?.setSmooth('alpha', 1, {
        duration: 0.2,
      })
      this.getByRef('StationControls')?.setSmooth('alpha', 1, { duration: 0.2 })
      this._song = track
      this.$playTrack(track)
    }
    this._song = track
  }
  get track() {
    return this._song
  }

  $playStation(station: RadioStation) {
    this.station = station
    const response = this.fireAncestors('$song', station.id).then()
    if (response) {
      response.then(track => {
        debug.info('Track', track, station)
        const defaultPaper =
          station.images.wallpaper ??
          station.images.wide ??
          station.images.thumb
        if (!track.images.wallpaper && defaultPaper) {
          track.images.wallpaper = defaultPaper
        }
        this.track = track
      })
    }
  }
  _blockTimeUpdate = false
  override $videoPlayerEvent(
    event: EventTypes,
    eventData?: PlayerEventPayload,
  ) {
    super.$videoPlayerEvent(event, eventData)
    switch (event) {
      case 'Play':
        if (eventData) {
          this.getByRef('Visualizer')?.patch({
            videoElm: eventData.videoElement,
          })
        }
        break
      case 'TimeUpdate':
        if (!this.track || !isString(this.track.id) || !eventData) return
        if (!isString(this._startTracked) && !this._blockTimeUpdate) {
          this.fireAncestors('$trackMediaEvent', {
            event: 'elapse',
            payload: {
              id: this.track.id,
              seconds: eventData.videoElement.currentTime,
            },
          })
        } else if (!this._blockTimeUpdate) {
          this._blockTimeUpdate = true
          this.fireAncestors('$trackMediaEvent', {
            event: 'start',
            payload: this._startTracked,
          }).then(() => {
            this._startTracked = undefined
            if (this.track) {
              this.fireAncestors('$trackMediaEvent', {
                event: 'elapse',
                payload: {
                  id: this.track.id,
                  seconds: eventData.videoElement.currentTime,
                },
              })
            }
            this._blockTimeUpdate = false
          })
        }
        break
      case 'Ended':
        if (this.track) {
          this.fireAncestors('$trackMediaEvent', {
            event: 'complete',
            payload: this.track.id,
          }).then(() => {
            if (this.track && this.station) {
              this.fireAncestors('$next', {
                done: true,
                trackId: this.track.id,
                listId: this.station.id,
              }).then(track => {
                if (track === false) {
                  console.warn('Unable to get next track')
                } else if (track === null) {
                  this.listDone()
                } else {
                  this.$playTrack(track)
                }
              })
            }
          })
        }
    }
  }
  listDone() {
    debug.info('Done here....')
  }

  _stations: RadioStation[] = []
  set stations(stations: RadioStation[]) {
    const items = stations.map(station => ({
      buttonText: station.title,
      action: {
        action: '$playStation',
        payload: station,
      },
    }))

    this.MainMenu?.patch({
      items,
    })
    this._stations = stations
  }

  get stations() {
    return this._stations
  }

  override _init() {
    this._setState('MenuFocus')
  }

  checkMute() {
    const history = Router.getHistory()
    const browser = Settings.get('user', 'browser')
    console.info('Mute check', browser, history.length)
    if (history.length === 0 && browser === true) this.mute()
  }

  static override _states() {
    return [
      class MenuFocus extends this {
        override $enter() {
          this.tag('StationMenu.MainMenuDescription')?.setSmooth('alpha', 1, {
            duration: 0.2,
          })
          this._refocus()
        }
        override $exit() {
          this.tag('StationMenu.MainMenuDescription')?.setSmooth('alpha', 0, {
            duration: 0.2,
          })
        }
        override _getFocused() {
          return this.tag('StationMenu.MainMenu')
        }

        _menuDown() {
          if (this.track) {
            this._setState('PlayFocus')
          }
        }
        _stationFocus(index: number) {
          const station = this.stations[index]
          if (isString(station?.description)) {
            this.tag('StationMenu.MainMenuDescription')?.patch({
              text: {
                text: `${station.title} - ${station.description}`,
              },
            })
          }
        }
        _stationUnfocus() {
          this.tag('StationMenu.MainMenuDescription')?.patch({
            text: {
              text: '',
            },
          })
        }
      },
      class PlayFocus extends this {
        override $enter() {
          this._refocus()
        }
        override _getFocused() {
          return this.tag('StationControls.PlayerControls')
        }
        _currentlyPlayingUp() {
          this._setState('MenuFocus')
        }
      },
    ]
  }
}
