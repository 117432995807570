import { DirectionalSignalMap } from '@adiffengine/engine-types'
import { Colors, Lightning } from '@lightningjs/sdk'
import { Debugger, getCoordinateDimensions } from '../lib'

const debug = new Debugger('Toggle.ts')
export class Toggle
  extends Lightning.Component<ToggleTemplateSpec, ToggleTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<ToggleTemplateSpec>
{
  private _settingState: 'on' | 'off' = 'off'
  set settingState(state: typeof this._settingState) {
    if (state !== this._settingState) {
      this._settingState = state
      this._setState(state === 'on' ? 'OnState' : 'OffState')
    }
  }
  static width = 40 * 3
  static height = 40

  static override _template(): Lightning.Component.Template<ToggleTemplateSpec> {
    return {
      h: Toggle.height,
      w: Toggle.height * 3.25,
      rect: true,
      rtt: true,
      shader: {
        type: Lightning.shaders.RoundedRectangle,
        radius: 18,
      },
      LabelWrapper: {
        x: 0,
        y: 0,
        h: Toggle.height,
        w: Toggle.height * 3.25,
        flex: {
          alignItems: 'center',
          justifyContent: 'center',
        },
        Label: {
          flexItem: {
            marginTop: 2,
          },
          color: Colors('white').get(),
          text: {
            fontFace: 'Bold',
            fontSize: 16,
            text: 'OFF',
          },
        },
      },
      Toggle: {
        h: Toggle.height,
        w: Toggle.height,
        x: 0,
        y: 0,
        rect: true,
        rtt: true,
        clipping: true,
        color: Colors('white').get(),
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 18,
          fillColor: Colors('white').get(),
        },
        ToggleBack: {
          h: Toggle.height - 4,
          w: Toggle.height - 4,
          x: 2,
          y: 2,
          color: 0x00000000,
          rect: true,
          rtt: true,
          shader: {
            type: Lightning.shaders.RadialGradient,
            radius: (Toggle.height - 4) / 2,
            pivot: 0.5,
            outerColor: Colors('black').alpha(0).get(),
            innerColor: Colors('black').alpha(0.3).get(),
          },
        },
        ToggleCover: {
          h: Toggle.height - 1,
          w: Toggle.height - 1,
          x: 0,
          y: 0,
          color: 0x00000000,
          rect: true,
          rtt: true,
          shader: {
            shader: {
              type: Lightning.shaders.RoundedRectangle,
              radius: (Toggle.height - 1) / 2,
            },
            stroke: 1,
            strokeColor: Colors('black').alpha(0.5).get(),
          },
        },
      },
    }
  }
  themeChanged() {
    debug.info('Theme Changed', this)
    this.themeable()
  }
  override _enable() {
    this._setState(this._settingState.toUpperCase())
  }

  themeable() {
    const theme = this.fireAncestors('$theme')
    if (theme) {
      this.patch({
        LabelWrapper: {
          Label: {
            color: Colors(theme.palette.text).alpha(0.7).get(),
          },
        },
        color: Colors(theme.palette.darks[600]).get(),
      })
      this._focusAnimation = this.animation({
        duration: 0.2,
        actions: [
          {
            p: 'color',
            v: {
              0: Colors(theme.palette.darks[600]).get(),
              1: Colors(theme.palette.highlights[500]).get(),
            },
          },
          {
            t: 'LabelWrapper.Label',
            p: 'color',
            v: {
              0: Colors(theme.palette.text).get(),
              1: Colors(theme.palette.darks[600]).get(),
            },
          },
        ],
      })
      this._onAnimation = this.animation({
        duration: 0.2,
        actions: [
          {
            t: 'Toggle',
            p: 'x',
            v: { 0: 0, 1: Toggle.height * 3.25 - Toggle.height },
          },
        ],
      })
    }
  }

  _signalDirection(dir: 'up' | 'down' | 'right' | 'left') {
    const coords = getCoordinateDimensions(this)
    return this.signal(dir, coords)
  }
  override _handleRight() {
    return this._signalDirection('right')
  }
  override _handleLeft() {
    return this._signalDirection('left')
  }
  override _handleUp() {
    return this._signalDirection('up')
  }
  override _handleDown() {
    return this._signalDirection('down')
  }
  override _focus() {
    this._focusAnimation?.start()
  }
  override _unfocus() {
    this._focusAnimation?.stop()
  }
  private _focusAnimation: Lightning.types.Animation | null = null
  private _onAnimation: Lightning.types.Animation | null = null

  static override _states() {
    return [
      class OffState extends this {
        override $enter() {
          const theme = this.fireAncestors('$theme')
          if (theme) {
            this.patch({
              LabelWrapper: {
                Label: {
                  color: Colors(theme.palette.darks[50]).get(),
                  text: {
                    text: 'OFF',
                  },
                },
              },
              color: Colors(theme.palette.darks[600]).get(),
            })
          }
          this._onAnimation?.stop()
        }
      } as any,
      class OnState extends this {
        override $enter() {
          const theme = this.fireAncestors('$theme')
          if (theme) {
            this.patch({
              LabelWrapper: {
                Label: {
                  color: Colors(theme.palette.buttonHighlightText).get(),
                  text: {
                    text: 'ON',
                  },
                },
              },
              color: Colors(theme.palette.highlights[700]).get(),
            })
          }
          this._onAnimation?.start()
        }

        override $exit() {
          const theme = this.fireAncestors('$theme')
          if (theme) {
            this.patch({
              LabelWrapper: {
                Label: {
                  color: Colors(theme.palette.text).get(),
                  text: {
                    text: 'OFF',
                  },
                },
              },
              color: Colors(theme.palette.darks[600]).get(),
            })
          }
          this._onAnimation?.stop()
        }
      },
    ]
  }
}
export interface ToggleTemplateSpec extends Lightning.Component.TemplateSpec {
  LabelWrapper: {
    Label: object
  }
  Toggle: {
    ToggleBack: object
    ToggleCover: object
  }
  settingState: 'on' | 'off'
}
export interface ToggleSignalMap extends DirectionalSignalMap {
  toggle(state: 'on' | 'off'): void
}
export interface ToggleTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: ToggleSignalMap
}
