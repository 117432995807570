import { Lightning } from '@lightningjs/sdk'

export interface KeyWidgetDisplayTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Content: {
    Label: object
    Description: object
  }
  keyEvent: KeyboardEvent
}

export class KeyWidgetDisplay
  extends Lightning.Component<KeyWidgetDisplayTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<KeyWidgetDisplayTemplateSpec>
{
  Content = this.getByRef('Content')!
  Label = this.Content.getByRef('Label')!
  Description = this.Content.getByRef('Description')!
  static override _template(): Lightning.Component.Template<KeyWidgetDisplayTemplateSpec> {
    return {
      x: 80,
      y: 80,
      h: 240,
      w: 400,
      rect: true,
      color: 0x00000000,
      shader: {
        type: Lightning.shaders.RoundedRectangle,
        radius: 24,
      },
      Content: {
        x: 24,
        y: 24,
        h: (h: number) => h - 48,
        w: (h: number) => h - 48,
        flex: {
          direction: 'column',
          alignItems: 'center',
        },
        Label: {
          text: {
            text: 'Key Label',
            fontFace: 'Bold',
            fontSize: 24,
            wordWrapWidth: 352,
          },
        },
        Description: {
          text: {
            text: 'Key Description',
            fontFace: 'Text',
            fontSize: 16,
            lineHeight: 20,
            wordWrapWidth: 352,
          },
        },
      },
    }
  }
  private _lastCode: string | null = null
  set keyEvent(e: KeyboardEvent) {
    if (e.code !== this._lastCode) {
      this._lastCode = e.code
      this.Label.patch({
        text: {
          text: 'Got Keycode ' + e.code,
        },
      })
      this.Description.patch({
        text: {
          text: `Code: ${e.code}\nChar Code: ${e.charCode}\nKey: ${e.key}`,
        },
      })
    }
  }
}
