import {
  ContentItem,
  CoordinateDimensions,
  DirectionalSignalMap,
} from '@adiffengine/engine-types'
import { Lightning } from '@lightningjs/sdk'
import equal from 'fast-deep-equal'
import { Debugger, getClosestIndexByX, isGoodNumber } from '../lib'
import {
  getClosestIndexByY,
  getCoordinateDimensions,
} from '../lib/lightning-tools'
import { AdeButton } from './AdeButton'
const debug = new Debugger('AdeButtonCollection')
export interface AdeButtonCollectionTemplateSpec<T = unknown>
  extends Lightning.Component.TemplateSpec {
  direction: Lightning.Component['flex']['direction']
  alignItems: Lightning.Component['flex']['alignItems']
  justifyContent: Lightning.Component['flex']['justifyContent']
  spacing: number
  buttons: ButtonCollectionButtonConfig<T>[]
  index: number
  buttonSize: {
    w: number
    h: number
  }
}

export interface ButtonCollectionButtonConfig<T = unknown> {
  buttonText: string
  leftIcon?: string
  payload: T
}
export interface ButtonCollectionSignalMap<T = unknown>
  extends DirectionalSignalMap {
  selected(payload: T): void
}

export interface ButtonCollectionActionConfig {
  action: '$navigate'
  payload: string
}
export interface ButtonCollectionWatchListAction {
  action: '$addToWatchlist'
  payload: ContentItem
}
export interface AdeButtonCollectionTypeConfig<T = unknown>
  extends Lightning.Component.TypeConfig {
  SignalMapType: ButtonCollectionSignalMap<T>
}
export class AdeButtonCollection<T = unknown>
  extends Lightning.Component<
    AdeButtonCollectionTemplateSpec<T>,
    AdeButtonCollectionTypeConfig<T>
  >
  implements
    Lightning.Component.ImplementTemplateSpec<AdeButtonCollectionTemplateSpec>
{
  static defaultDirection: Lightning.Component['flex']['direction'] = 'column'
  static override _template(): Lightning.Component.Template<AdeButtonCollectionTemplateSpec> {
    return {
      flex: {
        direction: this.defaultDirection,
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
    }
  }
  set alignItems(alignItems: Lightning.Component['flex']['alignItems']) {
    this.patch({
      flex: {
        alignItems,
      },
    })
  }
  set justifyContent(
    justifyContent: Lightning.Component['flex']['justifyContent'],
  ) {
    this.patch({
      flex: {
        justifyContent,
      },
    })
  }
  set direction(direction: Lightning.Component['flex']['direction']) {
    if (direction !== this.direction) {
      this.patch({
        flex: {
          direction,
        },
      })
      this._renderButtons()
    }
  }
  get items() {
    return this.children
  }
  get direction() {
    return this.flex.direction!
  }
  private _spacing: number = 36
  public get spacing(): number {
    return this._spacing
  }
  public set spacing(value: number) {
    if (value !== this._spacing) {
      this._spacing = value
      this._renderButtons()
    }
  }
  public buttonSize = {
    h: 60,
    w: 200,
  }
  private _buttons: ButtonCollectionButtonConfig<T>[] = []
  public get buttons(): ButtonCollectionButtonConfig<T>[] {
    return this._buttons
  }
  public set buttons(buttons: ButtonCollectionButtonConfig<T>[]) {
    if (!equal(this._buttons, buttons)) {
      this._buttons = buttons
      if (this._index > buttons.length - 1) {
        this.index = buttons.length - 1
      }
      this._renderButtons()
    }
  }

  _setHovered(idx: number) {
    debug.info('Button collection hovered', idx)
    this.index = idx
    this.signal('hovered', getCoordinateDimensions(this.children[idx]))
  }
  private _renderButtons() {
    this.childList.clear()
    this.buttons.forEach((b, idx: number) =>
      this.childList.a(
        this.stage.c({
          type: AdeButton,
          w: this.buttonSize.w,
          h: this.buttonSize.h,
          buttonText: b.buttonText,
          signals: {
            selected: () => {
              this.signal('selected', b.payload)
            },
            hovered: () => {
              this._setHovered(idx)
            },
          },
          flexItem: {
            marginBottom: this.direction === 'column' ? this.spacing : 0,
            marginRight: this.direction === 'row' ? this.spacing : 0,
          },
        }),
      ),
    )
  }
  currentCoords() {
    return getCoordinateDimensions(this.children[this.index])
  }
  setClosestByX(coords?: CoordinateDimensions | null) {
    if (coords && this.direction === 'row') {
      const index = getClosestIndexByX(coords, this.children ?? [])
      debug.info('index %s', index)
      if (isGoodNumber(index, true)) this.index = index
    } else if (coords) {
      console.warn(
        'Calling set closest by X on a column of buttons, using setClosestByY',
      )
      this.setClosestByY(coords)
    }
  }
  setClosestByY(coords: CoordinateDimensions | null) {
    if (coords && this.direction === 'column') {
      const index = getClosestIndexByY(coords, this.children ?? [], true)
      if (isGoodNumber(index, true)) this.index = index
    } else if (coords) {
      console.warn(
        'Calling set closest by Y on a row of buttons, using setClosestByX',
      )
      this.setClosestByX(coords)
    }
  }
  private _index = 0
  set index(index: number) {
    debug.info('Index: %s, length %s', index, this.childList.length)
    if (index < this.childList.length && index >= 0) {
      this._index = index
      this._refocus()
    }
  }
  get index() {
    return this._index
  }
  override _getFocused(): AdeButton | null {
    const button = this.childList.getAt(this._index)
    return button ? (button as AdeButton) : null
  }

  override _captureDown() {
    if (this.direction === 'column' && this.index < this.buttons.length - 1) {
      this.index++
      return true
    }
    this.signal('down')
    return false
  }
  override _captureUp() {
    if (this.direction === 'column' && this.index > 0) {
      this.index--
      return true
    }
    this.signal('up')
    return false
  }

  override _captureRight() {
    if (this.direction === 'row' && this.index < this.buttons.length - 1) {
      this.index++
      return true
    }
    this.signal('right')
    return false
  }
  override _captureLeft() {
    if (this.direction === 'row' && this.index > 0) {
      this.index--
      return true
    }
    this.signal('left')
    return false
  }
}
