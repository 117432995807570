import { ID } from '@adiffengine/engine-types'
import { Settings } from '@lightningjs/sdk'
// @ts-ignore
import mux from 'mux-embed'
import { Debugger } from '../../../lib'
import { hlsLoader } from './hls'
import { CreateVideoLoader, VideoDetails } from './types'
const debug = new Debugger('PlayerPlane:OptsLoader')
export const optsLoader: CreateVideoLoader =
  (details: VideoDetails) => (url: string, video: HTMLVideoElement) => {
    const loader = hlsLoader(details)
    const adsEnabled = Settings.get('app', 'ADS_ENABLED')
    debug.info('Ads Enabled?', adsEnabled)
    const APP_MUX_ENV_KEY = Settings.get('app', 'APP_MUX_ENV_KEY')
    const APP_MUX_DEBUG = Settings.get('app', 'APP_MUX_DEBUG')
    const APP_SHORT_NAME = Settings.get(
      'app',
      'APP_SHORT_NAME',
      'lightning-engine',
    )
    if (APP_MUX_ENV_KEY) {
      const playerInitTime = mux.utils.now()
      const data: Record<string, ID> = {
        env_key: APP_MUX_ENV_KEY, // required
        // Metadata fields
        player_name: APP_SHORT_NAME, // any arbitrary string you want to use to identify this player
        player_init_time: playerInitTime,
      }
      if (details.media || details.content) {
        const { media = null, content = null } = details
        if (content) data['video_id'] = media?.id ?? content.id
        const title = content?.title ?? media?.button_text
        if (title) data['video_title'] = title
      }
      const setup: Record<string, any> = {
        debug: APP_MUX_DEBUG === true || APP_MUX_DEBUG === 'true', // Never can tell how these get returned
        data,
      }
      debug.info('Setup', setup)
      mux.monitor(video, setup)
    }
    return loader(url, video)
  }
