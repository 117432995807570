import { ImageSet } from '@adiffengine/engine-types'
import { Settings, Utils } from '@lightningjs/sdk'

export interface SizedImageJson {
  type: 'SizedImage'
  images: Record<number, string>
}

export class SizedImageSet implements ImageSet {
  private _images: Record<number, string>
  private _largest: number
  private _sorted: number[]
  constructor(images: Record<number, string>) {
    this._images = images
    const keys = Object.keys(images) as unknown as number[]
    this._largest = Math.max(...keys)
    this._sorted = keys.sort()
  }

  static rehydrate(r: SizedImageJson) {
    return new SizedImageSet(r.images)
  }

  getForWidth(_t: number): null | string {
    const reverso = [...this._sorted].reverse()
    const itemKey = reverso.find(x => x >= _t)
    return this.getUrlForKey(itemKey ?? this._largest)
  }
  getUrlForKey(key: number): string | null {
    const src = this._images[key]
    if (!src) return null
    const needsProxy = Settings.get('app', 'NEEDS_PROXY', false)
    return needsProxy ? Utils.proxyUrl(src) : src
  }
  getUrl(_s: string): string | null {
    return this.getUrlForKey(parseInt(_s.toString(), 10))
  }
  getBiggest(): string | null {
    return this.getUrlForKey(this._largest)
  }
  toJSON(): SizedImageJson {
    return {
      type: 'SizedImage',
      images: this._images,
    }
  }
}
