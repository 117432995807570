import {
  ContentItem,
  ContentSelectedDirectionalSignalMap,
  Coords,
} from '@adiffengine/engine-types'
import { Colors, Img, Lightning, Settings } from '@lightningjs/sdk'
import equal from 'fast-deep-equal/es6'
import {
  Debugger,
  confirmCollisionsAreSet,
  getCoordinateDimensions,
  passSignal,
  registerHoverable,
} from '../../lib'

import { getStoredTheme } from '../../themes'
import { AdvancedGridCardSetup, CardRenderConfig } from './AdvancedGridTypes'
const debug = new Debugger('AdvancedBoxCard')

export interface AdvancedBoxCardTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Inner: {
    Shadow: object
    PosterFallback: {
      PosterGradient: object
    }
    Poster: object
    Focus: object
    Vignette: object
    InnerContent: {
      Label: {
        Title: object
      }
    }
  }
  cardConfig: Partial<CardRenderConfig>
  content: ContentItem | null
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const debug = new Debugger('AdvancedGridList')

export interface AdvancedBoxCardTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: ContentSelectedDirectionalSignalMap
}

export class AdvancedBoxCard
  extends Lightning.Component<
    AdvancedBoxCardTemplateSpec,
    AdvancedBoxCardTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<AdvancedBoxCardTemplateSpec>
{
  private _contentItem: ContentItem | null = null
  private _focusAnimation: Lightning.types.Animation | undefined = undefined

  Inner = this.getByRef('Inner')!
  Shadow = this.Inner.getByRef('Shadow')!
  InnerContent = this.Inner.getByRef('InnerContent')!
  Vignette = this.Inner.getByRef('Vignette')!
  Label = this.InnerContent.getByRef('Label')!
  Title = this.Label.getByRef('Title')!
  Poster = this.Inner.getByRef('Poster')!
  PosterFallback = this.Inner.getByRef('PosterFallback')!

  static ratio = 66 / 44
  static numberVisible = 6
  static spacing = 20
  static width = 318
  static height = (318 - this.spacing) * (9 / 16)
  static cardOffset: Partial<Coords> = {
    x: 16,
    y: 16,
  }
  static cardSetup: AdvancedGridCardSetup = {
    spacing: 20,
    cardsPerRow: 7,
  }

  static getDimensionsForWidth(
    w: number,
    setup: Partial<AdvancedGridCardSetup> = {},
  ): CardRenderConfig {
    const { cardsPerRow = this.numberVisible, spacing = this.spacing } = setup
    const width = w / cardsPerRow
    const height = (width - spacing) * this.ratio

    return {
      height,
      spacing,
      width,
    }
  }

  static override _template(): Lightning.Component.Template<AdvancedBoxCardTemplateSpec> {
    const theme = getStoredTheme()
    const {
      components: { VideoCardConfig },
    } = theme
    const radiusShader = {
      type: Lightning.shaders.RoundedRectangle,
      radius: VideoCardConfig.radius,
    }

    return {
      collision: true,
      w: this.width,
      h: this.height,
      Inner: {
        w: (w: number) => w - this.spacing,
        h: (h: number) => h,
        Shadow: {
          alpha: 0,
          mountX: 0.5,
          mountY: 0.5,
          x: (w: number) => w / 2,
          y: (h: number) => h / 2,
          w: (w: number) => w + 32,
          h: (h: number) => h + 32,
          color: Colors('shadow').alpha(0.6).get(),
          rect: true,
          shader: { type: Lightning.shaders.FadeOut, fade: 32 },
        },

        Focus: {
          alpha: 0,
          mountX: 0.5,
          mountY: 0.5,
          x: (w: number) => w / 2,
          y: (h: number) => h / 2,
          w: (w: number) => w + 8,
          h: (h: number) => h + 8,
          rect: true,
          color: Colors(theme.palette.highlights[500]).get(),
          shader: radiusShader,
        },

        PosterFallback: {
          x: 0,
          y: 0,
          w: (w: number) => w,
          h: (h: number) => h,
          rect: true,
          visible: true,
          colorTop: Colors(theme.palette.darks[500]).get(),
          colorBottom: Colors(theme.palette.darks[800]).get(),
          shader: radiusShader,
        },
        Poster: {
          x: 0,
          y: 0,
          w: (w: number) => w,
          h: (h: number) => h,
          rect: true,
          rtt: true,
          alpha: 0.0001,
          shader: radiusShader,
        },
        Vignette: {
          x: 0,
          y: 0,
          w: (w: number) => w,
          h: (h: number) => h,
          alpha: 0.3,
          colorBottom: Colors(VideoCardConfig.vignetteColor).get(),
          colorTop: Colors(VideoCardConfig.vignetteColor).alpha(0.01).get(),
          rect: true,
          rtt: true,
          shader: radiusShader,
        },
        InnerContent: {
          x: 12,
          y: 12,
          w: (w: number) => w - 24,
          h: (h: number) => h - 24,
          clipping: true,
          rect: true,
          rtt: true,
          color: 0x00000000,
          Label: {
            x: 0,
            y: 40,
            w: (w: number) => w,
            h: (h: number) => h,
            alpha: 0,
            flex: {
              direction: 'column',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            },
            Title: {
              text: {
                advancedRenderer: true,
                text: '',
                fontFace: 'Regular',
                fontSize: 22,
                lineHeight: 24,
                textAlign: 'right',
                wordWrap: true,
                maxLines: 2,
              },
            },
          },
        },
      },
    }
  }

  private _cardConfig: CardRenderConfig = {
    width: AdvancedBoxCard.width,
    height:
      (AdvancedBoxCard.width - AdvancedBoxCard.spacing) * AdvancedBoxCard.ratio,
    spacing: AdvancedBoxCard.spacing,
  }

  set cardConfig(config: Partial<CardRenderConfig>) {
    const update = { ...this._cardConfig, ...config }
    if (!equal(update, this._cardConfig)) {
      this._cardConfig = update
      this.renderCard()
    }
  }
  get cardConfig(): CardRenderConfig {
    return this._cardConfig
  }
  set content(v: ContentItem | null) {
    this._contentItem = v
  }
  get content() {
    return this._contentItem
  }

  renderCard() {
    const config = this.fireAncestors('$getCardConfig', 'box')
    if (config) {
      this.patch(config)
    }
    if (this.content && this.cardConfig && this.active) {
      const theme = this.fireAncestors('$theme')
      const {
        components: { VideoCardConfig },
      } = theme
      const { height, width, spacing } = this.cardConfig
      const radiusShader = {
        type: Lightning.shaders.RoundedRectangle,
        radius: VideoCardConfig.radius,
      }
      const innerWidth = width - spacing
      this.patch({
        w: width,
        h: height,
        Inner: {
          w: innerWidth,
          h: height,
        },
      })
      this.Label.patch({
        Title: {
          text: {
            text: this.content.title,
            wordWrapWidth: innerWidth - 24,
          },
        },
      })
      let imageUrl = this.content.images.box?.getForWidth(318)
      if (!imageUrl) imageUrl = this.content.images.box?.getForWidth(318)

      const fallbackImage = () => {
        this.Poster.patch({
          visible: false,
        })
        this.PosterFallback.patch({
          visible: true,
        })
      }

      if (imageUrl != null) {
        this.Poster.patch({
          shader: radiusShader,
          texture: Img(imageUrl).cover(width, height),
        })
      } else {
        fallbackImage()
      }

      if (this.Poster) {
        this.Poster.on('txLoaded', () => {
          this.Poster.setSmooth('alpha', 1)
        })
        this.Poster.on('txError', fallbackImage)
      }
      this.Shadow.patch({
        w: innerWidth + 32,
        h: height + 32,
        x: innerWidth / 2,
        y: height / 2,
      })
    }
  }

  override _init() {
    // Ensure we have dimensions since everything flows from this.
    if (this._mouseEnabled) {
      registerHoverable('AdvancedBoxCard', this)
    }
    if (this.content) {
      this._focusAnimation = this.animation({
        duration: 0.2,
        delay: 0.05,
        actions: [
          { p: 'scale', v: { 0: 1, 1: 1.04 } },
          { t: 'Inner.Shadow', p: 'alpha', v: { 0: 0, 1: 1 } },
          { t: 'Inner.Vignette', p: 'alpha', v: { 0: 0, 1: 1 } },
          { t: 'Inner.Focus', p: 'alpha', v: { 0: 0, 1: 1 } },
          { t: 'Inner.InnerContent.Label', p: 'y', v: { 0: 40, 1: 0 } },
          { t: 'Inner.InnerContent.Label', p: 'alpha', v: { 0: 0, 1: 1 } },
        ],
      })
      this.renderCard()
    }
  }
  override _handleUp() {
    return passSignal(this.signal('up', getCoordinateDimensions(this)), false)
  }
  override _handleDown() {
    return passSignal(this.signal('down', getCoordinateDimensions(this)), false)
  }
  override _handleLeft() {
    return passSignal(this.signal('left', getCoordinateDimensions(this)), false)
  }
  override _handleRight() {
    return passSignal(
      this.signal('right', getCoordinateDimensions(this)),
      false,
    )
  }
  private _cachedMouseEnabled: boolean | null = null
  get _mouseEnabled() {
    if (this._cachedMouseEnabled === null) {
      this._cachedMouseEnabled = Settings.get('app', 'enablePointer', false)
    }
    return this._cachedMouseEnabled
  }
  override _handleHover() {
    debug.info('Hovered on wide card', getCoordinateDimensions(this))
    if (this._mouseEnabled) {
      this.signal('hovered')
    }
  }
  _handleClick() {
    if (this._mouseEnabled) {
      this._captureEnter()
    }
  }
  override _captureEnter() {
    if (this.content) {
      this.fireAncestors('$contentSelected', this.content)
    }
  }

  override _focus() {
    if (this._focusAnimation && this.content) {
      this.fireAncestors('$currentFocusContent', this.content)
      this._focusAnimation.start()
    }
  }
  override _unfocus() {
    if (this._focusAnimation) {
      this._focusAnimation.stop()
    }
    this.patch({
      zIndex: 1,
    })
  }
  override _active() {
    confirmCollisionsAreSet(this)
    this.renderCard()
  }
}
