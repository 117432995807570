import { DirectionalSignalMap, FocusSignalMap } from '@adiffengine/engine-types'
import { Lightning } from '@lightningjs/sdk'
import { Debugger, getCoordinateDimensions, isGoodArray } from '../lib'

export interface StackConfig {
  direction: 'row' | 'column'
  spacing: number
  alignItems: Lightning.Component['flex']['alignItems']
  justifyContent: Lightning.Component['flex']['justifyContent']
  padding: number
}
export interface StackSignals extends DirectionalSignalMap, FocusSignalMap {}
export interface StackTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: StackSignals
}

export interface StackTemplateSpec
  extends Lightning.Component.TemplateSpecLoose {
  stackConfig: Partial<StackConfig>
  itemType: object
  currentIndex: number
  Content: object
  items: object[]
}
const debug = new Debugger('Stack')
debug.enabled = false

export class AdeStack
  extends Lightning.Component<StackTemplateSpec, StackTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<StackTemplateSpec>
{
  Content = this.tag('Content')!
  static override _template(): Lightning.Component.Template<StackTemplateSpec> {
    return {
      Content: {
        x: 0,
        y: 0,
        w: (w: number) => w,
        h: (h: number) => h,
      },
    }
  }

  private _stackConfig: StackConfig = {
    direction: 'row',
    spacing: 0,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
  }

  set stackConfig(config: Partial<StackConfig>) {
    this._stackConfig = {
      ...this._stackConfig,
      ...config,
    }
    const { justifyContent, alignItems, direction } = this._stackConfig
    this.Content.patch({
      flex: {
        direction,
        alignItems,
        justifyContent,
      },
    })
  }

  override _enable() {
    this._renderItems()
  }

  private _itemType: Lightning.Component | null = null

  set itemType(itemType: Lightning.Component | null) {
    this._itemType = itemType
    this._renderItems()
  }
  get itemType() {
    return this._itemType
  }

  public _spacing = 0
  private _items: object[] | null = null

  private _renderItems() {
    debug.info('Rendering Items', this.items)
    if (this.items.length === 0) {
      this.Content.patch({ children: [] })
      return
    }

    const { justifyContent, alignItems } = this._stackConfig
    const { width, height } = getCoordinateDimensions(this)
    const getFlexItemSpacing = (first: boolean, last: boolean) => {
      const start =
        this._stackConfig.direction === 'row' ? 'marginLeft' : 'marginTop'
      const end =
        this._stackConfig.direction === 'row' ? 'marginRight' : 'marginBottom'
      const spaceAmount = this._stackConfig.spacing

      return {
        [start]: first ? 0 : spaceAmount,
        [end]: last ? 0 : spaceAmount,
      }
    }

    const contentPatch: Record<string, unknown> = {
      x: this._stackConfig.padding,
      y: this._stackConfig.padding,
      w: width - this._stackConfig.padding * 2,
      h: height - this._stackConfig.padding * 2,
      flex: {
        direction: this._stackConfig.direction,
        justifyContent,
        alignItems,
      },
    }
    if (this.kids && this.kids.length > 0) contentPatch['children'] = this.kids
    this.Content.patch(contentPatch)
    this.Content.children.forEach(
      (
        child: { patch: (arg0: { flexItem: { [x: string]: number } }) => void },
        idx: number,
      ) => {
        child.patch({
          flexItem: {
            ...getFlexItemSpacing(idx === 0, idx === this.kids.length - 1),
          },
        })
      },
    )
    debug.info('Patched Content for Stack', this.Content.children)
  }
  private _typeForChild(obj: Record<string, unknown>): Record<string, unknown> {
    if (obj['type']) return { type: obj['type'] }
    else if (this.itemType) return { type: this.itemType }
    else return {}
  }
  get kids(): object[] {
    return this.items.map(item => {
      const type = this._typeForChild(item as Record<string, unknown>)
      return {
        ...type,
        ...item,
      }
    })
  }

  private _desiredIndex: number | null = null
  private _currentIndex = 0

  set currentIndex(x: number) {
    debug.info('Setting Current Index to %s - %s', x, this.kids.length)
    if (x === this._currentIndex) return
    if (x >= 0 && x < this.kids.length) {
      this.signal('unfocus', this._currentIndex)
      this._currentIndex = x
      if (this.hasFocus()) this._refocus()
      this.signal('focus', this._currentIndex)
    } else if (!this.hasFocus()) {
      this._desiredIndex = x
    }
  }
  get currentIndex() {
    return this._currentIndex
  }
  override _focus() {
    this.signal('focus', this._currentIndex)
  }
  override _unfocus() {
    this.signal('unfocus', this._currentIndex)
  }

  override _getFocused() {
    if (this._desiredIndex !== null) this.currentIndex = this._desiredIndex
    this._desiredIndex = null
    const item = this.Content?.children[this._currentIndex]
    return (item as Lightning.Component) ?? null
  }

  override _handleUp() {
    if (this._stackConfig.direction === 'column') {
      if (this._currentIndex === 0) this.signal('up')
      else this.currentIndex--
    } else {
      this.signal('up')
    }
  }
  override _handleDown() {
    if (this._stackConfig.direction === 'column' && this._items !== null) {
      if (this._currentIndex >= this._items.length - 1) this.signal('down')
      else this.currentIndex++
    } else {
      this.signal('down')
    }
  }
  override _handleLeft() {
    if (this._stackConfig.direction === 'row') {
      if (this._currentIndex === 0) this.signal('left')
      else this.currentIndex--
    } else {
      this.signal('left')
    }
  }
  override _handleRight() {
    if (this._stackConfig.direction === 'row' && this._items !== null) {
      if (this._currentIndex === this._items.length - 1) this.signal('right')
      else this.currentIndex++
    } else {
      this.signal('right')
    }
  }
  get items(): object[] {
    if (this._items !== null && isGoodArray(this._items)) return this._items
    else return []
  }
  set items(items: object[]) {
    this._items = items
    this._renderItems()
  }
}
