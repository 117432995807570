import { Colors, Img, Lightning, Router } from '@lightningjs/sdk'
import { StandardBackground } from '../components'
import { defer } from '../lib'
import { getStoredTheme } from '../themes'

export interface AdeLoadingPageTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Content: typeof AdeLoader
}
export interface AdeLoadingPageTypeConfig
  extends Lightning.Component.TypeConfig {
  IsPage: true
}

export class AdeLoadingPage
  extends Lightning.Component<
    AdeLoadingPageTemplateSpec,
    AdeLoadingPageTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<AdeLoadingPageTemplateSpec>
{
  static override _template(): Lightning.Component.Template<AdeLoadingPageTemplateSpec> {
    return {
      x: 0,
      y: 0,
      w: 1920,
      h: 1080,
      Content: {
        x: 0,
        y: 0,
        w: 1920,
        h: 1080,
        type: AdeLoader,
      },
    }
  }

  resumeRouterIfNeeded() {
    defer(() => {
      if (Router.getActiveHash() === '$') Router.resume()
    })
  }

  startup() {
    this.resumeRouterIfNeeded()
  }
  override _active() {
    this.startup()

    this.stage.application.emit(
      'screenView',
      'Loading Screen',
      'loading_screen',
    )
  }
}

export interface AdeLoaderTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Background: typeof StandardBackground
  Content: {
    Bubble: object
    Image: object
    Text: object
  }
}

const imageScaleEnd = 0.8

export class AdeLoader extends Lightning.Component<
  AdeLoaderTemplateSpec,
  Lightning.Component.TypeConfig
> {
  Background = this.getByRef('Background')!
  static override _template(): Lightning.Component.Template<AdeLoaderTemplateSpec> {
    const theme = getStoredTheme()
    return {
      Background: {
        type: StandardBackground,
      },
      Content: {
        x: 0,
        y: 0,
        w: 1920,
        h: 1080,
        Image: {
          x: (1920 - theme.logo_size) / 2,
          y: (1080 - theme.logo_size) / 2,
          alpha: 1,
          w: theme.logo_size,
          h: theme.logo_size,
          texture: Img(theme.logo).cover(theme.logo_size, theme.logo_size),
          rtt: true,
        },
        Text: {
          x: (1920 - 800) / 2,
          y: 840,
          w: 800,
          alpha: 0,
          color: Colors(theme.palette.highlights[700]).get(),
          text: {
            text: 'LOADING...',
            fontFace: 'Regular',
            fontSize: 24,
            textAlign: 'center',
          },
        },
      },
    }
  }
  override get id() {
    return 'AdeLoadingPage'
  }
  private _focusAnimation: Lightning.types.Animation | undefined = undefined
  private _pulse: Lightning.types.Animation | undefined = undefined
  private _showLoading: Lightning.types.Animation | undefined = undefined
  override _init() {
    this._focusAnimation = this.animation({
      duration: 2,
      actions: [
        {
          t: 'Content.Image',
          p: 'scale',
          v: { 0: 3, 1: imageScaleEnd },
        },
        {
          t: 'Content.Image',
          p: 'alpha',
          v: { 0: 0, 1: 0.8 },
        },
      ],
    })

    this._showLoading = this.animation({
      duration: 1,
      actions: [
        {
          t: 'Content.Text',
          p: 'alpha',
          v: { 0: 0, 1: 0.8 },
        },
      ],
    })

    this._pulse = this.animation({
      duration: 4,
      autostop: true,
      stopMethod: 'reverse',
      stopDelay: 4,
      actions: [
        {
          t: 'Content.Image',
          p: 'scale',
          v: { 0: imageScaleEnd, 0.5: 1.05, 1: imageScaleEnd },
        },
        {
          t: 'Content.Image',
          p: 'alpha',
          v: { 0: 0.8, 0.5: 1.05, 1: 0.8 },
        },
      ],
    })
    this._focusAnimation.on('finish', () => {
      this._showLoading?.start()
      this._pulse?.start()
    })
  }

  override _active() {
    this._focusAnimation?.start()
    this.Background.dance()
  }
  override _inactive() {
    this._focusAnimation?.stop()
    this.Background.stopDancing()
  }
}
