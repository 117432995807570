import {
  CoordinateDimensions,
  DirectionalSignalMap,
  ListIndexChange,
} from '@adiffengine/engine-types'
import { Lightning } from '@lightningjs/sdk'
import { Grid } from '@lightningjs/ui'
import {
  Debugger,
  getClosestIndexByX,
  getCoordinateDimensions,
  isGoodNumber,
} from '../lib'
import { SearchLetter } from './SearchLetter'
import { SearchTextInput } from './SearchTextInput'

const debug = new Debugger('LSK')
const LETTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
export interface LongSearchKeyboardTemplateSpec
  extends Lightning.Component.TemplateSpec {
  SearchList: typeof Grid
  SearchDisplay: typeof SearchTextInput
  CommandKeys: typeof Grid
  placeholderText: string
}

export interface LongSearchKeyboardSignals extends DirectionalSignalMap {
  onIndexChanged(arg: ListIndexChange): void
  onQueryChanged(arg: string, fullterm?: boolean): void
}

export interface LongSearchKeyboardTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: LongSearchKeyboardSignals
}

export class LongSearchKeyboard
  extends Lightning.Component<
    LongSearchKeyboardTemplateSpec,
    LongSearchKeyboardTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<LongSearchKeyboardTemplateSpec>
{
  static letters = LETTERS
  static height = 49 * 2 + 74
  SearchList = this.getByRef('SearchList')!
  SearchDisplay = this.getByRef('SearchDisplay')!
  CommandKeys = this.getByRef('CommandKeys')!
  static override _template(): Lightning.Component.Template<LongSearchKeyboardTemplateSpec> {
    return {
      SearchDisplay: {
        x: 59 * 13 + 35,
        y: 0,
        type: SearchTextInput,
        w: 1500 - (59 * 13 + 35),
        lines: 3,
      },
      SearchList: {
        x: 0,
        y: 0,
        type: Grid,
        columns: 13,
        itemType: SearchLetter,
        spacing: 5,
        signals: {
          onIndexChanged: '_indexChanged',
        },
      },
      CommandKeys: {
        x: 0,
        y: 49 * 2 + 20,
        h: 54,
        type: Grid,
        columns: 2,
        itemType: SearchLetter,
        spacing: 5,
        items: [
          {
            w: 112,
            h: 54,
            label: 'SPACE',
            type: SearchLetter,
            dontHandleKeys: true,
          },
          {
            w: 112,
            h: 54,
            label: 'DEL',
            type: SearchLetter,
            dontHandleKeys: true,
          },
        ],
      },
    }
  }

  setClosestByX(coords: CoordinateDimensions | null) {
    if (coords) {
      const index = getClosestIndexByX(
        coords,
        this.getByRef('SearchList').itemWrappers,
      )
      debug.info('Index %s', index)
      if (isGoodNumber(index, true)) this.getByRef('SearchList').setIndex(index)
    }
  }

  private _index: number = 0
  _indexChanged(change: ListIndexChange) {
    this._index = change.index
    this.signal('onIndexChanged', change)
  }
  get index() {
    return this._index
  }

  override _active() {
    this.SearchDisplay.patch({
      width: this.w - 59 * 13 + 35,
    })
  }

  override _init() {
    const items = LongSearchKeyboard.letters.map(label => ({
      h: 54,
      w: 54,
      label,
      dontHandleKeys: true,
    }))

    this.SearchList.patch({ items })
  }

  private _focused: typeof this.CommandKeys | typeof this.SearchList =
    this.SearchList
  set focused(focused: typeof this._focused) {
    if (this._focused !== focused) {
      this._focused = focused
      this._refocus()
    }
  }
  get focused() {
    return this._focused
  }
  override _getFocused() {
    return this.focused
  }

  static isTypeahead(q: string, lastQ: string) {
    if (q.length > lastQ.length && lastQ !== '') {
      return q.indexOf(lastQ) === 0
    } else if (q.length < lastQ.length) {
      return lastQ.indexOf(q) === 0
    }
    return false
  }
  setQuery(q: string) {
    q = q.trim()
    if (q !== this._query) {
      const isTypeahead = LongSearchKeyboard.isTypeahead(q, this._query)
      debug.info(
        'Query %s Last Query %s typeahead %s',
        q,
        this._query,
        isTypeahead,
      )
      this._query = q
      this.SearchDisplay.patch({ searchText: this._query })
      this.signal('onQueryChanged', this._query, !isTypeahead)
    }
  }
  private _query: string = ''
  set query(x: string) {
    switch (x.toLowerCase()) {
      case 'del':
        if (this._query.length > 0) this._query = this._query.slice(0, -1)
        break
      case 'space':
        if (!/\s$/.test(this._query)) this._query = `${this._query} `
        break
      default:
        this._query += x
        console.warn('Got invalid letter')
    }
    this.SearchDisplay.patch({ searchText: this._query })
    this.signal('onQueryChanged', this._query)
  }
  get query() {
    return this._query
  }
  $searchKey(x: string) {
    this.query = x
  }

  set placeholderText(text: string) {
    this.SearchDisplay.patch({
      placeholderText: text,
    })
  }
  sendSignal(direction: keyof DirectionalSignalMap) {
    this.signal(direction)
  }
  override _handleDown() {
    if (this.focused === this.SearchList && this.SearchList._mainIndex === 1) {
      this.CommandKeys.setIndex(this.SearchList._crossIndex > 1 ? 1 : 0)
      this.focused = this.CommandKeys
    } else {
      return this.signal(
        'down',
        getCoordinateDimensions(this.focused.items[this.focused.index]),
      )
    }
  }
  override _handleUp() {
    if (this.focused === this.CommandKeys) {
      this.SearchList.setIndex(this.CommandKeys._crossIndex > 0 ? 16 : 14)
      this.focused = this.SearchList
    } else {
      return this.signal(
        'up',
        getCoordinateDimensions(this.focused.items[this.focused.index]),
      )
    }
  }
  override _handleLeft() {
    if (this.focused === this.SearchList)
      debug.info('Left From Search List %s', this.SearchList._crossIndex)
    if (this.focused === this.CommandKeys)
      debug.info('Left From Command List %s', this.CommandKeys._crossIndex)
    if (
      (this.focused === this.SearchList && this.SearchList._crossIndex === 0) ||
      (this.focused === this.CommandKeys && this.CommandKeys._crossIndex === 0)
    ) {
      return this.signal(
        'left',
        getCoordinateDimensions(this.focused.items[this.focused.index]),
      )
    }
  }
  override _handleRight() {
    if (
      (this.focused === this.SearchList && this.SearchList._crossIndex === 9) ||
      (this.focused === this.CommandKeys && this.CommandKeys._crossIndex === 2)
    ) {
      return this.signal(
        'right',
        getCoordinateDimensions(this.focused.items[this.focused.index]),
      )
    }
  }
}
