import { Colors, Img, Lightning, Router } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import { Debugger, cp } from '../../lib'
import { AdeButton } from '../AdeButton'

import { Lifecycle } from '@firebolt-js/sdk'
import { ThorError, isCodedError, isThorError } from '../../lib'
import { getStoredTheme } from '../../themes'
const debug = new Debugger('AdeErrorScreen')

export interface AdeErrorMessageTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Background: object
  Image: object
  InnerWrapper: {
    Header: object
    Text: object
    ErrorCode: object
    Buttons: typeof List
  }
  backgroundAlpha: number
  buttons?: AdeErrorMessageButtonConfig[]
  error: Error | null
  title: string
  description: string
  noExit: boolean
}

const DEFAULT_ERROR_CODE = 'ERR_01'
export interface AdeErrorMessageTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: {
    back(): void
    home(): void
  }
}
export interface AdeErrorMessageButtonConfig {
  buttonId?: string
  h?: number
  buttonText: string
  selected: () => unknown
}

export class AdeErrorMessage
  extends Lightning.Component<AdeErrorMessageTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<AdeErrorMessageTemplateSpec>
{
  private readonly _buttonConfigs: AdeErrorMessageButtonConfig[] = [
    {
      buttonId: 'back',
      buttonText: 'Go Back',
      selected: this._backSelected.bind(this),
    },
    {
      buttonId: 'home',
      buttonText: 'Go Home',
      selected: this._homeSelected.bind(this),
    },
    {
      buttonId: 'exit',
      buttonText: 'Exit',
      selected: this._exitSelected.bind(this),
    },
  ]

  private _userButtonConfigs: AdeErrorMessageButtonConfig[] = []
  get currentButtons() {
    const history = Router.getHistory()
    debug.info('History length %s', history.length)
    const configs =
      this._userButtonConfigs && this._userButtonConfigs.length
        ? this._userButtonConfigs
        : this._buttonConfigs
            .filter(({ buttonId }) => !this.noExit || buttonId !== 'exit')
            .filter(
              ({ buttonId }) => history.length !== 0 || buttonId !== 'back',
            )

    return configs.map(({ selected, h = 60, ...rest }) => ({
      ...rest,
      type: AdeButton,
      h,
      signals: {
        selected,
      },
    }))
  }

  set buttons(configs: AdeErrorMessageButtonConfig[]) {
    this._userButtonConfigs = configs
    this.patchButtons()
  }

  static height = 600
  static width = 1200
  Background = this.tag('Background')
  InnerWrapper = this.tag('InnerWrapper')!
  Buttons = this.InnerWrapper.tag('Buttons')!
  Title = this.InnerWrapper.tag('Header')!
  ErrorCode = this.InnerWrapper.tag('ErrorCode')!
  Description = this.InnerWrapper.tag('Text')!
  private _noExit: boolean = false

  static defaultBackgroundAlpha = 0.8

  static override _template(): Lightning.Component.Template<AdeErrorMessageTemplateSpec> {
    const theme = getStoredTheme()
    return {
      x: 0,
      y: 0,
      h: this.height,
      w: this.width,
      Background: {
        x: 0,
        y: 0,
        h: cp,
        w: cp,
        rect: true,
        color: Colors('background').alpha(this.defaultBackgroundAlpha).get(),
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 24,
          stroke: 2,
          strokeColor: Colors('backgroundGradient').get(),
        },
      },
      Image: {
        x: 48,
        y: (this.height - 304) / 2,
        alpha: 1,
        h: 304,
        w: 304,
        rtt: true,
        texture: Img(theme.logo).contain(304, 304),
      },
      InnerWrapper: {
        x: 400,
        y: 0,
        w: 752,
        h: this.height,
        flex: {
          direction: 'column',
          justifyContent: 'center',
        },
        Header: {
          text: {
            text: 'AN ERROR OCCURRED',
            fontFace: 'Bold',
            fontSize: 36,
            wordWrap: true,
            wordWrapWidth: 740,
          },
        },
        Text: {
          text: {
            text: 'An Error occurred loading this page, please try again later',
            fontFace: 'Regular',
            fontSize: 24,
            textAlign: 'left',
            wordWrap: true,
            wordWrapWidth: 740,
          },
          flexItem: {
            marginTop: 12,
            marginBottom: 12,
          },
        },
        ErrorCode: {
          text: {
            text: `Error Code: ${DEFAULT_ERROR_CODE}`,
            fontFace: 'Regular',
            fontSize: 18,
            textAlign: 'center',
            wordWrap: true,
            wordWrapWidth: 740,
          },
          flexItem: {
            marginBottom: 12,
          },
        },
        Buttons: {
          type: List,
          spacing: 20,
          h: 60,
          w: 742,
          direction: 'row',
          flexItem: {
            marginTop: 20,
          },
        },
      },
    }
  }

  private _backgroundAlpha: number = AdeErrorMessage.defaultBackgroundAlpha
  set backgroundAlpha(alpha: number) {
    if (alpha < 0 || alpha > 1) {
      console.warn(
        'Invalid alpha value, background color alpha mus be between 0 and 1',
      )
    } else {
      this._backgroundAlpha = alpha
      this.Background?.patch({
        color: Colors('background').alpha(alpha).get(),
      })
    }
  }
  get backgroundAlpha() {
    return this._backgroundAlpha
  }

  private _error: Error | null = null
  get error() {
    return this._error
  }
  set error(error: Error | null) {
    debug.info('Setting Error Screen Erorr to ', error)
    if (error && isCodedError(error)) {
      console.warn("Still got a coded error, this shouldn't be the case", error)
      this._error = error
      this.title = error.errorTitle
      this.description = error.errorDescription
      this.errorCode = error.errorCode
    } else if (error) {
      this._error = error
      const e: ThorError = isThorError(error)
        ? error
        : new ThorError(error.message, ThorError.Type.UnknownError)
      this.title = e.title
      this.description = e.description
      this.errorCode = e.displayCode
    } else {
      this._error = error
    }
  }

  set noExit(noExit: boolean) {
    this._noExit = noExit
  }
  get noExit() {
    this._noExit = this._noExit === undefined ? false : this._noExit
    return this._noExit
  }
  set title(text: string) {
    this.Title.patch({ text: { text } })
  }
  get title() {
    return this.Title.text?.text ?? ''
  }
  set description(text: string) {
    this.Description.patch({ text: { text: text ?? '' } })
  }
  get description() {
    return this.Description.text?.text ?? ''
  }
  set errorCode(code: string) {
    this.ErrorCode.patch({
      text: {
        text: `Error Code: ${code}`,
      },
    })
  }
  get errorCode() {
    return this.ErrorCode.text?.text ?? ''
  }
  _backSelected() {
    Router.back()
    return false
  }
  _homeSelected() {
    Router.navigate('home')
    return false
  }

  _exitSelected() {
    this.fireAncestors('$exitApp', Lifecycle.CloseReason.USER_EXIT)
    return false
  }

  patchButtons() {
    const currentIndex = this.Buttons.index
    const items =
      history.length > 1 ? this.currentButtons : this.currentButtons.slice(1)
    this.Buttons.patch({ items })
    if (!this.Buttons.items[currentIndex] && items.length > 0) {
      this.Buttons.setIndex(this.Buttons.items.length - 1)
    }
  }

  override _enable() {
    let currentError = ThorError.lastError()
    currentError =
      currentError ??
      new ThorError('Unknown Error', ThorError.Type.UnknownError)
    this.error = currentError
    this.patchButtons()
  }
  override _getFocused() {
    return this.Buttons
  }
}

export interface AdeErrorPageTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Wrapper: typeof AdeErrorMessage
}

export interface AdeErrorPageTypeConfig extends Lightning.Component.TypeConfig {
  IsPage: true
}
export class AdeErrorPage extends Lightning.Component<
  AdeErrorPageTemplateSpec,
  AdeErrorPageTypeConfig
> {
  Wrapper = this.getByRef('Wrapper')!
  static override _template(): Lightning.Component.Template<AdeErrorPageTemplateSpec> {
    return {
      x: 0,
      y: 0,
      w: 1920,
      h: 1080,
      Wrapper: {
        x: (1920 - AdeErrorMessage.width) / 2,
        y: (1080 - AdeErrorMessage.height) / 2,
        w: AdeErrorMessage.width,
        h: AdeErrorMessage.height,
        type: AdeErrorMessage,
      },
    }
  }
  override _active() {
    this.stage.application.emit('screenView', 'Error Screen', 'error_screen')
  }
  override _getFocused() {
    return this.Wrapper
  }
}
