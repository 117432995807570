import { Colors, Img, Lightning } from '@lightningjs/sdk'
import { Debugger, getCoordinateDimensions } from '../lib'
import { getStoredTheme } from '../themes'
const debug = new Debugger('AdeDetailsHeroImage')
export interface AdeDetailsHeroImageTemplateSpec
  extends Lightning.Component.TemplateSpec {
  ImageWrapper: {
    BackgroundImage: object
    Overlay: object
  }

  imageSrc: string | null
  overflowed: boolean
}
export interface AdeDetailsHeroImageTypeConfig
  extends Lightning.Component.TypeConfig {}

export class AdeDetailsHeroImage
  extends Lightning.Component<
    AdeDetailsHeroImageTemplateSpec,
    AdeDetailsHeroImageTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<AdeDetailsHeroImageTemplateSpec>
{
  ImageWrapper = this.getByRef('ImageWrapper')!
  Overlay = this.ImageWrapper.getByRef('Overlay')!
  BackgroundImage = this.ImageWrapper.getByRef('BackgroundImage')!
  static width = 1920
  static height = 800
  static override _template(): Lightning.Component.Template<AdeDetailsHeroImageTemplateSpec> {
    const theme = getStoredTheme()
    return {
      x: 0,
      y: 0,
      w: 1920,
      h: 800,
      ImageWrapper: {
        rtt: true,
        rect: true,
        BackgroundImage: {
          alpha: 0,
          x: 0,
          y: 0,
          rtt: true,
        },
        Overlay: {
          alpha: 1,
          w: (w: number) => w,
          h: (h: number) => h,
          x: 0,
          y: 0,
          rect: true,
          colorLeft: Colors(theme.palette.darks[500]).alpha(1).get(),
          colorRight: Colors(theme.palette.darks[800]).alpha(0.001).get(),
        },
      },
    }
  }
  private _overflowed = false
  public get overflowed() {
    return this._overflowed
  }

  public set overflowed(value: boolean) {
    if (value !== this._overflowed) {
      this._overflowed = value
      if (this.active) this.renderImage()
    }
  }

  private get _imageWidth() {
    return this.overflowed ? 1920 : this.w
  }
  private get _xPos() {
    const coords = getCoordinateDimensions(this)
    return this.overflowed ? coords.x * -1 : 0
  }

  renderImage() {
    if (this.imageSrc) {
      debug.info('Rendering image', this._xPos, this._imageWidth)
      this.BackgroundImage.patch({
        w: this._imageWidth,
        h: this.h,
        visible: true,
        texture: Img(this.imageSrc).cover(this._imageWidth, this.h),
        alpha: 1,
      })
      this.ImageWrapper.patch({
        w: this._imageWidth,
        h: this.h,
        rtt: true,
        rect: true,
        x: this._xPos,
        color: 0x00000000,
        clipping: true,
        shader: {
          type: Lightning.shaders.FadeOut,
          bottom: 500,
        },
      })
      this.Overlay.patch({
        x: this._xPos,
        w: this._imageWidth,
        h: this.h,
      })
    } else {
      this.BackgroundImage.patch({ visible: false })
    }
  }
  private _imageSrc: string | null = null
  public get imageSrc(): string | null {
    return this._imageSrc
  }
  public set imageSrc(value: string | null) {
    if (value !== this._imageSrc) {
      this._imageSrc = value
      if (this.active) {
        this.renderImage()
      }
    }
  }

  override _active() {
    this.renderImage()
  }
}
