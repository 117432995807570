import { Lightning, Registry, Router } from '@lightningjs/sdk'
import { Frame } from '../components/Frame'
import { Debugger } from '../lib'
const debug = new Debugger('FrameScreen')
export interface FrameScreenTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Frame: typeof Frame
  Overlay: object
}
export interface FrameScreenTypeConfig extends Lightning.Component.TypeConfig {
  IsPage: true
}
export class FrameScreen
  extends Lightning.Component<FrameScreenTemplateSpec, FrameScreenTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<FrameScreenTemplateSpec>
{
  Frame = this.getByRef('Frame')
  static override _template(): Lightning.Component.Template<FrameScreenTemplateSpec> {
    return {
      x: 0,
      y: 0,
      h: 1080,
      w: 1920,
      Frame: {
        type: Frame,
        x: 0,
        y: 0,
        h: 1080,
        w: 1920,
      },
    }
  }
  override _active() {
    Registry.setTimeout(() => {
      Router.navigate('welcome')
    }, 5000)
  }
  departed() {
    debug.info('Frame Screen Departed')
  }
}
