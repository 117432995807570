import { VideoPlayer } from '@lightningjs/sdk'
import Hls from 'hls.js'
import isFunction from 'lodash/isFunction'
import { Debugger, ThorMetrics } from '../../../lib'
import { CreateVideoLoader, VideoDetails } from './types'

const debug = new Debugger('hls')
export type HlsPlayer = Hls
export type PlayerOptions = NonNullable<
  ConstructorParameters<typeof Hls>[0]
> & {
  autoLevelCapping: number
}
export type HlsError = typeof Hls.Events.ERROR
let player: null | HlsPlayer = null
const defaults = {
  debug: false,
}

function handleUnrecoverableError(
  player: HlsPlayer | null,
  errorEvent: typeof Hls.Events.ERROR,
) {
  if (VideoPlayer._consumer) {
    VideoPlayer._consumer.fire(
      '$videoPlayerError',
      errorEvent,
      VideoPlayer.currentTime,
    )
    VideoPlayer._consumer.fire(
      '$videoPlayerEvent',
      'Error',
      errorEvent,
      VideoPlayer.currentTime,
    )
  }
  if (player) player.destroy()
}

const unload = (videoEl: HTMLVideoElement) => {
  if (player && isFunction(player.destroy)) {
    player.destroy()
    player = null
  }
  videoEl.removeAttribute('src')
  videoEl.load()
}

export const hlsLoader: CreateVideoLoader =
  (details: VideoDetails = {}) =>
  (
    url: string,
    videoEl: HTMLVideoElement,
    passedOptions: Partial<PlayerOptions> = {},
  ) => {
    debug.info('PassedOptions', passedOptions)
    return new Promise<void>(resolve => {
      unload(videoEl)
      const metrics = new ThorMetrics()
      const { content = null } = details
      if (content !== null) {
        videoEl.addEventListener('loadstart', () => {
          metrics.mediaLoadStart(content)
        })
        videoEl.addEventListener('pause', () => {
          metrics.mediaPause(content)
        })
        videoEl.addEventListener('play', () => {
          metrics.mediaPlaying(content)
        })
        videoEl.addEventListener('progress', () => {
          metrics.mediaProgress(content, videoEl.currentTime)
        })
        videoEl.addEventListener('seeked', () => {
          metrics.mediaSeeked(content, videoEl.currentTime)
        })
        videoEl.addEventListener('waiting', () => {
          metrics.mediaWaiting(content)
        })
      }
      debug.info('Loader....', url, videoEl)
      const { autoLevelCapping = -1, ...options } = passedOptions
      const hlsOptions = { ...defaults, ...options, enableWorker: false }
      debug.info('Hls Options', hlsOptions)
      player = new Hls({ ...defaults, ...options, enableWorker: false })
      player.autoLevelCapping = autoLevelCapping
      let currentBitrate: number | null = null
      player.on(Hls.Events.LEVEL_SWITCHED, () => {
        const level = player?.levels[player?.currentLevel]
        if (level && content) {
          if (currentBitrate !== level.bitrate) {
            currentBitrate = level.bitrate
            metrics.mediaRateChange(content, level.bitrate)
          }
          metrics.mediaRenditionChange(
            content,
            level.bitrate,
            level.width,
            level.height,
            level.codecSet,
          )
        }
      })
      player.on(Hls.Events.MANIFEST_PARSED, () => resolve())
      player.on(Hls.Events.ERROR, (event, data) => {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.MEDIA_ERROR:
              switch (data.details) {
                case Hls.ErrorDetails.MANIFEST_INCOMPATIBLE_CODECS_ERROR:
                  handleUnrecoverableError(player, event)
                  break
                default:
                  if (player) player.recoverMediaError()
                  break
              }
              break
            case Hls.ErrorTypes.NETWORK_ERROR:
              switch (data.details) {
                case Hls.ErrorDetails.FRAG_LOAD_ERROR:
                  if (player)
                    player.currentLevel =
                      data.frag!.start + data.frag!.duration + 0.1
                  break
                case Hls.ErrorDetails.MANIFEST_LOAD_ERROR:
                  handleUnrecoverableError(player, event)
                  break
                default:
                  if (player) player.startLoad()
                  break
              }
              break

            default:
              handleUnrecoverableError(player, event)
              break
          }
        }
      })

      player.loadSource(url)
      player.attachMedia(videoEl)
      debug.info('Player', player)
    })
  }

export async function unloader(videoEl: HTMLVideoElement) {
  await unload(videoEl)
}
