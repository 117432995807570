import { Colors, Lightning } from '@lightningjs/sdk'
export interface DefaultHomeScreenTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Background: object
  Text: {
    Header: object
    Text: object
  }
}

const CONTENT_WIDTH = 900
const CONTENT_HEIGHT = 400
const CONTENT_PADDING = 24
const TEXT_WIDTH = CONTENT_WIDTH - CONTENT_PADDING * 2
const TEXT_HEIGHT = CONTENT_HEIGHT - CONTENT_PADDING * 2
export interface DefaultHomeScreenTypeConfig
  extends Lightning.Component.TypeConfig {
  IsPage: true
}
export class DefaultHomeScreen
  extends Lightning.Component<
    DefaultHomeScreenTemplateSpec,
    DefaultHomeScreenTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<DefaultHomeScreenTemplateSpec>
{
  static override _template(): Lightning.Component.Template<DefaultHomeScreenTemplateSpec> {
    return {
      x: 0,
      y: 0,
      h: 1080,
      w: 1920,
      rect: true,
      rtt: true,
      color: Colors('background').get(),
      Background: {
        x: (1920 - CONTENT_WIDTH) / 2,
        y: (1080 - CONTENT_HEIGHT) / 2,
        w: CONTENT_WIDTH,
        h: CONTENT_HEIGHT,
        rect: true,
        rtt: true,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: CONTENT_PADDING,
          stroke: 4,
          strokeColor: Colors('primaryHighlight').get(),
          fillColor: Colors('shadow').get(),
        },
      },
      Text: {
        x: (1920 - TEXT_WIDTH) / 2,
        y: (1080 - TEXT_HEIGHT) / 2,
        w: TEXT_WIDTH,
        h: TEXT_HEIGHT,
        flex: {
          direction: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        },
        Header: {
          text: {
            text: 'Welcome to your new app',
            fontFace: 'ExtraBold',
            fontSize: 48,
            textAlign: 'center',
          },
        },
        Text: {
          flexItem: {
            marginTop: CONTENT_PADDING,
          },
          text: {
            text: `Welcome to your new app. Start by editing your './App.ts' file and setting up your route configs in './routeConfig' `,
            fontFace: 'Text',
            fontSize: 20,
            textAlign: 'left',
            wordWrap: true,
            wordWrapWidth: TEXT_WIDTH,
            lineHeight: 36,
          },
        },
      },
    }
  }
}
