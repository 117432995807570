import { Colors, Lightning } from '@lightningjs/sdk'

export interface BubbleTemplateSpec extends Lightning.Component.TemplateSpec {
  Bubble: object
  primaryColor: number
  secondaryColor: number
}

export class Bubble
  extends Lightning.Component<BubbleTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<BubbleTemplateSpec>
{
  Bubble = this.getByRef('Bubble')!
  static override _template(): Lightning.Component.Template<BubbleTemplateSpec> {
    return {
      w: 1920,
      h: 1080,
      x: 0,
      y: 0,
      rect: true,
      rtt: true,
      color: Colors('background').get(),
      Bubble: {
        w: 1920,
        h: 1080,
        x: 0,
        y: 0,
        rect: true,
        shader: {
          type: Lightning.shaders.RadialGradient,
          pivot: [0.8, 1.5, 0.4],
          outerColor: Colors('backgroundGradient').get(),
          innerColor: Colors('primaryHighlight').alpha(0.8).get(),
        },
      },
    }
  }
  override _init() {
    const theme = this.fireAncestors('$theme')
    const FullPageConfig = theme.components.FullPageBackground ?? {}
    if (FullPageConfig.backgroundColor) {
      this.primaryColor = Colors(FullPageConfig.backgroundColor).get()
    }
    if (FullPageConfig.highlightColor) {
      this.secondaryColor = Colors(FullPageConfig.highlightColor).get()
    }
  }
  private _primaryColor: number = Colors('backgroundGradient').get()
  public get primaryColor(): number {
    return this._primaryColor
  }
  public set primaryColor(value: number) {
    this._primaryColor = value
    this.Bubble.patch({
      shader: {
        outerColor: value,
      },
    })
  }
  private _secondaryColor: number = Colors('primaryHighlight').alpha(0.8).get()
  public get secondaryColor(): number {
    return this._secondaryColor
  }
  public set secondaryColor(value: number) {
    this._secondaryColor = value
    this.Bubble.patch({
      shader: {
        innerColor: value,
      },
    })
  }
}
