import { Lightning } from '@lightningjs/sdk'
import { AdeButton, AdeButtonSignals } from '../components'

export class AuthScreenText
  extends Lightning.Component<
    AuthScreenTextTemplateSpec,
    AuthScreenTextTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<AuthScreenTextTemplateSpec>
{
  Title = this.getByRef('Title')!
  Text = this.getByRef('Text')!
  Button = this.getByRef('Button')!

  static override _template(): Lightning.Component.Template<AuthScreenTextTemplateSpec> {
    return {
      x: 0,
      y: 0,
      h: 1000,
      w: 1000,
      alpha: 0,
      flex: {
        direction: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      },
      Title: {
        text: {
          text: '',
          fontFace: 'Bold',
          fontSize: 48,
          wordWrap: false,
        },
      },
      Text: {
        text: {
          text: ``,
          fontFace: 'Text',
          fontSize: 24,
          lineHeight: 36,
          wordWrap: true,
          wordWrapWidth: 1000,
        },
      },
      Button: {
        flexItem: {
          marginTop: 24,
        },
        alpha: 0,
        type: AdeButton,
        buttonText: 'Try Again',
        w: 200,
        h: 60,
        passSignals: {
          selected: true,
          left: true,
        },
      },
    }
  }
  private _hasButton = false
  set hasButton(has: boolean) {
    if (has !== this._hasButton) {
      this._hasButton = has
      this.Button.patch({
        alpha: has ? 1 : 0,
      })
    }
  }
  get hasButton() {
    return this._hasButton
  }

  set wordWrap(wordWrapWidth: number) {
    this.Text.patch({ text: { wordWrapWidth } })
    this.Title.patch({ text: { wordWrapWidth } })
  }

  set description(text: string) {
    this.Text.patch({ text: { text } })
  }
  get description() {
    return this.Text.text?.text ?? ''
  }
  set title(text: string) {
    this.Title.patch({ text: { text } })
  }
  get title() {
    return this.Title.text?.text ?? ''
  }
  set buttonText(buttonText: string) {
    this.Button.patch({ buttonText })
  }
  get buttonText() {
    return this.Button.buttonText
  }

  override _getFocused() {
    return this._hasButton ? this.Button : null
  }
}

export interface AuthScreenTextTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Title: object
  Text: object
  Button: typeof AdeButton
  title: string
  description: string
  buttonText: string
  hasButton: boolean
  wordWrap: number
}

export interface AuthScreenTextTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: AdeButtonSignals
}
