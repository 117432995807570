import { AdeLoadingPage } from '@adiffengine/elements'
import { getMituSdk } from '../mitu-sdk'

export class MituBootPage extends AdeLoadingPage {
  async startup() {
    const sdk = getMituSdk()
    try {
      await sdk.preload()
    } catch (error) {
      console.warn('Preload Error: %s', error.message)
      console.warn(error)
    }
    this.resumeRouterIfNeeded()
  }
}
