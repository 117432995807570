import { SlowPlayerState } from '@adiffengine/engine-types'
import { Colors, Lightning, VideoPlayer } from '@lightningjs/sdk'
import { Debugger } from '../../../lib'
import { getStoredTheme } from '../../../themes/theme'
import { PlayControlButton, PlayControlHoverName } from '../PlayControlButton'
import { HoverZone } from '../../helpers/HoverZone'

const debug = new Debugger('TogglePlayButton')
export interface TogglePlayButtonTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Play: typeof PlayControlButton
  Background: object
  Pause: typeof PlayControlButton
  backgroundDisabled: boolean
  HoverZone: typeof HoverZone
}
export interface TogglePlayButtonTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: {
    hovered(button: 'play'): void
    unhovered(): void
    clicked(): void
  }
}

export class TogglePlayButton
  extends Lightning.Component<TogglePlayButtonTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<TogglePlayButtonTemplateSpec>
{
  private _playerPaused: boolean = true
  private _focusAnimation: Lightning.types.Animation | undefined = undefined
  static override _template(): Lightning.Component.Template<TogglePlayButtonTemplateSpec> {
    const theme = getStoredTheme()

    return {
      w: PlayControlButton.width,
      h: PlayControlButton.height,
      Background: {
        color: Colors(theme.components.PlayControlConfig.playBackground).get(),
        alpha: theme.components.PlayControlConfig.backgroundAlphaUnfocused,
        x: (w: number) => w / 2,
        y: (h: number) => h / 2,
        mount: 0.5,
        scale: theme.components.PlayControlConfig.scale,
        rect: true,
      },
    }
  }
  _handlePaused(state: boolean) {
    if (state === this._playerPaused) return
    this._playerPaused = state
    this.patch({
      Play: {
        visible: state === true,
      },
      Pause: {
        visible: state === false,
      },
    })
    this._refocus()
  }
  set backgroundDisabled(backgroundDisabled: boolean) {
    this.getByRef('Background')!.patch({
      visible: !backgroundDisabled,
    })
  }

  handleStateUpdate(state: SlowPlayerState) {
    debug.info('Handling State Update', state)
    this._handlePaused(state.paused)
  }
  override _firstActive() {
    this.handleStateUpdate = this.handleStateUpdate.bind(this)
    this.stage.application.on('playerState', this.handleStateUpdate)
  }

  override _init() {
    this.patch({
      Background: {
        x: this.w / 2,
        y: this.h / 2,
        mount: 0.5,
        scale: 1.6,
        w: this.w,
        h: this.h,
        rtt: true,
        rect: true,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: this.w / 2,
        },
      },
      Play: {
        x: this.w * 0.05, // Slight offset for the icon
        y: 0,
        w: this.w,
        h: this.h,
        visible: !VideoPlayer.playing,
        action: '$playerControl',
        actionPayload: 'play',
        type: PlayControlButton,
        icon: 'play.svg',
        signals: {
          hovered: '_hovered',
        },
      },
      Pause: {
        x: 0,
        y: 0,
        visible: VideoPlayer.playing,
        w: this.w,
        h: this.h,
        action: '$playerControl',
        actionPayload: 'pause',
        type: PlayControlButton,
        icon: 'pause.svg',
        signals: {
          hovered: '_hovered',
        },
      },
    })
    const theme = this.fireAncestors('$theme')
    this._focusAnimation = this.animation({
      duration: 0.2,
      actions: [
        {
          t: 'Background',
          p: 'alpha',
          v: {
            0: theme.components.PlayControlConfig.backgroundAlphaUnfocused,
            1: theme.components.PlayControlConfig.backgroundAlphaFocused,
          },
        },
      ],
    })
    this._handlePaused(!VideoPlayer.playing)
  }

  override _focus() {
    this._focusAnimation?.start()
  }
  override _unfocus() {
    this._focusAnimation?.stop()
  }

  override _getFocused() {
    return this._playerPaused ? this.tag('Play') : this.tag('Pause')
  }

  _hovered(name: Extract<PlayControlHoverName, 'play' | 'pause'>) {
    this.signal('hovered', name)
  }
}
