import {
  AdePlayerEventMap,
  CoordinateDimensions,
  PlayerState,
} from '@adiffengine/engine-types'
import { Lightning, VideoPlayer } from '@lightningjs/sdk'
import isEqual from 'fast-deep-equal/es6'
import isNumber from 'lodash/isNumber'
import { Entries, Entry } from 'type-fest'
import { EventTypes, PlayerEventPayload } from '../components'
import { Debugger } from '../lib'
const debug = new Debugger('PlayerPage')

export type PlayerPageTypeConfig<
  T extends Lightning.Component.TypeConfig = Lightning.Component.TypeConfig,
> = T & { EventMapType: AdePlayerEventMap }

export abstract class PlayerPage<
  T extends Lightning.Component.TemplateSpec = Lightning.Component.TemplateSpec,
  C extends Lightning.Component.TypeConfig = Lightning.Component.TypeConfig,
> extends Lightning.Component<T, C> {
  public _currentFocus: CoordinateDimensions | null = null
  _videoElement: HTMLVideoElement | null = null
  _playerState: PlayerState = {
    canPlay: false,
    content: null,
    paused: true,
    currentTime: 0,
    duration: 0,
    error: null,
    ended: false,
    adActive: false,
    controlsHidden: false,
  }
  mute() {
    debug.info('Muting video player')
    VideoPlayer.mute()
  }
  updateState(state: Partial<PlayerState>) {
    const newstate: PlayerState = { ...this._playerState, ...state }
    if (!isEqual(newstate, this._playerState)) {
      const emissions: Partial<PlayerState> = Object.entries(newstate).reduce(
        (acc, [k, v]) => {
          if (!isEqual(this._playerState[k as keyof PlayerState], v)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ;(acc as any)[k] = v
          }
          return acc
        },
        {} as Partial<PlayerState>,
      )
      this._playerState = newstate
      ;(Object.entries(emissions) as Entries<Partial<PlayerState>>).forEach(
        entry => {
          const [k, v] = entry as Entry<Partial<PlayerState>>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          this.emit(k, v)
        },
      )
    }
  }
  $jumpToTime(time: number) {
    debug.info('Jump to time %s', VideoPlayer.duration)
    if (time >= 0 && time <= VideoPlayer.duration) {
      VideoPlayer.currentTime = time
    }
  }

  $skip(arg: number) {
    return VideoPlayer.skip(arg)
  }
  $playerControl(event: 'play' | 'pause' | 'togglePaused'): unknown {
    switch (event) {
      case 'pause':
        return VideoPlayer.pause()
      case 'play':
        return VideoPlayer.play()
      case 'togglePaused':
        return this.paused ? VideoPlayer.play() : VideoPlayer.pause()
    }
  }

  $videoState() {
    return this._playerState
  }
  get paused() {
    if (this._videoElement) return this._videoElement.paused
    else return this._playerState.paused
  }

  $videoPlayerEvent(_event: EventTypes, eventData?: PlayerEventPayload) {
    if (eventData) {
      const { videoElement } = eventData
      this._videoElement = videoElement
      const current: Partial<PlayerState> = {
        paused: videoElement.paused,
        currentTime: isNumber(videoElement.currentTime)
          ? videoElement.currentTime
          : 0,
        duration: isNumber(videoElement.duration)
          ? videoElement.duration
          : Infinity,
      }
      this.updateState(current)
    }
  }
}
