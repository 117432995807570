import {
  ContentItem,
  DirectionalSignalMap,
  GridPosition,
  LightningDimensions,
} from '@adiffengine/engine-types'
import { Colors, Img, Lightning, Utils } from '@lightningjs/sdk'
import { Grid } from '@lightningjs/ui'
import { Debugger } from '../lib'
import { getStoredTheme } from '../themes'
import { AdvancedBoxCard, AdvancedGridCardTypes } from './AdvancedGrid'
const debug = new Debugger('MoreGrid')
debug.enabled = true

export interface MoreGridTemplateSpec extends Lightning.Component.TemplateSpec {
  Grid: typeof Grid
  items: ContentItem[]
  spacing: number
  cardType: AdvancedGridCardTypes
  gridSetup: Lightning.Element.PatchTemplate<typeof Grid>
}
interface MoreGridSignalMap extends DirectionalSignalMap {
  contentSelected(content: ContentItem): void
}
export interface MoreGridTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: MoreGridSignalMap
}
export class MoreGrid
  extends Lightning.Component<MoreGridTemplateSpec, MoreGridTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<MoreGridTemplateSpec>
{
  gridSetup: Lightning.Element.PatchTemplate<Lightning.Element.TemplateSpecLoose> =
    {}
  Grid = this.getByRef('Grid')
  private _items: ContentItem[] = []
  public cardType = AdvancedBoxCard
  static override _template(): Lightning.Component.Template<MoreGridTemplateSpec> {
    return {
      Grid: {
        x: 0,
        y: 0,
        columns: 5,
        spacing: 20,
        type: Grid,
        itemType: MoreGridItem,
        signals: {
          onIndexChanged: '_indexChanged',
        },
        passSignals: {
          up: true,
          down: true,
          left: true,
          right: true,
        },
      },
    }
  }

  private _mainIndex = 0
  private _crossIndex = 0

  setUpIndexes() {
    const currentIndex = this.Grid.index
    this._mainIndex = Math.floor(currentIndex / 5)
    this._crossIndex = currentIndex % 5
  }

  _indexChanged(gridChange: GridPosition) {
    this._mainIndex = gridChange.mainIndex
    this._crossIndex = gridChange.crossIndex
  }

  cardDimensions() {
    const width = (this.w - this.spacing * 4) / 5
    const out = {
      w: width,
      h: (width * 9) / 16,
    }
    return out
  }

  set items(items: ContentItem[]) {
    this._items = items
    if (this.__initialized) this.patchGrid()
  }
  get items() {
    return this._items
  }
  patchGrid() {
    const dimensions = this.cardDimensions()
    const gridHeight = dimensions.h * 2 + this.spacing
    const gridWidth = dimensions.w * 5 + this.spacing * 5
    this.patch({
      h: gridHeight,
      w: gridWidth,
    })
    if (this._items && this._items.length > 0) {
      this.Grid.patch({
        visible: true,
        x: 0,
        y: 0,
        columns: 5,
        spacing: this.spacing,
        type: Grid,
        items: this._items
          .map(i => ({
            content: i,
            h: dimensions.h,
            w: dimensions.w,
          }))
          .slice(0, 10),
        h: gridHeight,
        w: gridWidth,
      })
    } else {
      this.Grid.patch({
        visible: false,
      })
    }
  }
  public spacing = 40
  override _init() {
    debug.info('Init')
    this.patchGrid()
  }

  override _getFocused() {
    return this.Grid
  }

  override _captureUp() {
    if (this._mainIndex === 0) return this.signal('up')
    else return false
  }
  override _captureLeft() {
    if (this._crossIndex === 0) return this.signal('left')
    else return false
  }
  override _captureRight() {
    debug.info('Capture Right', this._crossIndex, this.Grid.index)
    if (
      this._crossIndex === 4 ||
      this.Grid.index === this.Grid.items.length - 1
    )
      return this.signal('right')
    else return false
  }
  override _handleEnter() {
    const current = this._items[this.Grid.index]
    if (current) {
      this.signal('contentSelected', current)
    }
  }
}

export interface MoreGridItemTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Focus: object
  Image: object
  DetailsWrapper: {
    Vignette: object
    Text: object
  }
  image: keyof ContentItem['images']
  content: ContentItem
  dimensions: LightningDimensions
}
export interface MoreGridItemTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: DirectionalSignalMap
}
export class MoreGridItem
  extends Lightning.Component<MoreGridItemTemplateSpec, MoreGridItemTypeConfig>
  implements
    Lightning.Component.ImplementTemplateSpec<MoreGridItemTemplateSpec>
{
  Focus = this.getByRef('Focus')!
  Image = this.getByRef('Image')!
  DetailsWrapper = this.getByRef('DetailsWrapper')!
  private _focusAnimation: Lightning.types.Animation | null = null
  public dimensions: LightningDimensions = { w: 240, h: 140 }
  private _content: ContentItem | undefined
  static override _template(): Lightning.Component.Template<MoreGridItemTemplateSpec> {
    const theme = getStoredTheme()
    return {
      Focus: {
        x: 0,
        y: 0,
        rect: true,
        rtt: true,
        alpha: 0.00001,
        color: Colors(theme.palette.highlights[500]).get(),
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 12,
        },
        scale: 1.025,
      },
      Image: {
        x: 4,
        y: 4,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 12,
        },
      },
      DetailsWrapper: {
        x: 0,
        y: 0,
        color: 0x00000000,
        rect: true,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 12,
        },
      },
    }
  }
  public image: keyof ContentItem['images'] = 'wide'

  set content(content: ContentItem) {
    this._content = content
    if (this.__initialized) this.patchContent()
  }
  get content() {
    return this._content!
  }

  patchContent() {
    if (!this.content) return
    const { h, w } = this
    const image = this.content.images[this.image]
    const theme = this.fireAncestors('$theme')

    const baseImageUrl = image ? image!.getForWidth(w) : null
    if (baseImageUrl) {
      const imageUrl = Utils.proxyUrl(baseImageUrl)
      this.Image.patch({
        w: w - 8,
        h: h - 8,
        x: 4,
        y: 4,
        texture: Img(imageUrl).cover(w - 8, h - 8),
      })
    } else {
      this.Image.patch({
        w: w - 8,
        h: h - 8,
        x: 4,
        y: 4,
        rect: true,
        rtt: true,
        colorTop: Colors(theme.palette.darks[500]).get(),
        colorBottom: Colors(theme.palette.darks[800]).get(),
      })
    }
    this.Focus.patch({ w, h })

    this.DetailsWrapper.patch({
      h: h - 8,
      w: w - 8,
      x: 4,
      y: 20,
      alpha: 0.00001,
      Vignette: {
        x: 0,
        y: 0,
        h: this.h - 8,
        w: this.w - 8,
        colorBottom: Colors(theme.palette.darks[900]).get(),
        colorTop: Colors(theme.palette.darks[900]).alpha(0.01).get(),
        rect: true,
        rtt: true,
      },
      Text: {
        mountX: 1,
        mountY: 1,
        x: this.w - 12,
        y: this.h - 12,
        color: Colors('text').get(),
        text: {
          text: this.content.title,
          fontFace: 'Regular',
          fontSize: 24,
          wordWrapWidth: this.w - 24,
          wordWrap: true,
          maxLines: 3,
        },
      },
      shader: {
        type: Lightning.shaders.RoundedRectangle,
        radius: 12,
      },
    })
  }

  override _init() {
    this.patchContent()
  }
  get focusAnimation() {
    if (!this._focusAnimation) {
      this._focusAnimation = this.animation({
        duration: 0.2,
        actions: [
          { t: 'Focus', p: 'alpha', v: { 0: 0.0001, 1: 1 } },
          { t: 'DetailsWrapper', p: 'alpha', v: { 0: 0.0001, 1: 1 } },
          { t: 'DetailsWrapper', p: 'y', v: { 0: 20, 1: 4 } },
        ],
      })
    }
    return this._focusAnimation
  }

  override _focus() {
    this.focusAnimation.start()
  }
  override _unfocus() {
    this.focusAnimation.stop()
  }
}
