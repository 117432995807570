import {
  ContentItem,
  ContentSelectedDirectionalSignalMap,
  Coords,
} from '@adiffengine/engine-types'
import { Colors, Img, Lightning } from '@lightningjs/sdk'
import isNumber from 'lodash/isNumber'
import { getStoredTheme } from '../themes'
// import { Debugger } from '../lib'
// const debug = new Debugger('VC')
export interface VideoCardTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Shadow: object
  PosterFallback: {
    PosterGradient: object
  }
  Poster: object
  Focus: object
  Vignette: object
  InnerContent: {
    Label: {
      Title: object
      Detail: object
    }
  }
  content: ContentItem | null
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars

export interface VideoCardTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: ContentSelectedDirectionalSignalMap
}

export class VideoCard
  extends Lightning.Component<VideoCardTemplateSpec, VideoCardTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<VideoCardTemplateSpec>
{
  private _contentItem: ContentItem | null = null
  private _focusAnimation: Lightning.types.Animation | undefined = undefined

  Shadow = this.getByRef('Shadow')!
  InnerContent = this.getByRef('InnerContent')!
  Vignette = this.getByRef('Vignette')!
  Label = this.InnerContent.getByRef('Label')!
  Title = this.Label.getByRef('Title')!
  Detail = this.Label.getByRef('Detail')!
  Poster = this.getByRef('Poster')!
  PosterFallback = this.getByRef('PosterFallback')!

  static override _template(): Lightning.Component.Template<VideoCardTemplateSpec> {
    const theme = getStoredTheme()
    const {
      components: { VideoCardConfig },
    } = theme
    const radiusShader = {
      type: Lightning.shaders.RoundedRectangle,
      radius: VideoCardConfig.radius,
    }

    return {
      Shadow: {
        alpha: 0,
        mountX: 0.5,
        mountY: 0.5,
        x: (w: number) => w / 2,
        y: (h: number) => h / 2,
        w: (w: number) => w + 32,
        h: (h: number) => h + 32,
        color: Colors('shadow').alpha(0.6).get(),
        rect: true,
        shader: { type: Lightning.shaders.FadeOut, fade: 32 },
      },
      Focus: {
        alpha: 0,
        mountX: 0.5,
        mountY: 0.5,
        x: (w: number) => w / 2,
        y: (h: number) => h / 2,
        w: (w: number) => w + 8,
        h: (h: number) => h + 8,
        rect: true,
        color: Colors(theme.palette.highlights[500]).get(),
        shader: radiusShader,
      },

      PosterFallback: {
        x: 0,
        y: 0,
        w: (w: number) => w,
        h: (h: number) => h,
        rect: true,
        visible: true,
        colorTop: Colors(theme.palette.darks[500]).get(),
        colorBottom: Colors(theme.palette.darks[800]).get(),
        shader: radiusShader,
      },
      Poster: {
        x: 0,
        y: 0,
        w: (w: number) => w,
        h: (h: number) => h,
        rect: true,
        rtt: true,
        alpha: 0.0001,
        shader: radiusShader,
      },
      Vignette: {
        x: 0,
        y: 0,
        w: (w: number) => w,
        h: (h: number) => h,
        alpha: 0.8,
        colorBottom: Colors(VideoCardConfig.vignetteColor).get(),
        colorTop: Colors(VideoCardConfig.vignetteColor).alpha(0.01).get(),
        rect: true,
        rtt: true,
        shader: radiusShader,
      },
      InnerContent: {
        x: 12,
        y: 12,
        w: (w: number) => w - 24,
        h: (h: number) => h - 24,
        clipping: true,
        rect: true,
        rtt: true,
        color: 0x00000000,
        Label: {
          x: 0,
          y: 36,
          w: (w: number) => w,
          h: (h: number) => h,
          flex: {
            direction: 'column',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
          },
          Title: {
            text: {
              advancedRenderer: true,
              text: '',
              fontFace: 'Regular',
              fontSize: 22,
              lineHeight: 24,
              textAlign: 'right',
              wordWrap: true,
              maxLines: 2,
            },
          },
          Detail: {
            text: {
              advancedRenderer: true,
              text: '',
              fontFace: 'Text',
              fontSize: 18,
              lineHeight: 20,
              textAlign: 'right',
              wordWrap: false,
              maxLines: 1,
            },
          },
        },
      },
    }
  }

  set content(v: ContentItem | null) {
    this._contentItem = v
  }
  get content() {
    return this._contentItem
  }

  override _init() {
    const theme = this.fireAncestors('$theme')
    // Ensure we have dimensions since everything flows from this.
    if (!isNumber(this.w) || !isNumber(this.h)) {
      const dimensions = this.fireAncestors('$cardDimensions')
      if (dimensions) {
        this.patch({ w: dimensions.width, h: dimensions.height })
      } else {
        let patch: Record<string, number> = !isNumber(this.w)
          ? { w: VideoCard.width }
          : {}
        if (!isNumber(this.h)) patch = { ...patch, h: VideoCard.height }
        this.patch(patch)
      }
    }

    if (this._contentItem) {
      const {
        components: { VideoCardConfig },
      } = theme
      const radiusShader = {
        type: Lightning.shaders.RoundedRectangle,
        radius: VideoCardConfig.radius,
      }

      this.Label.patch({
        Title: {
          text: {
            text: this._contentItem.title,
            wordWrapWidth: this.w - 24,
          },
        },
        Detail: {
          text: {
            text: this._contentItem.details ?? '',
            wordWrapWidth: this.w - 24,
          },
        },
      })
      let imageUrl = this._contentItem.images.wide?.getForWidth(318)
      if (!imageUrl) imageUrl = this._contentItem.images.box?.getForWidth(318)

      const fallbackImage = () => {
        this.Poster.patch({
          visible: false,
        })
        this.PosterFallback.patch({
          visible: true,
        })
      }

      if (imageUrl != null) {
        this.Poster.patch({
          shader: radiusShader,
          texture: Img(imageUrl).cover(this.w, this.h),
        })
      } else {
        fallbackImage()
      }

      const image = this.tag('Poster')
      if (image) {
        image.on('txLoaded', () => {
          this.tag('Poster')?.setSmooth('alpha', 1)
        })
        image.on('txError', fallbackImage)
      }
      this.Shadow.patch({
        w: this.w + 32,
        h: this.h + 32,
        x: this.w / 2,
        y: this.h / 2,
      })

      this._focusAnimation = this.animation({
        duration: 0.2,
        delay: 0.05,
        actions: [
          { t: 'Shadow', p: 'alpha', v: { 0: 0, 1: 1 } },
          { t: 'Focus', p: 'alpha', v: { 0: 0, 1: 1 } },
          { t: 'InnerContent.Label', p: 'y', v: { 0: 36, 1: 0 } },
        ],
      })
    }
  }

  override _focus() {
    if (this._focusAnimation && this.content) {
      this.signal('contentFocused', this.content)
      this._focusAnimation.start()
    }
  }
  override _unfocus() {
    if (this._focusAnimation) {
      this._focusAnimation.stop()
    }
    this.patch({
      zIndex: 1,
    })
  }
  static width: number = 318
  static height: number = 318 * (9 / 16)
  static cardOffset: Partial<Coords> = {
    x: 16,
    y: 16,
  }
}
