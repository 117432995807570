import { ID, VideoEvent } from './contentTypes'
import { isString } from './utils'

export type MediaTrackedEventName = 'start' | 'elapse' | 'complete'
export interface MediaTracker {
  event: MediaTrackedEventName
}

export interface MediaTrackStart extends MediaTracker {
  event: 'start'
  payload: ID
}
export interface MediaTrackElapsed extends MediaTracker {
  event: 'elapse'
  payload: { id: ID; seconds: number }
}
export interface MediaTrackComplete extends MediaTracker {
  event: 'complete'
  payload: ID
}

export function isMediaTrackEvent(e: EventMediaTrackers): e is MediaTrackEvent {
  return isString((e as MediaTrackEvent).event)
}

export type MediaTrackEvent =
  | MediaTrackStart
  | MediaTrackElapsed
  | MediaTrackComplete
export type EventMediaTrackers = MediaTrackEvent | VideoEvent
export interface DeviceLinkPoller {
  readonly state: 'inactive' | 'polling' | 'canceled'
  poll(interval?: number, timeout?: number): Promise<PollerResponse>
  cancel(): void
}
export interface DeviceLinkPollClass {
  new (deviceId: string, linkCode: string): DeviceLinkPoller
}
export interface PollerResponseSuccess {
  uid: ID
  username?: string
}
export interface PollerResponseCodeUpdate {
  linkCode: string
}
export type PollerResponse = PollerResponseSuccess | PollerResponseCodeUpdate
export function isPollerResponseSuccess(
  response: PollerResponse,
): response is PollerResponseSuccess {
  return isString((response as PollerResponseSuccess).uid)
}
export function isPollerResponseCodeUpdate(
  response: PollerResponse,
): response is PollerResponseCodeUpdate {
  return isString((response as PollerResponseCodeUpdate).linkCode)
}
