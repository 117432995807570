import { Parameters } from '@firebolt-js/sdk'
import { isGoodString } from '../lightning-tools'

export type ProgramEntity =
  | Parameters.TVEpisodeEntity
  | Parameters.TVSeasonEntity
  | Parameters.TVSeriesEntity
  | Parameters.MovieEntity

export function isSearchIntent(
  x?: Parameters.NavigationIntent,
): x is Parameters.SearchIntent {
  return x != null && x.action === 'search'
}
export function isProgramEntity(
  x: Parameters.EntityIntent['data'],
): x is ProgramEntity {
  const { entityType = null } = x as ProgramEntity
  return isGoodString(entityType) && entityType === 'program'
}

export function isTVEpisodeEntity(
  data: Parameters.EntityIntent['data'],
): data is Parameters.TVEpisodeEntity {
  return isProgramEntity(data) && data.programType === 'episode'
}
export function isTVSeasonEntity(
  data: Parameters.EntityIntent['data'],
): data is Parameters.TVSeasonEntity {
  return isProgramEntity(data) && data.programType === 'season'
}
export function isTVSeriesEntity(
  data: Parameters.EntityIntent['data'],
): data is Parameters.TVSeriesEntity {
  return isProgramEntity(data) && data.programType === 'series'
}
export function isMovieEntity(
  data: Parameters.EntityIntent['data'],
): data is Parameters.MovieEntity {
  return isProgramEntity(data) && data.programType === 'movie'
}
