import { DeviceTypes, PartialSettingsFile } from '@adiffengine/engine-types'

export const DeviceSettings: PartialSettingsFile = {
  appSettings: {
    platform: DeviceTypes.LG,
    enablePointer: true,
    keys: {
      '37': 'Left',
      '38': 'Up',
      '39': 'Right',
      '40': 'Down',
      '13': 'Enter',
      '461': 'Back',
      '403': 'ColorA',
      '404': 'ColorB',
      '405': 'ColorC',
      '406': 'ColorD',
      '415': 'Play',
      '19': 'Pause',
      '417': 'FastForward',
      '412': 'Rewind',
    },
    stage: {
      w: '1920',
      h: '1080',
    },
  },
}
