import * as Sentry from '@sentry/browser'
import { Settings } from '@lightningjs/sdk'
// import { Debugger } from '../debugger'
// const debug = new Debugger('Sentry')

export interface SentryOptions {
  id?: string
  tracePropagationTargets?: string[]
}

export async function enableSentry(id?: string) {
  const tracePropagationTargets = ['localhost', /^\//]
  const dsn = Settings.get('app', 'SENTRY_DSN')
  const tracesSampleRate = Settings.get('app', 'SENTRY_TRACE_SAMPLE_RATE', 1)
  const environment = Settings.get('app', 'env', 'production')
  if (dsn) {
    Sentry.init({
      dsn,
      integrations: [new Sentry.BrowserTracing({ tracePropagationTargets })],
      environment,
      // Performance Monitoring
      tracesSampleRate, // Capture 100% of the transactions, reduce in production!
      // Session Replay
    })

    if (id) Sentry.setUser({ id })
  } else {
    console.warn('No sentry DSN so not enabling.')
  }
}
