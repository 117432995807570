import { MetroPlayer } from '@adiffengine/vast-player'
import { Settings } from '@lightningjs/sdk'
import Hls from 'hls.js'
import { Debugger, ThorError } from '../../../lib'
import { AdePlayerPlane } from '../PlayerPlane'
import {
  ADE_CONTAINER_CLASS,
  ADE_CONTAINER_ID,
  ADE_CONTENT_CLASS,
  ADE_VIDEO_CLASS,
  ADE_VIDEO_ID,
  IsBooleanOrString,
} from './types'
const debug = new Debugger('VastLoader')

export class AdeVastPlayerSetup extends AdePlayerPlane {
  _rmp: any | null = null
  _hls: Hls | null = null

  adEvents() {
    return {
      adpodcompleted: this.adEnd.bind(this),
      adtagloaded: this.adTagLoaded.bind(this),
      adstarted: this.adStart.bind(this),
      aderror: this.adError.bind(this),
    }
  }

  adTagLoaded() {
    MetroPlayer.pauseEventListeners()
  }

  adStart() {
    this.adStarted()
  }
  adEnd() {
    debug.info('A Pod Completed Called')
    this.allAdsCompleted()
    MetroPlayer.resumeEventListeners()
  }

  adError() {
    if (this._rmp) {
      const env = this._rmp.getEnvironment()
      const msg = this._rmp.getAdErrorMessage()
      const errorCode = this._rmp.getAdVastErrorCode()
      const type = this._rmp.getAdErrorType()
      new ThorError(`Ad Error: ${msg}`, ThorError.Type.AdError, {
        env: env ?? null,
        msg: msg ?? null,
        errorCode: errorCode ?? null,
        type: type ?? null,
      })
    }
  }

  reset() {
    this.destroyVastInstance()
    this.destroyHlsInstance()
  }
  destroyVastInstance() {
    if (this._rmp !== null) {
      this._rmp.off(Object.keys(this.adEvents()))
      this._rmp.destroy()
      this._rmp = null
    }
  }

  destroyHlsInstance() {
    if (this._hls) {
      this._hls.destroy()
    }
  }
  setupPlayerHtml(video: HTMLVideoElement, hasAds = false) {
    this.reset()
    if (!hasAds) return video
    let container: HTMLDivElement | null = document.body.querySelector(
      `#${ADE_CONTAINER_ID}`,
    )
    if (container === null) {
      container = document.createElement('div') as HTMLDivElement
      container.setAttribute('id', ADE_CONTAINER_ID)
      container.setAttribute('class', ADE_CONTAINER_CLASS)
      this.zeroOut(container)
    }
    let content: HTMLDivElement | null = document.body.querySelector(
      `.${ADE_CONTENT_CLASS}`,
    )
    if (content === null) {
      content = document.createElement('div')
      content.setAttribute('class', ADE_CONTENT_CLASS)
      this.zeroOut(content)
    }
    let videoId = video.getAttribute('id')
    if (videoId === null) {
      videoId = ADE_VIDEO_ID
      video.setAttribute('id', videoId)
    }

    video.setAttribute('playsinline', 'true')
    video.setAttribute('controls', 'false')
    video.setAttribute('class', ADE_VIDEO_CLASS)
    video.parentNode?.insertBefore(container, video)
    container.appendChild(content)
    content.appendChild(video)
    return video
  }

  // * UTILS * //
  getCanvasWidth<T extends boolean | undefined = false>(
    string?: T,
  ): IsBooleanOrString<T> {
    let width = parseInt(Settings.get('platform', 'width', 1920), 10)
    if (isNaN(width)) width = 1920
    return this.withPrecision(width, string) as IsBooleanOrString<T>
  }

  getCanvasHeight<T extends boolean | undefined = false>(
    string?: T,
  ): IsBooleanOrString<T> {
    let height = parseInt(Settings.get('platform', 'height', 1080), 10)
    if (isNaN(height)) height = 1080
    return this.withPrecision(height, string) as IsBooleanOrString<T>
  }
  withPrecision<T extends boolean | undefined = false>(
    val: number,
    string?: T,
  ): IsBooleanOrString<T> {
    const precision = this.stage.getRenderPrecision()
    const value = Math.round(precision * val)
    return (string === true ? `${value}px` : value) as IsBooleanOrString<T>
  }
  zeroOut(element: HTMLDivElement | HTMLVideoElement) {
    element.style.position = 'absolute'
    element.style.zIndex = '1'
    element.style.top = this.withPrecision(0, true)
    element.style.left = this.withPrecision(0, true)
    element.style.width = this.getCanvasWidth(true)
    element.style.height = this.getCanvasHeight(true)
    return element
  }
}
