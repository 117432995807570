import {
  ContentItem,
  ContentSelectedDirectionalSignalMap,
} from '@adiffengine/engine-types'
import { Colors, Lightning, Utils } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import equal from 'fast-deep-equal/es6'
import {
  Debugger,
  PaletteColor,
  getCoordinateDimensions,
  isGoodArray,
  isGoodNumber,
  isGoodString,
  prettyTime,
} from '../lib'

import defer from 'lodash/defer'
import {
  AdeImage,
  AlbumEpisodeModal,
  StackModalTemplateSpec,
  StackModalTypeConfig,
  StandardBackground,
  WideButton,
  getStoredTheme,
} from '..'
import { AdeButton } from './AdeButton'
import {
  StackModal,
  StackModalImplement,
  StackModalTemplate,
} from './StackModal'
const debug = new Debugger('AlbumPlayerModal')
export interface AlbumPlayerModalTemplateSpec extends StackModalTemplateSpec {
  Shadow: object
  InnerWrapper: object
  BackgroundWrapper: {
    ThemedBackground: typeof StandardBackground
  }
  Content: {
    Art: typeof AdeImage
    ContentList: typeof AlbumPlayerModalContentList
  }
  content: ContentItem
}
const MODAL_HEIGHT = 800
const MODAL_WIDTH = 1400
const MODAL_X = (1920 - MODAL_WIDTH) / 2
const MODAL_Y = (1080 - MODAL_HEIGHT) / 2
const MODAL_PADDING = 48
const MODAL_IMAGE_DIMENSION = 400

const CONTENT_X = MODAL_IMAGE_DIMENSION + MODAL_PADDING * 2

const CONTENT_Y = MODAL_PADDING
const CONTENT_WIDTH = MODAL_WIDTH - MODAL_PADDING * 3
const CONTENT_HEIGHT = MODAL_HEIGHT - MODAL_PADDING * 2
const CONTENT_LIST_WIDTH =
  CONTENT_WIDTH - MODAL_IMAGE_DIMENSION - MODAL_PADDING * 2

const NOART_CONTENT_LIST_WIDTH = CONTENT_WIDTH - MODAL_PADDING * 2

export type AlbumPlayerModalTypeConfig = StackModalTypeConfig

export class AlbumPlayerModal
  extends StackModal<AlbumPlayerModalTemplateSpec, AlbumPlayerModalTypeConfig>
  implements StackModalImplement<AlbumPlayerModalTemplateSpec>
{
  static override ModalName = 'AlbumPlayerModal'
  Content = this.tag('Content')!
  Art = this.Content.tag('Art')!
  ContentList = this.Content.tag('ContentList')!
  ThemedBackground = this.tag('BackgroundWrapper')!.tag('ThemedBackground')!

  static override _template(): StackModalTemplate<AlbumPlayerModalTemplateSpec> {
    const theme = getStoredTheme()
    return {
      ...super._template(),
      Shadow: {
        x: MODAL_X + MODAL_WIDTH / 2 + 12,
        y: MODAL_Y + MODAL_HEIGHT / 2 + 12,
        w: MODAL_WIDTH + 96,
        h: MODAL_HEIGHT + 96,
        mountX: 0.5,
        mountY: 0.5,
        color: Colors(theme.palette.darks[600]).alpha(1).get(),
        rect: true,
        rtt: true,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 24,
        },
        alpha: 0.0001,
        texture: Lightning.Tools.getShadowRect(
          MODAL_WIDTH + 96,
          MODAL_HEIGHT + 96,
          24,
          24,
          48,
        ),
      },
      InnerWrapper: {
        x: MODAL_X,
        y: MODAL_Y,
        w: MODAL_WIDTH,
        h: MODAL_HEIGHT,
        rect: true,
        rtt: true,
        color: Colors(theme.palette.darks[700]).get(),
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 24,
        },
      },
      BackgroundWrapper: {
        x: MODAL_X,
        y: MODAL_Y,
        w: MODAL_WIDTH,
        h: MODAL_HEIGHT,
        rect: true,
        rtt: true,
        color: Colors('background').get(),
        ThemedBackground: {
          // alpha: 0.0001,
          type: StandardBackground,
          x: 0,
          y: 0,
          w: MODAL_WIDTH,
          h: MODAL_HEIGHT,
          // colorTop: Colors(theme.palette.darks[600]).get(),
          // colorBottom: Colors(theme.palette.darks[200]).get(),
          // rect: true,
          // rtt: true,
        },
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 24,
        },
      },
      Content: {
        x: MODAL_X,
        y: MODAL_Y,
        w: MODAL_WIDTH,
        h: MODAL_HEIGHT,
        Art: {
          type: AdeImage,
          borderRadius: 24,
          x: MODAL_PADDING,
          y: MODAL_PADDING,
          w: MODAL_IMAGE_DIMENSION,
          h: MODAL_IMAGE_DIMENSION,
          signals: {
            palette: 'paletteSignal',
          },
        },
        ContentList: {
          x: CONTENT_X,
          y: CONTENT_Y,
          w: CONTENT_WIDTH,
          h: CONTENT_HEIGHT - 20,
          type: AlbumPlayerModalContentList,
          signals: {
            contentSelected: 'contentSelected',
          },
        },

        shader: {
          type: Lightning.shaders.FadeOut,
          top: 10,
          bottom: 10,
        },
      },
    }
  }

  paletteSignal(save: PaletteColor[]) {
    const palette = [...save]
    const back = palette.pop()
    const mid = palette[Math.floor(palette.length / 2)]
    const colorBottom = mid ? mid.color : back!.complement
    this.ThemedBackground.patch({
      smooth: {
        colorBottom: Colors(mid ? mid.color : back!.complement).get(),
        colorTop: Colors(back!.color).get(),
      },
    })
    debug.info('Color Bottom: %s Color Top %s', colorBottom, back!.color)
    this.fireAncestors('$setCurrentPalette', save)
  }

  override _inactive() {
    this.fireAncestors('$setCurrentPalette', null)
  }

  resetTheme() {
    const theme = this.fireAncestors('$theme')
    this.ThemedBackground.patch({
      smooth: {
        colorTop: Colors(theme.palette.darks[600]).get(),
        colorBottom: Colors(theme.palette.darks[200]).get(),
      },
    })
    this.fireAncestors('$setCurrentPalette', null)
  }

  private _content: ContentItem | undefined = undefined
  set content(content: ContentItem) {
    if (!equal(content, this._content)) {
      this.resetTheme()
    }
    let imageSrc = content.images?.thumb?.getForWidth(300)
    if (imageSrc) {
      imageSrc = Utils.proxyUrl(imageSrc)
    }
    const imageFailover = () => {
      this.ContentList.patch({
        x: 0,
        w: this.Content.finalW,
        noArt: true,
      })
    }
    this.Art.on('txError', imageFailover)
    if (imageSrc) {
      this.ContentList.patch({
        x: CONTENT_X,
        w: CONTENT_WIDTH,
        noArt: false,
      })
      this.Art.patch({
        imageSrc,
      })
    }

    this.ContentList.patch({
      w: this.Content.finalW - 440,
      content,
    })
  }

  override _getFocused() {
    return this.ContentList
  }

  contentSelected(item: ContentItem) {
    if (item)
      this.fireAncestors(
        '$setModalContent',
        AlbumEpisodeModal.ModalName,
        item,
        true,
      )
    return false
  }
}

export interface AlbumPlayerModalContentListTemplateSpec
  extends Lightning.Component.TemplateSpec {
  ListWrapper: {
    List: typeof List
  }
  content: ContentItem
  noArt: boolean
}

export interface AlbumPlayerModalContentListTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: ContentSelectedDirectionalSignalMap
}
export class AlbumPlayerModalContentList
  extends Lightning.Component<
    AlbumPlayerModalContentListTemplateSpec,
    AlbumPlayerModalContentListTypeConfig
  >
  implements
    Lightning.Component
      .ImplementTemplateSpec<AlbumPlayerModalContentListTemplateSpec>
{
  ListWrapper = this.tag('ListWrapper')!
  List = this.ListWrapper.tag('List')!
  static override _template(): Lightning.Component.Template<AlbumPlayerModalContentListTemplateSpec> {
    return {
      ListWrapper: {
        x: 0,
        y: 0,
        w: CONTENT_WIDTH,
        h: CONTENT_HEIGHT,
        rect: true,
        rtt: true,
        color: 0x0000000,
        List: {
          x: 20,
          y: 0,
          h: CONTENT_HEIGHT - 20,
          w: CONTENT_WIDTH,
          type: List,
          direction: 'column',
          spacing: 20,
        },
      },
    }
  }

  private _noArt = false
  set noArt(noArt: boolean) {
    if (this._noArt !== noArt) {
      this._noArt = noArt
      if (noArt) this._setState('NoArt')
      else this._setState('Art')
    }
  }

  get noArt() {
    return this._noArt
  }

  reposition(h: number) {
    this.List.reposition()
    if (this._detailsItem) this._detailsItem.h = h
  }

  private _items: Lightning.Element.PatchTemplate[] = []
  set items(items: Lightning.Element.PatchTemplate[]) {
    this._items = items
    this.renderItems()
  }
  renderItems() {
    const items: Lightning.Element.PatchTemplate[] = this._items
    if (this._detailsItem) items.unshift(this._detailsItem)
    items.forEach(
      i => (i.w = this.noArt ? NOART_CONTENT_LIST_WIDTH : CONTENT_LIST_WIDTH),
    )
    this.List.clear()
    debug.info('Rendering', items)
    this.List.add(items)
  }
  private _detailsItem: Lightning.Element.PatchTemplate | undefined = undefined
  private _content: ContentItem | null = null
  set content(item: ContentItem) {
    this._content = item
    this._items = []
    this._detailsItem = {
      w: CONTENT_LIST_WIDTH,
      h: 60,
      type: AlbumPlayerModalContent,
      content: item,
      signals: {
        reposition: this.reposition.bind(this),
      },
    }
    const { children } = item
    const kids = isGoodArray<ContentItem>(children)
      ? children.map(item => ({
          type: WideButton,
          w: CONTENT_LIST_WIDTH,
          h: 60,
          leftText: item.title,
          rightText:
            item.details ??
            (item.media && isGoodNumber(item.media[0]?.duration)
              ? prettyTime(item.media[0].duration)
              : ''),
        }))
      : null
    debug.info('Kids', kids)
    if (kids !== null) this.items = kids
    else {
      this.renderItems()
      this.fireAncestors('$fetchEpisodes', item).then(episodes => {
        debug.info('Got episodes', episodes)
        const items = episodes
          .sort((a: { pub_date_ms: number }, b: { pub_date_ms: number }) => {
            return a.pub_date_ms - b.pub_date_ms
          })
          .map(
            (item: {
              title: any
              details: any
              media: { duration: number }[]
            }) => ({
              type: WideButton,
              w: CONTENT_LIST_WIDTH,
              h: 60,
              leftText: item.title,
              rightText:
                item.details ?? isGoodNumber(item.media[0]?.duration)
                  ? prettyTime(item.media[0].duration)
                  : '',
            }),
          )
        if (this._content) {
          this._content.children = episodes
        }
        this.items = items
      })
    }
  }
  get content() {
    return this._content!
  }
  override _captureLeft(): boolean | void {
    debug.info('Capturing Left', this.List.index)
    if (this.List.index !== 0) {
      return this.signal(
        'left',
        getCoordinateDimensions(this.List.items[this.List.index]),
      )
    } else {
      return false
    }
  }
  override _captureRight(): boolean | void {
    debug.info('Capturing Right', this.List.index)
    if (this.List.index !== 0) {
      return this.signal(
        'right',
        getCoordinateDimensions(this.List.items[this.List.index]),
      )
    } else {
      return false
    }
  }
  override _captureUp(): boolean | void {
    if (this.List.index === 0) {
      return this.signal(
        'up',
        getCoordinateDimensions(this.List.items[this.List.index]),
      )
    }
    return false
  }
  override _captureDown(): boolean | void {
    if (this.List.index >= this.List.items.length - 1) {
      return this.signal(
        'down',
        getCoordinateDimensions(this.List.items[this.List.index]),
      )
    }
    return false
  }
  override _captureEnter(): boolean | void {
    if (this.List.index > 0) {
      const index = this.List.index + 1
      const item = Array.isArray(this.content.children)
        ? this.content.children[index]
        : null
      if (item) {
        if (!isGoodArray(item.playlist) && isGoodArray(this.content.children)) {
          item.playlist = this.content.children.filter(i => i.id !== item.id)
        }
        return this.signal('contentSelected', item)
      }
    }
  }
  override _getFocused() {
    return this.List
  }
}

export interface AlbumPlayerModalContentTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Text: {
    Title: object
    Description: object
    Details: object
    Buttons: typeof List
  }
  content: ContentItem
  noArt: boolean
}
export interface AlbumPlayerModalContentTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: {
    reposition(h?: number): void
  }
}

export class AlbumPlayerModalContent
  extends Lightning.Component<
    AlbumPlayerModalContentTemplateSpec,
    AlbumPlayerModalContentTypeConfig
  >
  implements
    Lightning.Component
      .ImplementTemplateSpec<AlbumPlayerModalContentTemplateSpec>
{
  Text = this.tag('Text')!
  Title = this.Text.tag('Title')!
  Description = this.Text.tag('Description')!
  Details = this.Text.tag('Details')!

  Buttons = this.Text.tag('Buttons')!

  static override _template(): Lightning.Component.Template<AlbumPlayerModalContentTemplateSpec> {
    return {
      x: 0,
      y: 0,
      w: 1184,
      Text: {
        x: 0,
        y: 0,
        w: (w: number) => w - 40,
        flex: {
          direction: 'column',
        },
        Title: {
          visible: false,
          color: Colors('text').get(),
          text: {
            wordWrapWidth: CONTENT_LIST_WIDTH,
            fontFace: 'Bold',
            fontSize: 36 * 1.25,
            wordWrap: true,
            maxLines: 2,
          },
          flexItem: {
            marginBottom: 24,
          },
        },
        Description: {
          flexItem: {
            marginTop: 0,
            marginBottom: 24,
          },
          visible: false,
          color: Colors('text').get(),
          text: {
            wordWrapWidth: CONTENT_LIST_WIDTH,
            fontFace: 'Text',
            fontSize: 20,
            lineHeight: 24 * 1.5,
            wordWrap: true,
            maxLines: 4,
          },
        },
        Details: {
          flexItem: {
            marginTop: 0,
          },
          visible: false,
          color: Colors('text').get(),
          text: {
            wordWrapWidth: CONTENT_LIST_WIDTH,
            fontFace: 'Regular',
            fontSize: 16,
            wordWrap: true,
            maxLines: 1,
          },
        },
        Buttons: {
          flexItem: {
            marginTop: 24,
            marginBottom: 24,
          },
          w: CONTENT_LIST_WIDTH,
          type: List,
          direction: 'row',
          spacing: 24,
          items: [
            {
              ref: 'Favorite',
              type: AdeButton,
              buttonText: 'FAVORITE',
              leftIcon: `add-circle.svg`,
              signals: {
                selected: 'favorite',
              },
            },
          ],
        },
      },
    }
  }
  override _construct() {
    this.favorite = this.favorite.bind(this)
    this.playNext = this.playNext.bind(this)
  }
  favorite() {
    debug.info('Favorite')
  }
  playNext() {
    debug.info('Play Next')
  }

  private _noArt = false
  set noArt(noArt: boolean) {
    if (this._noArt !== noArt) {
      this._noArt = noArt
      if (noArt) this._setState('NoArt')
      else this._setState('Art')
    }
  }
  get noArt() {
    return this._noArt
  }

  set content(item: ContentItem) {
    this.Title.patch({
      visible: isGoodString(item.title),
      text: {
        text: isGoodString(item.title) ? item.title.toUpperCase().trim() : '',
      },
    })
    this.Description.patch({
      visible: isGoodString(item.description),
      text: {
        text: isGoodString(item.description)
          ? item.description.replace(/<\/?[^>]+(>|$)/gi, '').trim()
          : '',
      },
    })
    this.Details.patch({
      visible: isGoodString(item.details),
      text: {
        text: isGoodString(item.details) ? item.details : '',
      },
    })
    if (isGoodArray(this.children)) {
      this.Buttons.patch({
        items: [
          {
            ref: 'Favorite',
            type: AdeButton,
            buttonText: 'FAVORITE',
            leftIcon: `add-circle.svg`,
            signals: {
              selected: this.favorite,
            },
          },
          {
            ref: 'PlayNext',
            type: AdeButton,
            buttonText: 'PLAY NEXT',
            leftIcon: `play-sharp.svg`,

            signals: {
              selected: this.playNext,
            },
          },
        ],
      })
    }
    defer(() => {
      this.patch({
        w: CONTENT_LIST_WIDTH,
        h: this.Text.finalH,
      })
      ;(this as any).collectionWrapper?.reposition()
      this.signal('reposition', this.Text.finalH)
    })
  }
  override _getFocused() {
    return this.Buttons
  }

  static override _states(): (typeof AlbumPlayerModalContent)[] {
    return [
      class NoArt extends this {
        override $enter() {
          this.Text.patch({ w: NOART_CONTENT_LIST_WIDTH })
          this.Title.patch({
            text: { wordWrapWidth: NOART_CONTENT_LIST_WIDTH },
          })
          this.Details.patch({
            text: { wordWrapWidth: NOART_CONTENT_LIST_WIDTH },
          })
          this.Description.patch({
            text: { wordWrapWidth: NOART_CONTENT_LIST_WIDTH },
          })
        }
        override $exit() {
          this.Text.patch({ w: CONTENT_LIST_WIDTH })
          this.Title.patch({
            text: { wordWrapWidth: CONTENT_LIST_WIDTH },
          })
          this.Details.patch({
            text: { wordWrapWidth: CONTENT_LIST_WIDTH },
          })
          this.Description.patch({
            text: { wordWrapWidth: CONTENT_LIST_WIDTH },
          })
        }
      },
    ]
  }
}
