import { ThemeConfig } from '@adiffengine/engine-types'
import { Colors, Lightning } from '@lightningjs/sdk'
import { Debugger, SettingsKey } from '../lib'

import { Toggle, ToggleSignalMap } from './Toggle'
const debug = new Debugger('TR')
export class ToggleRow
  extends Lightning.Component<ToggleRowTemplateSpec, ToggleRowTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<ToggleRowTemplateSpec>
{
  set label(text: string) {
    this.tag('Content.LabelWrapper.Label')?.patch({
      text: { text },
    })
  }
  set description(text: string) {
    this.tag('Content.LabelWrapper.Description')?.patch({
      visible: true,
      text: { text },
    })
  }
  private _settingsKey: SettingsKey | null = null
  private _settingsValue: 'on' | 'off' = 'off'

  set settingsKey(key: SettingsKey) {
    this._settingsKey = key
  }
  set settingsValue(value: 'on' | 'off') {
    debug.info('Setting value in settingsValue to %s', value)
    this._settingsValue = value
    this.tag('Content.Toggle')?.patch({
      settingState: value,
    })
  }
  // static height = 200

  static override _template(): Lightning.Component.Template<ToggleRowTemplateSpec> {
    return {
      h: 90,
      Background: {
        mountX: 0.5,
        mountY: 0.5,
        x: (w: number) => w / 2,
        y: (w: number) => w / 2,
        w: (w: number) => w,
        h: (w: number) => w,
        rect: true,
      },
      Content: {
        x: 36,
        y: 18,
        w: (w: number) => w - 64,
        flex: {
          direction: 'row',
          alignItems: 'center',
        },
        LabelWrapper: {
          flexItem: {
            grow: 1,
          },
          flex: {
            direction: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
          },
          Label: {
            w: 400,
            text: {
              text: 'Setting 1',
              fontFace: 'Regular',
              fontSize: 24,
            },
          },
          Description: {
            w: 400,
            visible: false,
            text: {
              text: '',
              fontFace: 'Text',
              fontSize: 18,
            },
          },
        },
        Toggle: {
          type: Toggle,
        },
      },
    }
  }

  private _focusAnimation: Lightning.types.Animation | null = null
  themeable(skipPatch = false) {
    const theme = this.fireAncestors('$theme')

    if (!skipPatch) {
      this.patch({
        Background: {
          color: Colors(theme.palette.darks[400]).get(),
          shader: {
            type: Lightning.shaders.RoundedRectangle,
            radius: theme.components.SimpleCardConfig.radius,
          },
        },
      })
    }

    this._focusAnimation = this.animation({
      duration: 0.2,
      actions: [
        {
          t: 'Background',
          p: 'color',
          v: {
            0: Colors(theme.palette.darks[400]).get(),
            1: Colors(theme.palette.highlights[500]).get(),
          },
        },
        {
          t: 'Background',
          p: 'scaleX',
          v: {
            0: 1,
            1: 1.0125,
          },
        },
        {
          t: 'Background',
          p: 'scaleY',
          v: {
            0: 1,
            1: 1.05,
          },
        },
        {
          t: 'Content.LabelWrapper.Label',
          p: 'color',
          v: {
            0: Colors(theme.palette.text).get(),
            1: Colors(theme.palette.darks[800]).get(),
          },
        },
        {
          t: 'Content.LabelWrapper.Description',
          p: 'color',
          v: {
            0: Colors(theme.palette.text).get(),
            1: Colors(theme.palette.darks[800]).get(),
          },
        },
      ],
    })
  }

  themeChanged(newTheme: ThemeConfig, oldTheme?: ThemeConfig) {
    if (oldTheme && newTheme && this.attached) {
      this.animation({
        duration: 0.2,
        actions: [
          {
            t: 'Background',
            p: 'color',
            v: {
              0: Colors(oldTheme.palette.darks[400]).get(),
              1: Colors(newTheme.palette.darks[400]).get(),
            },
          },
          {
            t: 'Background',
            p: 'color',
            v: {
              0: Colors(oldTheme.palette.darks[400]).get(),
              1: Colors(newTheme.palette.darks[400]).get(),
            },
          },
          {
            t: 'Background',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            p: 'shader.radius',
            v: {
              0: oldTheme.components.SimpleCardConfig.radius,
              1: newTheme.components.SimpleCardConfig.radius,
            },
          },
        ],
      }).start()
      if (this.attached) {
        this.themeable(true)
      }
    } else if (this.attached) {
      this.themeable()
    }
  }

  override _init() {
    if (this._settingsKey !== null) {
      const value = this.fireAncestors('$setting', this._settingsKey)
      if (value === true || value === false) {
        this.settingsValue = value === true ? 'on' : 'off'
      }
    }
  }
  override _focus() {
    this._focusAnimation?.start()
  }
  override _unfocus() {
    this._focusAnimation?.stop()
  }
  override _handleEnter() {
    console.info('Got enter, toggling %s', this._settingsKey)
    if (this._settingsKey !== null) {
      const newState = this._settingsValue === 'on' ? 'off' : 'on'
      this.fireAncestors('$setting', this._settingsKey, newState === 'on')
      this.settingsValue = newState
    }
  }
}
export interface ToggleRowTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Background: object
  Content: {
    LabelWrapper: {
      Label: object
      Description: object
    }
    Toggle: typeof Toggle
  }
  label: string
  description: string
  settingsKey: SettingsKey
  settingsValue: 'on' | 'off'
}
export interface ToggleRowTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: ToggleSignalMap
}
