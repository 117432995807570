import { ThemeConfig } from '@adiffengine/engine-types'
import { AppInstance } from '@lightningjs/sdk/src/Application'
import isObject from 'lodash/isObject'
import { SettingsStore } from '../lib'
import { baseTheme } from './baseTheme'
import { excitementTheme } from './excitementTheme'
import { poppyTheme } from './poppyTheme'

export const Themes = [baseTheme, excitementTheme, poppyTheme]

export interface ThemeStore {
  theme: ThemeConfig['name']
}

let globalTheme: ThemeConfig | null
export function setGlobalTheme(theme: ThemeConfig) {
  globalTheme = theme
}
export function getStoredTheme(): ThemeConfig {
  if (isObject(AppInstance) && (AppInstance as any)._theme) {
    // eslint-disable-next-line
    return (AppInstance as any)._theme as ThemeConfig
  } else if (globalTheme) {
    return globalTheme
  }

  const themeName = SettingsStore.get('theme')
  const foundTheme = Themes.find(({ name }) => name === themeName)
  return foundTheme ?? baseTheme
}

export interface ThemeFont {
  family: string
  url: string
}
export function getThemeFonts(): ThemeFont[] {
  const out = (Object.values(Themes) as Array<ThemeConfig>).reduce(
    (acc, theme) => {
      const { name, ...rest } = theme.fonts
      acc = acc.concat(
        Object.entries(rest).map(([family, url]) => ({
          family: `${name}${family}`,
          url,
        })),
      )
      return acc
    },
    [] as ThemeFont[],
  )
  return out
}
