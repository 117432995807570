import { Metrics } from '@firebolt-js/sdk'
import { Settings } from '@lightningjs/sdk'
import { isCodedError } from './coded-error'
import { defer } from './utils'

export enum ThorErrorType {
  Warning,
  NotAuthorized,
  NotAuthenticated,
  GridError,
  DetailsError,
  EpisodeDetailsError,
  SeasonDetailsError,
  SearchError,
  UnknownError,
  ConvertedUnknownError,
  RelatedError,
  PlayerDetailsError,
  ParameterError,
  NotFoundError,
  PlaybackError,
  NoMedia,
  BadData,
  FunctionNotImplemented,
  AdError,
}

export enum ErrorClassification {
  Network,
  Authentication,
  Unknown,
  Playback,
  Parsing,
  Warning,
}

export interface ErrorDetails {
  code: number
  displayCode: string
  description: string
  classification: ErrorClassification
  debugDescription: string
  visible: boolean
}

const FireboltErrorDictionairy: Record<ErrorClassification, Metrics.ErrorType> =
  {
    [ErrorClassification.Authentication]: Metrics.ErrorType.ENTITLEMENT,
    [ErrorClassification.Network]: Metrics.ErrorType.NETWORK,
    [ErrorClassification.Parsing]: Metrics.ErrorType.OTHER,
    [ErrorClassification.Playback]: Metrics.ErrorType.MEDIA,
    [ErrorClassification.Unknown]: Metrics.ErrorType.OTHER,
    [ErrorClassification.Warning]: Metrics.ErrorType.OTHER,
  }
export function getFireboltErrorType(error: Error): Metrics.ErrorType {
  if (isThorError(error)) {
    return error.fireboltError
  } else if (isCodedError(error)) {
    return error.errorType
  } else {
    return Metrics.ErrorType.OTHER
  }
}

export const ThorErrors: Record<ThorErrorType, ErrorDetails> = {
  [ThorErrorType.NotAuthenticated]: {
    code: 401,
    displayCode: 'AUTH_1',
    description: 'You have to log in to view this content.',
    visible: true,
    classification: ErrorClassification.Authentication,
    debugDescription: 'User has to log in to perform this action.',
  },

  [ThorErrorType.Warning]: {
    code: 201,
    displayCode: 'WARN_1',
    description: 'Warning for tracking purposes, this should never be seen.',
    visible: false,
    classification: ErrorClassification.Warning,
    debugDescription: 'Warning for tracking purposes.',
  },
  [ThorErrorType.AdError]: {
    code: 401,
    displayCode: 'AD_1',
    description: 'An advertising error occurred',
    visible: false,
    classification: ErrorClassification.Playback,
    debugDescription:
      'An ad error ocurred, this should not be visible to the user outside of the ad not playing.',
  },
  [ThorErrorType.NotAuthorized]: {
    code: 401,
    displayCode: 'AUTH_2',
    description: 'You are not authorized to view this content.',
    visible: true,
    classification: ErrorClassification.Authentication,
    debugDescription: 'User is not entitled in to perform this action.',
  },
  [ThorErrorType.GridError]: {
    code: 400,
    displayCode: 'NET_1',
    description:
      'We are unable to access the service at this time. Please try again later.',
    debugDescription:
      'Can not load grid feed at this time. Check network logs and contact provider to confirm that their service is up.',
    visible: true,
    classification: ErrorClassification.Network,
  },

  [ThorErrorType.DetailsError]: {
    code: 400,
    displayCode: 'NET_2',
    description:
      'We are unable to load the details for this content at this time.  Please try again later.',
    debugDescription:
      'Can not load details at this time. Check network logs and contact provider to confirm that their service is up.',
    visible: true,
    classification: ErrorClassification.Network,
  },

  [ThorErrorType.SearchError]: {
    code: 400,
    displayCode: 'NET_3',
    description:
      'Unable to complete your search request at this time. Please try again later.',
    debugDescription:
      'Can not perform search at this time. Check network logs and contact provider to confirm that their service is up.',
    visible: true,
    classification: ErrorClassification.Network,
  },
  [ThorErrorType.PlayerDetailsError]: {
    code: 400,
    displayCode: 'NET_4',
    description:
      'We are unable to load the details for this content at this time.  Please try again later.',
    visible: true,
    classification: ErrorClassification.Network,
    debugDescription: 'Can not play content. Check error logs for details.',
  },
  [ThorErrorType.NotFoundError]: {
    code: 404,
    displayCode: 'NET_4',
    description:
      'We are unable to load the details for this content at this time.  Please try again later.',
    visible: true,
    classification: ErrorClassification.Network,
    debugDescription:
      'Content lookup failed, can not find content as specified. Check error logs for details.',
  },
  [ThorErrorType.RelatedError]: {
    code: 400,
    displayCode: 'NET_5',
    description:
      'We are unable to load the details for this content at this time.  Please try again later.',
    visible: false,
    classification: ErrorClassification.Network,
    debugDescription:
      'Can not find related content. Check error logs for details.',
  },
  [ThorErrorType.ParameterError]: {
    code: 400,
    displayCode: 'NET_6',
    description:
      'Invalid parameter, unable to load content.  Please try again later.',
    visible: true,
    classification: ErrorClassification.Unknown,
    debugDescription:
      'Invalid or missing parameter in request. Check error logs for details.',
  },
  [ThorErrorType.SeasonDetailsError]: {
    code: 400,
    displayCode: 'NET_8',
    description:
      'We are unable to load the details for this season at this time.  Please try again later.',
    visible: true,
    classification: ErrorClassification.Network,
    debugDescription:
      'Can not load season details. Check network logs and contact provider to confirm that their service is up.',
  },
  [ThorErrorType.EpisodeDetailsError]: {
    code: 400,
    displayCode: 'NET_9',
    description:
      'We are unable to load the details for this episode at this time.  Please try again later.',

    visible: true,
    classification: ErrorClassification.Network,
    debugDescription:
      'Can not load episode details. Check network logs and contact provider to confirm that their service is up.',
  },
  [ThorErrorType.PlaybackError]: {
    code: 500,
    displayCode: 'VID_1',
    description:
      'We are unable to play this content at this time. Please try again later.',
    visible: true,
    classification: ErrorClassification.Playback,
    debugDescription: 'Can not play content. Check error logs for details.',
  },
  [ThorErrorType.NoMedia]: {
    code: 404,
    displayCode: 'PRS_1',
    description:
      'We are unable to play this content at this time. Please try again later.',
    visible: true,
    classification: ErrorClassification.Parsing,
    debugDescription:
      'Can not find playable media for content. Provider needs to confirm that the data is properly formatted and returned in the response.',
  },
  [ThorErrorType.BadData]: {
    code: 500,
    displayCode: 'PRS_2',
    description:
      'We are unable to play this content at this time. Please try again later.',
    debugDescription:
      'Provider API returned invalid content. Notify provider to check API services.',
    visible: true,
    classification: ErrorClassification.Parsing,
  },
  [ThorErrorType.FunctionNotImplemented]: {
    code: 500,
    displayCode: 'UNKNOWN',
    description: 'An unknown error ocurred.',
    visible: true,
    classification: ErrorClassification.Unknown,
    debugDescription:
      'Function is not implemented, this is a development error.',
  },
  [ThorErrorType.UnknownError]: {
    code: 500,
    displayCode: 'UNKNOWN',
    description: 'An unknown error ocurred.',
    visible: true,
    classification: ErrorClassification.Unknown,
    debugDescription: 'An Unknown Error Occurred',
  },
  [ThorErrorType.ConvertedUnknownError]: {
    code: 501,
    displayCode: 'UNKNOWN',
    description: 'An unknown error ocurred.',
    visible: true,
    classification: ErrorClassification.Unknown,
    debugDescription: 'An Unknown Error Occurred',
  },
}
export function convertErrorToThorError(
  e: Error,
  type = ThorErrorType.ConvertedUnknownError,
): ThorError {
  if (isThorError(e)) return e
  else {
    return new ThorError(e.message, type, {
      originalError: e,
    })
  }
}

export const PlaybackErrors: ThorErrorType[] = [ThorErrorType.PlaybackError]

export type ErrorHandler = (message: string, error: ThorError) => void

export class ThorError extends Error {
  static _lastError: ThorError | null = null
  static _errorHandlers: ErrorHandler[] = []
  static Types = ThorErrorType
  static addErrorHandler(handler: ErrorHandler) {
    ThorError.removeErrorHandler(handler)
    this._errorHandlers.push(handler)
    return () => {
      ThorError.removeErrorHandler(handler)
    }
  }
  static removeErrorHandler(handler: ErrorHandler) {
    this._errorHandlers = this._errorHandlers.filter(e => e !== handler)
  }
  static handleError(error: ThorError) {
    this._lastError = error
    this._errorHandlers.forEach(h => h(error.message, error))
  }
  static lastError(clear = true) {
    defer(() => {
      if (clear) this._lastError = null
    })
    return this._lastError
  }

  static Type = ThorErrorType
  static getErrorTitle(details: ErrorDetails): string {
    switch (details.classification) {
      case ErrorClassification.Authentication:
        return 'Authentication Error'
      case ErrorClassification.Network:
        return 'Network Error'
      case ErrorClassification.Unknown:
        return 'Unknown Error'
      case ErrorClassification.Playback:
        return 'Playback Error'
      case ErrorClassification.Parsing:
        return 'Unknown Error'
      case ErrorClassification.Warning:
        return 'Warning'
    }
  }

  public readonly type: ThorErrorType
  public readonly details: Record<string, unknown> = {}
  private errorDetails: ErrorDetails
  public readonly title: string
  private _displayCode: string
  public readonly isThorError: boolean = true
  constructor(
    msg: string,
    type: ThorErrorType,
    details: Record<string, unknown> = {},
  ) {
    super(msg)
    this.type = type
    this.details = details
    this.errorDetails = ThorErrors[type]
    this.title = ThorError.getErrorTitle(this.errorDetails)
    const AppErrorCode = Settings.get('app', 'APP_ERROR_CODE', 'ADE')
    this._displayCode = `${AppErrorCode.toUpperCase()}_${
      this.errorDetails.displayCode
    }`
    if (type !== ThorErrorType.Warning) {
      ThorError.handleError(this)
    }
    console.warn('Thor Error thrown: %s', msg, { error: this })
  }
  get code() {
    return this.errorDetails.code
  }
  get displayCode() {
    return this._displayCode
  }
  get visible() {
    return this.errorDetails.visible
  }
  get description() {
    return this.errorDetails.description
  }
  get fireboltError(): Metrics.ErrorType {
    return FireboltErrorDictionairy[this.errorDetails.classification]
  }

  static printErrors(app = 'ADE') {
    let out = `
  | Code      | Title | Description | Care Notes |
  |-----------|-------|-------------|------------|`
    Object.values(ThorErrors).forEach(e => {
      out += `
| ${app}_${e.displayCode} | ${ThorError.getErrorTitle(e)} | ${
        e.description
      } | ${e.debugDescription} |`
    })
    console.info(`
    ${out}
    `)
  }
}

export function isThorError(error?: unknown): error is ThorError {
  return error != null && error instanceof ThorError
}

export class ThorWarning extends ThorError {}
