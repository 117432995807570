import {
  PageTrackingEvent,
  SearchEvent,
  VideoEvent,
  VideoTrackingEvent,
  VideoViewTrackingEvent,
  isVideoRateChangeEvent,
  isVideoRenditionChangeEvent,
} from '@adiffengine/engine-types'
import type { ThorMetrics } from '../metrics'
import { Settings } from '@lightningjs/sdk'
import Plausible from 'plausible-tracker'
import { Debugger } from '../../debugger'
const debug = new Debugger('plausible')
type PlausibleInstance = ReturnType<typeof Plausible>
type PlausibleEventPayload = {
  [propName: string]: string | number | boolean
}

function converVideoEventToEventData(e: VideoEvent) {
  const {
    video_id,
    video_title,
    video_position,
    video_duration,
    video_progress,
    video_event,
  } = e
  let out: PlausibleEventPayload = {
    video_event,
    video_id,
    video_title,
    video_position: video_position ?? false,
    video_duration: video_duration ?? false,
    video_progress: video_progress ?? false,
  }

  if (isVideoRateChangeEvent(e)) {
    out['bitrate'] = e.rate_data.bitrate
  } else if (isVideoRenditionChangeEvent(e)) {
    out = {
      ...out,
      ...e.rendition_data,
    }
  }
  return out
}

export class PlausibleTracker {
  private _instance: PlausibleInstance
  private _platform: string
  constructor(metrics: ThorMetrics, plausibleInstance: PlausibleInstance) {
    this._platform = Settings.get('app', 'platform', 'smart-tv')
    this._instance = plausibleInstance

    this.screen_view_event = this.screen_view_event.bind(this)
    metrics.on('screen_view_event', e => {
      debug.info('Screen View Event', e)
      this.screen_view_event(e)
    })
    metrics.on('video_playback_event', e => {
      this._instance.trackEvent('video_playback_event')
      debug.info('Video Playback Event', e)

      this.video_playback_event(e)
    })
    metrics.on('video_view_event', e => {
      debug.info('Video View Event', e)
      this.video_view_event(e)
    })
  }
  search_event(event: SearchEvent) {
    this._instance.trackEvent(event.type, {
      props: { ...event.payload, platform: this._platform },
    })
  }

  screen_view_event(event: PageTrackingEvent) {
    this._instance.trackEvent('screen_view', {
      props: { ...event.payload, platform: this._platform },
    })
    this._instance.trackPageview(
      {
        hashMode: true,
      },
      {
        props: {
          platform: this._platform,
        },
      },
    )
  }
  video_playback_event(event: VideoTrackingEvent) {
    const props = converVideoEventToEventData(event.payload)
    this._instance.trackEvent('video_playback_event', {
      props: { ...props, platform: this._platform },
    })
  }
  video_view_event(event: VideoViewTrackingEvent) {
    this._instance.trackEvent('video_view_event', {
      props: { ...event.payload, platform: this._platform }, // this is just a hack to get around some type index issues.
    })
  }
  static trackEvents(metrics: ThorMetrics): PlausibleTracker | null {
    const plausibleUrl = Settings.get('app', 'PLAUSIBLE_DOMAIN')
    const apiHost = Settings.get(
      'app',
      'PLAUSIBLE_HOST',
      'https://clicks.adetv.co',
    )
    if (plausibleUrl) {
      const env = Settings.get('app', 'env', 'production')
      const plausible = Plausible({
        domain: plausibleUrl,
        apiHost,
        hashMode: true,
        trackLocalhost: env === 'development',
      })
      return new PlausibleTracker(metrics, plausible)
    }
    return null
  }
}
