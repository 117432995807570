import { Settings, Registry } from '@lightningjs/sdk'
import Emittery from 'emittery'
import { Debugger } from '../../lib'
const debug = new Debugger('UserKeyMonitor')
export type UserActivityState = 'inactive' | 'active'
export interface KeyMonitorEvents {
  reset: number
  timedout: undefined
  stateChange: UserActivityState
}

export class UserKeyMonitor extends Emittery<KeyMonitorEvents> {
  private _timeoutInMS: number
  private _timer: ReturnType<typeof Registry.setTimeout> | null = null

  constructor(timeoutInMinutes?: number) {
    super()
    if (timeoutInMinutes) this._timeoutInMS = timeoutInMinutes * 60000
    else {
      const timeout = Settings.get(
        'app',
        'APP_TIMEOUT_DURATION_IN_MINUTES',
        150,
      )
      this._timeoutInMS = timeout * 60000
    }
  }
  private _state: UserActivityState = 'active'
  get state() {
    return this._state
  }
  set state(state: UserActivityState) {
    if (this._state !== state) {
      debug.info('Setting state to %s, from: %s', state, this._state)
      this._state = state
      this.emit('stateChange', state)
    }
  }
  private _clearTimer() {
    if (this._timer !== null) Registry.clearTimeout(this._timer)
    this._timer = null
  }
  private _resetTimer(skipEmit = false) {
    this._clearTimer()
    this._timer = Registry.setTimeout(() => {
      this._timer = null
      this.state = 'inactive'
      this.emit('timedout')
    }, this._timeoutInMS)
    if (!skipEmit === true)
      this.emit('reset', Math.round(this._timeoutInMS / 60000))
  }

  start() {
    this._resetTimer(true)
  }

  ping() {
    this._resetTimer()
    this.state = 'active'
  }
  destroy() {
    this._clearTimer()
  }
}
