import { Storage } from '@lightningjs/sdk'
// import { Debugger } from '../lib'
// const debug = new Debugger('FullPageModal')

const defaultSettings = {
  tvOn: true,
  moviesOn: true,
  theme: 'ADE Base Theme',
  staticHero: false,
}
export type Settings = typeof defaultSettings
export type SettingsKey = keyof Settings

export class SettingsStorage {
  get<T extends keyof Settings>(setting: T): Settings[T] {
    const stored = Storage.get(setting)
    if (stored === undefined) {
      Storage.set(setting, defaultSettings[setting])
      return defaultSettings[setting]
    } else {
      return stored as Settings[T]
    }
  }

  set<T extends keyof Settings>(setting: T, value: Settings[T]): Settings[T] {
    Storage.set(setting, value)
    return value
  }
}
export const SettingsStore = new SettingsStorage()
