import { ContentItem, DirectionalSignalMap } from '@adiffengine/engine-types'
import { Lightning } from '@lightningjs/sdk'
import { Debugger } from '../lib'
import { MoreGrid } from './MoreGrid'
const debug = new Debugger('RelatedGrid')

interface GridPosition {
  x: number
  y: number
}

export interface RelatedGridTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Title: object
  Grid: typeof MoreGrid
  gridPosition: Partial<GridPosition>
  title: string
  content: ContentItem[]
}
export interface RelatedGridTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: DirectionalSignalMap
}
export class RelatedGrid
  extends Lightning.Component<RelatedGridTemplateSpec, RelatedGridTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<RelatedGridTemplateSpec>
{
  Title = this.getByRef('Title')!
  Grid = this.getByRef('Grid')!
  static override _template(): Lightning.Component.Template<RelatedGridTemplateSpec> {
    return {
      Title: {
        x: 0,
        y: 0,
        h: 60,
        text: {
          text: 'More Like This',
          fontFace: 'Bold',
          fontSize: 36,
        },
      },
      Grid: {
        x: 0,
        y: 80,
        type: MoreGrid,
        passSignals: {
          contentSelected: true,
          up: true,
          down: true,
          left: true,
          right: true,
        },
      },
    }
  }
  private _position: GridPosition = { x: 0, y: 0 }
  set gridPosition(position: Partial<GridPosition>) {
    this._position = { ...this._position, ...position }
    this.Grid.patch({
      x: this.gridPosition.x,
      y: this.gridPosition.y,
      w: this.w - (this.gridPosition.x ?? 0) * 2,
      h: this.h,
      items: this._content,
    })
    this.Title.patch({
      x: this.gridPosition.x,
    })
  }
  get gridPosition() {
    return this._position
  }
  set title(text: string) {
    this.Title.patch({
      x: this.gridPosition.x,
      text: { text: text.toUpperCase() },
    })
  }
  private _content: ContentItem[] = []
  set content(content: ContentItem[]) {
    this.Grid.patch({
      items: content,
    })
    this._content = content
    debug.info('Set Grid Position', this.Grid)
  }
  override _getFocused() {
    return this.Grid
  }
}
