import { LightningDimensions } from '@adiffengine/engine-types'
import { Colors, Img, Lightning, Utils } from '@lightningjs/sdk'

import isEqual from 'fast-deep-equal/es6'
import { PaletteColor, getImagePalette, getPaletteWorkerURL } from '..'
import { Debugger, getBestDimensionValue } from '../lib'
import { LocalCache } from '../lib/cache'

const debug = new Debugger('AdeImage')
debug.enabled = false

export interface AdeImageTemplateSpec extends Lightning.Component.TemplateSpec {
  Image: object
  Overlay: object
  imageSrc: string
  borderRadius: number
  generatePalette: boolean
  debugPalette: boolean
}
export interface AdeImageSignals {
  palette(palette: PaletteColor[]): void
  loaded(): void
  error(error?: Error): void
}
interface ImageTracker extends LightningDimensions {
  url?: string
}

const cache = new LocalCache<PaletteColor[]>()
export interface AdeImageTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: AdeImageSignals
}

export class AdeImage
  extends Lightning.Component<AdeImageTemplateSpec, AdeImageTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<AdeImageTemplateSpec>
{
  Image = this.getByRef('Image')!
  static override _template(): Lightning.Component.Template<AdeImageTemplateSpec> {
    return {
      rect: true,
      rtt: true,
      color: 0x00000000,
      Image: {
        rect: true,
        rtt: true,
        x: 0,
        y: 0,
      },
      shader: {
        type: Lightning.shaders.RoundedRectangle,
        radius: 0,
      },
    }
  }

  public generatePalette: boolean = false
  public debugPalette: boolean = false
  private _currentImageSpecs: ImageTracker = {
    h: 0,
    w: 0,
  }
  private _imageSrc: string | undefined = undefined
  private _url: string | undefined
  set imageSrc(src: string) {
    if (src !== this._imageSrc) {
      this._url = Utils.proxyUrl(src)
      this.renderImage()
    }
  }
  get imageSrc() {
    return this._imageSrc!
  }
  get url() {
    return this._url
  }

  private _borderRadius: number = 0
  set borderRadius(radius: number) {
    this.patch({
      shader: {
        radius,
      },
    })
  }
  get borderRadius() {
    return this._borderRadius
  }

  private _palette: PaletteColor[] | null = null
  set palette(palette: PaletteColor[] | null) {
    if (!isEqual(palette, this._palette)) {
      if (palette) {
        this.signal('palette', [...palette]) // send a copy so no one hoses it.
        this._palette = palette
        if (this.debugPalette) {
          const h = getBestDimensionValue(this, 'h')
          const items = getDebugOverlayItems(palette, h / 4)
          this.DebugOverlay.childList.clear()
          items.forEach(i => this.DebugOverlay.childList.add(this.stage.c(i)))
        }
      }
      if (this._imageSrc && this.palette)
        cache.set(this._imageSrc, this.palette)
    }
  }

  get DebugOverlay() {
    let Overlay = this.getByRef('Overlay')
    if (!Overlay) {
      const w = getBestDimensionValue(this, 'h')
      const h = getBestDimensionValue(this, 'w')
      Overlay = this.stage.c({
        ref: 'Overlay',
        x: 0,
        y: 0,
        h,
        w,
        flex: {
          direction: 'row',
          wrap: true,
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        },
      })
      this.childList.add(Overlay)
    }
    return Overlay
  }

  override _construct() {
    this.onErrorHandler = this.onErrorHandler.bind(this)
    this.onLoaded = this.onLoaded.bind(this)
  }
  override _init() {
    this.Image.on('txError', this.onErrorHandler)
    this.Image.on('txLoaded', this.onLoaded)
  }

  override _active() {
    // we want to pick up updates to w/h settings
    this.renderImage()
  }

  renderImage() {
    const w = getBestDimensionValue(this, 'w')
    const h = getBestDimensionValue(this, 'h')
    const update: ImageTracker = {
      w,
      h,
      url: this.url,
    }
    // We may end up calling this a lot, so we don't
    // want to keep thrashing on the image.
    if (!isEqual(update, this._currentImageSpecs)) {
      this._currentImageSpecs = update
      this._loadedOnce = false
      if (this.url) {
        debug.info(
          'Rendering (w: %s, h: %s) image url %s ',
          w,
          h,
          this.url,
          this,
        )
        this.Image.patch({
          alpha: 1,
          w,
          h,
          texture: Img(this.url).cover(w, h),
        })
      } else {
        this.Image.patch({
          alpha: 0,
        })
      }
    }
  }

  onErrorHandler(error: Error) {
    this.signal('error', error)
  }
  private _loadedOnce: boolean = false
  onLoaded() {
    debug.info('Loaded!', this._loadedOnce)
    if (!this._loadedOnce) {
      debug.info('Signaling loaded')
      this.signal('loaded')
      this._loadedOnce = true
    }
  }

  getPalette() {
    if (this.generatePalette !== true) return
    if (this._imageSrc && this._imageSrc !== '') {
      try {
        const canvas = this.stage.platform.getDrawingCanvas()
        const offscreen = canvas.transferControlToOffscreen()
        if (typeof Worker !== undefined) {
          const worker = new Worker(getPaletteWorkerURL(this._imageSrc))
          worker.addEventListener('message', evt => {
            const palette = evt.data as PaletteColor[]
            this.palette = palette
          })
          worker.postMessage(offscreen, [offscreen])
        } else {
          const image = new Image()
          image.addEventListener('load', () => {
            const canvas = this.stage.platform.getDrawingCanvas()
            canvas.width = image.width
            canvas.height = image.height
            const ctx = canvas.getContext('2d')
            if (ctx === null) {
              console.warn('Context is null, not getting palette')
              return
            }
            ctx.drawImage(image, 0, 0)
            const imageData = ctx.getImageData(
              0,
              0,
              canvas.width,
              canvas.height,
            )
            this.palette = getImagePalette(imageData)
          })
          if (!Lightning.Utils.isPS4) {
            image.crossOrigin = 'Anonymous'
            image.src = this._imageSrc
          }
        }
      } catch (error) {
        console.warn(
          'Error getting the palette %s, ignoring for now.',
          error.message,
        )
      }
    }
  }
}

function getDebugOverlayItems(
  palette: PaletteColor[],
  dimension: number,
): Lightning.Element.PatchTemplate[] {
  console.warn('Palette Image Debug On.')
  return palette.map((color, idx) => ({
    rect: true,
    rtt: true,
    color: Colors(color.color).get(),
    h: dimension,
    w: dimension,
    Text: {
      w: dimension,
      color: Colors(color.complement).get(),
      text: {
        text: `${idx}`,
        fontSize: dimension * 0.6,
        fontFace: 'Text',
        textAlign: 'center',
        lineHeight: dimension,
      },
    },
  }))
}
