/*
 * If not stated otherwise in this file or this component's LICENSE file the
 * following copyright and licenses apply:
 *
 * Copyright 2021 Metrological
 *
 * Licensed under the Apache License, Version 2.0 (the License);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Lightning, Router } from '@lightningjs/sdk'

export interface AdePageTemplateSpec extends Lightning.Component.TemplateSpec {
  Content: object
}

export interface AdePageTypeConfig extends Lightning.Component.TypeConfig {
  IsPage: true
}

export class AdePage
  extends Lightning.Component<AdePageTemplateSpec, AdePageTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<AdePageTemplateSpec>
{
  static override _template(): Lightning.Component.Template<AdePageTemplateSpec> {
    return {
      Content: {},
    }
  }

  override _focus() {
    this.setSmooth('alpha', 1)
  }

  override _unfocus() {
    this.setSmooth('alpha', 0)
  }

  override _handleDown() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    Router.focusWidget('Menu')
  }

  static get header() {
    return 'AdePage'
  }

  static get icon() {
    return 'images/splash.png'
  }
}
