import { ActionConfig, Direction, ID } from '@adiffengine/engine-types'
import { Colors, Img, Lightning } from '@lightningjs/sdk'
import isString from 'lodash/isString'
import { Debugger, isGoodNumber } from '../lib'
import { getStoredTheme } from '../themes'
import { SimpleCardText } from './SimpleCardText'

export interface SimpleCardType extends Lightning.Component.TypeConfig {
  SignalMapType: {
    focus(): void
    unfocus(): void
  }
}

export interface SimpleCardTemplateConfig {
  contentId?: ID
  title: string
  description?: string | null
  details?: string | null
  imageSrc?: string | null
  padding?: number
  imageWidthRatio?: number
  action?: ActionConfig

  disabled?: boolean
  fontName?: string
}

export interface SimpleCardSpec
  extends Lightning.Component.TemplateSpec,
    SimpleCardTemplateConfig {
  Background: object
  Focus: object
  Shadow: object
  ImageWrapper: {
    Image: object
  }
  Text: typeof SimpleCardText
  FocusText: typeof SimpleCardText
}

export type SimpleCardPatch =
  Lightning.Element.PatchTemplate<SimpleCardSpec> & {
    type?: typeof SimpleCard
  }

const debug = new Debugger('SC')
export const SIMPLE_CARD_BASE_WIDTH = 240
export const SIMPLE_CARD_BASE_HEIGHT = 80
export const SIMPLE_CARD_BASE_PADDING = 16
const fontSizeHeightSwitch = 180

export class SimpleCard extends Lightning.Component<
  SimpleCardSpec,
  SimpleCardType
> {
  public direction: Direction = 'horizontal'
  public imageWidthRatio = 1
  Focus = this.tag('Focus')!
  Background = this.tag('Background')!
  Text = this.tag('Text')!
  static override _template(): Lightning.Component.Template<SimpleCardSpec> {
    const theme = getStoredTheme()
    const {
      components: { SimpleCardConfig },
    } = theme
    const cardRadiusShader = {
      type: Lightning.shaders.RoundedRectangle,
      radius: SimpleCardConfig.radius,
    }
    return {
      x: 0,
      y: 0,
      w: SIMPLE_CARD_BASE_WIDTH,
      h: SIMPLE_CARD_BASE_HEIGHT,
      Shadow: {
        alpha: 0,
        mountX: 0.5,
        mountY: 0.5,
        rect: true,
        color: Colors(theme.palette.darks[900]).get(),
        shader: cardRadiusShader,
      },
      Focus: {
        x: 0,
        y: 0,
        alpha: 0,
        rect: true,
        shader: {
          ...cardRadiusShader,
          fillColor: Colors(theme.palette.highlights[500]).get(),
        },
      },
      Background: {
        x: 0,
        y: 0,
        alpha: 1,
        rect: true,
        rtt: true,
        shader: {
          ...cardRadiusShader,
          fillColor: Colors(theme.palette.darks[700]).get(),
        },
      },
      ImageWrapper: {
        w: SIMPLE_CARD_BASE_HEIGHT - 16,
        h: SIMPLE_CARD_BASE_HEIGHT - 16,
        x: 8,
        y: 8,
        shader: cardRadiusShader,
        Image: {
          x: 0,
          y: 0,
          rtt: true,
          w: SIMPLE_CARD_BASE_HEIGHT - 16,
          h: SIMPLE_CARD_BASE_HEIGHT - 36,
        },
      },
      Text: {
        y: 8,
        x: 8,
        w: SIMPLE_CARD_BASE_WIDTH - 16,
        h: SIMPLE_CARD_BASE_HEIGHT - 16,
        type: SimpleCardText,
      },
    }
  }
  get cardHeight(): number {
    return isGoodNumber(this.finalH) ? this.finalH : SIMPLE_CARD_BASE_HEIGHT
  }
  get cardWidth() {
    return isGoodNumber(this.finalW) ? this.finalW : SIMPLE_CARD_BASE_WIDTH
  }
  set title(text: string) {
    this.Text.patch({ title: text })
  }

  private _disabled: boolean = false

  set disabled(disabled: boolean) {
    if (disabled) {
      this.getByRef('Background')?.patch({
        alpha: 0.6,
      })
    }
    this._disabled = disabled
  }
  get disabled() {
    return this._disabled
  }

  set description(text: string | null) {
    this.Text.patch({ description: text })
  }
  set details(text: string) {
    this.Text.patch({ details: text })
  }

  private _imageSrc: string | null = null
  set imageSrc(src: string | null) {
    this._imageSrc = src
    if (this.__initialized) this.patchForDimensions()
  }
  private _focusAnimation: Lightning.types.Animation | null = null
  get focusAnimation(): Lightning.types.Animation {
    if (!this._focusAnimation) {
      this._focusAnimation = this.animation({
        duration: 0.2,
        actions: [
          {
            t: 'Focus',
            p: 'alpha',
            v: { 0: 0, 1: 1 },
          },
          { t: 'Shadow', p: 'alpha', v: { 0: 0, 1: 0.3 } },
        ],
      })
    }
    return this._focusAnimation
  }

  override _active() {
    this.patchForDimensions()
  }
  private _padding: number = SIMPLE_CARD_BASE_PADDING
  set padding(padding: number) {
    this._padding = padding
    if (this.__initialized) this.patchForDimensions()
  }
  get padding() {
    return this._padding
  }

  patchForDimensions() {
    const width = this.cardWidth
    const height = this.cardHeight

    const imageHeight = this._imageSrc
      ? height - this.padding * 2
      : SIMPLE_CARD_BASE_HEIGHT
    const imageWidth = imageHeight * this.imageWidthRatio

    if (this.cardHeight > fontSizeHeightSwitch) {
      this.Text.patch({ largeFonts: true })
    }

    const textX = this._imageSrc ? imageWidth + this.padding * 2 : this.padding
    const textWidth = width - textX - this.padding

    this.patch({
      Shadow: {
        h: height,
        w: width,
        x: width / 2,
        y: height / 2,
      },
      ImageWrapper: {
        x: this.padding,
        y: this.padding,
        w: imageWidth,
        h: imageHeight,
        Image: {
          w: imageWidth,
          h: imageHeight,
          texture: isString(this._imageSrc)
            ? Img(this._imageSrc).cover(imageWidth, imageHeight)
            : undefined,
        },
      },
      Background: {
        w: width - this.padding,
        x: this.padding / 2,
        y: this.padding / 2,
        h: height - this.padding,
      },
      Focus: {
        w: width,
        x: 0,
        y: 0,
        h: height,
      },
      Text: {
        largeFonts: this.cardHeight > fontSizeHeightSwitch,
        width: textWidth,
        x: this._imageSrc ? imageWidth + this.padding * 3 : this.padding,
        y: this.padding * 2,
      },
      FocusText: {
        largeFonts: this.cardHeight > fontSizeHeightSwitch,
        width: textWidth,
        x: this._imageSrc ? imageWidth + this.padding * 2 : this.padding,
        y: this.padding,
      },
    })
  }
  private _action: ActionConfig | undefined = undefined
  set action(x: ActionConfig | undefined) {
    this._action = x
  }
  get action() {
    return this._action
  }

  override _handleEnter(): false | void {
    debug.info('Handing Enter', this.action)
    if (this.action) {
      this.fireAncestors(this.action.action, this.action.payload)
    } else {
      return false
    }
  }
  override _focus() {
    this.signal('focus')
    this.focusAnimation.start()
  }

  override _unfocus() {
    this.signal('unfocus')
    this.focusAnimation.stop()
  }
}
