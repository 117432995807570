import { Lightning } from '@lightningjs/sdk'
import {
  AdeErrorMessage,
  AdeErrorPageTemplateSpec,
  AdeErrorPageTypeConfig,
} from '../components/ErrorHandler/AdeErrorScreen'

export class NotFoundPage extends Lightning.Component<
  AdeErrorPageTemplateSpec,
  AdeErrorPageTypeConfig
> {
  Wrapper = this.getByRef('Wrapper')!
  static override _template(): Lightning.Component.Template<AdeErrorPageTemplateSpec> {
    return {
      Wrapper: {
        x: (1920 - AdeErrorMessage.width) / 2,
        y: (1080 - AdeErrorMessage.height) / 2,
        w: AdeErrorMessage.width,
        h: AdeErrorMessage.height,
        type: AdeErrorMessage,
        title: 'Not Found',
        description:
          'Our apologies, you should not be here, please try again later.',
        noExit: true,
      },
    }
  }

  override _getFocused() {
    return this.Wrapper
  }
  override _active() {
    this.stage.application.emit('screenView', 'Not Found', 'not_found')
  }
}
