import {
  ContentItem,
  DetailsContext,
  DetailsResponse,
} from '@adiffengine/engine-types'
import { Lightning } from '@lightningjs/sdk'
import isEqual from 'fast-deep-equal/es6'

export type ContentPageTypeConfig<
  T extends Lightning.Component.TypeConfig = Lightning.Component.TypeConfig,
> = T & {
  IsPage: true
  content: ContentItem
}
export type ContentPageTemplateSpec<
  T extends Lightning.Component.TemplateSpec = Lightning.Component.TemplateSpec,
  // eslint-disable-next-line @typescript-eslint/ban-types
> = T & {}

export abstract class ContentPage<
  T extends Lightning.Component.TemplateSpec = Lightning.Component.TemplateSpec,
  S extends Lightning.Component.TypeConfig = Lightning.Component.TypeConfig,
> extends Lightning.Component<
  ContentPageTemplateSpec<T>,
  ContentPageTypeConfig<S>
> {
  abstract setContent<
    T extends Record<string, unknown> = Record<string, unknown>,
  >(content: ContentItem | null, extraParams?: T): void
  private _currentContent: DetailsResponse | null = null
  public skipReload = false

  async reloadDetails(params: DetailsContext['params']) {
    try {
      const { type, id, ...data } = params
      const content = await this.fireAncestors('$reloadDetails', { type, id })
      if (content && !isEqual(content.meta, this._currentContent)) {
        // Lets not thrash unless updated
        this._currentContent = content
        this.setContent(content.meta, data)
      }
    } catch (error) {
      console.warn('Error loading details %s', error.message, error)
    }
  }

  public contentType: ContentItem['type'] = 'movie'
  private _lastParams: { id: string; type: ContentItem['type'] } | null = null
  override set params(args: NonNullable<typeof this._lastParams>) {
    if (!isEqual(args, this._lastParams)) this.setContent(null)
    this._lastParams = args
    const { id, type = this.contentType, ...rest } = args
    if (id && type) {
      const currentContent = this.fireAncestors('$currentContent', id, type)
      if (currentContent) {
        this.setContent(currentContent, rest)
      }
      this.reloadDetails({ id, type, ...rest })
    } else {
      console.warn('No id and type params passed to Details Page')
    }
  }
}
