import { FocusableMixin } from '@adiffengine/engine-types'
import { Colors, Lightning } from '@lightningjs/sdk'
import { Debugger } from '../../../lib'
import { HoverZone } from '../../helpers/HoverZone'
import { AdvancedGridMainMenuScrollButtons } from './AdavancedGridMainMenuButtons'
const debug = new Debugger('AdvancedGridScrollBar')
export interface AdvancedGridScrollBarTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Shadow: object
  Background: object
  HoverZone: typeof HoverZone
  Content: {
    Buttons: typeof AdvancedGridMainMenuScrollButtons
  }
  handleInactivePointer: boolean
}
export interface AdvancedGridScrollBarTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: FocusableMixin<{
    up(): void
    down(): void
  }>
}
export class AdvancedGridScrollBar
  extends Lightning.Component<
    AdvancedGridScrollBarTemplateSpec,
    AdvancedGridScrollBarTypeConfig
  >
  implements
    Lightning.Component
      .ImplementTemplateSpec<AdvancedGridScrollBarTemplateSpec>
{
  static width = 96
  static height = AdvancedGridMainMenuScrollButtons.height + 80
  Content = this.getByRef('Content')!
  Buttons = this.Content.getByRef('Buttons')!
  static override _template(): Lightning.Component.Template<AdvancedGridScrollBarTemplateSpec> {


    return {
      collision: true,
      zIndex: 10,
      Shadow: {
        alpha: 0.6,
        mountX: 0.5,
        mountY: 0.5,
        x: (w: number) => w / 2,
        y: (h: number) => h / 2,
        w: (w: number) => w + 32,
        h: (h: number) => h + 32,
        color: Colors('background').alpha(0.4).get(),
        rect: true,
        rtt: true,
        shader: { type: Lightning.shaders.FadeOut, fade: 32 },
      },
      Background: {
        x: 0,
        w: this.width,
        h: this.height,
        clipping: true,
        rect: true,
        rtt: true,
        color: Colors('background').get(),
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: this.width / 2,
        },
      },
      HoverZone: {
        x: 0,
        y: 0,
        w: this.width,
        h: this.height,
        type: HoverZone,
        signals: {
          hovered: '_zoneHover',
        },
      },
      Content: {
        x: (this.width - AdvancedGridMainMenuScrollButtons.width) / 2,
        y: 0,
        h: this.height,
        w: AdvancedGridMainMenuScrollButtons.width,
        rect: true,
        color: 0x00000000,
        Buttons: {
          x: 0,
          y: (this.height - AdvancedGridMainMenuScrollButtons.height) / 2,
          w: AdvancedGridMainMenuScrollButtons.width,
          h: AdvancedGridMainMenuScrollButtons.height,
          handleInactivePointer: false,
          type: AdvancedGridMainMenuScrollButtons,
        },
      },
    }
  }
  _zoneHover() {
    debug.info('Zone Hovered %s', new Date().getTime().toString().slice(-5))
    return false
  }
  _handleUnhover() {
    this.signal('unhovered')
  }
  override _handleHover() {
    this.signal('hovered')
    return true
  }
  _handleInactivePointer = true
  set handleInactivePointer(handleInactivePointer: boolean) {
    if (this._handleInactivePointer !== handleInactivePointer) {
      this.Buttons.patch({ handleInactivePointer })
    }
  }
  get handleInactivePointer() {
    return this.Buttons.handleInactivePointer
  }
}
