import {
  AuthStatus,
  CoordinateDimensions,
  StoreDeviceLink,
  isPollerResponseCodeUpdate,
  setsByY,
} from '@adiffengine/engine-types'
import { Colors, Lightning, Router } from '@lightningjs/sdk'
import defer from 'lodash-es/defer'
import { AdeButton, MainMenu } from '../components'
import { AdeQrCode } from '../components/AdeQrCode'
import { Debugger } from '../lib'
import { getStoredTheme } from '../themes'
import { AuthScreenText } from './AuthScreenText'
const debug = new Debugger('AuthScreen')

export interface AuthScreenTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Content: {
    Background: object
    Linking: {
      InstructionText: {
        Title: object
        DescriptionHeader: object
        DescriptionLink: object
        DescriptionFooter: object
        LinkCodeTitle: object
        LinkCode: object
        Reload: typeof AdeButton
      }
      QRAccess: {
        QRTitle: object
        QRCode: typeof AdeQrCode
      }
    }

    LoadingText: typeof AuthScreenText
    ErrorText: typeof AuthScreenText
    LoggedIn: typeof AuthScreenText
  }
}
export interface AuthScreenTypeConfig extends Lightning.Component.TypeConfig {
  IsPage: true
}
export class AuthScreen
  extends Lightning.Component<AuthScreenTemplateSpec, AuthScreenTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<AuthScreenTemplateSpec>
{
  static defaultConfig = {
    width: 1920 - (MainMenu.widthClosed + 180),
    height: 1080 - 160,
    wordWrap: (1920 - (MainMenu.widthClosed + 180)) / 2 - 160,
  }
  Content = this.getByRef('Content')!
  Linking = this.Content.getByRef('Linking')!
  InstructionText = this.Linking.getByRef('InstructionText')!
  LinkCode = this.InstructionText.getByRef('LinkCode')!
  QRCode = this.Linking.getByRef('QRAccess')!.getByRef('QRCode')!
  DescriptionLink = this.InstructionText.getByRef('DescriptionLink')!
  Reload = this.InstructionText.getByRef('Reload')!
  LoadingText = this.Content.getByRef('LoadingText')!
  ErrorText = this.Content.getByRef('ErrorText')!
  LoggedIn = this.Content.getByRef('LoggedIn')!

  static override _template(): Lightning.Component.Template<AuthScreenTemplateSpec> {
    const theme = getStoredTheme()
    const {
      components: { SimpleCardConfig },
    } = theme
    return {
      x: 0,
      y: 0,
      w: 1920,
      h: 1080,
      Content: {
        x: 100 + MainMenu.widthClosed, // 80 margin left, menu, 40 margin right to hero.
        y: 0,
        w: this.defaultConfig.width,
        h: this.defaultConfig.height,
        Background: {
          x: 0,
          y: 80,
          h: (x: number) => x,
          w: (x: number) => x,
          rtt: true,
          rect: true,
          color: Colors(SimpleCardConfig.backgroundColor).get(),
          alpha: 0.8,
          shader: {
            type: Lightning.shaders.RoundedRectangle,
            radius: 8,
          },
        },
        Linking: {
          alpha: 0,
          QRAccess: {
            x: this.defaultConfig.width / 2 + 40,
            y: 160,
            w: this.defaultConfig.width / 2 - 120,
            flex: {
              direction: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            },
            QRTitle: {
              flexItem: {
                marginBottom: 24,
              },
              text: {
                text: 'QR Code',
                fontFace: 'Bold',
                fontSize: 48,
                wordWrap: false,
              },
            },
            QRCode: {
              type: AdeQrCode,
              size: 400,
            },
          },
          InstructionText: {
            x: 80,
            y: 160,
            h: this.defaultConfig.height - 80,
            w: this.defaultConfig.width / 2,
            flex: {
              direction: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            },
            Title: {
              text: {
                text: 'Link Your Device',
                fontFace: 'Bold',
                fontSize: 48,
                wordWrap: false,
              },
            },
            DescriptionHeader: {
              text: {
                text: 'Log into your account at:',
                fontFace: 'Text',
                fontSize: 24,
                lineHeight: 36,
                wordWrap: true,
                wordWrapWidth: this.defaultConfig.wordWrap,
              },
            },
            DescriptionLink: {
              flexItem: {
                marginTop: 24,
                marginBottom: 24,
              },
              text: {
                text: 'example.com/link',
                fontFace: 'Bold',
                fontSize: 36,
              },
            },
            DescriptionFooter: {
              text: {
                text: 'Enter the following code there to link this device to your account. Once you enter the code this page should update automatically',
                fontFace: 'Text',
                fontSize: 24,
                lineHeight: 36,
                wordWrap: true,
                wordWrapWidth: this.defaultConfig.wordWrap,
              },
            },
            LinkCodeTitle: {
              flexItem: {
                marginTop: 24,
              },
              text: {
                text: 'YOUR LINKING CODE',
                fontFace: 'Text',
                fontSize: 24,
                lineHeight: 36,
                wordWrap: true,
                wordWrapWidth: this.defaultConfig.wordWrap,
              },
            },
            LinkCode: {
              text: {
                text: '',
                fontFace: 'Bold',
                fontSize: 60,
                lineHeight: 36,
                wordWrap: true,
                wordWrapWidth: this.defaultConfig.wordWrap,
                letterSpacing: 12,
              },
            },
            Reload: {
              flexItem: {
                marginTop: 24,
              },
              type: AdeButton,
              buttonText: 'Reload Code',
              w: 200,
              h: 60,
              signals: {
                selected: '_reloadCode',
                left: '_focusMainMenu',
              },
            },
          },
        },
        LoadingText: {
          x: 80,
          y: 160,
          alpha: 0,
          h: this.defaultConfig.height - 160,
          w: this.defaultConfig.width - 160,
          type: AuthScreenText,
          title: 'Loading Link Code',
          description: `Getting a code we can use to link your account to this device`,
          wordWrap: this.defaultConfig.wordWrap,
          signals: {
            selected: '_reloadCode',
            left: '_focusMainMenu',
          },
        },
        ErrorText: {
          x: 80,
          y: 160,
          alpha: 0,
          h: this.defaultConfig.height - 160,
          w: this.defaultConfig.width - 160,
          type: AuthScreenText,
          title: 'An Error Occurred',
          description:
            'We are working to solve this issue, please try again later.',

          wordWrap: this.defaultConfig.wordWrap,
          buttonText: 'Try Again',
          hasButton: true,
          signals: {
            selected: '_reloadCode',
            left: '_focusMainMenu',
          },
        },
        LoggedIn: {
          x: 80,
          y: 160,
          alpha: 0,
          h: this.defaultConfig.height - 160,
          w: this.defaultConfig.width - 160,
          type: AuthScreenText,
          title: 'You Are Logged In',
          description:
            'This device has been linked to your account. You should now have access to the content you are looking for.',
          wordWrap: this.defaultConfig.wordWrap,
          buttonText: 'Log Out',
          hasButton: true,
          signals: {
            selected: '_logOut',
          },
        },
      },
    }
  }

  set loginUrl(url: string) {
    console.info('Got url for Description Link %s', url)
    this.DescriptionLink.patch({ text: { text: url } })
  }

  _linkInfo: StoreDeviceLink | null = null
  set linkInfo(info: StoreDeviceLink | null) {
    this._linkInfo = info
    if (info !== null) {
      this.deviceCode = info.linkCode
      this.loginUrl = info.linkUrl
      const url = info.directLinkUrl ?? info.linkUrl
      this.QRCode.patch({ url, alpha: 1 })
      this._pollForCode()
    } else {
      this.deviceCode = null
      this.loginUrl = ''
      this.QRCode.patch({ alpha: 0 })
    }
  }
  get linkInfo() {
    return this._linkInfo
  }

  _deviceCode: string | null = null
  set deviceCode(code: string | null) {
    if (code !== null) {
      this.LinkCode.patch({ text: { text: code } })
    }
    this._deviceCode = code
  }
  get deviceCode() {
    return this._deviceCode
  }

  _focusMainMenu(coords?: CoordinateDimensions | null) {
    if (coords != null && setsByY(this.widgets['mainmenu'])) {
      this.widgets['mainmenu'].setClosestByY(coords)
    }
    defer(() => {
      Router.focusWidget('MainMenu')
    })
  }
  _reloadCode() {
    this._linkInfo = null
    this._loadCode()
  }
  async _pollForCode() {
    if (this.linkInfo !== null) {
      try {
        const result = await this.fireAncestors(
          '$pollForCode',
          this.linkInfo.deviceId,
          this.linkInfo.linkCode,
          this.linkInfo.pollingInterval,
        )
        if (isPollerResponseCodeUpdate(result)) {
          this.linkInfo = { ...this.linkInfo, linkCode: result.linkCode }
          this._reloadCode()
        } else {
          this._setState('LoggedIn')
        }
      } catch (error) {
        console.error('Error getting linking code')
      }
    }
  }
  async _loadCode() {
    try {
      this._setState('Loading')
      this.linkInfo = await this.fireAncestors('$getDeviceCode')
      this._setState('Linking')
    } catch (error) {
      this.fireAncestors('$trackError', error)
      console.error(error)
      this._setState('Error')
    }
  }
  override _active() {
    this.stage.application.emit('screenView', 'Auth Screen', 'auth_screen')
  }
  override async _enable() {
    this.stage.application.on('authStatusChanged', this._authStatusChange)
    const authStatus = this.fireAncestors('$authStatus')
    this._authStatusChange(authStatus)
  }
  override async _disable() {
    this.stage.application.off('authStatusChanged', this._authStatusChange)
  }
  _openAnimation: Lightning.types.Animation | undefined = undefined
  get mainMenuOpenAnimation() {
    if (!this._openAnimation) {
      this._openAnimation = this.animation({
        duration: 0.2,
        actions: [
          {
            t: 'Content',
            p: 'x',
            v: { 0: MainMenu.widthClosed + 120, 1: MainMenu.width + 120 },
          },
        ],
      })
    }
    return this._openAnimation
  }

  _authStatusChange(status: AuthStatus) {
    debug.info('Got status change %s', status.status)
    if (status.status === 'user') {
      this._setState('LoggedIn')
    } else if (status.status !== 'notready') {
      this._setState('Loading')
      this._loadCode()
    }
  }
  override _construct() {
    this._authStatusChange = this._authStatusChange.bind(this)
    this._menuOpen = this._menuOpen.bind(this)
  }
  _menuOpen(open: boolean) {
    if (open) this.mainMenuOpenAnimation.start()
    else this.mainMenuOpenAnimation.stop()
  }
  override _init() {
    const menuOpen = this.fireAncestors('$menuOpen')
    this.Content.patch({
      x: menuOpen ? 120 + MainMenu.width : 120 + MainMenu.widthClosed,
    })
    this.stage.application.on('menuOpen', this._menuOpen)
  }
  override _getFocused(): Lightning.Component {
    return this.Reload
  }
  static override _states() {
    return [
      class Linking extends this {
        override $exit() {
          this.Linking.setSmooth('alpha', 0)
        }
        override $enter() {
          this.Linking.setSmooth('alpha', 1)
        }
        override _getFocused() {
          return this.Reload
        }
      },
      class Loading extends this {
        _loadingTimer: ReturnType<typeof setTimeout> | null = null
        override $exit() {
          if (this._loadingTimer !== null) {
            clearTimeout(this._loadingTimer)
            this._loadingTimer = null
          }
          this.LoadingText.setSmooth('alpha', 0)
        }
        override $enter() {
          this._loadingTimer = setTimeout(() => {
            this.LoadingText.setSmooth('alpha', 1)
          }, 300)
        }
      },
      class Error extends this {
        override $exit() {
          this.ErrorText.setSmooth('alpha', 0)
        }
        override $enter() {
          this.ErrorText.setSmooth('alpha', 1)
        }
        override _getFocused() {
          return this.ErrorText
        }
      },
      class LoggedIn extends this {
        override $exit() {
          this.LoggedIn.setSmooth('alpha', 0)
        }
        override $enter() {
          this.LoggedIn.setSmooth('alpha', 1)
        }
        override _getFocused() {
          return this.LoggedIn
        }
      },
    ]
  }
}
