import { ColorHue, ThemeConfig } from '@adiffengine/engine-types'
import { createPalette, createThemeConfig } from '../lib'

const darks: ColorHue = {
  50: '#e2e0e7',
  100: '#b6b3c2',
  200: '#858099',
  300: '#544d70',
  400: '#302652',
  500: '#0b0033',
  600: '#0a002e',
  700: '#080027',
  800: '#060020',
  900: '#030014',
}

const highlights: ColorHue = {
  50: '#EFF5A3',
  100: '#F0EC9E',
  200: '#F1E39A',
  300: '#F2DB95',
  400: '#F3D290',
  500: '#F4C98C',
  600: '#F5C087',
  700: '#F6B882',
  800: '#F7AF7E',
  900: '#F8A679',
}

export const poppyTheme: ThemeConfig = createThemeConfig({
  name: 'Poppy',
  palette: createPalette(
    { darks, highlights },
    {
      text: '#F9F9F9',
      buttonText: highlights[900],
      buttonHighlightText: darks[700],
      buttonBackground: darks[700],
      backgroundGradient: highlights[900],
    },
  ),
  logo: 'images/logos/logo@0.75x.png',
  logo_size: 400,
  fonts: {
    name: 'Poppins',
    Text: 'fonts/Poppins/Poppins-Regular.ttf',
    Regular: 'fonts/Poppins/Poppins-Medium.ttf',
    Bold: 'fonts/Poppins/Poppins-SemiBold.ttf',
    ExtraBold: 'fonts/Poppins/Poppins-Bold.ttf',
  },

  components: {
    VideoCardConfig: {
      radius: 24,
      textHighlightColor: highlights[50],
      vignetteColor: darks[400],
    },

    HeroWidgetItemConfig: {
      colorLeft: darks[400],
      colorRight: darks[900],
    },

    MainMenuButtonConfig: {
      backgroundColor: darks[400],
    },

    MainMenuConfig: {
      radius: 12,
      backgroundColor: darks[400],
    },
    SimpleCardConfig: {
      radius: 24,
      imageRadius: 12,
    },
    BoxCardConfig: {
      radius: 24,
      textHighlightColor: highlights[50],
    },
  },
})
