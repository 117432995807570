import { ContentSelectedDirectionalSignalMap } from '@adiffengine/engine-types'
import { Colors, Lightning } from '@lightningjs/sdk'
import { getStoredTheme } from '..'
import { cp } from '../lib'

export class WideButton
  extends Lightning.Component<WideButtonTemplateSpec, WideButtonTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<WideButtonTemplateSpec>
{
  Text = this.getByRef('Text')!

  static override _template() {
    const theme = getStoredTheme()
    return {
      h: 60,
      w: 300,
      Shadow: {
        alpha: 1,
        mountX: 0.5,
        mountY: 0.5,
        x: (w: number) => w / 2,
        y: (h: number) => h / 2,
        w: (w: number) => w + 16,
        h: (h: number) => h + 16,
        color: Colors('black').alpha(0.4).get(),
        rect: true,
        shader: { type: Lightning.shaders.FadeOut, fade: 32 },
      },
      Background: {
        w: (w: number) => w,
        h: (h: number) => h,
        x: 0,
        y: 0,
        rect: true,
        rtt: true,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: theme.components.ButtonConfig.radius,
        },
        color: Colors('buttonBackground').get(),
      },
      Text: {
        x: 0,
        y: 0,
        h: cp,
        w: cp,
        type: WideButtonText,
      },
    }
  }

  set leftText(leftText: string) {
    this.Text.patch({ leftText })
  }
  set rightText(rightText: string) {
    this.Text.patch({ rightText })
  }
  set rightTextStyle(
    rightTextStyle: Omit<Lightning.Element.PatchTemplate['text'], 'text'>,
  ) {
    this.Text.patch({ rightTextStyle })
  }
  set leftTextStyle(
    leftTextStyle: Omit<Lightning.Element.PatchTemplate['text'], 'text'>,
  ) {
    this.Text.patch({ leftTextStyle })
  }

  override _active() {
    this.Text.patch({ w: this.finalW })
  }
  set width(w: number) {
    this.Text.patch({ w })
  }

  override _focus() {
    this.Text.focus()
  }
  override _unfocus() {
    this.Text.unfocus()
  }
}
export interface WideButtonTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Background: object
  Text: typeof WideButtonText
  leftText: string
  width: number
  rightText: string
  rightTextStyle: Omit<Lightning.Element.PatchTemplate['text'], 'text'>
  leftTextStyle: Omit<Lightning.Element.PatchTemplate['text'], 'text'>
}
export interface WideButtonTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: ContentSelectedDirectionalSignalMap
}

export interface WideButtonTextTemplateSpec
  extends Lightning.Component.TemplateSpec {
  DefaultText: {
    DefaultLeftText: object
    DefaultRightText: object
  }
  FocusText: {
    FocusLeftText: object
    FocusRightText: object
  }

  leftText: string
  rightText: string
  rightTextStyle: Omit<Lightning.Element.PatchTemplate['text'], 'text'>
  leftTextStyle: Omit<Lightning.Element.PatchTemplate['text'], 'text'>
}

export type WideButtonTextTypeConfig = Lightning.Component.TypeConfig

export class WideButtonText
  extends Lightning.Component<
    WideButtonTextTemplateSpec,
    WideButtonTextTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<WideButtonTextTemplateSpec>
{
  _focusAnimation: Lightning.types.Animation | undefined

  DefaultText = this.getByRef('DefaultText')!
  LeftText = this.DefaultText.getByRef('DefaultLeftText')!
  RightText = this.DefaultText.getByRef('DefaultRightText')!
  FocusText = this.getByRef('FocusText')!
  FocusLeftText = this.FocusText.getByRef('FocusLeftText')!
  FocusRightText = this.FocusText.getByRef('FocusRightText')!

  static leftText: Lightning.Element.PatchTemplate = {
    flexItem: {
      marginLeft: 24,
    },
    text: {
      text: '',
      fontFace: 'Regular',
      fontSize: 18,
      lineHeight: 48,
      verticalAlign: 'middle',
      textAlign: 'left',
      wordWrap: true,
      wordWrapWidth: 400,
      maxLines: 1,
    },
  }
  static rightText: Lightning.Element.PatchTemplate = {
    flexItem: {
      marginRight: 24,
    },
    text: {
      text: '',
      fontFace: 'Text',
      fontSize: 16,
      lineHeight: 36,
      verticalAlign: 'middle',
      textAlign: 'right',
    },
  }

  static override _template(): Lightning.Component.Template<WideButtonTextTemplateSpec> {
    const theme = getStoredTheme()
    return {
      DefaultText: {
        h: 60,
        w: cp,
        x: 0,
        y: 0,
        color: Colors('buttonBackground').get(),
        rect: true,
        rtt: true,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: theme.components.ButtonConfig.radius,
        },
        flex: {
          direction: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        },
        DefaultLeftText: {
          ...this.leftText,
          color: Colors('buttonText').get(),
        },
        DefaultRightText: {
          ...this.rightText,
          color: Colors('buttonText').get(),
        },
      },
      FocusText: {
        h: 60,
        w: cp,
        x: 0,
        y: 0,
        alpha: 0,
        color: Colors('primaryHighlight').get(),
        rect: true,
        rtt: true,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: theme.components.ButtonConfig.radius,
        },
        flex: {
          direction: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        },
        FocusLeftText: {
          ...this.leftText,
          color: Colors('buttonHighlightText').get(),
        },
        FocusRightText: {
          ...this.rightText,
          color: Colors('buttonHighlightText').get(),
        },
      },
    }
  }
  override _init() {
    this.DefaultText.patch({ w: this.w })
    this.FocusText.patch({ w: this.w })
  }
  set leftText(text: string) {
    this.LeftText.patch({ text: { text } })
    this.FocusLeftText.patch({ text: { text } })
  }
  set rightText(text: string) {
    this.RightText.patch({ text: { text } })
    this.FocusRightText.patch({ text: { text } })
  }
  set rightTextStyle(
    text: Omit<Lightning.Element.PatchTemplate['text'], 'text'>,
  ) {
    this.RightText.patch({ text })
    this.FocusRightText.patch({ text })
  }
  set leftTextStyle(
    text: Omit<Lightning.Element.PatchTemplate['text'], 'text'>,
  ) {
    this.RightText.patch({ text })
    this.FocusRightText.patch({ text })
  }

  get focusAnimation(): Lightning.types.Animation {
    if (!this._focusAnimation) {
      this._focusAnimation = this.animation({
        duration: 0.2,
        actions: [
          {
            t: 'DefaultText',
            p: 'alpha',
            v: {
              0: 1,
              1: 0,
            },
          },
          {
            t: 'FocusText',
            p: 'alpha',
            v: {
              0: 0,
              1: 1,
            },
          },
        ],
      })
    }
    return this._focusAnimation
  }
  focus() {
    this.focusAnimation.start()
  }
  unfocus() {
    this.focusAnimation.stop()
  }
}
