import { Lightning } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import isEqual from 'fast-deep-equal/es6'
import defer from 'lodash/defer'
import { Debugger, cp, getBestDimensionValue } from '../lib'
import {
  CardConfig,
  ContentCardConstructor,
  HeaderList,
  HeaderListTemplateSpec,
} from './HeaderList'
import { SongCard } from './SongCard'
const debug = new Debugger('HeaderListGrid')

export type HeaderListGridRow = Pick<HeaderListTemplateSpec, 'items' | 'title'>

export interface HeaderListGridTemplateSpec
  extends Lightning.Component.TemplateSpec {
  List: typeof List
  spacing: number
  itemType: ContentCardConstructor
  cardConfig: Partial<CardConfig>
  items: HeaderListGridRow[]
}
export interface HeaderListGridTypeConfig
  extends Lightning.Component.TypeConfig {}
export class HeaderListGrid
  extends Lightning.Component<
    HeaderListGridTemplateSpec,
    HeaderListGridTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<HeaderListGridTemplateSpec>
{
  static spacing = 20
  List = this.getByRef('List')
  static override _template(): Lightning.Component.Template<HeaderListGridTemplateSpec> {
    return {
      x: 0,
      y: 0,
      w: 1920 - 160,
      List: {
        x: 0,
        y: 0,
        w: cp,
        itemType: HeaderList,
        type: List,
        direction: 'column',
        spacing: 20,
      },
    }
  }
  _repo() {
    debug.info('repo')
  }
  private _items: HeaderListGridRow[] = []

  set items(items: HeaderListGridRow[]) {
    if (!isEqual(items, this._items)) {
      this._items = items
      this.patchList()
    }
  }
  get items() {
    return this._items
  }
  private _spacing: number = HeaderList.spacing
  set spacing(spacing: number) {
    if (this._spacing !== spacing) {
      this._spacing = spacing
      this.patchList()
    }
  }
  get spacing(): number {
    return this.List.spacing
  }

  private _config: Partial<CardConfig> = {}
  set cardConfig(config: Partial<CardConfig>) {
    const updatedConfig = { ...this.cardConfig, ...config }
    if (!isEqual(updatedConfig, this.cardConfig)) {
      this._config = updatedConfig
      this.patchList()
    }
  }

  get cardConfig(): Partial<CardConfig> {
    return this._config
  }

  private _itemType: ContentCardConstructor = SongCard
  set itemType(itemType: ContentCardConstructor) {
    if (this._itemType !== itemType) {
      this._itemType = itemType
      this.patchList()
    }
  }
  get itemType() {
    return this._itemType
  }

  patchList() {
    if (this.items.length > 0 && this.itemType) {
      this.List.patch({
        h: getBestDimensionValue(this, 'h'),
        w: getBestDimensionValue(this, 'w'),

        // note if you put this last, it should pick up the above
        items: this.items.map(i => ({
          cardConfig: this.cardConfig,
          spacing: this.spacing,
          itemType: this.itemType,
          signals: {
            reposition: () => {
              debug.info('Got repo signal')
              this.List.reposition()
            },
          },
          ...i,
        })),
      })
      defer(() => {
        this.List.reposition()
      })
    } else {
      this.List.clear()
    }
  }
}
