/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ThorApp } from '@adiffengine/elements'
import {
  AdeErrorPage,
  AdePlayerPage,
  AdeSplashScreen,
  AdvancedWideCard,
  Debugger,
  DevScreen,
  GridHome,
  MovieDetailsPage,
  NotFoundPage,
  SearchPage,
  ThorError,
  appErrorWrapper,
} from '@adiffengine/elements'
import { ID } from '@adiffengine/engine-types'
import { Router } from '@lightningjs/sdk'

const commonWidgets: (keyof Router.WidgetContainer)[] = [
  'errormodal',
  'confirmmodal',
]
const menuCommonWidget: (keyof Router.WidgetContainer)[] = [
  'mainmenu',
  ...commonWidgets,
]

import { MituBootPage } from './components/mitu_boot_page'
import { getMituSdk } from './mitu-sdk'
import { MituSettingsPage } from './mitu_settings_page'
// @ts-ignore
const debug = new Debugger('CONF')

export function getRouteConfig(
  app: ThorApp,
  rootPath: string | null,
): Router.Config {
  const root: string = rootPath === null ? 'home' : rootPath

  return {
    root,
    beforeEachRoute: async (from, to) => {
      app.breadcrumb({
        type: 'route_change',
        data: {
          from,
          to,
        },
      })
      if (from) {
        try {
          if (from.indexOf('player') === 0 && to.hash.indexOf('player') !== 0) {
            if (app && (app as any).displayVideo) {
              ;(app as any).displayVideo = false
            }
            if (app && (app as any).$setHeroWidgetImage) {
              ;(app as any).$setHeroWidgetImage(null)
            }
          }
        } catch (error) {
          console.error('Error ensuring player is closed')
        }
      }
      return true
    },

    routes: [
      { path: '$', component: MituBootPage },
      { path: 'loading', component: MituBootPage },
      {
        path: '*',
        component: NotFoundPage,
      },
      {
        path: '!',
        component: AdeErrorPage,
      },
      {
        path: 'error',
        component: AdeErrorPage,
      },
      {
        path: 'search',
        component: SearchPage,
        widgets: menuCommonWidget,
      },
      {
        path: 'dev',
        component: DevScreen,
        // widgets: ['endcard'],
      },
      {
        path: 'splash',
        component: AdeSplashScreen,
      },
      {
        path: 'settings',
        component: MituSettingsPage,
        widgets: menuCommonWidget,
        options: {
          clearHistory: true,
        },
      },
      {
        path: 'home',
        component: GridHome,
        before: appErrorWrapper<GridHome>(async (page: GridHome) => {
          page.page = 'Home'
          const mituSdk = getMituSdk()
          page.card = 'wide'
          page.content = await mituSdk.grid({
            params: { type: 'all' },
          })
        }),
        // @ts-ignore
        widgets: menuCommonWidget,
        options: {
          clearHistory: true,
        },
      },
      {
        path: 'details/movie/:id',
        component: MovieDetailsPage,
        options: {
          reuseInstance: false,
        },
        before: appErrorWrapper<MovieDetailsPage>(
          async (page: MovieDetailsPage, params) => {
            if (!params?.id)
              throw new ThorError(
                'No ID passed to movie details page',
                ThorError.Type.ParameterError,
                { params, page: 'details' },
              )
            const mituSdk = getMituSdk()
            const content = await mituSdk.details(params.id as ID)
            // Set this first to be safer.
            page.cardType = AdvancedWideCard
            page.page = `Movie Details -  ${content.title}`
            page.content = content
          },
        ),
        cache: 1000 * 60 * 15,
      },
      {
        path: 'player/movie/:id',
        component: AdePlayerPage,
        before: appErrorWrapper<AdePlayerPage>(
          async (page: AdePlayerPage, params) => {
            if (!params?.id)
              throw new ThorError(
                'No ID passed to movie player page',
                ThorError.Type.ParameterError,
                { params, page: 'player' },
              )

            const mituSdk = getMituSdk()
            const content = await mituSdk.details(params.id!)

            if (!Array.isArray(content.media) || content.media.length === 0) {
              throw new ThorError(
                `No media for ${content.title}`,
                ThorError.Type.NoMedia,
                { content, params },
              )
            }
            page.page = `PlayerPage -  ${content.title}`
            page.setPlayerPayload({ content, media: content.media[0] })
          },
        ),
        options: { preventStorage: true },
        widgets: ['videoplayer', ...commonWidgets],
      },
    ],
  }
}
